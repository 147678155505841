import {observer} from "mobx-react-lite";
import {TableStore} from "../../stores/table_store";
import styled from "styled-components";
import {SP} from "../../theme";

export const GridscopeStaticTable = observer(({cols, rows}: { cols: any[], rows: any[][] }) => {
    return <TableContainer>
        <thead>
        <tr>
            {cols.map((c: string, i) => <th key={i}>{c}</th>)}
        </tr>
        </thead>
        <tbody>
        {rows.map((r, ri) =>
            <tr key={ri}>{r.map((c, rc) => <td key={`td-${ri}-${rc}`}>{c}</td>)}</tr>
        )}
        </tbody>
    </TableContainer>;
});

export const GridscopeTable = observer(({store}: { store: TableStore }) => {
    return <TableContainer ref={(ref) => store.setRef(ref)}>
        <thead>
        <tr>
            {store.header?.map((c: string, i) => <th key={i}>{c}</th>)}
        </tr>
        </thead>
        <tbody>
        {store.rows
            .map((r, ri) =>
                <tr key={ri}>{r.map((c, rc) => <td key={`td-${ri}-${rc}`}>{c}</td>)}</tr>
            )}
        </tbody>
    </TableContainer>;
});

const TableContainer = styled.table`
  border-collapse: collapse;
  background-color: white;
  border-style: hidden;
  width: 100%;

  tr {
    position: relative;
    border-bottom: solid 1px ${({theme}: SP) => theme.shade2};
    border-top: solid 1px ${({theme}: SP) => theme.shade2};
  }

  th, td {
    position: relative;
    border-left: solid 1px ${({theme}: SP) => theme.shade1};
    border-right: solid 1px ${({theme}: SP) => theme.shade1};
    padding: 2px 4px 2px 4px;
    text-align: start;
    font-size: 13px;
  }

  thead {
    position: sticky;
    top: 0;
    background-color: ${({theme}: SP) => theme.shade2};
    z-index: 10;
  }

  th {
    height: 22px;
    font-size: 15px;
  }
`;