import {observer} from "mobx-react-lite";
import {useContext, useEffect, useMemo, useState} from "react";
import {ApiContext, GridscopeStoreContext} from "../../../app";
import {UploadStore} from "../../../stores/upload_store";
import styled, {useTheme} from "styled-components";
import {UFileUploadCard, UploadArea, UploadStoreContext} from "../../../components/upload_area";
import {Card, UploadCard} from "../../../components/card";
import {LoadingOverlay, LoadingOverlayAction} from "../../../components/overlays";
import {gridscope_view} from "../../../proto/compiled";
import {Theme} from "../../../theme";
import {DataFileSummary} from "../../../components/data_file_summary";
import {NoScrollbar} from "../../../styles/gridscope";
import {DataGroupSummary} from "../../../components/data_group_summary";
import {ResultGroupSummary} from "../../../components/result_group_summary";

export const ProcessingData = observer(() => {
    const store = useContext(GridscopeStoreContext)!;
    const [fingerprint, setFingerprint] = useState<string | undefined>();
    const api = useContext(ApiContext);
    const uploadStore = useMemo(() => new UploadStore(api, new Set(['csv', 'txt', 'zip', 'tsv'])), [api]);
    const action = useMemo(() => new LoadingOverlayAction(), []);
    useEffect(() => () => uploadStore.dispose(), [uploadStore]);
    const theme = useTheme() as Theme;

    return <UploadStoreContext.Provider value={uploadStore}>
        <Container>
            <LoadingOverlay action={action} untrackedLoading={!!store.project.measurement?.processing}
                            overlayStyle={{borderRadius: theme.dimens.radius3}}>
                <UploadCard clickable={!fingerprint && !store.project.measurement?.sourceFile}>
                    {!store.project.measurement?.sourceFile && <UploadArea
                        onUploadStart={(f) => setFingerprint(f)}
                        onUploadDone={(f) => {
                            action.trigger(store.project.measurementFileUploaded(f));
                        }}
                        onCancel={() => setFingerprint(undefined)}
                    />}
                    {store.project.measurement?.sourceFile &&
                        <UFileUploadCard file={store.project.measurement.sourceFile}/>}
                </UploadCard>
            </LoadingOverlay>
            <ScrollContainer>
                {store.project.data?.groups && store.project.data.groups.map((group: any, i) =>
                    <DataGroupSummary
                        key={'dfg-' + group.id}
                        group={store.global.dataFilesGroups.get(group.id)!}/>)}
                {store.project.data?.ungroupedFiles && store.project.data.ungroupedFiles.map((id: string, i) =>
                    <DataFileSummary
                        key={'dfs-' + id}
                        file={store.global.dataFiles.get(id)!}/>)}
                {store.project.view.results?.results && store.project.view.results.results.filter((r: gridscope_view.IProjectResult) => !!store.global.results.get(r.id!)?.results?.length).map((result: gridscope_view.IProjectResult, i) =>
                    <ResultGroupSummary
                        key={'dgs-' + result.id}
                        resultId={result.id!}/>)}
            </ScrollContainer>
        </Container>
    </UploadStoreContext.Provider>
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0;
  padding: 0 32px 0 32px;
`;

const ScrollContainer = styled.div`
  ${NoScrollbar};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0;
  overflow: auto;
  margin-top: 8px;
}`;