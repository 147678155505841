import {inputs, gridscope_types} from "./proto/compiled";
import {makeAutoObservable} from "mobx";

export abstract class GridscopeTypes {
    static makeInputObservable(input: inputs.IInput) {
        const defaultDate = () => ({
            day: undefined,
            month: undefined,
            year: undefined,
            hour: undefined,
            minute: undefined,
            rDay: gridscope_types.GTERelation.eq,
            rMonth: gridscope_types.GTERelation.eq,
            rYear: gridscope_types.GTERelation.eq,
        });
        if (input.repeated) {
            if (!input.values) input.values = [];
            input.values.map((v) => {
                makeAutoObservable(v);
                if (input.type === inputs.InputType.date) {
                    if (!v.date) v.date = defaultDate();
                    makeAutoObservable(v.date);
                }
                return undefined;
            })
        } else {
            if (!input.value) {
                input.value = inputs.InputValue.create();
            }
            if (input.type === inputs.InputType.date || input.type === inputs.InputType.dateTime) {
                if (!input.value.date) {
                    input.value.date = defaultDate();
                }
                makeAutoObservable(input.value.date);
            }
            makeAutoObservable(input.value);
        }
    }

    static Date(date: Date): gridscope_types.Date {
        return gridscope_types.Date.create({
            day: date.getDate(),
            month: date.getMonth() + 1,
            year: date.getFullYear(),
            hour: date.getHours(),
            minute: date.getMinutes(),
            ts: date.getTime(),
        });
    }
}