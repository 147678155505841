import styled, {useTheme} from "styled-components";
import React, {CSSProperties, useEffect, useMemo, useState} from "react";
import {observer} from "mobx-react-lite";
import {SP, Theme} from "../theme";
import {HashLoader} from "react-spinners";
import useWave from "use-wave";
import {MdFirstPage, MdLastPage, MdNavigateNext, MdNavigateBefore} from "react-icons/md"
import {Action} from "../utils";

export const OverlayContainer = styled.div<{ hidden?: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  opacity: ${({hidden}) => hidden ? 0 : 1};
  pointer-events: ${({hidden}) => hidden ? 'none' : 'auto'};
  transition: opacity 200ms ease;
`;

export const GridscopeLoadingOverlay = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: ${({visible}) => visible ? 'all' : 'none'};
  opacity: ${({visible}) => visible ? '1' : '0'};
  transition: opacity 100ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 700;
  background: linear-gradient(270deg, rgba(150, 150, 150, 0.5), rgba(255, 255, 255, 0.6), rgba(150, 150, 150, 0.5));
  background-size: 200% 200%;

  animation: LoadingBg 2s linear infinite;

  @keyframes LoadingBg {
    0% {
      background-position: 200% 200%;
    }
    100% {
      background-position: 0 0;
    }
  }
`;

export class LoadingOverlayAction<T> extends Action<Promise<T>, Promise<T>> {
}

export const LoadingOverlay = observer(({
                                            children,
                                            action,
                                            style,
                                            untrackedLoading,
                                            overlayStyle
                                        }: { children: any, untrackedLoading?: boolean, overlayStyle?: CSSProperties, style?: CSSProperties, action?: LoadingOverlayAction<any> }) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => action?.register((action) => {
        setLoading(true);
        action.then(() => setLoading(false));
        return action;
    }), []);

    return <div style={{position: 'relative', ...(style ?? {})}}>
        {children}
        <GridscopeLoadingOverlay visible={loading || !!untrackedLoading} style={overlayStyle}/>
    </div>
})

export const DropdownContainer = styled.div`
  position: relative;
  background-color: white;
  border-radius: ${({theme}: SP) => theme.dimens.radius2};
  box-shadow: 0 0.25em 0.5em rgba(0, 45, 98, .3);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;

export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  width: 100%;
  padding: 0 8px 0 8px;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: solid 1px ${({theme}: SP) => theme.shade1};

  :hover {
    background-color: ${({theme}: SP) => theme.shade2};
  }
`;


const PaginationButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({theme}: SP) => theme.primary};
  font-size: 20px;
  height: 30px;
  width: 33px;

  :hover {
    background-color: ${({theme}: SP) => theme.content5};
  }
`;

const PaginationContainer = styled.div`
  background-color: white;
  box-shadow: 0 0 0.3em rgba(0, 45, 98, .3);
  height: 30px;
  border-radius: ${({theme}: SP) => theme.dimens.radius1};
  border: solid 1px ${({theme}: SP) => theme.primary};
  display: flex;
  overflow: hidden;
`;

const PaginationInput = styled.input.attrs({type: 'number'})`
  border: none;
  outline: none;
  text-align: center;
  border-radius: ${({theme}: SP) => theme.dimens.radius3};
  background-color: ${({theme}: SP) => theme.shade2};
  width: 30px;
  margin: 4px;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
`;

interface PaginationProps {
    onNext: () => any;
    onPrev: () => any;
    onLast: () => any;
    onFirst: () => any;
    onChange: (p: number) => any;
    current: number;
    pages: number;
}

export const Pagination = (props: PaginationProps) => {
    const wave = useWave();

    return <PaginationContainer>
        <PaginationButton
            onClick={(e) => props.onFirst()}
            ref={wave}>
            <MdFirstPage/>
        </PaginationButton>
        <PaginationButton
            onClick={(e) => props.onPrev()}
            ref={wave}>
            <MdNavigateBefore/>
        </PaginationButton>
        <PaginationInput
            min={1}
            max={props.pages + 1}
            value={props.current + 1}
            onFocus={(e) => e.target.select()}
            onChange={(e) => {
                const page = e.target.valueAsNumber - 1;
                if (isNaN(page) || page > (props.pages) || page < 0) {
                    e.preventDefault();
                    setTimeout(() => e.target.select(), 0);
                } else {
                    props.onChange(page);
                }
            }}/>
        <span style={{lineHeight: '30px', marginRight: '4px'}}>od {(props.pages) + 1}</span>
        <PaginationButton
            onClick={(e) => props.onNext()}
            ref={wave}>
            <MdNavigateNext/>
        </PaginationButton>
        <PaginationButton
            onClick={(e) => props.onLast()}
            ref={wave}>
            <MdLastPage/>
        </PaginationButton>
    </PaginationContainer>
}