import {gridscope_data, gridscope_view} from "../proto/compiled";
import Papa, {Parser, ParseResult, ParseStepResult} from "papaparse";
import {readRemoteFile} from "react-papaparse/dist/readRemoteFile";
import {makeAutoObservable} from "mobx";

export class TableStore {
    ref?: HTMLTableElement;
    dataFile: gridscope_data.IDataFile | gridscope_view.IResultFile;
    header?: string[];
    private _rows?: string[][];
    private parser?: Parser;
    private completed = false;
    private canLoadMore = false;

    constructor(dataFile: gridscope_data.IDataFile | gridscope_view.IResultFile) {
        this.dataFile = dataFile;
        Papa.parse(this.dataFile.url!, {
            download: true,
            chunkSize: 1024 * 10,
            error: (error: Error, file: string) => {
                console.log('error', error, file);
            },
            complete: (results: ParseResult<string[]>, file: string) => {
                this.completed = true;
            },
            chunk: (c, p) => this.onChunk(c, p)
        });
        makeAutoObservable(this);
    }

    setRef(ref: HTMLTableElement | null) {
        this.ref = ref ?? undefined;
    }


    get loaded() {
        return !!this._rows;
    }

    get rows(): string[][] {
        if (!this._rows) return [];
        return this._rows;
    }

    onChunk(c: ParseResult<string[]>, p: Parser) {
        if (this._rows === undefined) {
            this._rows = c.data;
        } else {
            this._rows.push(...c.data);
        }
        if (!this.header && this._rows.length) {
            const csvHeader = this._rows.splice(0, 1)[0];
            if (Array.isArray(this.dataFile.header) && this.dataFile.header.length === csvHeader.length) {
                this.header = this.dataFile.header;
            } else {
                this.header = csvHeader;
            }
        }
        p.pause();
        this.canLoadMore = true;
        this.parser = p;
    }

    dispose() {
        this.parser?.abort();
    }

    loadMore() {
        if (this.canLoadMore) {
            this.canLoadMore = false;
            this.parser?.resume();
        }
    }
}