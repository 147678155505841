import {observer} from "mobx-react-lite";
import styled, {useTheme} from "styled-components";
import {SP, Theme} from "../theme";
import {useContext} from "react";
import {ApiContext, AuthStoreContext} from "../app";
import {MdPerson, MdArrowDropDown} from "react-icons/md";
import useWave from "use-wave";
import {DropdownAnything} from "dropdown_anything";
import {DropdownContainer} from "./overlays";
import {MdLogout} from "react-icons/md";
import {useNavigate} from "react-router-dom";
import {GridscopeRoutes} from "../strings";

const ProfileIndicatorContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  border-left: solid 1px rgba(255, 255, 255, 0.1);
  margin-left: auto;
  padding: 0 16px 0 16px;
  height: 42px;
  ${({theme}: SP) => theme.textTheme.button.css};
  color: ${({theme}: SP) => theme.content};
  cursor: ${({disabled}: SP) => disabled ? 'auto' : 'pointer'};
  pointer-events: ${({disabled}: SP) => disabled ? 'none' : 'auto'};
  border-radius: 10px;

  :hover {
    background-color: ${({theme, disabled}: SP) => disabled ? 'transparent' : theme.content10};
  }
`;

const DDButtonContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  border: solid 1px ${({theme}: SP) => theme.content5};
  font-size: 13px;
  font-weight: bold;

  :hover {
    background-color: ${({theme}: SP) => theme.content10};
  }
`;

export const ProfileIndicator = observer(() => {
    const wave = useWave();
    const api = useContext(ApiContext);
    const navigate = useNavigate();
    return <DropdownAnything
        barrierDismissible={true}
        builder={() => <DropdownContainer style={{overflow: 'hidden'}}>
            <DDButtonContainer ref={wave} onClick={() => {
                navigate(GridscopeRoutes.index);
                api.setAuth(null);
                window.location.reload();
            }}>
                <MdLogout style={{
                    position: 'absolute',
                    left: '16px',
                    height: '100%'
                }}/><span style={{marginLeft: '42px'}}>ODJAVA</span>
            </DDButtonContainer>
        </DropdownContainer>}
        triggerBuilder={(isOpen, setIsOpen) => <ProfileIndicatorTrigger isOpen={isOpen} setIsOpen={setIsOpen}/>}
    />
});

const ProfileIndicatorTrigger = observer(({isOpen, setIsOpen}: { isOpen: boolean, setIsOpen: (v: boolean) => any }) => {
    const store = useContext(AuthStoreContext);
    const wave = useWave();
    const theme = useTheme() as Theme;
    return <ProfileIndicatorContainer ref={wave} onClick={() => setIsOpen(!isOpen)} style={{
        backgroundColor: isOpen ? theme.content10 : undefined
    }}>
        <MdPerson style={{marginRight: '8px', fontSize: '23px'}}/>
        {store.profile?.name}
        <MdArrowDropDown style={{marginLeft: '8px'}}/>
    </ProfileIndicatorContainer>
});