import {observer} from "mobx-react-lite";
import {gridscope_data, gridscope_view} from "../proto/compiled";
import styled, {useTheme} from "styled-components";
import {Card} from "./card";
import {SP, Theme} from "../theme";
import {DateFormatters} from "../utils";
import {CSSProperties, useContext, useEffect, useMemo} from "react";
import {GridscopeStoreContext} from "../app";
import {GridscopeCollapsible} from "./collapsible";
import {autorun, makeAutoObservable} from "mobx";
import useWave from "use-wave";
import {MdCheck, MdDelete, MdDragIndicator, MdEdit, MdExpandMore} from "react-icons/md";
import {GridscopeStaticTable} from "./table/gridscope_table";
import {GridscopeStore} from "../stores/gridscope_store";
import {YesNoDialog} from "./dialogs";
import {DataFileRowInnerPadding} from "./data_file_summary";
import {ClassicTextInput} from "./common_inputs/input";
import {IconButtonContainer} from "./buttons";
import {proto} from "../proto/messages";

export const ResultGroupSummary = observer(({
                                                resultId,
                                            }: { resultId: string }) => {
    const theme = useTheme() as Theme;
    const store = useContext(GridscopeStoreContext)!;
    const state = useMemo(() => {
        const s = {
            expanded: false
        };
        makeAutoObservable(s);
        return s;
    }, []);

    const result = store.global.results.get(resultId);
    return <Container>
        <GroupSummaryHeader group={result!} expanded={state.expanded} onClick={() => state.expanded = !state.expanded}/>
        <GridscopeCollapsible expanded={state.expanded && result?.headerData?.collapsible !== false}>
            <span style={{width: '100%', height: '1px', backgroundColor: theme.shade1}}/>
            <GridscopeStaticTable
                cols={['Name', 'Series present', 'Count']}
                rows={result!.results!.map((df: gridscope_view.IResultFile) => {
                    return [
                        df.label,
                        df.header,
                        df.count,
                    ];
                })}/>
        </GridscopeCollapsible>
    </Container>;
});

const GroupSummaryHeader = observer(({
                                         group,
                                         expanded,
                                         onClick
                                     }: { group: gridscope_view.IProjectResult, expanded: boolean, onClick: () => any }) => {
    const theme = useTheme() as Theme;
    const wave = useWave();
    const store = useContext(GridscopeStoreContext) as GridscopeStore;
    const state = useMemo(() => {
        const s = {
            editing: false,
            label: group.name ?? '',
        };
        makeAutoObservable(s);
        return s;
    }, []);
    useEffect(() => autorun(() => group.name = state.label), []);
    const save = () => {
        state.editing = false;
        store.api.sendMessage(proto.TxSaveModuleResult.create({
            id: group.id,
            name: state.label
        }));
    };

    const getDataRows = () => {
        let remainingFlex = 5;

        const rows = [];

        for (const detail of group.headerData?.details ?? []) {
            remainingFlex--;
            rows.push(
                <DataFileRow key={remainingFlex}>
                    <DataFileRowInnerPadding>
                        <DataFileLabel>{detail.label}</DataFileLabel>
                        <DataFileValue>{detail.value}</DataFileValue>
                    </DataFileRowInnerPadding>
                </DataFileRow>
            );
        }
        rows.push(
            <DataFileRow key={remainingFlex} style={{flexGrow: remainingFlex}}>
                <DataFileRowInnerPadding>
                    <DataFileLabel>start date</DataFileLabel>
                    <DataFileValue>{DateFormatters.dateTime(new Date(group.dateCreated!.ts as number))}</DataFileValue>
                </DataFileRowInnerPadding>
            </DataFileRow>
        );
        return rows;
    }

    return <DataFileContainer onClick={() => onClick()}
                              style={{cursor: !group.headerData?.collapsible ? 'auto' : 'pointer'}}>
        <DataFileRow style={{backgroundColor: theme.primary, width: '230px', flexGrow: '0'}}>
            <DataFileRowInnerPadding>
                <DataFileLabel style={{color: theme.neutral}}>
                    {group.headerData?.collapsible && <MdExpandMore style={{
                        fontSize: '24px',
                        marginRight: '8px',
                        transition: 'all 300ms',
                        transform: `rotate(${expanded ? '180' : '0'}deg)`
                    }}/>}
                    {!group.headerData?.collapsible && <MdDragIndicator style={{
                        fontSize: '16px',
                        marginRight: '12px',
                        marginLeft: '4px',
                    }}/>}
                    <ClassicTextInput
                        disabled={!state.editing}
                        onSubmit={() => save()}
                        value={state.label} onChanged={(v) => state.label = v}
                        style={{...theme.textTheme.headline4.style(), color: 'white'}}
                    />
                    <IconButtonContainer ref={wave} onClick={(e) => {
                        if (state.editing) {
                            save();
                        } else {
                            state.editing = true;
                        }
                        e.stopPropagation();
                        e.preventDefault();
                    }}>
                        {state.editing && <MdCheck/>}
                        {!state.editing && <MdEdit/>}
                    </IconButtonContainer>
                </DataFileLabel>
            </DataFileRowInnerPadding>
        </DataFileRow>
        <DataFileRow>
            <DataFileRowInnerPadding>
                <DataFileLabel>data type</DataFileLabel>
                <DataFileValue>{group.dataType}</DataFileValue>
            </DataFileRowInnerPadding>
        </DataFileRow>
        {getDataRows()}
        <div ref={wave} onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            store.dialogs.push(<YesNoDialog result={(v) => {
                if (v) {
                    store.deleteModuleResult(group.id!);
                }
                store.dialogs.pop();
            }}>
                Are you sure?
            </YesNoDialog>)
        }} style={{
            display: 'flex',
            alignItems: 'center',
            borderLeft: 'solid 1px ' + theme.content10,
            padding: '0 16px 0 16px',
        }}>
            <MdDelete/>
        </div>
    </DataFileContainer>
});

const Container = styled(Card).attrs({clickable: false})`
  height: auto;
  flex-direction: column;
  box-shadow: none;
  border: solid 1px ${({theme}: SP) => theme.shade2};
  margin-bottom: 4px;
`;


const DataFileContainer = styled(Card).attrs({clickable: false})`
  height: auto;
  flex-direction: row;
  border-radius: 0;
  cursor: pointer;
  box-shadow: none;
  flex-shrink: 0;
`;

const DataFileRow = styled.div`
  display: flex;
  flex-grow: 1;
  width: 0;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
`;

const DataFileLabel = styled.span`
  ${({theme}: SP) => theme.textTheme.headline4.css};
  display: flex;
  align-items: center;
`;

const DataFileValue = styled.span`
`;
