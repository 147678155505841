import {observer} from "mobx-react-lite";
import styled, {useTheme} from "styled-components";
import {SP, Theme} from "../theme";
import {useContext, useMemo} from "react";
import {LoadingOverlay, LoadingOverlayAction} from "../components/overlays";
import {Navigate, useSearchParams} from "react-router-dom";
import Logo from "../assets/logo_primary.png";
import {AuthStoreContext} from "../app";
import {GridscopeTextInput} from "../components/common_inputs/text_input";
import {GridscopeInputField} from "../components/common_inputs/input";
import {GridscopeButton} from "../components/buttons";
import {MdPerson, MdLock} from "react-icons/md";
import useWave from "use-wave";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({theme}: SP) => theme.dark};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 400px;
  padding: 16px;
  background-color: ${({theme}: SP) => theme.neutral};
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.08);
  border-radius: ${({theme}: SP) => theme.dimens.radius2};
`;

export const Login = observer(() => {
    const [params, setParams] = useSearchParams()
    const store = useContext(AuthStoreContext);
    const theme = useTheme() as Theme;
    const wave = useWave();
    const action = useMemo(() => new LoadingOverlayAction(), []);
    return store.authenticated ? <Navigate to={params.get('from') || '/'}/> : <Container>
        <LoadingOverlay
            action={action}
            overlayStyle={{
                borderRadius: '10px'
            }}>
            <LoginContainer onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}>
                <img src={Logo} style={{height: '60px', margin: '16px 0 16px'}} alt={'logo'}/>
                <div style={{marginTop: 'auto', marginBottom: '24px', width: '100%'}}>
                    <GridscopeInputField
                        label={'UPORABNIŠKO IME'}
                        icon={<MdPerson/>}
                        input={
                            <GridscopeTextInput invalid={!!store.errorText} value={store.username}
                                                onChange={(e) => store.username = e.target.value}/>
                        }/>
                </div>
                <div style={{marginBottom: 'auto', width: '100%'}}>
                    <GridscopeInputField
                        label={'GESLO'}
                        icon={<MdLock/>}
                        input={
                            <GridscopeTextInput invalid={!!store.errorText} value={store.password}
                                                type={'password'}
                                                onChange={(e) => store.password = e.target.value}/>
                        }/>
                </div>
                <theme.textTheme.error.span>&nbsp;{store.errorText}&nbsp;</theme.textTheme.error.span>
                <GridscopeButton
                    ref={wave}
                    onClick={() => action.trigger(store.authenticate())}
                    style={{justifyContent: 'center', marginTop: 'auto', width: '100%'}}
                    disabled={store.password.length === 0 || store.username.length === 0}>
                    <theme.textTheme.headline5.span style={{color: 'white'}}>PRIJAVA</theme.textTheme.headline5.span>
                </GridscopeButton>
                <input type="submit" hidden/>
            </LoginContainer>
        </LoadingOverlay>
    </Container>;
});