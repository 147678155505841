import styled from "styled-components";
import {SP} from "../theme";
import {ShadowLight} from "../styles/gridscope";

export const Card = styled.div<{ clickable?: boolean }>`
  ${ShadowLight};
  display: flex;
  position: relative;
  align-items: stretch;
  overflow: hidden;
  height: 56px;
  background-color: ${({theme}: SP) => theme.neutral};
  border-radius: ${({theme}: SP) => theme.dimens.radius3};
  ${({clickable}: any) => clickable === false ? '' : 'cursor: pointer;'};
  transition: all 300ms;

  :hover {
    ${({clickable}: any) => clickable === false ? '' : 'box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);'}
  }
`;

export const UploadCard = styled(Card)`
  & > * {
    width: 100%;
  }
`;
