import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import {configure} from "mobx";
import '@material/react-linear-progress/dist/linear-progress.css';

configure({
    enforceActions: "never",
});

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);