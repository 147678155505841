import {GridscopeTextInput} from "./text_input";
import {CommonInputProps} from "./common_input";
import {useCallback, useContext, useMemo, useState} from "react";
import {debounce} from "lodash";
import {inputs} from "../../proto/compiled";
import {DropdownAnything} from "dropdown_anything";
import {DropdownContainer, DropdownItem} from "../overlays";
import {observer} from "mobx-react-lite";
import {GridscopeLoader} from "../loader";
import {InputsStore, InputsStoreContext} from "../../stores/inputs_store";
import useWave from "use-wave";


interface GridscopeSelectInputProps extends CommonInputProps {
    options?: inputs.IInputValue[];
    onBlur?: () => any;
    alwaysReload?: boolean;
}

export const GridscopeSelectInput = observer((props: GridscopeSelectInputProps) => {
    const input = useMemo(() => props.input ?? {} as inputs.IInput, [props.input]);
    const [value, setValue] = useState<string>(input.value?.text ?? '');
    useMemo(() => setValue(input.value?.text ?? value), [input.value?.text]);
    const [searchString, setSearchString] = useState(value);
    const store = useContext(InputsStoreContext) as InputsStore;
    const fixed = input.type === inputs.InputType.fixed_select;
    const optionsI = props.options ?? store.selectOptions.get(input.key!)?.values();
    const options: inputs.IInputValue[] | undefined = optionsI ? Array.from(optionsI) : optionsI;
    const wave = useWave();

    useMemo(() => {
        if (props.alwaysReload) {
            store.invalidate(input.key!);
        }
    }, []);
    useMemo(() => options?.sort((a: inputs.IInputValue, b: inputs.IInputValue) => {
        const sl = searchString.toLowerCase();
        const incA = a.text!.toLowerCase().includes(sl);
        const incB = b.text!.toLowerCase().includes(sl);
        if (incA && !incB) return -1;
        if (!incA && incB) return 1;
        return a.text!.localeCompare(b.text!);
    }), [options, searchString]);

    const search = useCallback(debounce((search: string) => {
        if (!props.alwaysReload && store.completeOptionSets.has(input.key ?? '')) return;
        if (!props.options) {
            store.loadOptions(input.key!, search)
        }
    }, 300, {leading: true}), [store.api]);

    return <DropdownAnything
        triggerBuilder={
            (isOpen: boolean, setIsOpen: any) => <GridscopeTextInput value={value}
                                                                 onBlur={(e) => {
                                                                     setValue(input.value?.text ?? '');
                                                                     setIsOpen(false);
                                                                     if (props.onBlur) props.onBlur();
                                                                 }}
                                                                 onKeyDown={() => setIsOpen(true)}
                                                                 onFocus={() => {
                                                                     setIsOpen(true);
                                                                     search(searchString);
                                                                 }}
                                                                 onChange={(e) => {
                                                                     setValue(e.target.value);
                                                                     setSearchString(e.target.value)
                                                                     search(e.target.value);
                                                                     if (fixed) return;
                                                                     input.value!.text! = e.target.value;
                                                                     props.onChange && props.onChange(input);
                                                                 }}/>

        }
        builder={(marginTop, close) => <DropdownContainer style={{
            height: '300px',
            marginTop: marginTop + 'px',
        }}>
            {!options && <GridscopeLoader style={{margin: 'auto 0 auto 0'}}/>}
            {options && options.map((o: inputs.IInputValue) =>
                <DropdownItem
                    ref={wave}
                    onMouseDown={(e) => {
                        e.preventDefault();
                    }}
                    onClick={() => {
                        setValue(o.text!);
                        setSearchString(o.text!);
                        input.value!.id = o.id;
                        input.value!.text = o.text;
                        input.value!.number = o.number;
                        props.onChange && props.onChange(input);
                        close();
                    }} key={o.text}>{o.text}</DropdownItem>)}
        </DropdownContainer>}
        compute={(rect: DOMRect) => window.innerHeight - rect.bottom < 300 ? -304 - rect.height : 4}
    />
})