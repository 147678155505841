import styled, {useTheme} from "styled-components";
import {SP, Theme} from "../../theme";
import {CSSProperties, useEffect, useRef} from "react";

interface ClassicTextInputProps {
    disabled?: boolean;
    value: string;
    onChanged: (val: string) => any;
    primary?: boolean;
    style?: CSSProperties;
    onSubmit?: () => any;
}

export const ClassicTextInput = (props: ClassicTextInputProps) => {
    const ref = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        if (!ref?.current) return;
        if (!props.disabled) {
            ref.current.select();
        } else {
            document.getSelection()?.removeAllRanges();
        }
    }, [props.disabled, ref]);
    return <CTIContainer
        ref={ref}
        onKeyPress={(e) => {
            if (e.key === 'Enter' && props.onSubmit) {
                props.onSubmit();
            }
        }}
        style={props.style}
        onClick={(e) => e.stopPropagation()}
        disabled={props.disabled}
        primary={props.primary !== false}
        value={props.value}
        onChange={(e) => props.onChanged(e.target.value)}/>;
};

interface GridscopeInputProps {
    icon?: any,
    label?: any,
    input: any
    error?: any
    style?: CSSProperties
}


export const GridscopeInputField = (props: GridscopeInputProps) => {
    const theme = useTheme() as Theme;
    return <Container style={props.style}>
        {(props.label || props.icon) && <LabelContainer>
            {props.icon && <theme.textTheme.label.span
                style={{
                    marginLeft: '2px',
                    display: 'flex',
                    alignItems: 'center'
                }}>{props.icon}</theme.textTheme.label.span>}
            {props.label && <theme.textTheme.label.span style={{marginLeft: '4px'}}>
                {props.label}
            </theme.textTheme.label.span>}
        </LabelContainer>}
        {props.input}
        <theme.textTheme.error.span style={{
            opacity: !!props.error ? 1 : 0,
            transition: 'opacity 300ms ease',
        }}>{props.error}</theme.textTheme.error.span>
    </Container>
}


const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  width: 100%;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  border-radius: ${({theme}: SP) => `${theme.dimens.radius3} ${theme.dimens.radius3} 0 0`};
  padding: 2px 8px 20px 4px;
  margin-bottom: -15px;
`;

const CTIContainer = styled.input<{ disabled?: boolean, primary?: boolean }>`
  border: none;
  resize: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  width: 0;
  flex-grow: 1;
  margin-bottom: -2px;
  border-bottom: solid 1px ${({
                                theme,
                                primary,
                                disabled
                              }: SP) => disabled ? 'transparent' : (primary ? theme.neutral : theme.primary)};
  transition: all 300ms;
  pointer-events: ${({disabled}: any) => disabled ? 'none' : 'auto'};
`;