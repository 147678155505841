import styled from "styled-components";
import {SP} from "../../theme";
import {GridscopeInputContainerCss, GridscopeTextInput} from "./text_input";
import {gridscope_types} from "../../proto/compiled";
import useWave from "use-wave";
import {useCallback, useMemo, useRef} from "react";

const InputButton = styled.div`
  font-size: 20px;
  z-index: 500;
  font-weight: bold;
  padding-bottom: 2px;
  width: 20px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({theme}: SP) => theme.primary};
  cursor: pointer;
  background-color: ${({theme}: SP) => theme.shade2};
  border-radius: ${({theme}: SP) => theme.dimens.radius4};
  border: solid 1px transparent;

  :hover {
    border: solid 1px ${({theme}: SP) => theme.primary};
  }
`;

const InputField = styled.input.attrs({type: 'number'})<{ leftButtons?: boolean }>`
  position: relative;
  border: none;
  background-color: transparent;
  border-bottom: solid 1px ${({theme}: SP) => theme.shade1};
  margin: 0 8px 0 8px;
  ${({theme}: SP) => theme.textTheme.input.css};
  line-height: 25px;
  padding-right: 4px;
  padding-left: ${({leftButtons}) => leftButtons ? '26px' : '0'};
  margin-left: ${({leftButtons}) => leftButtons ? '-22px' : '0'};
  outline: none;
  flex: 1;
  width: 0;
  transition: all 300ms;
  text-align: center;

  :focus {
    border-bottom-color: ${({theme}: SP) => theme.primary};
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
`;

const Container = styled.div`
  ${GridscopeInputContainerCss};
  display: flex;
  align-items: center;
`;


interface GridscopeDateInputProps {
    value: gridscope_types.IDate;
    onChange: (d: gridscope_types.IDate) => any;
    filter?: boolean;
}

export const GridscopeDateInput = (props: GridscopeDateInputProps) => {
    const value = useMemo(() => gridscope_types.Date.create(props.value), [props.value]);
    const wave = useWave();
    const refDay = useRef<any>();
    const refMonth = useRef<any>();
    const refYear = useRef<any>();
    const icons = [
        '=',
        '>',
        '<',
    ];
    return <Container>
        {props.filter && <InputButton onClick={() => {
            value.rDay += 1;
            value.rDay %= 3;
            props.onChange(value);
        }} ref={wave}>{icons[props.value?.rDay ?? 0]}</InputButton>}
        <InputField
            ref={refDay}
            placeholder={'DD'}
            onFocus={(e) => {
                e.currentTarget.select();
            }}
            value={value.day ? value.day : ''}
            onChange={(e) => {
                let num = e.target.valueAsNumber;
                if (num > 31) {
                    num = 31;
                }
                if (num > 3) {
                    refMonth.current.focus();
                }
                value.day = num;
                props.onChange(value);
            }} leftButtons={props.filter}/>
        {props.filter && <InputButton onClick={() => {
            value.rMonth += 1;
            value.rMonth %= 3;
            props.onChange(value);
        }} ref={wave}>{icons[props.value?.rMonth ?? 0]}</InputButton>}
        <InputField
            ref={refMonth}
            placeholder={'MM'}
            onFocus={(e) => {
                e.currentTarget.select();
            }}
            value={value.month ? value.month : ''}
            onChange={(e) => {
                let num = e.target.valueAsNumber;
                if (num > 1) {
                    refYear.current.focus();
                }
                value.month = num;
                props.onChange(value);
            }} leftButtons={props.filter}/>
        {props.filter && <InputButton onClick={() => {
            value.rYear += 1;
            value.rYear %= 3;
            props.onChange(value);
        }} ref={wave}>{icons[props.value?.rYear ?? 0]}</InputButton>}
        <InputField
            ref={refYear}
            placeholder={'YYYY'}
            onFocus={(e) => {
                e.currentTarget.select();
            }}
            value={value.year ? value.year : ''}
            onChange={(e) => {
                let num = e.target.valueAsNumber;
                value.year = num;
                props.onChange(value);
            }} leftButtons={props.filter}/>
    </Container>
};
