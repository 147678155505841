import {observer} from "mobx-react-lite";
import {TableStore} from "../../stores/table_store";
import {GridscopeTable} from "./gridscope_table";
import {gridscope_data, gridscope_view} from "../../proto/compiled";
import {CSSProperties, useContext, useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import {SP} from "../../theme";
import {LoadingOverlay, LoadingOverlayAction} from "../overlays";
import {FlexCenter, NoScrollbar} from "../../styles/gridscope";
import useWave from "use-wave";
import {GridscopeStoreContext} from "../../app";

export const DataFilesTable = observer(({
                                            files,
                                            style
                                        }: { files?: (gridscope_data.IDataFile | gridscope_view.IResultFile)[], style?: CSSProperties }) => {
    const stores = useMemo<{ [k: string]: TableStore }>(() => ({}), []);
    const [tab, setTab] = useState(0);
    if (files && tab > files.length) {
        setTab(0);
    }

    const getStore = (i: number) => {
        if (!files) return;
        const df = files[i];
        if (!df?.id) return;
        if (!stores[df.id]) {
            stores[df.id] = new TableStore(df);
        }
        return stores[df.id];
    }

    useEffect(() => () => {
        Object.values(stores).map((s) => s.dispose());
    }, []);

    const store = getStore(tab);
    const action = useMemo(() => new LoadingOverlayAction(), []);
    const wave = useWave();
    const scrollRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        const el = scrollRef.current;
        if (!el) return;
        const onScroll = () => {
            const el = scrollRef.current;
            if (!el) return;
            if (el.offsetHeight + el.scrollTop === el.scrollHeight) {
                store?.loadMore();
            }
        };
        el.addEventListener('scroll', onScroll);
        return () => {
            el.removeEventListener('scroll', onScroll);
        };
    }, [scrollRef.current]);

    const global = useContext(GridscopeStoreContext)!.global;
    return <Container style={style}>
        <LoadingOverlay action={action} untrackedLoading={!store}
                        style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <TabsContainer>
                {files?.map((f, i) => <TabContainer key={i} selected={i === tab} ref={wave} onClick={() => setTab(i)}>
                    {(global.dataFiles.get(f.id!)?.name || f.label)}
                </TabContainer>)}
            </TabsContainer>
            <ScrollContainer ref={scrollRef} key={store?.dataFile.id ?? ''}>
                {store && <GridscopeTable store={store}/>}
            </ScrollContainer>
        </LoadingOverlay>
    </Container>;
});

const Container = styled.div`
  height: 0;
  border-radius: ${({theme}: SP) => theme.dimens.radius2} ${({theme}: SP) => theme.dimens.radius2} 0 0;
  background-color: ${({theme}: SP) => theme.neutral};
  overflow: hidden;
`;

const ScrollContainer = styled.div`
  flex-grow: 1;
  height: 0;
  overflow: auto;
`;

const TabContainer = styled.div<{ selected: boolean }>`
  ${FlexCenter};
  cursor: pointer;
  background-color: ${({theme, selected}: SP) => selected ? theme.primary : theme.dark};
  padding: 0 8px 0 8px;
  border-right: solid 1px ${({theme}: SP) => theme.brighten1};
  transition: all 300ms;
`;

const TabsContainer = styled.div`
  ${NoScrollbar};
  position: sticky;
  display: flex;
  overflow: auto;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({theme}: SP) => theme.dark};
  height: ${({theme}: SP) => theme.dimens.minClickable};
  ${({theme}: SP) => theme.textTheme.button.css};
  color: ${({theme}: SP) => theme.neutral};
  font-size: 12px;
`;