import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {GridscopeProjectSidebar} from "../components/sidebar";
import {SP} from "../theme";
import {GridscopeRoutes} from "../strings";
import {Processing} from "./project/processing";
import {Dashboard} from "./project/dashboard";
import React, {useContext, useEffect, useMemo} from "react";
import {GridscopeStoreContext} from "../app";
import {useParams} from "react-router-dom";
import {ProjectSettings} from "./project/project_settings";
import {GridscopeLoadingOverlay} from "../components/overlays";
import { InputsStoreContext } from "../stores/inputs_store";
import {GridscopeStore} from "../stores/gridscope_store";
import {Bottombar} from "../components/bottombar";

export const ProjectPage = observer(({location}: { location: string }) => {
    const store = useContext(GridscopeStoreContext)!;
    const {id} = useParams();
    useMemo(() => store.project.setProjectKey(id), [id]);

    return <InputsStoreContext.Provider value={store.project.processing.inputsStore}>
        <Container>
            <GridscopeProjectSidebar location={location}/>
            <Bottombar/>
            <ContainerContent>
                {location === GridscopeRoutes.project.dashboardR && <Dashboard/>}
                {location === GridscopeRoutes.project.processingR && <Processing/>}
                {location === GridscopeRoutes.project.settingsR && <ProjectSettings/>}
            </ContainerContent>
            <GridscopeLoadingOverlay visible={!store.project.data}/>
        </Container>
    </InputsStoreContext.Provider>;
});

const Container = styled.div``;

const ContainerContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  left: ${({theme}: SP) => theme.dimens.clickable};
  top: 0;
  bottom: 50px;
  right: 0;
`;