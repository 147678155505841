import styled, {css} from "styled-components";
import {SP} from "../../theme";

export const GridscopeInputContainerCss = css`
  background: ${({theme, readOnly}: SP) => readOnly ? theme.content10 : 'white'};
  pointer-events: ${({readOnly}: any) => readOnly ? 'none' : 'auto'};
  border-radius: ${({theme}: SP) => theme.dimens.radius3};
  outline: none;
  width: 100%;
  height: 45px;
  min-height: 2.5rem;
  padding: 0 8px 0 8px;
  ${({theme}: SP) => theme.textTheme.input.css};
  transition: all 300ms ease-in-out;
  box-sizing: border-box;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;

  border: solid 1px ${({theme, invalid}: SP) => invalid ? theme.error : 'transparent'};
  box-shadow: 0 0 3px ${({readOnly}: SP) => readOnly ? 'none' : 'rgba(0, 0, 0, .25)'};

  :focus {
    box-shadow: 0 0 3px ${({theme, readOnly}: SP) => readOnly ? 'none' : theme.primary};
  }

  :focus-within {
    box-shadow: 0 0 3px ${({theme, readOnly}: SP) => readOnly ? 'none' : theme.primary};
  }
`;

export const GridscopeTextInput = styled.input<{ invalid?: boolean, readOnly?: boolean }>`
  ${GridscopeInputContainerCss}
`;

export const GridscopeTextareaInput = styled.textarea.attrs({cols: 100, rows: 4})<{ invalid?: boolean, readOnly?: boolean }>`
  ${GridscopeInputContainerCss};
  min-height: 45px;
  padding: 8px;
  height: auto;
  margin: 8px 0 8px 0;
  color: ${({theme}: SP) => theme.content};
  transition: none;
`;

