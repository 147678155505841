/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const gridscope_data = $root.gridscope_data = (() => {

    /**
     * Namespace gridscope_data.
     * @exports gridscope_data
     * @namespace
     */
    const gridscope_data = {};

    gridscope_data.CreateMeasurement = (function() {

        /**
         * Properties of a CreateMeasurement.
         * @memberof gridscope_data
         * @interface ICreateMeasurement
         * @property {string|null} [projectKey] CreateMeasurement projectKey
         * @property {string|null} [ufileId] CreateMeasurement ufileId
         */

        /**
         * Constructs a new CreateMeasurement.
         * @memberof gridscope_data
         * @classdesc Represents a CreateMeasurement.
         * @implements ICreateMeasurement
         * @constructor
         * @param {gridscope_data.ICreateMeasurement=} [properties] Properties to set
         */
        function CreateMeasurement(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateMeasurement projectKey.
         * @member {string} projectKey
         * @memberof gridscope_data.CreateMeasurement
         * @instance
         */
        CreateMeasurement.prototype.projectKey = "";

        /**
         * CreateMeasurement ufileId.
         * @member {string} ufileId
         * @memberof gridscope_data.CreateMeasurement
         * @instance
         */
        CreateMeasurement.prototype.ufileId = "";

        /**
         * Creates a new CreateMeasurement instance using the specified properties.
         * @function create
         * @memberof gridscope_data.CreateMeasurement
         * @static
         * @param {gridscope_data.ICreateMeasurement=} [properties] Properties to set
         * @returns {gridscope_data.CreateMeasurement} CreateMeasurement instance
         */
        CreateMeasurement.create = function create(properties) {
            return new CreateMeasurement(properties);
        };

        /**
         * Encodes the specified CreateMeasurement message. Does not implicitly {@link gridscope_data.CreateMeasurement.verify|verify} messages.
         * @function encode
         * @memberof gridscope_data.CreateMeasurement
         * @static
         * @param {gridscope_data.ICreateMeasurement} message CreateMeasurement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateMeasurement.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectKey != null && Object.hasOwnProperty.call(message, "projectKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.projectKey);
            if (message.ufileId != null && Object.hasOwnProperty.call(message, "ufileId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.ufileId);
            return writer;
        };

        /**
         * Encodes the specified CreateMeasurement message, length delimited. Does not implicitly {@link gridscope_data.CreateMeasurement.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_data.CreateMeasurement
         * @static
         * @param {gridscope_data.ICreateMeasurement} message CreateMeasurement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateMeasurement.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateMeasurement message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_data.CreateMeasurement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_data.CreateMeasurement} CreateMeasurement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateMeasurement.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_data.CreateMeasurement();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.projectKey = reader.string();
                    break;
                case 2:
                    message.ufileId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateMeasurement message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_data.CreateMeasurement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_data.CreateMeasurement} CreateMeasurement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateMeasurement.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateMeasurement message.
         * @function verify
         * @memberof gridscope_data.CreateMeasurement
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateMeasurement.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                if (!$util.isString(message.projectKey))
                    return "projectKey: string expected";
            if (message.ufileId != null && message.hasOwnProperty("ufileId"))
                if (!$util.isString(message.ufileId))
                    return "ufileId: string expected";
            return null;
        };

        /**
         * Creates a CreateMeasurement message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_data.CreateMeasurement
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_data.CreateMeasurement} CreateMeasurement
         */
        CreateMeasurement.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_data.CreateMeasurement)
                return object;
            let message = new $root.gridscope_data.CreateMeasurement();
            if (object.projectKey != null)
                message.projectKey = String(object.projectKey);
            if (object.ufileId != null)
                message.ufileId = String(object.ufileId);
            return message;
        };

        /**
         * Creates a plain object from a CreateMeasurement message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_data.CreateMeasurement
         * @static
         * @param {gridscope_data.CreateMeasurement} message CreateMeasurement
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateMeasurement.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.projectKey = "";
                object.ufileId = "";
            }
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                object.projectKey = message.projectKey;
            if (message.ufileId != null && message.hasOwnProperty("ufileId"))
                object.ufileId = message.ufileId;
            return object;
        };

        /**
         * Converts this CreateMeasurement to JSON.
         * @function toJSON
         * @memberof gridscope_data.CreateMeasurement
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateMeasurement.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateMeasurement;
    })();

    gridscope_data.LoadData = (function() {

        /**
         * Properties of a LoadData.
         * @memberof gridscope_data
         * @interface ILoadData
         * @property {string|null} [projectKey] LoadData projectKey
         */

        /**
         * Constructs a new LoadData.
         * @memberof gridscope_data
         * @classdesc Represents a LoadData.
         * @implements ILoadData
         * @constructor
         * @param {gridscope_data.ILoadData=} [properties] Properties to set
         */
        function LoadData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadData projectKey.
         * @member {string} projectKey
         * @memberof gridscope_data.LoadData
         * @instance
         */
        LoadData.prototype.projectKey = "";

        /**
         * Creates a new LoadData instance using the specified properties.
         * @function create
         * @memberof gridscope_data.LoadData
         * @static
         * @param {gridscope_data.ILoadData=} [properties] Properties to set
         * @returns {gridscope_data.LoadData} LoadData instance
         */
        LoadData.create = function create(properties) {
            return new LoadData(properties);
        };

        /**
         * Encodes the specified LoadData message. Does not implicitly {@link gridscope_data.LoadData.verify|verify} messages.
         * @function encode
         * @memberof gridscope_data.LoadData
         * @static
         * @param {gridscope_data.ILoadData} message LoadData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectKey != null && Object.hasOwnProperty.call(message, "projectKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.projectKey);
            return writer;
        };

        /**
         * Encodes the specified LoadData message, length delimited. Does not implicitly {@link gridscope_data.LoadData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_data.LoadData
         * @static
         * @param {gridscope_data.ILoadData} message LoadData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadData message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_data.LoadData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_data.LoadData} LoadData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_data.LoadData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.projectKey = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_data.LoadData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_data.LoadData} LoadData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadData message.
         * @function verify
         * @memberof gridscope_data.LoadData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                if (!$util.isString(message.projectKey))
                    return "projectKey: string expected";
            return null;
        };

        /**
         * Creates a LoadData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_data.LoadData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_data.LoadData} LoadData
         */
        LoadData.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_data.LoadData)
                return object;
            let message = new $root.gridscope_data.LoadData();
            if (object.projectKey != null)
                message.projectKey = String(object.projectKey);
            return message;
        };

        /**
         * Creates a plain object from a LoadData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_data.LoadData
         * @static
         * @param {gridscope_data.LoadData} message LoadData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.projectKey = "";
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                object.projectKey = message.projectKey;
            return object;
        };

        /**
         * Converts this LoadData to JSON.
         * @function toJSON
         * @memberof gridscope_data.LoadData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoadData;
    })();

    gridscope_data.Data = (function() {

        /**
         * Properties of a Data.
         * @memberof gridscope_data
         * @interface IData
         * @property {Array.<gridscope_data.IDataFilesGroup>|null} [groups] Data groups
         * @property {Array.<gridscope_data.IDataFile>|null} [files] Data files
         * @property {Array.<gridscope_data.IMeasurement>|null} [measurements] Data measurements
         * @property {Array.<string>|null} [ungroupedFiles] Data ungroupedFiles
         */

        /**
         * Constructs a new Data.
         * @memberof gridscope_data
         * @classdesc Represents a Data.
         * @implements IData
         * @constructor
         * @param {gridscope_data.IData=} [properties] Properties to set
         */
        function Data(properties) {
            this.groups = [];
            this.files = [];
            this.measurements = [];
            this.ungroupedFiles = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Data groups.
         * @member {Array.<gridscope_data.IDataFilesGroup>} groups
         * @memberof gridscope_data.Data
         * @instance
         */
        Data.prototype.groups = $util.emptyArray;

        /**
         * Data files.
         * @member {Array.<gridscope_data.IDataFile>} files
         * @memberof gridscope_data.Data
         * @instance
         */
        Data.prototype.files = $util.emptyArray;

        /**
         * Data measurements.
         * @member {Array.<gridscope_data.IMeasurement>} measurements
         * @memberof gridscope_data.Data
         * @instance
         */
        Data.prototype.measurements = $util.emptyArray;

        /**
         * Data ungroupedFiles.
         * @member {Array.<string>} ungroupedFiles
         * @memberof gridscope_data.Data
         * @instance
         */
        Data.prototype.ungroupedFiles = $util.emptyArray;

        /**
         * Creates a new Data instance using the specified properties.
         * @function create
         * @memberof gridscope_data.Data
         * @static
         * @param {gridscope_data.IData=} [properties] Properties to set
         * @returns {gridscope_data.Data} Data instance
         */
        Data.create = function create(properties) {
            return new Data(properties);
        };

        /**
         * Encodes the specified Data message. Does not implicitly {@link gridscope_data.Data.verify|verify} messages.
         * @function encode
         * @memberof gridscope_data.Data
         * @static
         * @param {gridscope_data.IData} message Data message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Data.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.groups != null && message.groups.length)
                for (let i = 0; i < message.groups.length; ++i)
                    $root.gridscope_data.DataFilesGroup.encode(message.groups[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.files != null && message.files.length)
                for (let i = 0; i < message.files.length; ++i)
                    $root.gridscope_data.DataFile.encode(message.files[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.measurements != null && message.measurements.length)
                for (let i = 0; i < message.measurements.length; ++i)
                    $root.gridscope_data.Measurement.encode(message.measurements[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.ungroupedFiles != null && message.ungroupedFiles.length)
                for (let i = 0; i < message.ungroupedFiles.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.ungroupedFiles[i]);
            return writer;
        };

        /**
         * Encodes the specified Data message, length delimited. Does not implicitly {@link gridscope_data.Data.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_data.Data
         * @static
         * @param {gridscope_data.IData} message Data message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Data.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Data message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_data.Data
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_data.Data} Data
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Data.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_data.Data();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.groups && message.groups.length))
                        message.groups = [];
                    message.groups.push($root.gridscope_data.DataFilesGroup.decode(reader, reader.uint32()));
                    break;
                case 2:
                    if (!(message.files && message.files.length))
                        message.files = [];
                    message.files.push($root.gridscope_data.DataFile.decode(reader, reader.uint32()));
                    break;
                case 3:
                    if (!(message.measurements && message.measurements.length))
                        message.measurements = [];
                    message.measurements.push($root.gridscope_data.Measurement.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.ungroupedFiles && message.ungroupedFiles.length))
                        message.ungroupedFiles = [];
                    message.ungroupedFiles.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Data message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_data.Data
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_data.Data} Data
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Data.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Data message.
         * @function verify
         * @memberof gridscope_data.Data
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Data.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.groups != null && message.hasOwnProperty("groups")) {
                if (!Array.isArray(message.groups))
                    return "groups: array expected";
                for (let i = 0; i < message.groups.length; ++i) {
                    let error = $root.gridscope_data.DataFilesGroup.verify(message.groups[i]);
                    if (error)
                        return "groups." + error;
                }
            }
            if (message.files != null && message.hasOwnProperty("files")) {
                if (!Array.isArray(message.files))
                    return "files: array expected";
                for (let i = 0; i < message.files.length; ++i) {
                    let error = $root.gridscope_data.DataFile.verify(message.files[i]);
                    if (error)
                        return "files." + error;
                }
            }
            if (message.measurements != null && message.hasOwnProperty("measurements")) {
                if (!Array.isArray(message.measurements))
                    return "measurements: array expected";
                for (let i = 0; i < message.measurements.length; ++i) {
                    let error = $root.gridscope_data.Measurement.verify(message.measurements[i]);
                    if (error)
                        return "measurements." + error;
                }
            }
            if (message.ungroupedFiles != null && message.hasOwnProperty("ungroupedFiles")) {
                if (!Array.isArray(message.ungroupedFiles))
                    return "ungroupedFiles: array expected";
                for (let i = 0; i < message.ungroupedFiles.length; ++i)
                    if (!$util.isString(message.ungroupedFiles[i]))
                        return "ungroupedFiles: string[] expected";
            }
            return null;
        };

        /**
         * Creates a Data message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_data.Data
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_data.Data} Data
         */
        Data.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_data.Data)
                return object;
            let message = new $root.gridscope_data.Data();
            if (object.groups) {
                if (!Array.isArray(object.groups))
                    throw TypeError(".gridscope_data.Data.groups: array expected");
                message.groups = [];
                for (let i = 0; i < object.groups.length; ++i) {
                    if (typeof object.groups[i] !== "object")
                        throw TypeError(".gridscope_data.Data.groups: object expected");
                    message.groups[i] = $root.gridscope_data.DataFilesGroup.fromObject(object.groups[i]);
                }
            }
            if (object.files) {
                if (!Array.isArray(object.files))
                    throw TypeError(".gridscope_data.Data.files: array expected");
                message.files = [];
                for (let i = 0; i < object.files.length; ++i) {
                    if (typeof object.files[i] !== "object")
                        throw TypeError(".gridscope_data.Data.files: object expected");
                    message.files[i] = $root.gridscope_data.DataFile.fromObject(object.files[i]);
                }
            }
            if (object.measurements) {
                if (!Array.isArray(object.measurements))
                    throw TypeError(".gridscope_data.Data.measurements: array expected");
                message.measurements = [];
                for (let i = 0; i < object.measurements.length; ++i) {
                    if (typeof object.measurements[i] !== "object")
                        throw TypeError(".gridscope_data.Data.measurements: object expected");
                    message.measurements[i] = $root.gridscope_data.Measurement.fromObject(object.measurements[i]);
                }
            }
            if (object.ungroupedFiles) {
                if (!Array.isArray(object.ungroupedFiles))
                    throw TypeError(".gridscope_data.Data.ungroupedFiles: array expected");
                message.ungroupedFiles = [];
                for (let i = 0; i < object.ungroupedFiles.length; ++i)
                    message.ungroupedFiles[i] = String(object.ungroupedFiles[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a Data message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_data.Data
         * @static
         * @param {gridscope_data.Data} message Data
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Data.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.groups = [];
                object.files = [];
                object.measurements = [];
                object.ungroupedFiles = [];
            }
            if (message.groups && message.groups.length) {
                object.groups = [];
                for (let j = 0; j < message.groups.length; ++j)
                    object.groups[j] = $root.gridscope_data.DataFilesGroup.toObject(message.groups[j], options);
            }
            if (message.files && message.files.length) {
                object.files = [];
                for (let j = 0; j < message.files.length; ++j)
                    object.files[j] = $root.gridscope_data.DataFile.toObject(message.files[j], options);
            }
            if (message.measurements && message.measurements.length) {
                object.measurements = [];
                for (let j = 0; j < message.measurements.length; ++j)
                    object.measurements[j] = $root.gridscope_data.Measurement.toObject(message.measurements[j], options);
            }
            if (message.ungroupedFiles && message.ungroupedFiles.length) {
                object.ungroupedFiles = [];
                for (let j = 0; j < message.ungroupedFiles.length; ++j)
                    object.ungroupedFiles[j] = message.ungroupedFiles[j];
            }
            return object;
        };

        /**
         * Converts this Data to JSON.
         * @function toJSON
         * @memberof gridscope_data.Data
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Data.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Data;
    })();

    gridscope_data.Measurement = (function() {

        /**
         * Properties of a Measurement.
         * @memberof gridscope_data
         * @interface IMeasurement
         * @property {string|null} [id] Measurement id
         * @property {uploader.IUFile|null} [sourceFile] Measurement sourceFile
         * @property {Array.<string>|null} [dataFileIds] Measurement dataFileIds
         * @property {boolean|null} [processing] Measurement processing
         * @property {string|null} [error] Measurement error
         */

        /**
         * Constructs a new Measurement.
         * @memberof gridscope_data
         * @classdesc Represents a Measurement.
         * @implements IMeasurement
         * @constructor
         * @param {gridscope_data.IMeasurement=} [properties] Properties to set
         */
        function Measurement(properties) {
            this.dataFileIds = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Measurement id.
         * @member {string} id
         * @memberof gridscope_data.Measurement
         * @instance
         */
        Measurement.prototype.id = "";

        /**
         * Measurement sourceFile.
         * @member {uploader.IUFile|null|undefined} sourceFile
         * @memberof gridscope_data.Measurement
         * @instance
         */
        Measurement.prototype.sourceFile = null;

        /**
         * Measurement dataFileIds.
         * @member {Array.<string>} dataFileIds
         * @memberof gridscope_data.Measurement
         * @instance
         */
        Measurement.prototype.dataFileIds = $util.emptyArray;

        /**
         * Measurement processing.
         * @member {boolean} processing
         * @memberof gridscope_data.Measurement
         * @instance
         */
        Measurement.prototype.processing = false;

        /**
         * Measurement error.
         * @member {string} error
         * @memberof gridscope_data.Measurement
         * @instance
         */
        Measurement.prototype.error = "";

        /**
         * Creates a new Measurement instance using the specified properties.
         * @function create
         * @memberof gridscope_data.Measurement
         * @static
         * @param {gridscope_data.IMeasurement=} [properties] Properties to set
         * @returns {gridscope_data.Measurement} Measurement instance
         */
        Measurement.create = function create(properties) {
            return new Measurement(properties);
        };

        /**
         * Encodes the specified Measurement message. Does not implicitly {@link gridscope_data.Measurement.verify|verify} messages.
         * @function encode
         * @memberof gridscope_data.Measurement
         * @static
         * @param {gridscope_data.IMeasurement} message Measurement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Measurement.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.sourceFile != null && Object.hasOwnProperty.call(message, "sourceFile"))
                $root.uploader.UFile.encode(message.sourceFile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.dataFileIds != null && message.dataFileIds.length)
                for (let i = 0; i < message.dataFileIds.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.dataFileIds[i]);
            if (message.processing != null && Object.hasOwnProperty.call(message, "processing"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.processing);
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.error);
            return writer;
        };

        /**
         * Encodes the specified Measurement message, length delimited. Does not implicitly {@link gridscope_data.Measurement.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_data.Measurement
         * @static
         * @param {gridscope_data.IMeasurement} message Measurement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Measurement.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Measurement message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_data.Measurement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_data.Measurement} Measurement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Measurement.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_data.Measurement();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.sourceFile = $root.uploader.UFile.decode(reader, reader.uint32());
                    break;
                case 3:
                    if (!(message.dataFileIds && message.dataFileIds.length))
                        message.dataFileIds = [];
                    message.dataFileIds.push(reader.string());
                    break;
                case 4:
                    message.processing = reader.bool();
                    break;
                case 5:
                    message.error = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Measurement message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_data.Measurement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_data.Measurement} Measurement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Measurement.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Measurement message.
         * @function verify
         * @memberof gridscope_data.Measurement
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Measurement.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.sourceFile != null && message.hasOwnProperty("sourceFile")) {
                let error = $root.uploader.UFile.verify(message.sourceFile);
                if (error)
                    return "sourceFile." + error;
            }
            if (message.dataFileIds != null && message.hasOwnProperty("dataFileIds")) {
                if (!Array.isArray(message.dataFileIds))
                    return "dataFileIds: array expected";
                for (let i = 0; i < message.dataFileIds.length; ++i)
                    if (!$util.isString(message.dataFileIds[i]))
                        return "dataFileIds: string[] expected";
            }
            if (message.processing != null && message.hasOwnProperty("processing"))
                if (typeof message.processing !== "boolean")
                    return "processing: boolean expected";
            if (message.error != null && message.hasOwnProperty("error"))
                if (!$util.isString(message.error))
                    return "error: string expected";
            return null;
        };

        /**
         * Creates a Measurement message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_data.Measurement
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_data.Measurement} Measurement
         */
        Measurement.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_data.Measurement)
                return object;
            let message = new $root.gridscope_data.Measurement();
            if (object.id != null)
                message.id = String(object.id);
            if (object.sourceFile != null) {
                if (typeof object.sourceFile !== "object")
                    throw TypeError(".gridscope_data.Measurement.sourceFile: object expected");
                message.sourceFile = $root.uploader.UFile.fromObject(object.sourceFile);
            }
            if (object.dataFileIds) {
                if (!Array.isArray(object.dataFileIds))
                    throw TypeError(".gridscope_data.Measurement.dataFileIds: array expected");
                message.dataFileIds = [];
                for (let i = 0; i < object.dataFileIds.length; ++i)
                    message.dataFileIds[i] = String(object.dataFileIds[i]);
            }
            if (object.processing != null)
                message.processing = Boolean(object.processing);
            if (object.error != null)
                message.error = String(object.error);
            return message;
        };

        /**
         * Creates a plain object from a Measurement message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_data.Measurement
         * @static
         * @param {gridscope_data.Measurement} message Measurement
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Measurement.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.dataFileIds = [];
            if (options.defaults) {
                object.id = "";
                object.sourceFile = null;
                object.processing = false;
                object.error = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                object.sourceFile = $root.uploader.UFile.toObject(message.sourceFile, options);
            if (message.dataFileIds && message.dataFileIds.length) {
                object.dataFileIds = [];
                for (let j = 0; j < message.dataFileIds.length; ++j)
                    object.dataFileIds[j] = message.dataFileIds[j];
            }
            if (message.processing != null && message.hasOwnProperty("processing"))
                object.processing = message.processing;
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = message.error;
            return object;
        };

        /**
         * Converts this Measurement to JSON.
         * @function toJSON
         * @memberof gridscope_data.Measurement
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Measurement.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Measurement;
    })();

    gridscope_data.DataFilesGroup = (function() {

        /**
         * Properties of a DataFilesGroup.
         * @memberof gridscope_data
         * @interface IDataFilesGroup
         * @property {string|null} [id] DataFilesGroup id
         * @property {Array.<string>|null} [dataFileIds] DataFilesGroup dataFileIds
         * @property {string|null} [label] DataFilesGroup label
         * @property {number|null} [count] DataFilesGroup count
         * @property {gridscope_types.IDate|null} [firstDate] DataFilesGroup firstDate
         * @property {gridscope_types.IDate|null} [lastDate] DataFilesGroup lastDate
         * @property {gridscope_types.ITimeDelta|null} [interval] DataFilesGroup interval
         * @property {string|null} [dataType] DataFilesGroup dataType
         */

        /**
         * Constructs a new DataFilesGroup.
         * @memberof gridscope_data
         * @classdesc Represents a DataFilesGroup.
         * @implements IDataFilesGroup
         * @constructor
         * @param {gridscope_data.IDataFilesGroup=} [properties] Properties to set
         */
        function DataFilesGroup(properties) {
            this.dataFileIds = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DataFilesGroup id.
         * @member {string} id
         * @memberof gridscope_data.DataFilesGroup
         * @instance
         */
        DataFilesGroup.prototype.id = "";

        /**
         * DataFilesGroup dataFileIds.
         * @member {Array.<string>} dataFileIds
         * @memberof gridscope_data.DataFilesGroup
         * @instance
         */
        DataFilesGroup.prototype.dataFileIds = $util.emptyArray;

        /**
         * DataFilesGroup label.
         * @member {string} label
         * @memberof gridscope_data.DataFilesGroup
         * @instance
         */
        DataFilesGroup.prototype.label = "";

        /**
         * DataFilesGroup count.
         * @member {number} count
         * @memberof gridscope_data.DataFilesGroup
         * @instance
         */
        DataFilesGroup.prototype.count = 0;

        /**
         * DataFilesGroup firstDate.
         * @member {gridscope_types.IDate|null|undefined} firstDate
         * @memberof gridscope_data.DataFilesGroup
         * @instance
         */
        DataFilesGroup.prototype.firstDate = null;

        /**
         * DataFilesGroup lastDate.
         * @member {gridscope_types.IDate|null|undefined} lastDate
         * @memberof gridscope_data.DataFilesGroup
         * @instance
         */
        DataFilesGroup.prototype.lastDate = null;

        /**
         * DataFilesGroup interval.
         * @member {gridscope_types.ITimeDelta|null|undefined} interval
         * @memberof gridscope_data.DataFilesGroup
         * @instance
         */
        DataFilesGroup.prototype.interval = null;

        /**
         * DataFilesGroup dataType.
         * @member {string} dataType
         * @memberof gridscope_data.DataFilesGroup
         * @instance
         */
        DataFilesGroup.prototype.dataType = "";

        /**
         * Creates a new DataFilesGroup instance using the specified properties.
         * @function create
         * @memberof gridscope_data.DataFilesGroup
         * @static
         * @param {gridscope_data.IDataFilesGroup=} [properties] Properties to set
         * @returns {gridscope_data.DataFilesGroup} DataFilesGroup instance
         */
        DataFilesGroup.create = function create(properties) {
            return new DataFilesGroup(properties);
        };

        /**
         * Encodes the specified DataFilesGroup message. Does not implicitly {@link gridscope_data.DataFilesGroup.verify|verify} messages.
         * @function encode
         * @memberof gridscope_data.DataFilesGroup
         * @static
         * @param {gridscope_data.IDataFilesGroup} message DataFilesGroup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataFilesGroup.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.dataFileIds != null && message.dataFileIds.length)
                for (let i = 0; i < message.dataFileIds.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.dataFileIds[i]);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.label);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.count);
            if (message.firstDate != null && Object.hasOwnProperty.call(message, "firstDate"))
                $root.gridscope_types.Date.encode(message.firstDate, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.lastDate != null && Object.hasOwnProperty.call(message, "lastDate"))
                $root.gridscope_types.Date.encode(message.lastDate, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.interval != null && Object.hasOwnProperty.call(message, "interval"))
                $root.gridscope_types.TimeDelta.encode(message.interval, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.dataType != null && Object.hasOwnProperty.call(message, "dataType"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.dataType);
            return writer;
        };

        /**
         * Encodes the specified DataFilesGroup message, length delimited. Does not implicitly {@link gridscope_data.DataFilesGroup.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_data.DataFilesGroup
         * @static
         * @param {gridscope_data.IDataFilesGroup} message DataFilesGroup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataFilesGroup.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DataFilesGroup message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_data.DataFilesGroup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_data.DataFilesGroup} DataFilesGroup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataFilesGroup.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_data.DataFilesGroup();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    if (!(message.dataFileIds && message.dataFileIds.length))
                        message.dataFileIds = [];
                    message.dataFileIds.push(reader.string());
                    break;
                case 3:
                    message.label = reader.string();
                    break;
                case 4:
                    message.count = reader.int32();
                    break;
                case 5:
                    message.firstDate = $root.gridscope_types.Date.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.lastDate = $root.gridscope_types.Date.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.interval = $root.gridscope_types.TimeDelta.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.dataType = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DataFilesGroup message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_data.DataFilesGroup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_data.DataFilesGroup} DataFilesGroup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataFilesGroup.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DataFilesGroup message.
         * @function verify
         * @memberof gridscope_data.DataFilesGroup
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DataFilesGroup.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.dataFileIds != null && message.hasOwnProperty("dataFileIds")) {
                if (!Array.isArray(message.dataFileIds))
                    return "dataFileIds: array expected";
                for (let i = 0; i < message.dataFileIds.length; ++i)
                    if (!$util.isString(message.dataFileIds[i]))
                        return "dataFileIds: string[] expected";
            }
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            if (message.count != null && message.hasOwnProperty("count"))
                if (!$util.isInteger(message.count))
                    return "count: integer expected";
            if (message.firstDate != null && message.hasOwnProperty("firstDate")) {
                let error = $root.gridscope_types.Date.verify(message.firstDate);
                if (error)
                    return "firstDate." + error;
            }
            if (message.lastDate != null && message.hasOwnProperty("lastDate")) {
                let error = $root.gridscope_types.Date.verify(message.lastDate);
                if (error)
                    return "lastDate." + error;
            }
            if (message.interval != null && message.hasOwnProperty("interval")) {
                let error = $root.gridscope_types.TimeDelta.verify(message.interval);
                if (error)
                    return "interval." + error;
            }
            if (message.dataType != null && message.hasOwnProperty("dataType"))
                if (!$util.isString(message.dataType))
                    return "dataType: string expected";
            return null;
        };

        /**
         * Creates a DataFilesGroup message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_data.DataFilesGroup
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_data.DataFilesGroup} DataFilesGroup
         */
        DataFilesGroup.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_data.DataFilesGroup)
                return object;
            let message = new $root.gridscope_data.DataFilesGroup();
            if (object.id != null)
                message.id = String(object.id);
            if (object.dataFileIds) {
                if (!Array.isArray(object.dataFileIds))
                    throw TypeError(".gridscope_data.DataFilesGroup.dataFileIds: array expected");
                message.dataFileIds = [];
                for (let i = 0; i < object.dataFileIds.length; ++i)
                    message.dataFileIds[i] = String(object.dataFileIds[i]);
            }
            if (object.label != null)
                message.label = String(object.label);
            if (object.count != null)
                message.count = object.count | 0;
            if (object.firstDate != null) {
                if (typeof object.firstDate !== "object")
                    throw TypeError(".gridscope_data.DataFilesGroup.firstDate: object expected");
                message.firstDate = $root.gridscope_types.Date.fromObject(object.firstDate);
            }
            if (object.lastDate != null) {
                if (typeof object.lastDate !== "object")
                    throw TypeError(".gridscope_data.DataFilesGroup.lastDate: object expected");
                message.lastDate = $root.gridscope_types.Date.fromObject(object.lastDate);
            }
            if (object.interval != null) {
                if (typeof object.interval !== "object")
                    throw TypeError(".gridscope_data.DataFilesGroup.interval: object expected");
                message.interval = $root.gridscope_types.TimeDelta.fromObject(object.interval);
            }
            if (object.dataType != null)
                message.dataType = String(object.dataType);
            return message;
        };

        /**
         * Creates a plain object from a DataFilesGroup message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_data.DataFilesGroup
         * @static
         * @param {gridscope_data.DataFilesGroup} message DataFilesGroup
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DataFilesGroup.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.dataFileIds = [];
            if (options.defaults) {
                object.id = "";
                object.label = "";
                object.count = 0;
                object.firstDate = null;
                object.lastDate = null;
                object.interval = null;
                object.dataType = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.dataFileIds && message.dataFileIds.length) {
                object.dataFileIds = [];
                for (let j = 0; j < message.dataFileIds.length; ++j)
                    object.dataFileIds[j] = message.dataFileIds[j];
            }
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            if (message.count != null && message.hasOwnProperty("count"))
                object.count = message.count;
            if (message.firstDate != null && message.hasOwnProperty("firstDate"))
                object.firstDate = $root.gridscope_types.Date.toObject(message.firstDate, options);
            if (message.lastDate != null && message.hasOwnProperty("lastDate"))
                object.lastDate = $root.gridscope_types.Date.toObject(message.lastDate, options);
            if (message.interval != null && message.hasOwnProperty("interval"))
                object.interval = $root.gridscope_types.TimeDelta.toObject(message.interval, options);
            if (message.dataType != null && message.hasOwnProperty("dataType"))
                object.dataType = message.dataType;
            return object;
        };

        /**
         * Converts this DataFilesGroup to JSON.
         * @function toJSON
         * @memberof gridscope_data.DataFilesGroup
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DataFilesGroup.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DataFilesGroup;
    })();

    gridscope_data.SaveDataFilesGroup = (function() {

        /**
         * Properties of a SaveDataFilesGroup.
         * @memberof gridscope_data
         * @interface ISaveDataFilesGroup
         * @property {string|null} [id] SaveDataFilesGroup id
         * @property {string|null} [label] SaveDataFilesGroup label
         */

        /**
         * Constructs a new SaveDataFilesGroup.
         * @memberof gridscope_data
         * @classdesc Represents a SaveDataFilesGroup.
         * @implements ISaveDataFilesGroup
         * @constructor
         * @param {gridscope_data.ISaveDataFilesGroup=} [properties] Properties to set
         */
        function SaveDataFilesGroup(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SaveDataFilesGroup id.
         * @member {string} id
         * @memberof gridscope_data.SaveDataFilesGroup
         * @instance
         */
        SaveDataFilesGroup.prototype.id = "";

        /**
         * SaveDataFilesGroup label.
         * @member {string} label
         * @memberof gridscope_data.SaveDataFilesGroup
         * @instance
         */
        SaveDataFilesGroup.prototype.label = "";

        /**
         * Creates a new SaveDataFilesGroup instance using the specified properties.
         * @function create
         * @memberof gridscope_data.SaveDataFilesGroup
         * @static
         * @param {gridscope_data.ISaveDataFilesGroup=} [properties] Properties to set
         * @returns {gridscope_data.SaveDataFilesGroup} SaveDataFilesGroup instance
         */
        SaveDataFilesGroup.create = function create(properties) {
            return new SaveDataFilesGroup(properties);
        };

        /**
         * Encodes the specified SaveDataFilesGroup message. Does not implicitly {@link gridscope_data.SaveDataFilesGroup.verify|verify} messages.
         * @function encode
         * @memberof gridscope_data.SaveDataFilesGroup
         * @static
         * @param {gridscope_data.ISaveDataFilesGroup} message SaveDataFilesGroup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveDataFilesGroup.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
            return writer;
        };

        /**
         * Encodes the specified SaveDataFilesGroup message, length delimited. Does not implicitly {@link gridscope_data.SaveDataFilesGroup.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_data.SaveDataFilesGroup
         * @static
         * @param {gridscope_data.ISaveDataFilesGroup} message SaveDataFilesGroup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveDataFilesGroup.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SaveDataFilesGroup message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_data.SaveDataFilesGroup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_data.SaveDataFilesGroup} SaveDataFilesGroup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveDataFilesGroup.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_data.SaveDataFilesGroup();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.label = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SaveDataFilesGroup message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_data.SaveDataFilesGroup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_data.SaveDataFilesGroup} SaveDataFilesGroup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveDataFilesGroup.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SaveDataFilesGroup message.
         * @function verify
         * @memberof gridscope_data.SaveDataFilesGroup
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SaveDataFilesGroup.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            return null;
        };

        /**
         * Creates a SaveDataFilesGroup message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_data.SaveDataFilesGroup
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_data.SaveDataFilesGroup} SaveDataFilesGroup
         */
        SaveDataFilesGroup.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_data.SaveDataFilesGroup)
                return object;
            let message = new $root.gridscope_data.SaveDataFilesGroup();
            if (object.id != null)
                message.id = String(object.id);
            if (object.label != null)
                message.label = String(object.label);
            return message;
        };

        /**
         * Creates a plain object from a SaveDataFilesGroup message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_data.SaveDataFilesGroup
         * @static
         * @param {gridscope_data.SaveDataFilesGroup} message SaveDataFilesGroup
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SaveDataFilesGroup.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.label = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            return object;
        };

        /**
         * Converts this SaveDataFilesGroup to JSON.
         * @function toJSON
         * @memberof gridscope_data.SaveDataFilesGroup
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SaveDataFilesGroup.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SaveDataFilesGroup;
    })();

    gridscope_data.DataFile = (function() {

        /**
         * Properties of a DataFile.
         * @memberof gridscope_data
         * @interface IDataFile
         * @property {string|null} [id] DataFile id
         * @property {gridscope_types.DataFileType|null} [fileType] DataFile fileType
         * @property {string|null} [label] DataFile label
         * @property {string|null} [name] DataFile name
         * @property {number|null} [count] DataFile count
         * @property {string|null} [url] DataFile url
         * @property {gridscope_types.IDate|null} [firstDate] DataFile firstDate
         * @property {gridscope_types.IDate|null} [lastDate] DataFile lastDate
         * @property {gridscope_types.ITimeDelta|null} [interval] DataFile interval
         * @property {number|null} [nPhases] DataFile nPhases
         * @property {Array.<string>|null} [header] DataFile header
         */

        /**
         * Constructs a new DataFile.
         * @memberof gridscope_data
         * @classdesc Represents a DataFile.
         * @implements IDataFile
         * @constructor
         * @param {gridscope_data.IDataFile=} [properties] Properties to set
         */
        function DataFile(properties) {
            this.header = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DataFile id.
         * @member {string} id
         * @memberof gridscope_data.DataFile
         * @instance
         */
        DataFile.prototype.id = "";

        /**
         * DataFile fileType.
         * @member {gridscope_types.DataFileType} fileType
         * @memberof gridscope_data.DataFile
         * @instance
         */
        DataFile.prototype.fileType = 0;

        /**
         * DataFile label.
         * @member {string} label
         * @memberof gridscope_data.DataFile
         * @instance
         */
        DataFile.prototype.label = "";

        /**
         * DataFile name.
         * @member {string} name
         * @memberof gridscope_data.DataFile
         * @instance
         */
        DataFile.prototype.name = "";

        /**
         * DataFile count.
         * @member {number} count
         * @memberof gridscope_data.DataFile
         * @instance
         */
        DataFile.prototype.count = 0;

        /**
         * DataFile url.
         * @member {string} url
         * @memberof gridscope_data.DataFile
         * @instance
         */
        DataFile.prototype.url = "";

        /**
         * DataFile firstDate.
         * @member {gridscope_types.IDate|null|undefined} firstDate
         * @memberof gridscope_data.DataFile
         * @instance
         */
        DataFile.prototype.firstDate = null;

        /**
         * DataFile lastDate.
         * @member {gridscope_types.IDate|null|undefined} lastDate
         * @memberof gridscope_data.DataFile
         * @instance
         */
        DataFile.prototype.lastDate = null;

        /**
         * DataFile interval.
         * @member {gridscope_types.ITimeDelta|null|undefined} interval
         * @memberof gridscope_data.DataFile
         * @instance
         */
        DataFile.prototype.interval = null;

        /**
         * DataFile nPhases.
         * @member {number} nPhases
         * @memberof gridscope_data.DataFile
         * @instance
         */
        DataFile.prototype.nPhases = 0;

        /**
         * DataFile header.
         * @member {Array.<string>} header
         * @memberof gridscope_data.DataFile
         * @instance
         */
        DataFile.prototype.header = $util.emptyArray;

        /**
         * Creates a new DataFile instance using the specified properties.
         * @function create
         * @memberof gridscope_data.DataFile
         * @static
         * @param {gridscope_data.IDataFile=} [properties] Properties to set
         * @returns {gridscope_data.DataFile} DataFile instance
         */
        DataFile.create = function create(properties) {
            return new DataFile(properties);
        };

        /**
         * Encodes the specified DataFile message. Does not implicitly {@link gridscope_data.DataFile.verify|verify} messages.
         * @function encode
         * @memberof gridscope_data.DataFile
         * @static
         * @param {gridscope_data.IDataFile} message DataFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.fileType != null && Object.hasOwnProperty.call(message, "fileType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.fileType);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.label);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.url);
            if (message.firstDate != null && Object.hasOwnProperty.call(message, "firstDate"))
                $root.gridscope_types.Date.encode(message.firstDate, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.lastDate != null && Object.hasOwnProperty.call(message, "lastDate"))
                $root.gridscope_types.Date.encode(message.lastDate, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.interval != null && Object.hasOwnProperty.call(message, "interval"))
                $root.gridscope_types.TimeDelta.encode(message.interval, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.nPhases != null && Object.hasOwnProperty.call(message, "nPhases"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.nPhases);
            if (message.header != null && message.header.length)
                for (let i = 0; i < message.header.length; ++i)
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.header[i]);
            return writer;
        };

        /**
         * Encodes the specified DataFile message, length delimited. Does not implicitly {@link gridscope_data.DataFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_data.DataFile
         * @static
         * @param {gridscope_data.IDataFile} message DataFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DataFile message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_data.DataFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_data.DataFile} DataFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_data.DataFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.fileType = reader.int32();
                    break;
                case 3:
                    message.label = reader.string();
                    break;
                case 4:
                    message.name = reader.string();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.url = reader.string();
                    break;
                case 7:
                    message.firstDate = $root.gridscope_types.Date.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.lastDate = $root.gridscope_types.Date.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.interval = $root.gridscope_types.TimeDelta.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.nPhases = reader.int32();
                    break;
                case 11:
                    if (!(message.header && message.header.length))
                        message.header = [];
                    message.header.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DataFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_data.DataFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_data.DataFile} DataFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DataFile message.
         * @function verify
         * @memberof gridscope_data.DataFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DataFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.fileType != null && message.hasOwnProperty("fileType"))
                switch (message.fileType) {
                default:
                    return "fileType: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.count != null && message.hasOwnProperty("count"))
                if (!$util.isInteger(message.count))
                    return "count: integer expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.firstDate != null && message.hasOwnProperty("firstDate")) {
                let error = $root.gridscope_types.Date.verify(message.firstDate);
                if (error)
                    return "firstDate." + error;
            }
            if (message.lastDate != null && message.hasOwnProperty("lastDate")) {
                let error = $root.gridscope_types.Date.verify(message.lastDate);
                if (error)
                    return "lastDate." + error;
            }
            if (message.interval != null && message.hasOwnProperty("interval")) {
                let error = $root.gridscope_types.TimeDelta.verify(message.interval);
                if (error)
                    return "interval." + error;
            }
            if (message.nPhases != null && message.hasOwnProperty("nPhases"))
                if (!$util.isInteger(message.nPhases))
                    return "nPhases: integer expected";
            if (message.header != null && message.hasOwnProperty("header")) {
                if (!Array.isArray(message.header))
                    return "header: array expected";
                for (let i = 0; i < message.header.length; ++i)
                    if (!$util.isString(message.header[i]))
                        return "header: string[] expected";
            }
            return null;
        };

        /**
         * Creates a DataFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_data.DataFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_data.DataFile} DataFile
         */
        DataFile.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_data.DataFile)
                return object;
            let message = new $root.gridscope_data.DataFile();
            if (object.id != null)
                message.id = String(object.id);
            switch (object.fileType) {
            case "ami_1phase":
            case 0:
                message.fileType = 0;
                break;
            case "ami_3phase":
            case 1:
                message.fileType = 1;
                break;
            case "ref_data":
            case 2:
                message.fileType = 2;
                break;
            }
            if (object.label != null)
                message.label = String(object.label);
            if (object.name != null)
                message.name = String(object.name);
            if (object.count != null)
                message.count = object.count | 0;
            if (object.url != null)
                message.url = String(object.url);
            if (object.firstDate != null) {
                if (typeof object.firstDate !== "object")
                    throw TypeError(".gridscope_data.DataFile.firstDate: object expected");
                message.firstDate = $root.gridscope_types.Date.fromObject(object.firstDate);
            }
            if (object.lastDate != null) {
                if (typeof object.lastDate !== "object")
                    throw TypeError(".gridscope_data.DataFile.lastDate: object expected");
                message.lastDate = $root.gridscope_types.Date.fromObject(object.lastDate);
            }
            if (object.interval != null) {
                if (typeof object.interval !== "object")
                    throw TypeError(".gridscope_data.DataFile.interval: object expected");
                message.interval = $root.gridscope_types.TimeDelta.fromObject(object.interval);
            }
            if (object.nPhases != null)
                message.nPhases = object.nPhases | 0;
            if (object.header) {
                if (!Array.isArray(object.header))
                    throw TypeError(".gridscope_data.DataFile.header: array expected");
                message.header = [];
                for (let i = 0; i < object.header.length; ++i)
                    message.header[i] = String(object.header[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a DataFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_data.DataFile
         * @static
         * @param {gridscope_data.DataFile} message DataFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DataFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.header = [];
            if (options.defaults) {
                object.id = "";
                object.fileType = options.enums === String ? "ami_1phase" : 0;
                object.label = "";
                object.name = "";
                object.count = 0;
                object.url = "";
                object.firstDate = null;
                object.lastDate = null;
                object.interval = null;
                object.nPhases = 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.fileType != null && message.hasOwnProperty("fileType"))
                object.fileType = options.enums === String ? $root.gridscope_types.DataFileType[message.fileType] : message.fileType;
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.count != null && message.hasOwnProperty("count"))
                object.count = message.count;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.firstDate != null && message.hasOwnProperty("firstDate"))
                object.firstDate = $root.gridscope_types.Date.toObject(message.firstDate, options);
            if (message.lastDate != null && message.hasOwnProperty("lastDate"))
                object.lastDate = $root.gridscope_types.Date.toObject(message.lastDate, options);
            if (message.interval != null && message.hasOwnProperty("interval"))
                object.interval = $root.gridscope_types.TimeDelta.toObject(message.interval, options);
            if (message.nPhases != null && message.hasOwnProperty("nPhases"))
                object.nPhases = message.nPhases;
            if (message.header && message.header.length) {
                object.header = [];
                for (let j = 0; j < message.header.length; ++j)
                    object.header[j] = message.header[j];
            }
            return object;
        };

        /**
         * Converts this DataFile to JSON.
         * @function toJSON
         * @memberof gridscope_data.DataFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DataFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DataFile;
    })();

    gridscope_data.SaveDataFile = (function() {

        /**
         * Properties of a SaveDataFile.
         * @memberof gridscope_data
         * @interface ISaveDataFile
         * @property {string|null} [id] SaveDataFile id
         * @property {string|null} [name] SaveDataFile name
         */

        /**
         * Constructs a new SaveDataFile.
         * @memberof gridscope_data
         * @classdesc Represents a SaveDataFile.
         * @implements ISaveDataFile
         * @constructor
         * @param {gridscope_data.ISaveDataFile=} [properties] Properties to set
         */
        function SaveDataFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SaveDataFile id.
         * @member {string} id
         * @memberof gridscope_data.SaveDataFile
         * @instance
         */
        SaveDataFile.prototype.id = "";

        /**
         * SaveDataFile name.
         * @member {string} name
         * @memberof gridscope_data.SaveDataFile
         * @instance
         */
        SaveDataFile.prototype.name = "";

        /**
         * Creates a new SaveDataFile instance using the specified properties.
         * @function create
         * @memberof gridscope_data.SaveDataFile
         * @static
         * @param {gridscope_data.ISaveDataFile=} [properties] Properties to set
         * @returns {gridscope_data.SaveDataFile} SaveDataFile instance
         */
        SaveDataFile.create = function create(properties) {
            return new SaveDataFile(properties);
        };

        /**
         * Encodes the specified SaveDataFile message. Does not implicitly {@link gridscope_data.SaveDataFile.verify|verify} messages.
         * @function encode
         * @memberof gridscope_data.SaveDataFile
         * @static
         * @param {gridscope_data.ISaveDataFile} message SaveDataFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveDataFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified SaveDataFile message, length delimited. Does not implicitly {@link gridscope_data.SaveDataFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_data.SaveDataFile
         * @static
         * @param {gridscope_data.ISaveDataFile} message SaveDataFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveDataFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SaveDataFile message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_data.SaveDataFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_data.SaveDataFile} SaveDataFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveDataFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_data.SaveDataFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SaveDataFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_data.SaveDataFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_data.SaveDataFile} SaveDataFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveDataFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SaveDataFile message.
         * @function verify
         * @memberof gridscope_data.SaveDataFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SaveDataFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a SaveDataFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_data.SaveDataFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_data.SaveDataFile} SaveDataFile
         */
        SaveDataFile.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_data.SaveDataFile)
                return object;
            let message = new $root.gridscope_data.SaveDataFile();
            if (object.id != null)
                message.id = String(object.id);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a SaveDataFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_data.SaveDataFile
         * @static
         * @param {gridscope_data.SaveDataFile} message SaveDataFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SaveDataFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.name = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this SaveDataFile to JSON.
         * @function toJSON
         * @memberof gridscope_data.SaveDataFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SaveDataFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SaveDataFile;
    })();

    gridscope_data.DeleteDataFiles = (function() {

        /**
         * Properties of a DeleteDataFiles.
         * @memberof gridscope_data
         * @interface IDeleteDataFiles
         * @property {Array.<string>|null} [ids] DeleteDataFiles ids
         */

        /**
         * Constructs a new DeleteDataFiles.
         * @memberof gridscope_data
         * @classdesc Represents a DeleteDataFiles.
         * @implements IDeleteDataFiles
         * @constructor
         * @param {gridscope_data.IDeleteDataFiles=} [properties] Properties to set
         */
        function DeleteDataFiles(properties) {
            this.ids = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteDataFiles ids.
         * @member {Array.<string>} ids
         * @memberof gridscope_data.DeleteDataFiles
         * @instance
         */
        DeleteDataFiles.prototype.ids = $util.emptyArray;

        /**
         * Creates a new DeleteDataFiles instance using the specified properties.
         * @function create
         * @memberof gridscope_data.DeleteDataFiles
         * @static
         * @param {gridscope_data.IDeleteDataFiles=} [properties] Properties to set
         * @returns {gridscope_data.DeleteDataFiles} DeleteDataFiles instance
         */
        DeleteDataFiles.create = function create(properties) {
            return new DeleteDataFiles(properties);
        };

        /**
         * Encodes the specified DeleteDataFiles message. Does not implicitly {@link gridscope_data.DeleteDataFiles.verify|verify} messages.
         * @function encode
         * @memberof gridscope_data.DeleteDataFiles
         * @static
         * @param {gridscope_data.IDeleteDataFiles} message DeleteDataFiles message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteDataFiles.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ids != null && message.ids.length)
                for (let i = 0; i < message.ids.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.ids[i]);
            return writer;
        };

        /**
         * Encodes the specified DeleteDataFiles message, length delimited. Does not implicitly {@link gridscope_data.DeleteDataFiles.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_data.DeleteDataFiles
         * @static
         * @param {gridscope_data.IDeleteDataFiles} message DeleteDataFiles message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteDataFiles.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteDataFiles message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_data.DeleteDataFiles
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_data.DeleteDataFiles} DeleteDataFiles
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteDataFiles.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_data.DeleteDataFiles();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.ids && message.ids.length))
                        message.ids = [];
                    message.ids.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteDataFiles message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_data.DeleteDataFiles
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_data.DeleteDataFiles} DeleteDataFiles
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteDataFiles.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteDataFiles message.
         * @function verify
         * @memberof gridscope_data.DeleteDataFiles
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteDataFiles.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ids != null && message.hasOwnProperty("ids")) {
                if (!Array.isArray(message.ids))
                    return "ids: array expected";
                for (let i = 0; i < message.ids.length; ++i)
                    if (!$util.isString(message.ids[i]))
                        return "ids: string[] expected";
            }
            return null;
        };

        /**
         * Creates a DeleteDataFiles message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_data.DeleteDataFiles
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_data.DeleteDataFiles} DeleteDataFiles
         */
        DeleteDataFiles.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_data.DeleteDataFiles)
                return object;
            let message = new $root.gridscope_data.DeleteDataFiles();
            if (object.ids) {
                if (!Array.isArray(object.ids))
                    throw TypeError(".gridscope_data.DeleteDataFiles.ids: array expected");
                message.ids = [];
                for (let i = 0; i < object.ids.length; ++i)
                    message.ids[i] = String(object.ids[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a DeleteDataFiles message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_data.DeleteDataFiles
         * @static
         * @param {gridscope_data.DeleteDataFiles} message DeleteDataFiles
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteDataFiles.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.ids = [];
            if (message.ids && message.ids.length) {
                object.ids = [];
                for (let j = 0; j < message.ids.length; ++j)
                    object.ids[j] = message.ids[j];
            }
            return object;
        };

        /**
         * Converts this DeleteDataFiles to JSON.
         * @function toJSON
         * @memberof gridscope_data.DeleteDataFiles
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteDataFiles.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteDataFiles;
    })();

    return gridscope_data;
})();

export const gridscope_types = $root.gridscope_types = (() => {

    /**
     * Namespace gridscope_types.
     * @exports gridscope_types
     * @namespace
     */
    const gridscope_types = {};

    gridscope_types.TimeDelta = (function() {

        /**
         * Properties of a TimeDelta.
         * @memberof gridscope_types
         * @interface ITimeDelta
         * @property {number|Long|null} [milliseconds] TimeDelta milliseconds
         */

        /**
         * Constructs a new TimeDelta.
         * @memberof gridscope_types
         * @classdesc Represents a TimeDelta.
         * @implements ITimeDelta
         * @constructor
         * @param {gridscope_types.ITimeDelta=} [properties] Properties to set
         */
        function TimeDelta(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TimeDelta milliseconds.
         * @member {number|Long} milliseconds
         * @memberof gridscope_types.TimeDelta
         * @instance
         */
        TimeDelta.prototype.milliseconds = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new TimeDelta instance using the specified properties.
         * @function create
         * @memberof gridscope_types.TimeDelta
         * @static
         * @param {gridscope_types.ITimeDelta=} [properties] Properties to set
         * @returns {gridscope_types.TimeDelta} TimeDelta instance
         */
        TimeDelta.create = function create(properties) {
            return new TimeDelta(properties);
        };

        /**
         * Encodes the specified TimeDelta message. Does not implicitly {@link gridscope_types.TimeDelta.verify|verify} messages.
         * @function encode
         * @memberof gridscope_types.TimeDelta
         * @static
         * @param {gridscope_types.ITimeDelta} message TimeDelta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimeDelta.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.milliseconds != null && Object.hasOwnProperty.call(message, "milliseconds"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.milliseconds);
            return writer;
        };

        /**
         * Encodes the specified TimeDelta message, length delimited. Does not implicitly {@link gridscope_types.TimeDelta.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_types.TimeDelta
         * @static
         * @param {gridscope_types.ITimeDelta} message TimeDelta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimeDelta.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TimeDelta message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_types.TimeDelta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_types.TimeDelta} TimeDelta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimeDelta.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_types.TimeDelta();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.milliseconds = reader.uint64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TimeDelta message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_types.TimeDelta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_types.TimeDelta} TimeDelta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimeDelta.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TimeDelta message.
         * @function verify
         * @memberof gridscope_types.TimeDelta
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TimeDelta.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.milliseconds != null && message.hasOwnProperty("milliseconds"))
                if (!$util.isInteger(message.milliseconds) && !(message.milliseconds && $util.isInteger(message.milliseconds.low) && $util.isInteger(message.milliseconds.high)))
                    return "milliseconds: integer|Long expected";
            return null;
        };

        /**
         * Creates a TimeDelta message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_types.TimeDelta
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_types.TimeDelta} TimeDelta
         */
        TimeDelta.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_types.TimeDelta)
                return object;
            let message = new $root.gridscope_types.TimeDelta();
            if (object.milliseconds != null)
                if ($util.Long)
                    (message.milliseconds = $util.Long.fromValue(object.milliseconds)).unsigned = true;
                else if (typeof object.milliseconds === "string")
                    message.milliseconds = parseInt(object.milliseconds, 10);
                else if (typeof object.milliseconds === "number")
                    message.milliseconds = object.milliseconds;
                else if (typeof object.milliseconds === "object")
                    message.milliseconds = new $util.LongBits(object.milliseconds.low >>> 0, object.milliseconds.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a TimeDelta message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_types.TimeDelta
         * @static
         * @param {gridscope_types.TimeDelta} message TimeDelta
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TimeDelta.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.milliseconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.milliseconds = options.longs === String ? "0" : 0;
            if (message.milliseconds != null && message.hasOwnProperty("milliseconds"))
                if (typeof message.milliseconds === "number")
                    object.milliseconds = options.longs === String ? String(message.milliseconds) : message.milliseconds;
                else
                    object.milliseconds = options.longs === String ? $util.Long.prototype.toString.call(message.milliseconds) : options.longs === Number ? new $util.LongBits(message.milliseconds.low >>> 0, message.milliseconds.high >>> 0).toNumber(true) : message.milliseconds;
            return object;
        };

        /**
         * Converts this TimeDelta to JSON.
         * @function toJSON
         * @memberof gridscope_types.TimeDelta
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TimeDelta.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TimeDelta;
    })();

    gridscope_types.Date = (function() {

        /**
         * Properties of a Date.
         * @memberof gridscope_types
         * @interface IDate
         * @property {number|Long|null} [ts] Date ts
         * @property {number|null} [day] Date day
         * @property {number|null} [month] Date month
         * @property {number|null} [year] Date year
         * @property {number|null} [hour] Date hour
         * @property {number|null} [minute] Date minute
         * @property {gridscope_types.GTERelation|null} [rDay] Date rDay
         * @property {gridscope_types.GTERelation|null} [rMonth] Date rMonth
         * @property {gridscope_types.GTERelation|null} [rYear] Date rYear
         */

        /**
         * Constructs a new Date.
         * @memberof gridscope_types
         * @classdesc Represents a Date.
         * @implements IDate
         * @constructor
         * @param {gridscope_types.IDate=} [properties] Properties to set
         */
        function Date(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Date ts.
         * @member {number|Long} ts
         * @memberof gridscope_types.Date
         * @instance
         */
        Date.prototype.ts = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Date day.
         * @member {number} day
         * @memberof gridscope_types.Date
         * @instance
         */
        Date.prototype.day = 0;

        /**
         * Date month.
         * @member {number} month
         * @memberof gridscope_types.Date
         * @instance
         */
        Date.prototype.month = 0;

        /**
         * Date year.
         * @member {number} year
         * @memberof gridscope_types.Date
         * @instance
         */
        Date.prototype.year = 0;

        /**
         * Date hour.
         * @member {number} hour
         * @memberof gridscope_types.Date
         * @instance
         */
        Date.prototype.hour = 0;

        /**
         * Date minute.
         * @member {number} minute
         * @memberof gridscope_types.Date
         * @instance
         */
        Date.prototype.minute = 0;

        /**
         * Date rDay.
         * @member {gridscope_types.GTERelation} rDay
         * @memberof gridscope_types.Date
         * @instance
         */
        Date.prototype.rDay = 0;

        /**
         * Date rMonth.
         * @member {gridscope_types.GTERelation} rMonth
         * @memberof gridscope_types.Date
         * @instance
         */
        Date.prototype.rMonth = 0;

        /**
         * Date rYear.
         * @member {gridscope_types.GTERelation} rYear
         * @memberof gridscope_types.Date
         * @instance
         */
        Date.prototype.rYear = 0;

        /**
         * Creates a new Date instance using the specified properties.
         * @function create
         * @memberof gridscope_types.Date
         * @static
         * @param {gridscope_types.IDate=} [properties] Properties to set
         * @returns {gridscope_types.Date} Date instance
         */
        Date.create = function create(properties) {
            return new Date(properties);
        };

        /**
         * Encodes the specified Date message. Does not implicitly {@link gridscope_types.Date.verify|verify} messages.
         * @function encode
         * @memberof gridscope_types.Date
         * @static
         * @param {gridscope_types.IDate} message Date message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Date.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ts != null && Object.hasOwnProperty.call(message, "ts"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.ts);
            if (message.day != null && Object.hasOwnProperty.call(message, "day"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.day);
            if (message.month != null && Object.hasOwnProperty.call(message, "month"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.month);
            if (message.year != null && Object.hasOwnProperty.call(message, "year"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.year);
            if (message.rDay != null && Object.hasOwnProperty.call(message, "rDay"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.rDay);
            if (message.rMonth != null && Object.hasOwnProperty.call(message, "rMonth"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.rMonth);
            if (message.rYear != null && Object.hasOwnProperty.call(message, "rYear"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.rYear);
            if (message.hour != null && Object.hasOwnProperty.call(message, "hour"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.hour);
            if (message.minute != null && Object.hasOwnProperty.call(message, "minute"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.minute);
            return writer;
        };

        /**
         * Encodes the specified Date message, length delimited. Does not implicitly {@link gridscope_types.Date.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_types.Date
         * @static
         * @param {gridscope_types.IDate} message Date message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Date.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Date message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_types.Date
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_types.Date} Date
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Date.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_types.Date();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.ts = reader.uint64();
                    break;
                case 2:
                    message.day = reader.int32();
                    break;
                case 3:
                    message.month = reader.int32();
                    break;
                case 4:
                    message.year = reader.int32();
                    break;
                case 8:
                    message.hour = reader.int32();
                    break;
                case 9:
                    message.minute = reader.int32();
                    break;
                case 5:
                    message.rDay = reader.int32();
                    break;
                case 6:
                    message.rMonth = reader.int32();
                    break;
                case 7:
                    message.rYear = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Date message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_types.Date
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_types.Date} Date
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Date.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Date message.
         * @function verify
         * @memberof gridscope_types.Date
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Date.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ts != null && message.hasOwnProperty("ts"))
                if (!$util.isInteger(message.ts) && !(message.ts && $util.isInteger(message.ts.low) && $util.isInteger(message.ts.high)))
                    return "ts: integer|Long expected";
            if (message.day != null && message.hasOwnProperty("day"))
                if (!$util.isInteger(message.day))
                    return "day: integer expected";
            if (message.month != null && message.hasOwnProperty("month"))
                if (!$util.isInteger(message.month))
                    return "month: integer expected";
            if (message.year != null && message.hasOwnProperty("year"))
                if (!$util.isInteger(message.year))
                    return "year: integer expected";
            if (message.hour != null && message.hasOwnProperty("hour"))
                if (!$util.isInteger(message.hour))
                    return "hour: integer expected";
            if (message.minute != null && message.hasOwnProperty("minute"))
                if (!$util.isInteger(message.minute))
                    return "minute: integer expected";
            if (message.rDay != null && message.hasOwnProperty("rDay"))
                switch (message.rDay) {
                default:
                    return "rDay: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.rMonth != null && message.hasOwnProperty("rMonth"))
                switch (message.rMonth) {
                default:
                    return "rMonth: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.rYear != null && message.hasOwnProperty("rYear"))
                switch (message.rYear) {
                default:
                    return "rYear: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            return null;
        };

        /**
         * Creates a Date message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_types.Date
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_types.Date} Date
         */
        Date.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_types.Date)
                return object;
            let message = new $root.gridscope_types.Date();
            if (object.ts != null)
                if ($util.Long)
                    (message.ts = $util.Long.fromValue(object.ts)).unsigned = true;
                else if (typeof object.ts === "string")
                    message.ts = parseInt(object.ts, 10);
                else if (typeof object.ts === "number")
                    message.ts = object.ts;
                else if (typeof object.ts === "object")
                    message.ts = new $util.LongBits(object.ts.low >>> 0, object.ts.high >>> 0).toNumber(true);
            if (object.day != null)
                message.day = object.day | 0;
            if (object.month != null)
                message.month = object.month | 0;
            if (object.year != null)
                message.year = object.year | 0;
            if (object.hour != null)
                message.hour = object.hour | 0;
            if (object.minute != null)
                message.minute = object.minute | 0;
            switch (object.rDay) {
            case "eq":
            case 0:
                message.rDay = 0;
                break;
            case "gt":
            case 1:
                message.rDay = 1;
                break;
            case "lt":
            case 2:
                message.rDay = 2;
                break;
            case "gte":
            case 3:
                message.rDay = 3;
                break;
            case "lte":
            case 4:
                message.rDay = 4;
                break;
            }
            switch (object.rMonth) {
            case "eq":
            case 0:
                message.rMonth = 0;
                break;
            case "gt":
            case 1:
                message.rMonth = 1;
                break;
            case "lt":
            case 2:
                message.rMonth = 2;
                break;
            case "gte":
            case 3:
                message.rMonth = 3;
                break;
            case "lte":
            case 4:
                message.rMonth = 4;
                break;
            }
            switch (object.rYear) {
            case "eq":
            case 0:
                message.rYear = 0;
                break;
            case "gt":
            case 1:
                message.rYear = 1;
                break;
            case "lt":
            case 2:
                message.rYear = 2;
                break;
            case "gte":
            case 3:
                message.rYear = 3;
                break;
            case "lte":
            case 4:
                message.rYear = 4;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a Date message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_types.Date
         * @static
         * @param {gridscope_types.Date} message Date
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Date.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.ts = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.ts = options.longs === String ? "0" : 0;
                object.day = 0;
                object.month = 0;
                object.year = 0;
                object.rDay = options.enums === String ? "eq" : 0;
                object.rMonth = options.enums === String ? "eq" : 0;
                object.rYear = options.enums === String ? "eq" : 0;
                object.hour = 0;
                object.minute = 0;
            }
            if (message.ts != null && message.hasOwnProperty("ts"))
                if (typeof message.ts === "number")
                    object.ts = options.longs === String ? String(message.ts) : message.ts;
                else
                    object.ts = options.longs === String ? $util.Long.prototype.toString.call(message.ts) : options.longs === Number ? new $util.LongBits(message.ts.low >>> 0, message.ts.high >>> 0).toNumber(true) : message.ts;
            if (message.day != null && message.hasOwnProperty("day"))
                object.day = message.day;
            if (message.month != null && message.hasOwnProperty("month"))
                object.month = message.month;
            if (message.year != null && message.hasOwnProperty("year"))
                object.year = message.year;
            if (message.rDay != null && message.hasOwnProperty("rDay"))
                object.rDay = options.enums === String ? $root.gridscope_types.GTERelation[message.rDay] : message.rDay;
            if (message.rMonth != null && message.hasOwnProperty("rMonth"))
                object.rMonth = options.enums === String ? $root.gridscope_types.GTERelation[message.rMonth] : message.rMonth;
            if (message.rYear != null && message.hasOwnProperty("rYear"))
                object.rYear = options.enums === String ? $root.gridscope_types.GTERelation[message.rYear] : message.rYear;
            if (message.hour != null && message.hasOwnProperty("hour"))
                object.hour = message.hour;
            if (message.minute != null && message.hasOwnProperty("minute"))
                object.minute = message.minute;
            return object;
        };

        /**
         * Converts this Date to JSON.
         * @function toJSON
         * @memberof gridscope_types.Date
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Date.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Date;
    })();

    /**
     * GTERelation enum.
     * @name gridscope_types.GTERelation
     * @enum {number}
     * @property {number} eq=0 eq value
     * @property {number} gt=1 gt value
     * @property {number} lt=2 lt value
     * @property {number} gte=3 gte value
     * @property {number} lte=4 lte value
     */
    gridscope_types.GTERelation = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "eq"] = 0;
        values[valuesById[1] = "gt"] = 1;
        values[valuesById[2] = "lt"] = 2;
        values[valuesById[3] = "gte"] = 3;
        values[valuesById[4] = "lte"] = 4;
        return values;
    })();

    /**
     * DataFileType enum.
     * @name gridscope_types.DataFileType
     * @enum {number}
     * @property {number} ami_1phase=0 ami_1phase value
     * @property {number} ami_3phase=1 ami_3phase value
     * @property {number} ref_data=2 ref_data value
     */
    gridscope_types.DataFileType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ami_1phase"] = 0;
        values[valuesById[1] = "ami_3phase"] = 1;
        values[valuesById[2] = "ref_data"] = 2;
        return values;
    })();

    /**
     * DataType enum.
     * @name gridscope_types.DataType
     * @enum {number}
     * @property {number} int=0 int value
     * @property {number} float=1 float value
     * @property {number} date=2 date value
     */
    gridscope_types.DataType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "int"] = 0;
        values[valuesById[1] = "float"] = 1;
        values[valuesById[2] = "date"] = 2;
        return values;
    })();

    return gridscope_types;
})();

export const uploader = $root.uploader = (() => {

    /**
     * Namespace uploader.
     * @exports uploader
     * @namespace
     */
    const uploader = {};

    uploader.UploadTask = (function() {

        /**
         * Properties of an UploadTask.
         * @memberof uploader
         * @interface IUploadTask
         * @property {number|Long|null} [created] UploadTask created
         * @property {string|null} [path] UploadTask path
         * @property {string|null} [name] UploadTask name
         * @property {string|null} [mime] UploadTask mime
         * @property {uploader.UploadStatus|null} [status] UploadTask status
         * @property {number|null} [retryCounter] UploadTask retryCounter
         * @property {string|null} [error] UploadTask error
         * @property {string|null} [fingerprint] UploadTask fingerprint
         * @property {string|null} [url] UploadTask url
         * @property {Object.<string,string>|null} [metadata] UploadTask metadata
         */

        /**
         * Constructs a new UploadTask.
         * @memberof uploader
         * @classdesc Represents an UploadTask.
         * @implements IUploadTask
         * @constructor
         * @param {uploader.IUploadTask=} [properties] Properties to set
         */
        function UploadTask(properties) {
            this.metadata = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadTask created.
         * @member {number|Long} created
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * UploadTask path.
         * @member {string} path
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.path = "";

        /**
         * UploadTask name.
         * @member {string} name
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.name = "";

        /**
         * UploadTask mime.
         * @member {string} mime
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.mime = "";

        /**
         * UploadTask status.
         * @member {uploader.UploadStatus} status
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.status = 0;

        /**
         * UploadTask retryCounter.
         * @member {number} retryCounter
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.retryCounter = 0;

        /**
         * UploadTask error.
         * @member {string} error
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.error = "";

        /**
         * UploadTask fingerprint.
         * @member {string} fingerprint
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.fingerprint = "";

        /**
         * UploadTask url.
         * @member {string} url
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.url = "";

        /**
         * UploadTask metadata.
         * @member {Object.<string,string>} metadata
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.metadata = $util.emptyObject;

        /**
         * Creates a new UploadTask instance using the specified properties.
         * @function create
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.IUploadTask=} [properties] Properties to set
         * @returns {uploader.UploadTask} UploadTask instance
         */
        UploadTask.create = function create(properties) {
            return new UploadTask(properties);
        };

        /**
         * Encodes the specified UploadTask message. Does not implicitly {@link uploader.UploadTask.verify|verify} messages.
         * @function encode
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.IUploadTask} message UploadTask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadTask.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.created != null && Object.hasOwnProperty.call(message, "created"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.created);
            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.path);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.mime != null && Object.hasOwnProperty.call(message, "mime"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.mime);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.status);
            if (message.retryCounter != null && Object.hasOwnProperty.call(message, "retryCounter"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.retryCounter);
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.error);
            if (message.fingerprint != null && Object.hasOwnProperty.call(message, "fingerprint"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.fingerprint);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.url);
            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                for (let keys = Object.keys(message.metadata), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 10, wireType 2 =*/82).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.metadata[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadTask message, length delimited. Does not implicitly {@link uploader.UploadTask.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.IUploadTask} message UploadTask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadTask.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadTask message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UploadTask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UploadTask} UploadTask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadTask.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UploadTask(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.created = reader.uint64();
                    break;
                case 2:
                    message.path = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.mime = reader.string();
                    break;
                case 5:
                    message.status = reader.int32();
                    break;
                case 6:
                    message.retryCounter = reader.int32();
                    break;
                case 7:
                    message.error = reader.string();
                    break;
                case 8:
                    message.fingerprint = reader.string();
                    break;
                case 9:
                    message.url = reader.string();
                    break;
                case 10:
                    if (message.metadata === $util.emptyObject)
                        message.metadata = {};
                    let end2 = reader.uint32() + reader.pos;
                    key = "";
                    value = "";
                    while (reader.pos < end2) {
                        let tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.string();
                            break;
                        case 2:
                            value = reader.string();
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.metadata[key] = value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadTask message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UploadTask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UploadTask} UploadTask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadTask.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadTask message.
         * @function verify
         * @memberof uploader.UploadTask
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadTask.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.created != null && message.hasOwnProperty("created"))
                if (!$util.isInteger(message.created) && !(message.created && $util.isInteger(message.created.low) && $util.isInteger(message.created.high)))
                    return "created: integer|Long expected";
            if (message.path != null && message.hasOwnProperty("path"))
                if (!$util.isString(message.path))
                    return "path: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.mime != null && message.hasOwnProperty("mime"))
                if (!$util.isString(message.mime))
                    return "mime: string expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 3:
                case 4:
                case 5:
                case 6:
                    break;
                }
            if (message.retryCounter != null && message.hasOwnProperty("retryCounter"))
                if (!$util.isInteger(message.retryCounter))
                    return "retryCounter: integer expected";
            if (message.error != null && message.hasOwnProperty("error"))
                if (!$util.isString(message.error))
                    return "error: string expected";
            if (message.fingerprint != null && message.hasOwnProperty("fingerprint"))
                if (!$util.isString(message.fingerprint))
                    return "fingerprint: string expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                if (!$util.isObject(message.metadata))
                    return "metadata: object expected";
                let key = Object.keys(message.metadata);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.metadata[key[i]]))
                        return "metadata: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates an UploadTask message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UploadTask
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UploadTask} UploadTask
         */
        UploadTask.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UploadTask)
                return object;
            let message = new $root.uploader.UploadTask();
            if (object.created != null)
                if ($util.Long)
                    (message.created = $util.Long.fromValue(object.created)).unsigned = true;
                else if (typeof object.created === "string")
                    message.created = parseInt(object.created, 10);
                else if (typeof object.created === "number")
                    message.created = object.created;
                else if (typeof object.created === "object")
                    message.created = new $util.LongBits(object.created.low >>> 0, object.created.high >>> 0).toNumber(true);
            if (object.path != null)
                message.path = String(object.path);
            if (object.name != null)
                message.name = String(object.name);
            if (object.mime != null)
                message.mime = String(object.mime);
            switch (object.status) {
            case "scheduled":
            case 0:
                message.status = 0;
                break;
            case "uploading":
            case 1:
                message.status = 1;
                break;
            case "paused":
            case 3:
                message.status = 3;
                break;
            case "done":
            case 4:
                message.status = 4;
                break;
            case "error":
            case 5:
                message.status = 5;
                break;
            case "restored":
            case 6:
                message.status = 6;
                break;
            }
            if (object.retryCounter != null)
                message.retryCounter = object.retryCounter | 0;
            if (object.error != null)
                message.error = String(object.error);
            if (object.fingerprint != null)
                message.fingerprint = String(object.fingerprint);
            if (object.url != null)
                message.url = String(object.url);
            if (object.metadata) {
                if (typeof object.metadata !== "object")
                    throw TypeError(".uploader.UploadTask.metadata: object expected");
                message.metadata = {};
                for (let keys = Object.keys(object.metadata), i = 0; i < keys.length; ++i)
                    message.metadata[keys[i]] = String(object.metadata[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadTask message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.UploadTask} message UploadTask
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadTask.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.metadata = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.created = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.created = options.longs === String ? "0" : 0;
                object.path = "";
                object.name = "";
                object.mime = "";
                object.status = options.enums === String ? "scheduled" : 0;
                object.retryCounter = 0;
                object.error = "";
                object.fingerprint = "";
                object.url = "";
            }
            if (message.created != null && message.hasOwnProperty("created"))
                if (typeof message.created === "number")
                    object.created = options.longs === String ? String(message.created) : message.created;
                else
                    object.created = options.longs === String ? $util.Long.prototype.toString.call(message.created) : options.longs === Number ? new $util.LongBits(message.created.low >>> 0, message.created.high >>> 0).toNumber(true) : message.created;
            if (message.path != null && message.hasOwnProperty("path"))
                object.path = message.path;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.mime != null && message.hasOwnProperty("mime"))
                object.mime = message.mime;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.uploader.UploadStatus[message.status] : message.status;
            if (message.retryCounter != null && message.hasOwnProperty("retryCounter"))
                object.retryCounter = message.retryCounter;
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = message.error;
            if (message.fingerprint != null && message.hasOwnProperty("fingerprint"))
                object.fingerprint = message.fingerprint;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            let keys2;
            if (message.metadata && (keys2 = Object.keys(message.metadata)).length) {
                object.metadata = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.metadata[keys2[j]] = message.metadata[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this UploadTask to JSON.
         * @function toJSON
         * @memberof uploader.UploadTask
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadTask.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadTask;
    })();

    /**
     * UploadStatus enum.
     * @name uploader.UploadStatus
     * @enum {number}
     * @property {number} scheduled=0 scheduled value
     * @property {number} uploading=1 uploading value
     * @property {number} paused=3 paused value
     * @property {number} done=4 done value
     * @property {number} error=5 error value
     * @property {number} restored=6 restored value
     */
    uploader.UploadStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "scheduled"] = 0;
        values[valuesById[1] = "uploading"] = 1;
        values[valuesById[3] = "paused"] = 3;
        values[valuesById[4] = "done"] = 4;
        values[valuesById[5] = "error"] = 5;
        values[valuesById[6] = "restored"] = 6;
        return values;
    })();

    uploader.UploadUFile = (function() {

        /**
         * Properties of an UploadUFile.
         * @memberof uploader
         * @interface IUploadUFile
         * @property {string|null} [fileId] UploadUFile fileId
         * @property {uploader.IUploadTask|null} [task] UploadUFile task
         */

        /**
         * Constructs a new UploadUFile.
         * @memberof uploader
         * @classdesc Represents an UploadUFile.
         * @implements IUploadUFile
         * @constructor
         * @param {uploader.IUploadUFile=} [properties] Properties to set
         */
        function UploadUFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadUFile fileId.
         * @member {string} fileId
         * @memberof uploader.UploadUFile
         * @instance
         */
        UploadUFile.prototype.fileId = "";

        /**
         * UploadUFile task.
         * @member {uploader.IUploadTask|null|undefined} task
         * @memberof uploader.UploadUFile
         * @instance
         */
        UploadUFile.prototype.task = null;

        /**
         * Creates a new UploadUFile instance using the specified properties.
         * @function create
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.IUploadUFile=} [properties] Properties to set
         * @returns {uploader.UploadUFile} UploadUFile instance
         */
        UploadUFile.create = function create(properties) {
            return new UploadUFile(properties);
        };

        /**
         * Encodes the specified UploadUFile message. Does not implicitly {@link uploader.UploadUFile.verify|verify} messages.
         * @function encode
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.IUploadUFile} message UploadUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadUFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fileId);
            if (message.task != null && Object.hasOwnProperty.call(message, "task"))
                $root.uploader.UploadTask.encode(message.task, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadUFile message, length delimited. Does not implicitly {@link uploader.UploadUFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.IUploadUFile} message UploadUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadUFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadUFile message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UploadUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UploadUFile} UploadUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadUFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UploadUFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fileId = reader.string();
                    break;
                case 2:
                    message.task = $root.uploader.UploadTask.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadUFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UploadUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UploadUFile} UploadUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadUFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadUFile message.
         * @function verify
         * @memberof uploader.UploadUFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadUFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isString(message.fileId))
                    return "fileId: string expected";
            if (message.task != null && message.hasOwnProperty("task")) {
                let error = $root.uploader.UploadTask.verify(message.task);
                if (error)
                    return "task." + error;
            }
            return null;
        };

        /**
         * Creates an UploadUFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UploadUFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UploadUFile} UploadUFile
         */
        UploadUFile.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UploadUFile)
                return object;
            let message = new $root.uploader.UploadUFile();
            if (object.fileId != null)
                message.fileId = String(object.fileId);
            if (object.task != null) {
                if (typeof object.task !== "object")
                    throw TypeError(".uploader.UploadUFile.task: object expected");
                message.task = $root.uploader.UploadTask.fromObject(object.task);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadUFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.UploadUFile} message UploadUFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadUFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.fileId = "";
                object.task = null;
            }
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            if (message.task != null && message.hasOwnProperty("task"))
                object.task = $root.uploader.UploadTask.toObject(message.task, options);
            return object;
        };

        /**
         * Converts this UploadUFile to JSON.
         * @function toJSON
         * @memberof uploader.UploadUFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadUFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadUFile;
    })();

    uploader.DeleteUFile = (function() {

        /**
         * Properties of a DeleteUFile.
         * @memberof uploader
         * @interface IDeleteUFile
         * @property {string|null} [id] DeleteUFile id
         */

        /**
         * Constructs a new DeleteUFile.
         * @memberof uploader
         * @classdesc Represents a DeleteUFile.
         * @implements IDeleteUFile
         * @constructor
         * @param {uploader.IDeleteUFile=} [properties] Properties to set
         */
        function DeleteUFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteUFile id.
         * @member {string} id
         * @memberof uploader.DeleteUFile
         * @instance
         */
        DeleteUFile.prototype.id = "";

        /**
         * Creates a new DeleteUFile instance using the specified properties.
         * @function create
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.IDeleteUFile=} [properties] Properties to set
         * @returns {uploader.DeleteUFile} DeleteUFile instance
         */
        DeleteUFile.create = function create(properties) {
            return new DeleteUFile(properties);
        };

        /**
         * Encodes the specified DeleteUFile message. Does not implicitly {@link uploader.DeleteUFile.verify|verify} messages.
         * @function encode
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.IDeleteUFile} message DeleteUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteUFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified DeleteUFile message, length delimited. Does not implicitly {@link uploader.DeleteUFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.IDeleteUFile} message DeleteUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteUFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteUFile message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.DeleteUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.DeleteUFile} DeleteUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteUFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.DeleteUFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteUFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.DeleteUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.DeleteUFile} DeleteUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteUFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteUFile message.
         * @function verify
         * @memberof uploader.DeleteUFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteUFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            return null;
        };

        /**
         * Creates a DeleteUFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.DeleteUFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.DeleteUFile} DeleteUFile
         */
        DeleteUFile.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.DeleteUFile)
                return object;
            let message = new $root.uploader.DeleteUFile();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a DeleteUFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.DeleteUFile} message DeleteUFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteUFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.id = "";
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this DeleteUFile to JSON.
         * @function toJSON
         * @memberof uploader.DeleteUFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteUFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteUFile;
    })();

    uploader.UploadSlot = (function() {

        /**
         * Properties of an UploadSlot.
         * @memberof uploader
         * @interface IUploadSlot
         * @property {uploader.IUFile|null} [file] UploadSlot file
         * @property {Object.<string,string>|null} [metadata] UploadSlot metadata
         */

        /**
         * Constructs a new UploadSlot.
         * @memberof uploader
         * @classdesc Represents an UploadSlot.
         * @implements IUploadSlot
         * @constructor
         * @param {uploader.IUploadSlot=} [properties] Properties to set
         */
        function UploadSlot(properties) {
            this.metadata = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadSlot file.
         * @member {uploader.IUFile|null|undefined} file
         * @memberof uploader.UploadSlot
         * @instance
         */
        UploadSlot.prototype.file = null;

        /**
         * UploadSlot metadata.
         * @member {Object.<string,string>} metadata
         * @memberof uploader.UploadSlot
         * @instance
         */
        UploadSlot.prototype.metadata = $util.emptyObject;

        /**
         * Creates a new UploadSlot instance using the specified properties.
         * @function create
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.IUploadSlot=} [properties] Properties to set
         * @returns {uploader.UploadSlot} UploadSlot instance
         */
        UploadSlot.create = function create(properties) {
            return new UploadSlot(properties);
        };

        /**
         * Encodes the specified UploadSlot message. Does not implicitly {@link uploader.UploadSlot.verify|verify} messages.
         * @function encode
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.IUploadSlot} message UploadSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadSlot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                $root.uploader.UFile.encode(message.file, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                for (let keys = Object.keys(message.metadata), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.metadata[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadSlot message, length delimited. Does not implicitly {@link uploader.UploadSlot.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.IUploadSlot} message UploadSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadSlot.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadSlot message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UploadSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UploadSlot} UploadSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadSlot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UploadSlot(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.file = $root.uploader.UFile.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (message.metadata === $util.emptyObject)
                        message.metadata = {};
                    let end2 = reader.uint32() + reader.pos;
                    key = "";
                    value = "";
                    while (reader.pos < end2) {
                        let tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.string();
                            break;
                        case 2:
                            value = reader.string();
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.metadata[key] = value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadSlot message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UploadSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UploadSlot} UploadSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadSlot.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadSlot message.
         * @function verify
         * @memberof uploader.UploadSlot
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadSlot.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.file != null && message.hasOwnProperty("file")) {
                let error = $root.uploader.UFile.verify(message.file);
                if (error)
                    return "file." + error;
            }
            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                if (!$util.isObject(message.metadata))
                    return "metadata: object expected";
                let key = Object.keys(message.metadata);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.metadata[key[i]]))
                        return "metadata: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates an UploadSlot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UploadSlot
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UploadSlot} UploadSlot
         */
        UploadSlot.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UploadSlot)
                return object;
            let message = new $root.uploader.UploadSlot();
            if (object.file != null) {
                if (typeof object.file !== "object")
                    throw TypeError(".uploader.UploadSlot.file: object expected");
                message.file = $root.uploader.UFile.fromObject(object.file);
            }
            if (object.metadata) {
                if (typeof object.metadata !== "object")
                    throw TypeError(".uploader.UploadSlot.metadata: object expected");
                message.metadata = {};
                for (let keys = Object.keys(object.metadata), i = 0; i < keys.length; ++i)
                    message.metadata[keys[i]] = String(object.metadata[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadSlot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.UploadSlot} message UploadSlot
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadSlot.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.metadata = {};
            if (options.defaults)
                object.file = null;
            if (message.file != null && message.hasOwnProperty("file"))
                object.file = $root.uploader.UFile.toObject(message.file, options);
            let keys2;
            if (message.metadata && (keys2 = Object.keys(message.metadata)).length) {
                object.metadata = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.metadata[keys2[j]] = message.metadata[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this UploadSlot to JSON.
         * @function toJSON
         * @memberof uploader.UploadSlot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadSlot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadSlot;
    })();

    uploader.UFile = (function() {

        /**
         * Properties of a UFile.
         * @memberof uploader
         * @interface IUFile
         * @property {string|null} [id] UFile id
         * @property {string|null} [src] UFile src
         * @property {string|null} [preview] UFile preview
         * @property {string|null} [previewLarge] UFile previewLarge
         * @property {string|null} [name] UFile name
         * @property {string|null} [uploadTask] UFile uploadTask
         * @property {string|null} [localPath] UFile localPath
         */

        /**
         * Constructs a new UFile.
         * @memberof uploader
         * @classdesc Represents a UFile.
         * @implements IUFile
         * @constructor
         * @param {uploader.IUFile=} [properties] Properties to set
         */
        function UFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UFile id.
         * @member {string} id
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.id = "";

        /**
         * UFile src.
         * @member {string} src
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.src = "";

        /**
         * UFile preview.
         * @member {string} preview
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.preview = "";

        /**
         * UFile previewLarge.
         * @member {string} previewLarge
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.previewLarge = "";

        /**
         * UFile name.
         * @member {string} name
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.name = "";

        /**
         * UFile uploadTask.
         * @member {string} uploadTask
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.uploadTask = "";

        /**
         * UFile localPath.
         * @member {string} localPath
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.localPath = "";

        /**
         * Creates a new UFile instance using the specified properties.
         * @function create
         * @memberof uploader.UFile
         * @static
         * @param {uploader.IUFile=} [properties] Properties to set
         * @returns {uploader.UFile} UFile instance
         */
        UFile.create = function create(properties) {
            return new UFile(properties);
        };

        /**
         * Encodes the specified UFile message. Does not implicitly {@link uploader.UFile.verify|verify} messages.
         * @function encode
         * @memberof uploader.UFile
         * @static
         * @param {uploader.IUFile} message UFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.src != null && Object.hasOwnProperty.call(message, "src"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.src);
            if (message.preview != null && Object.hasOwnProperty.call(message, "preview"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.preview);
            if (message.previewLarge != null && Object.hasOwnProperty.call(message, "previewLarge"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.previewLarge);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.name);
            if (message.uploadTask != null && Object.hasOwnProperty.call(message, "uploadTask"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.uploadTask);
            if (message.localPath != null && Object.hasOwnProperty.call(message, "localPath"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.localPath);
            return writer;
        };

        /**
         * Encodes the specified UFile message, length delimited. Does not implicitly {@link uploader.UFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UFile
         * @static
         * @param {uploader.IUFile} message UFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UFile message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UFile} UFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.src = reader.string();
                    break;
                case 3:
                    message.preview = reader.string();
                    break;
                case 4:
                    message.previewLarge = reader.string();
                    break;
                case 5:
                    message.name = reader.string();
                    break;
                case 6:
                    message.uploadTask = reader.string();
                    break;
                case 7:
                    message.localPath = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UFile} UFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UFile message.
         * @function verify
         * @memberof uploader.UFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.src != null && message.hasOwnProperty("src"))
                if (!$util.isString(message.src))
                    return "src: string expected";
            if (message.preview != null && message.hasOwnProperty("preview"))
                if (!$util.isString(message.preview))
                    return "preview: string expected";
            if (message.previewLarge != null && message.hasOwnProperty("previewLarge"))
                if (!$util.isString(message.previewLarge))
                    return "previewLarge: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.uploadTask != null && message.hasOwnProperty("uploadTask"))
                if (!$util.isString(message.uploadTask))
                    return "uploadTask: string expected";
            if (message.localPath != null && message.hasOwnProperty("localPath"))
                if (!$util.isString(message.localPath))
                    return "localPath: string expected";
            return null;
        };

        /**
         * Creates a UFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UFile} UFile
         */
        UFile.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UFile)
                return object;
            let message = new $root.uploader.UFile();
            if (object.id != null)
                message.id = String(object.id);
            if (object.src != null)
                message.src = String(object.src);
            if (object.preview != null)
                message.preview = String(object.preview);
            if (object.previewLarge != null)
                message.previewLarge = String(object.previewLarge);
            if (object.name != null)
                message.name = String(object.name);
            if (object.uploadTask != null)
                message.uploadTask = String(object.uploadTask);
            if (object.localPath != null)
                message.localPath = String(object.localPath);
            return message;
        };

        /**
         * Creates a plain object from a UFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UFile
         * @static
         * @param {uploader.UFile} message UFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.src = "";
                object.preview = "";
                object.previewLarge = "";
                object.name = "";
                object.uploadTask = "";
                object.localPath = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.src != null && message.hasOwnProperty("src"))
                object.src = message.src;
            if (message.preview != null && message.hasOwnProperty("preview"))
                object.preview = message.preview;
            if (message.previewLarge != null && message.hasOwnProperty("previewLarge"))
                object.previewLarge = message.previewLarge;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.uploadTask != null && message.hasOwnProperty("uploadTask"))
                object.uploadTask = message.uploadTask;
            if (message.localPath != null && message.hasOwnProperty("localPath"))
                object.localPath = message.localPath;
            return object;
        };

        /**
         * Converts this UFile to JSON.
         * @function toJSON
         * @memberof uploader.UFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UFile;
    })();

    return uploader;
})();

export const inputs = $root.inputs = (() => {

    /**
     * Namespace inputs.
     * @exports inputs
     * @namespace
     */
    const inputs = {};

    inputs.LoadSelectOptions = (function() {

        /**
         * Properties of a LoadSelectOptions.
         * @memberof inputs
         * @interface ILoadSelectOptions
         * @property {string|null} [key] LoadSelectOptions key
         * @property {string|null} [search] LoadSelectOptions search
         */

        /**
         * Constructs a new LoadSelectOptions.
         * @memberof inputs
         * @classdesc Represents a LoadSelectOptions.
         * @implements ILoadSelectOptions
         * @constructor
         * @param {inputs.ILoadSelectOptions=} [properties] Properties to set
         */
        function LoadSelectOptions(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadSelectOptions key.
         * @member {string} key
         * @memberof inputs.LoadSelectOptions
         * @instance
         */
        LoadSelectOptions.prototype.key = "";

        /**
         * LoadSelectOptions search.
         * @member {string} search
         * @memberof inputs.LoadSelectOptions
         * @instance
         */
        LoadSelectOptions.prototype.search = "";

        /**
         * Creates a new LoadSelectOptions instance using the specified properties.
         * @function create
         * @memberof inputs.LoadSelectOptions
         * @static
         * @param {inputs.ILoadSelectOptions=} [properties] Properties to set
         * @returns {inputs.LoadSelectOptions} LoadSelectOptions instance
         */
        LoadSelectOptions.create = function create(properties) {
            return new LoadSelectOptions(properties);
        };

        /**
         * Encodes the specified LoadSelectOptions message. Does not implicitly {@link inputs.LoadSelectOptions.verify|verify} messages.
         * @function encode
         * @memberof inputs.LoadSelectOptions
         * @static
         * @param {inputs.ILoadSelectOptions} message LoadSelectOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadSelectOptions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.search != null && Object.hasOwnProperty.call(message, "search"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.search);
            return writer;
        };

        /**
         * Encodes the specified LoadSelectOptions message, length delimited. Does not implicitly {@link inputs.LoadSelectOptions.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inputs.LoadSelectOptions
         * @static
         * @param {inputs.ILoadSelectOptions} message LoadSelectOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadSelectOptions.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadSelectOptions message from the specified reader or buffer.
         * @function decode
         * @memberof inputs.LoadSelectOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inputs.LoadSelectOptions} LoadSelectOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadSelectOptions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inputs.LoadSelectOptions();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.search = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadSelectOptions message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inputs.LoadSelectOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inputs.LoadSelectOptions} LoadSelectOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadSelectOptions.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadSelectOptions message.
         * @function verify
         * @memberof inputs.LoadSelectOptions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadSelectOptions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.search != null && message.hasOwnProperty("search"))
                if (!$util.isString(message.search))
                    return "search: string expected";
            return null;
        };

        /**
         * Creates a LoadSelectOptions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inputs.LoadSelectOptions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inputs.LoadSelectOptions} LoadSelectOptions
         */
        LoadSelectOptions.fromObject = function fromObject(object) {
            if (object instanceof $root.inputs.LoadSelectOptions)
                return object;
            let message = new $root.inputs.LoadSelectOptions();
            if (object.key != null)
                message.key = String(object.key);
            if (object.search != null)
                message.search = String(object.search);
            return message;
        };

        /**
         * Creates a plain object from a LoadSelectOptions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inputs.LoadSelectOptions
         * @static
         * @param {inputs.LoadSelectOptions} message LoadSelectOptions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadSelectOptions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.search = "";
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.search != null && message.hasOwnProperty("search"))
                object.search = message.search;
            return object;
        };

        /**
         * Converts this LoadSelectOptions to JSON.
         * @function toJSON
         * @memberof inputs.LoadSelectOptions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadSelectOptions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoadSelectOptions;
    })();

    inputs.SelectOptions = (function() {

        /**
         * Properties of a SelectOptions.
         * @memberof inputs
         * @interface ISelectOptions
         * @property {string|null} [key] SelectOptions key
         * @property {string|null} [search] SelectOptions search
         * @property {Array.<inputs.IInputValue>|null} [options] SelectOptions options
         * @property {boolean|null} [isAll] SelectOptions isAll
         */

        /**
         * Constructs a new SelectOptions.
         * @memberof inputs
         * @classdesc Represents a SelectOptions.
         * @implements ISelectOptions
         * @constructor
         * @param {inputs.ISelectOptions=} [properties] Properties to set
         */
        function SelectOptions(properties) {
            this.options = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SelectOptions key.
         * @member {string} key
         * @memberof inputs.SelectOptions
         * @instance
         */
        SelectOptions.prototype.key = "";

        /**
         * SelectOptions search.
         * @member {string} search
         * @memberof inputs.SelectOptions
         * @instance
         */
        SelectOptions.prototype.search = "";

        /**
         * SelectOptions options.
         * @member {Array.<inputs.IInputValue>} options
         * @memberof inputs.SelectOptions
         * @instance
         */
        SelectOptions.prototype.options = $util.emptyArray;

        /**
         * SelectOptions isAll.
         * @member {boolean} isAll
         * @memberof inputs.SelectOptions
         * @instance
         */
        SelectOptions.prototype.isAll = false;

        /**
         * Creates a new SelectOptions instance using the specified properties.
         * @function create
         * @memberof inputs.SelectOptions
         * @static
         * @param {inputs.ISelectOptions=} [properties] Properties to set
         * @returns {inputs.SelectOptions} SelectOptions instance
         */
        SelectOptions.create = function create(properties) {
            return new SelectOptions(properties);
        };

        /**
         * Encodes the specified SelectOptions message. Does not implicitly {@link inputs.SelectOptions.verify|verify} messages.
         * @function encode
         * @memberof inputs.SelectOptions
         * @static
         * @param {inputs.ISelectOptions} message SelectOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SelectOptions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.search != null && Object.hasOwnProperty.call(message, "search"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.search);
            if (message.options != null && message.options.length)
                for (let i = 0; i < message.options.length; ++i)
                    $root.inputs.InputValue.encode(message.options[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.isAll != null && Object.hasOwnProperty.call(message, "isAll"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isAll);
            return writer;
        };

        /**
         * Encodes the specified SelectOptions message, length delimited. Does not implicitly {@link inputs.SelectOptions.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inputs.SelectOptions
         * @static
         * @param {inputs.ISelectOptions} message SelectOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SelectOptions.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SelectOptions message from the specified reader or buffer.
         * @function decode
         * @memberof inputs.SelectOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inputs.SelectOptions} SelectOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SelectOptions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inputs.SelectOptions();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.search = reader.string();
                    break;
                case 3:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.inputs.InputValue.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.isAll = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SelectOptions message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inputs.SelectOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inputs.SelectOptions} SelectOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SelectOptions.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SelectOptions message.
         * @function verify
         * @memberof inputs.SelectOptions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SelectOptions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.search != null && message.hasOwnProperty("search"))
                if (!$util.isString(message.search))
                    return "search: string expected";
            if (message.options != null && message.hasOwnProperty("options")) {
                if (!Array.isArray(message.options))
                    return "options: array expected";
                for (let i = 0; i < message.options.length; ++i) {
                    let error = $root.inputs.InputValue.verify(message.options[i]);
                    if (error)
                        return "options." + error;
                }
            }
            if (message.isAll != null && message.hasOwnProperty("isAll"))
                if (typeof message.isAll !== "boolean")
                    return "isAll: boolean expected";
            return null;
        };

        /**
         * Creates a SelectOptions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inputs.SelectOptions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inputs.SelectOptions} SelectOptions
         */
        SelectOptions.fromObject = function fromObject(object) {
            if (object instanceof $root.inputs.SelectOptions)
                return object;
            let message = new $root.inputs.SelectOptions();
            if (object.key != null)
                message.key = String(object.key);
            if (object.search != null)
                message.search = String(object.search);
            if (object.options) {
                if (!Array.isArray(object.options))
                    throw TypeError(".inputs.SelectOptions.options: array expected");
                message.options = [];
                for (let i = 0; i < object.options.length; ++i) {
                    if (typeof object.options[i] !== "object")
                        throw TypeError(".inputs.SelectOptions.options: object expected");
                    message.options[i] = $root.inputs.InputValue.fromObject(object.options[i]);
                }
            }
            if (object.isAll != null)
                message.isAll = Boolean(object.isAll);
            return message;
        };

        /**
         * Creates a plain object from a SelectOptions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inputs.SelectOptions
         * @static
         * @param {inputs.SelectOptions} message SelectOptions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SelectOptions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.options = [];
            if (options.defaults) {
                object.key = "";
                object.search = "";
                object.isAll = false;
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.search != null && message.hasOwnProperty("search"))
                object.search = message.search;
            if (message.options && message.options.length) {
                object.options = [];
                for (let j = 0; j < message.options.length; ++j)
                    object.options[j] = $root.inputs.InputValue.toObject(message.options[j], options);
            }
            if (message.isAll != null && message.hasOwnProperty("isAll"))
                object.isAll = message.isAll;
            return object;
        };

        /**
         * Converts this SelectOptions to JSON.
         * @function toJSON
         * @memberof inputs.SelectOptions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SelectOptions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SelectOptions;
    })();

    inputs.InputValue = (function() {

        /**
         * Properties of an InputValue.
         * @memberof inputs
         * @interface IInputValue
         * @property {number|null} [number] InputValue number
         * @property {string|null} [text] InputValue text
         * @property {gridscope_types.IDate|null} [date] InputValue date
         * @property {boolean|null} [boolVal] InputValue boolVal
         * @property {string|null} [id] InputValue id
         * @property {boolean|null} [hasData] InputValue hasData
         */

        /**
         * Constructs a new InputValue.
         * @memberof inputs
         * @classdesc Represents an InputValue.
         * @implements IInputValue
         * @constructor
         * @param {inputs.IInputValue=} [properties] Properties to set
         */
        function InputValue(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InputValue number.
         * @member {number} number
         * @memberof inputs.InputValue
         * @instance
         */
        InputValue.prototype.number = 0;

        /**
         * InputValue text.
         * @member {string} text
         * @memberof inputs.InputValue
         * @instance
         */
        InputValue.prototype.text = "";

        /**
         * InputValue date.
         * @member {gridscope_types.IDate|null|undefined} date
         * @memberof inputs.InputValue
         * @instance
         */
        InputValue.prototype.date = null;

        /**
         * InputValue boolVal.
         * @member {boolean} boolVal
         * @memberof inputs.InputValue
         * @instance
         */
        InputValue.prototype.boolVal = false;

        /**
         * InputValue id.
         * @member {string} id
         * @memberof inputs.InputValue
         * @instance
         */
        InputValue.prototype.id = "";

        /**
         * InputValue hasData.
         * @member {boolean} hasData
         * @memberof inputs.InputValue
         * @instance
         */
        InputValue.prototype.hasData = false;

        /**
         * Creates a new InputValue instance using the specified properties.
         * @function create
         * @memberof inputs.InputValue
         * @static
         * @param {inputs.IInputValue=} [properties] Properties to set
         * @returns {inputs.InputValue} InputValue instance
         */
        InputValue.create = function create(properties) {
            return new InputValue(properties);
        };

        /**
         * Encodes the specified InputValue message. Does not implicitly {@link inputs.InputValue.verify|verify} messages.
         * @function encode
         * @memberof inputs.InputValue
         * @static
         * @param {inputs.IInputValue} message InputValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InputValue.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.number);
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.text);
            if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                $root.gridscope_types.Date.encode(message.date, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.boolVal != null && Object.hasOwnProperty.call(message, "boolVal"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.boolVal);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.id);
            if (message.hasData != null && Object.hasOwnProperty.call(message, "hasData"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.hasData);
            return writer;
        };

        /**
         * Encodes the specified InputValue message, length delimited. Does not implicitly {@link inputs.InputValue.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inputs.InputValue
         * @static
         * @param {inputs.IInputValue} message InputValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InputValue.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InputValue message from the specified reader or buffer.
         * @function decode
         * @memberof inputs.InputValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inputs.InputValue} InputValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InputValue.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inputs.InputValue();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.number = reader.double();
                    break;
                case 2:
                    message.text = reader.string();
                    break;
                case 3:
                    message.date = $root.gridscope_types.Date.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.boolVal = reader.bool();
                    break;
                case 5:
                    message.id = reader.string();
                    break;
                case 6:
                    message.hasData = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an InputValue message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inputs.InputValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inputs.InputValue} InputValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InputValue.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an InputValue message.
         * @function verify
         * @memberof inputs.InputValue
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InputValue.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.number != null && message.hasOwnProperty("number"))
                if (typeof message.number !== "number")
                    return "number: number expected";
            if (message.text != null && message.hasOwnProperty("text"))
                if (!$util.isString(message.text))
                    return "text: string expected";
            if (message.date != null && message.hasOwnProperty("date")) {
                let error = $root.gridscope_types.Date.verify(message.date);
                if (error)
                    return "date." + error;
            }
            if (message.boolVal != null && message.hasOwnProperty("boolVal"))
                if (typeof message.boolVal !== "boolean")
                    return "boolVal: boolean expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.hasData != null && message.hasOwnProperty("hasData"))
                if (typeof message.hasData !== "boolean")
                    return "hasData: boolean expected";
            return null;
        };

        /**
         * Creates an InputValue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inputs.InputValue
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inputs.InputValue} InputValue
         */
        InputValue.fromObject = function fromObject(object) {
            if (object instanceof $root.inputs.InputValue)
                return object;
            let message = new $root.inputs.InputValue();
            if (object.number != null)
                message.number = Number(object.number);
            if (object.text != null)
                message.text = String(object.text);
            if (object.date != null) {
                if (typeof object.date !== "object")
                    throw TypeError(".inputs.InputValue.date: object expected");
                message.date = $root.gridscope_types.Date.fromObject(object.date);
            }
            if (object.boolVal != null)
                message.boolVal = Boolean(object.boolVal);
            if (object.id != null)
                message.id = String(object.id);
            if (object.hasData != null)
                message.hasData = Boolean(object.hasData);
            return message;
        };

        /**
         * Creates a plain object from an InputValue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inputs.InputValue
         * @static
         * @param {inputs.InputValue} message InputValue
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InputValue.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.number = 0;
                object.text = "";
                object.date = null;
                object.boolVal = false;
                object.id = "";
                object.hasData = false;
            }
            if (message.number != null && message.hasOwnProperty("number"))
                object.number = options.json && !isFinite(message.number) ? String(message.number) : message.number;
            if (message.text != null && message.hasOwnProperty("text"))
                object.text = message.text;
            if (message.date != null && message.hasOwnProperty("date"))
                object.date = $root.gridscope_types.Date.toObject(message.date, options);
            if (message.boolVal != null && message.hasOwnProperty("boolVal"))
                object.boolVal = message.boolVal;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.hasData != null && message.hasOwnProperty("hasData"))
                object.hasData = message.hasData;
            return object;
        };

        /**
         * Converts this InputValue to JSON.
         * @function toJSON
         * @memberof inputs.InputValue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InputValue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return InputValue;
    })();

    inputs.Input = (function() {

        /**
         * Properties of an Input.
         * @memberof inputs
         * @interface IInput
         * @property {string|null} [key] Input key
         * @property {inputs.InputType|null} [type] Input type
         * @property {string|null} [label] Input label
         * @property {inputs.IInputValue|null} [value] Input value
         * @property {Array.<inputs.IInputValue>|null} [values] Input values
         * @property {boolean|null} [readonly] Input readonly
         * @property {boolean|null} [repeated] Input repeated
         */

        /**
         * Constructs a new Input.
         * @memberof inputs
         * @classdesc Represents an Input.
         * @implements IInput
         * @constructor
         * @param {inputs.IInput=} [properties] Properties to set
         */
        function Input(properties) {
            this.values = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Input key.
         * @member {string} key
         * @memberof inputs.Input
         * @instance
         */
        Input.prototype.key = "";

        /**
         * Input type.
         * @member {inputs.InputType} type
         * @memberof inputs.Input
         * @instance
         */
        Input.prototype.type = 0;

        /**
         * Input label.
         * @member {string} label
         * @memberof inputs.Input
         * @instance
         */
        Input.prototype.label = "";

        /**
         * Input value.
         * @member {inputs.IInputValue|null|undefined} value
         * @memberof inputs.Input
         * @instance
         */
        Input.prototype.value = null;

        /**
         * Input values.
         * @member {Array.<inputs.IInputValue>} values
         * @memberof inputs.Input
         * @instance
         */
        Input.prototype.values = $util.emptyArray;

        /**
         * Input readonly.
         * @member {boolean} readonly
         * @memberof inputs.Input
         * @instance
         */
        Input.prototype.readonly = false;

        /**
         * Input repeated.
         * @member {boolean} repeated
         * @memberof inputs.Input
         * @instance
         */
        Input.prototype.repeated = false;

        /**
         * Creates a new Input instance using the specified properties.
         * @function create
         * @memberof inputs.Input
         * @static
         * @param {inputs.IInput=} [properties] Properties to set
         * @returns {inputs.Input} Input instance
         */
        Input.create = function create(properties) {
            return new Input(properties);
        };

        /**
         * Encodes the specified Input message. Does not implicitly {@link inputs.Input.verify|verify} messages.
         * @function encode
         * @memberof inputs.Input
         * @static
         * @param {inputs.IInput} message Input message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Input.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.label);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                $root.inputs.InputValue.encode(message.value, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.values != null && message.values.length)
                for (let i = 0; i < message.values.length; ++i)
                    $root.inputs.InputValue.encode(message.values[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.readonly != null && Object.hasOwnProperty.call(message, "readonly"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.readonly);
            if (message.repeated != null && Object.hasOwnProperty.call(message, "repeated"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.repeated);
            return writer;
        };

        /**
         * Encodes the specified Input message, length delimited. Does not implicitly {@link inputs.Input.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inputs.Input
         * @static
         * @param {inputs.IInput} message Input message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Input.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Input message from the specified reader or buffer.
         * @function decode
         * @memberof inputs.Input
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inputs.Input} Input
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Input.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inputs.Input();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 3:
                    message.label = reader.string();
                    break;
                case 4:
                    message.value = $root.inputs.InputValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    if (!(message.values && message.values.length))
                        message.values = [];
                    message.values.push($root.inputs.InputValue.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.readonly = reader.bool();
                    break;
                case 7:
                    message.repeated = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Input message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inputs.Input
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inputs.Input} Input
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Input.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Input message.
         * @function verify
         * @memberof inputs.Input
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Input.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                    break;
                }
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            if (message.value != null && message.hasOwnProperty("value")) {
                let error = $root.inputs.InputValue.verify(message.value);
                if (error)
                    return "value." + error;
            }
            if (message.values != null && message.hasOwnProperty("values")) {
                if (!Array.isArray(message.values))
                    return "values: array expected";
                for (let i = 0; i < message.values.length; ++i) {
                    let error = $root.inputs.InputValue.verify(message.values[i]);
                    if (error)
                        return "values." + error;
                }
            }
            if (message.readonly != null && message.hasOwnProperty("readonly"))
                if (typeof message.readonly !== "boolean")
                    return "readonly: boolean expected";
            if (message.repeated != null && message.hasOwnProperty("repeated"))
                if (typeof message.repeated !== "boolean")
                    return "repeated: boolean expected";
            return null;
        };

        /**
         * Creates an Input message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inputs.Input
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inputs.Input} Input
         */
        Input.fromObject = function fromObject(object) {
            if (object instanceof $root.inputs.Input)
                return object;
            let message = new $root.inputs.Input();
            if (object.key != null)
                message.key = String(object.key);
            switch (object.type) {
            case "text":
            case 0:
                message.type = 0;
                break;
            case "number":
            case 1:
                message.type = 1;
                break;
            case "date":
            case 2:
                message.type = 2;
                break;
            case "free_select":
            case 3:
                message.type = 3;
                break;
            case "fixed_select":
            case 4:
                message.type = 4;
                break;
            case "check":
            case 5:
                message.type = 5;
                break;
            case "dateTime":
            case 6:
                message.type = 6;
                break;
            }
            if (object.label != null)
                message.label = String(object.label);
            if (object.value != null) {
                if (typeof object.value !== "object")
                    throw TypeError(".inputs.Input.value: object expected");
                message.value = $root.inputs.InputValue.fromObject(object.value);
            }
            if (object.values) {
                if (!Array.isArray(object.values))
                    throw TypeError(".inputs.Input.values: array expected");
                message.values = [];
                for (let i = 0; i < object.values.length; ++i) {
                    if (typeof object.values[i] !== "object")
                        throw TypeError(".inputs.Input.values: object expected");
                    message.values[i] = $root.inputs.InputValue.fromObject(object.values[i]);
                }
            }
            if (object.readonly != null)
                message.readonly = Boolean(object.readonly);
            if (object.repeated != null)
                message.repeated = Boolean(object.repeated);
            return message;
        };

        /**
         * Creates a plain object from an Input message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inputs.Input
         * @static
         * @param {inputs.Input} message Input
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Input.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.values = [];
            if (options.defaults) {
                object.key = "";
                object.type = options.enums === String ? "text" : 0;
                object.label = "";
                object.value = null;
                object.readonly = false;
                object.repeated = false;
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.inputs.InputType[message.type] : message.type;
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = $root.inputs.InputValue.toObject(message.value, options);
            if (message.values && message.values.length) {
                object.values = [];
                for (let j = 0; j < message.values.length; ++j)
                    object.values[j] = $root.inputs.InputValue.toObject(message.values[j], options);
            }
            if (message.readonly != null && message.hasOwnProperty("readonly"))
                object.readonly = message.readonly;
            if (message.repeated != null && message.hasOwnProperty("repeated"))
                object.repeated = message.repeated;
            return object;
        };

        /**
         * Converts this Input to JSON.
         * @function toJSON
         * @memberof inputs.Input
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Input.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Input;
    })();

    /**
     * InputType enum.
     * @name inputs.InputType
     * @enum {number}
     * @property {number} text=0 text value
     * @property {number} number=1 number value
     * @property {number} date=2 date value
     * @property {number} free_select=3 free_select value
     * @property {number} fixed_select=4 fixed_select value
     * @property {number} check=5 check value
     * @property {number} dateTime=6 dateTime value
     */
    inputs.InputType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "text"] = 0;
        values[valuesById[1] = "number"] = 1;
        values[valuesById[2] = "date"] = 2;
        values[valuesById[3] = "free_select"] = 3;
        values[valuesById[4] = "fixed_select"] = 4;
        values[valuesById[5] = "check"] = 5;
        values[valuesById[6] = "dateTime"] = 6;
        return values;
    })();

    return inputs;
})();

export const gridscope_project = $root.gridscope_project = (() => {

    /**
     * Namespace gridscope_project.
     * @exports gridscope_project
     * @namespace
     */
    const gridscope_project = {};

    gridscope_project.SaveProject = (function() {

        /**
         * Properties of a SaveProject.
         * @memberof gridscope_project
         * @interface ISaveProject
         * @property {string|null} [projectKey] SaveProject projectKey
         * @property {string|null} [name] SaveProject name
         */

        /**
         * Constructs a new SaveProject.
         * @memberof gridscope_project
         * @classdesc Represents a SaveProject.
         * @implements ISaveProject
         * @constructor
         * @param {gridscope_project.ISaveProject=} [properties] Properties to set
         */
        function SaveProject(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SaveProject projectKey.
         * @member {string} projectKey
         * @memberof gridscope_project.SaveProject
         * @instance
         */
        SaveProject.prototype.projectKey = "";

        /**
         * SaveProject name.
         * @member {string} name
         * @memberof gridscope_project.SaveProject
         * @instance
         */
        SaveProject.prototype.name = "";

        /**
         * Creates a new SaveProject instance using the specified properties.
         * @function create
         * @memberof gridscope_project.SaveProject
         * @static
         * @param {gridscope_project.ISaveProject=} [properties] Properties to set
         * @returns {gridscope_project.SaveProject} SaveProject instance
         */
        SaveProject.create = function create(properties) {
            return new SaveProject(properties);
        };

        /**
         * Encodes the specified SaveProject message. Does not implicitly {@link gridscope_project.SaveProject.verify|verify} messages.
         * @function encode
         * @memberof gridscope_project.SaveProject
         * @static
         * @param {gridscope_project.ISaveProject} message SaveProject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveProject.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectKey != null && Object.hasOwnProperty.call(message, "projectKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.projectKey);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified SaveProject message, length delimited. Does not implicitly {@link gridscope_project.SaveProject.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_project.SaveProject
         * @static
         * @param {gridscope_project.ISaveProject} message SaveProject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveProject.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SaveProject message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_project.SaveProject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_project.SaveProject} SaveProject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveProject.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_project.SaveProject();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.projectKey = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SaveProject message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_project.SaveProject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_project.SaveProject} SaveProject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveProject.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SaveProject message.
         * @function verify
         * @memberof gridscope_project.SaveProject
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SaveProject.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                if (!$util.isString(message.projectKey))
                    return "projectKey: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a SaveProject message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_project.SaveProject
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_project.SaveProject} SaveProject
         */
        SaveProject.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_project.SaveProject)
                return object;
            let message = new $root.gridscope_project.SaveProject();
            if (object.projectKey != null)
                message.projectKey = String(object.projectKey);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a SaveProject message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_project.SaveProject
         * @static
         * @param {gridscope_project.SaveProject} message SaveProject
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SaveProject.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.projectKey = "";
                object.name = "";
            }
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                object.projectKey = message.projectKey;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this SaveProject to JSON.
         * @function toJSON
         * @memberof gridscope_project.SaveProject
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SaveProject.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SaveProject;
    })();

    gridscope_project.ArchiveProject = (function() {

        /**
         * Properties of an ArchiveProject.
         * @memberof gridscope_project
         * @interface IArchiveProject
         * @property {string|null} [projectKey] ArchiveProject projectKey
         */

        /**
         * Constructs a new ArchiveProject.
         * @memberof gridscope_project
         * @classdesc Represents an ArchiveProject.
         * @implements IArchiveProject
         * @constructor
         * @param {gridscope_project.IArchiveProject=} [properties] Properties to set
         */
        function ArchiveProject(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ArchiveProject projectKey.
         * @member {string} projectKey
         * @memberof gridscope_project.ArchiveProject
         * @instance
         */
        ArchiveProject.prototype.projectKey = "";

        /**
         * Creates a new ArchiveProject instance using the specified properties.
         * @function create
         * @memberof gridscope_project.ArchiveProject
         * @static
         * @param {gridscope_project.IArchiveProject=} [properties] Properties to set
         * @returns {gridscope_project.ArchiveProject} ArchiveProject instance
         */
        ArchiveProject.create = function create(properties) {
            return new ArchiveProject(properties);
        };

        /**
         * Encodes the specified ArchiveProject message. Does not implicitly {@link gridscope_project.ArchiveProject.verify|verify} messages.
         * @function encode
         * @memberof gridscope_project.ArchiveProject
         * @static
         * @param {gridscope_project.IArchiveProject} message ArchiveProject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArchiveProject.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectKey != null && Object.hasOwnProperty.call(message, "projectKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.projectKey);
            return writer;
        };

        /**
         * Encodes the specified ArchiveProject message, length delimited. Does not implicitly {@link gridscope_project.ArchiveProject.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_project.ArchiveProject
         * @static
         * @param {gridscope_project.IArchiveProject} message ArchiveProject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArchiveProject.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ArchiveProject message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_project.ArchiveProject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_project.ArchiveProject} ArchiveProject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArchiveProject.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_project.ArchiveProject();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.projectKey = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ArchiveProject message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_project.ArchiveProject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_project.ArchiveProject} ArchiveProject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArchiveProject.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ArchiveProject message.
         * @function verify
         * @memberof gridscope_project.ArchiveProject
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ArchiveProject.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                if (!$util.isString(message.projectKey))
                    return "projectKey: string expected";
            return null;
        };

        /**
         * Creates an ArchiveProject message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_project.ArchiveProject
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_project.ArchiveProject} ArchiveProject
         */
        ArchiveProject.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_project.ArchiveProject)
                return object;
            let message = new $root.gridscope_project.ArchiveProject();
            if (object.projectKey != null)
                message.projectKey = String(object.projectKey);
            return message;
        };

        /**
         * Creates a plain object from an ArchiveProject message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_project.ArchiveProject
         * @static
         * @param {gridscope_project.ArchiveProject} message ArchiveProject
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArchiveProject.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.projectKey = "";
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                object.projectKey = message.projectKey;
            return object;
        };

        /**
         * Converts this ArchiveProject to JSON.
         * @function toJSON
         * @memberof gridscope_project.ArchiveProject
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArchiveProject.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ArchiveProject;
    })();

    gridscope_project.LoadProject = (function() {

        /**
         * Properties of a LoadProject.
         * @memberof gridscope_project
         * @interface ILoadProject
         * @property {string|null} [projectKey] LoadProject projectKey
         */

        /**
         * Constructs a new LoadProject.
         * @memberof gridscope_project
         * @classdesc Represents a LoadProject.
         * @implements ILoadProject
         * @constructor
         * @param {gridscope_project.ILoadProject=} [properties] Properties to set
         */
        function LoadProject(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadProject projectKey.
         * @member {string} projectKey
         * @memberof gridscope_project.LoadProject
         * @instance
         */
        LoadProject.prototype.projectKey = "";

        /**
         * Creates a new LoadProject instance using the specified properties.
         * @function create
         * @memberof gridscope_project.LoadProject
         * @static
         * @param {gridscope_project.ILoadProject=} [properties] Properties to set
         * @returns {gridscope_project.LoadProject} LoadProject instance
         */
        LoadProject.create = function create(properties) {
            return new LoadProject(properties);
        };

        /**
         * Encodes the specified LoadProject message. Does not implicitly {@link gridscope_project.LoadProject.verify|verify} messages.
         * @function encode
         * @memberof gridscope_project.LoadProject
         * @static
         * @param {gridscope_project.ILoadProject} message LoadProject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadProject.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectKey != null && Object.hasOwnProperty.call(message, "projectKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.projectKey);
            return writer;
        };

        /**
         * Encodes the specified LoadProject message, length delimited. Does not implicitly {@link gridscope_project.LoadProject.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_project.LoadProject
         * @static
         * @param {gridscope_project.ILoadProject} message LoadProject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadProject.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadProject message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_project.LoadProject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_project.LoadProject} LoadProject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadProject.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_project.LoadProject();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.projectKey = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadProject message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_project.LoadProject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_project.LoadProject} LoadProject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadProject.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadProject message.
         * @function verify
         * @memberof gridscope_project.LoadProject
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadProject.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                if (!$util.isString(message.projectKey))
                    return "projectKey: string expected";
            return null;
        };

        /**
         * Creates a LoadProject message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_project.LoadProject
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_project.LoadProject} LoadProject
         */
        LoadProject.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_project.LoadProject)
                return object;
            let message = new $root.gridscope_project.LoadProject();
            if (object.projectKey != null)
                message.projectKey = String(object.projectKey);
            return message;
        };

        /**
         * Creates a plain object from a LoadProject message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_project.LoadProject
         * @static
         * @param {gridscope_project.LoadProject} message LoadProject
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadProject.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.projectKey = "";
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                object.projectKey = message.projectKey;
            return object;
        };

        /**
         * Converts this LoadProject to JSON.
         * @function toJSON
         * @memberof gridscope_project.LoadProject
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadProject.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoadProject;
    })();

    gridscope_project.CreateProject = (function() {

        /**
         * Properties of a CreateProject.
         * @memberof gridscope_project
         * @interface ICreateProject
         */

        /**
         * Constructs a new CreateProject.
         * @memberof gridscope_project
         * @classdesc Represents a CreateProject.
         * @implements ICreateProject
         * @constructor
         * @param {gridscope_project.ICreateProject=} [properties] Properties to set
         */
        function CreateProject(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new CreateProject instance using the specified properties.
         * @function create
         * @memberof gridscope_project.CreateProject
         * @static
         * @param {gridscope_project.ICreateProject=} [properties] Properties to set
         * @returns {gridscope_project.CreateProject} CreateProject instance
         */
        CreateProject.create = function create(properties) {
            return new CreateProject(properties);
        };

        /**
         * Encodes the specified CreateProject message. Does not implicitly {@link gridscope_project.CreateProject.verify|verify} messages.
         * @function encode
         * @memberof gridscope_project.CreateProject
         * @static
         * @param {gridscope_project.ICreateProject} message CreateProject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateProject.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified CreateProject message, length delimited. Does not implicitly {@link gridscope_project.CreateProject.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_project.CreateProject
         * @static
         * @param {gridscope_project.ICreateProject} message CreateProject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateProject.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateProject message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_project.CreateProject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_project.CreateProject} CreateProject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateProject.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_project.CreateProject();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateProject message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_project.CreateProject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_project.CreateProject} CreateProject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateProject.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateProject message.
         * @function verify
         * @memberof gridscope_project.CreateProject
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateProject.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a CreateProject message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_project.CreateProject
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_project.CreateProject} CreateProject
         */
        CreateProject.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_project.CreateProject)
                return object;
            return new $root.gridscope_project.CreateProject();
        };

        /**
         * Creates a plain object from a CreateProject message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_project.CreateProject
         * @static
         * @param {gridscope_project.CreateProject} message CreateProject
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateProject.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this CreateProject to JSON.
         * @function toJSON
         * @memberof gridscope_project.CreateProject
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateProject.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateProject;
    })();

    gridscope_project.SetProject = (function() {

        /**
         * Properties of a SetProject.
         * @memberof gridscope_project
         * @interface ISetProject
         * @property {string|null} [key] SetProject key
         */

        /**
         * Constructs a new SetProject.
         * @memberof gridscope_project
         * @classdesc Represents a SetProject.
         * @implements ISetProject
         * @constructor
         * @param {gridscope_project.ISetProject=} [properties] Properties to set
         */
        function SetProject(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetProject key.
         * @member {string} key
         * @memberof gridscope_project.SetProject
         * @instance
         */
        SetProject.prototype.key = "";

        /**
         * Creates a new SetProject instance using the specified properties.
         * @function create
         * @memberof gridscope_project.SetProject
         * @static
         * @param {gridscope_project.ISetProject=} [properties] Properties to set
         * @returns {gridscope_project.SetProject} SetProject instance
         */
        SetProject.create = function create(properties) {
            return new SetProject(properties);
        };

        /**
         * Encodes the specified SetProject message. Does not implicitly {@link gridscope_project.SetProject.verify|verify} messages.
         * @function encode
         * @memberof gridscope_project.SetProject
         * @static
         * @param {gridscope_project.ISetProject} message SetProject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetProject.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            return writer;
        };

        /**
         * Encodes the specified SetProject message, length delimited. Does not implicitly {@link gridscope_project.SetProject.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_project.SetProject
         * @static
         * @param {gridscope_project.ISetProject} message SetProject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetProject.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetProject message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_project.SetProject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_project.SetProject} SetProject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetProject.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_project.SetProject();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetProject message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_project.SetProject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_project.SetProject} SetProject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetProject.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetProject message.
         * @function verify
         * @memberof gridscope_project.SetProject
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetProject.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            return null;
        };

        /**
         * Creates a SetProject message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_project.SetProject
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_project.SetProject} SetProject
         */
        SetProject.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_project.SetProject)
                return object;
            let message = new $root.gridscope_project.SetProject();
            if (object.key != null)
                message.key = String(object.key);
            return message;
        };

        /**
         * Creates a plain object from a SetProject message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_project.SetProject
         * @static
         * @param {gridscope_project.SetProject} message SetProject
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetProject.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.key = "";
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            return object;
        };

        /**
         * Converts this SetProject to JSON.
         * @function toJSON
         * @memberof gridscope_project.SetProject
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetProject.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SetProject;
    })();

    gridscope_project.Project = (function() {

        /**
         * Properties of a Project.
         * @memberof gridscope_project
         * @interface IProject
         * @property {string|null} [projectKey] Project projectKey
         * @property {string|null} [name] Project name
         */

        /**
         * Constructs a new Project.
         * @memberof gridscope_project
         * @classdesc Represents a Project.
         * @implements IProject
         * @constructor
         * @param {gridscope_project.IProject=} [properties] Properties to set
         */
        function Project(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Project projectKey.
         * @member {string} projectKey
         * @memberof gridscope_project.Project
         * @instance
         */
        Project.prototype.projectKey = "";

        /**
         * Project name.
         * @member {string} name
         * @memberof gridscope_project.Project
         * @instance
         */
        Project.prototype.name = "";

        /**
         * Creates a new Project instance using the specified properties.
         * @function create
         * @memberof gridscope_project.Project
         * @static
         * @param {gridscope_project.IProject=} [properties] Properties to set
         * @returns {gridscope_project.Project} Project instance
         */
        Project.create = function create(properties) {
            return new Project(properties);
        };

        /**
         * Encodes the specified Project message. Does not implicitly {@link gridscope_project.Project.verify|verify} messages.
         * @function encode
         * @memberof gridscope_project.Project
         * @static
         * @param {gridscope_project.IProject} message Project message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Project.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectKey != null && Object.hasOwnProperty.call(message, "projectKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.projectKey);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified Project message, length delimited. Does not implicitly {@link gridscope_project.Project.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_project.Project
         * @static
         * @param {gridscope_project.IProject} message Project message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Project.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Project message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_project.Project
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_project.Project} Project
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Project.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_project.Project();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.projectKey = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Project message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_project.Project
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_project.Project} Project
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Project.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Project message.
         * @function verify
         * @memberof gridscope_project.Project
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Project.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                if (!$util.isString(message.projectKey))
                    return "projectKey: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a Project message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_project.Project
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_project.Project} Project
         */
        Project.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_project.Project)
                return object;
            let message = new $root.gridscope_project.Project();
            if (object.projectKey != null)
                message.projectKey = String(object.projectKey);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a Project message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_project.Project
         * @static
         * @param {gridscope_project.Project} message Project
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Project.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.projectKey = "";
                object.name = "";
            }
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                object.projectKey = message.projectKey;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this Project to JSON.
         * @function toJSON
         * @memberof gridscope_project.Project
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Project.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Project;
    })();

    return gridscope_project;
})();

export const gridscope_dashboard = $root.gridscope_dashboard = (() => {

    /**
     * Namespace gridscope_dashboard.
     * @exports gridscope_dashboard
     * @namespace
     */
    const gridscope_dashboard = {};

    gridscope_dashboard.LoadProjects = (function() {

        /**
         * Properties of a LoadProjects.
         * @memberof gridscope_dashboard
         * @interface ILoadProjects
         */

        /**
         * Constructs a new LoadProjects.
         * @memberof gridscope_dashboard
         * @classdesc Represents a LoadProjects.
         * @implements ILoadProjects
         * @constructor
         * @param {gridscope_dashboard.ILoadProjects=} [properties] Properties to set
         */
        function LoadProjects(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new LoadProjects instance using the specified properties.
         * @function create
         * @memberof gridscope_dashboard.LoadProjects
         * @static
         * @param {gridscope_dashboard.ILoadProjects=} [properties] Properties to set
         * @returns {gridscope_dashboard.LoadProjects} LoadProjects instance
         */
        LoadProjects.create = function create(properties) {
            return new LoadProjects(properties);
        };

        /**
         * Encodes the specified LoadProjects message. Does not implicitly {@link gridscope_dashboard.LoadProjects.verify|verify} messages.
         * @function encode
         * @memberof gridscope_dashboard.LoadProjects
         * @static
         * @param {gridscope_dashboard.ILoadProjects} message LoadProjects message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadProjects.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified LoadProjects message, length delimited. Does not implicitly {@link gridscope_dashboard.LoadProjects.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_dashboard.LoadProjects
         * @static
         * @param {gridscope_dashboard.ILoadProjects} message LoadProjects message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadProjects.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadProjects message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_dashboard.LoadProjects
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_dashboard.LoadProjects} LoadProjects
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadProjects.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_dashboard.LoadProjects();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadProjects message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_dashboard.LoadProjects
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_dashboard.LoadProjects} LoadProjects
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadProjects.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadProjects message.
         * @function verify
         * @memberof gridscope_dashboard.LoadProjects
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadProjects.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a LoadProjects message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_dashboard.LoadProjects
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_dashboard.LoadProjects} LoadProjects
         */
        LoadProjects.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_dashboard.LoadProjects)
                return object;
            return new $root.gridscope_dashboard.LoadProjects();
        };

        /**
         * Creates a plain object from a LoadProjects message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_dashboard.LoadProjects
         * @static
         * @param {gridscope_dashboard.LoadProjects} message LoadProjects
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadProjects.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this LoadProjects to JSON.
         * @function toJSON
         * @memberof gridscope_dashboard.LoadProjects
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadProjects.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoadProjects;
    })();

    gridscope_dashboard.Projects = (function() {

        /**
         * Properties of a Projects.
         * @memberof gridscope_dashboard
         * @interface IProjects
         * @property {Array.<gridscope_project.IProject>|null} [projects] Projects projects
         */

        /**
         * Constructs a new Projects.
         * @memberof gridscope_dashboard
         * @classdesc Represents a Projects.
         * @implements IProjects
         * @constructor
         * @param {gridscope_dashboard.IProjects=} [properties] Properties to set
         */
        function Projects(properties) {
            this.projects = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Projects projects.
         * @member {Array.<gridscope_project.IProject>} projects
         * @memberof gridscope_dashboard.Projects
         * @instance
         */
        Projects.prototype.projects = $util.emptyArray;

        /**
         * Creates a new Projects instance using the specified properties.
         * @function create
         * @memberof gridscope_dashboard.Projects
         * @static
         * @param {gridscope_dashboard.IProjects=} [properties] Properties to set
         * @returns {gridscope_dashboard.Projects} Projects instance
         */
        Projects.create = function create(properties) {
            return new Projects(properties);
        };

        /**
         * Encodes the specified Projects message. Does not implicitly {@link gridscope_dashboard.Projects.verify|verify} messages.
         * @function encode
         * @memberof gridscope_dashboard.Projects
         * @static
         * @param {gridscope_dashboard.IProjects} message Projects message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Projects.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projects != null && message.projects.length)
                for (let i = 0; i < message.projects.length; ++i)
                    $root.gridscope_project.Project.encode(message.projects[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Projects message, length delimited. Does not implicitly {@link gridscope_dashboard.Projects.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_dashboard.Projects
         * @static
         * @param {gridscope_dashboard.IProjects} message Projects message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Projects.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Projects message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_dashboard.Projects
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_dashboard.Projects} Projects
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Projects.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_dashboard.Projects();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.projects && message.projects.length))
                        message.projects = [];
                    message.projects.push($root.gridscope_project.Project.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Projects message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_dashboard.Projects
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_dashboard.Projects} Projects
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Projects.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Projects message.
         * @function verify
         * @memberof gridscope_dashboard.Projects
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Projects.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.projects != null && message.hasOwnProperty("projects")) {
                if (!Array.isArray(message.projects))
                    return "projects: array expected";
                for (let i = 0; i < message.projects.length; ++i) {
                    let error = $root.gridscope_project.Project.verify(message.projects[i]);
                    if (error)
                        return "projects." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Projects message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_dashboard.Projects
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_dashboard.Projects} Projects
         */
        Projects.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_dashboard.Projects)
                return object;
            let message = new $root.gridscope_dashboard.Projects();
            if (object.projects) {
                if (!Array.isArray(object.projects))
                    throw TypeError(".gridscope_dashboard.Projects.projects: array expected");
                message.projects = [];
                for (let i = 0; i < object.projects.length; ++i) {
                    if (typeof object.projects[i] !== "object")
                        throw TypeError(".gridscope_dashboard.Projects.projects: object expected");
                    message.projects[i] = $root.gridscope_project.Project.fromObject(object.projects[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a Projects message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_dashboard.Projects
         * @static
         * @param {gridscope_dashboard.Projects} message Projects
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Projects.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.projects = [];
            if (message.projects && message.projects.length) {
                object.projects = [];
                for (let j = 0; j < message.projects.length; ++j)
                    object.projects[j] = $root.gridscope_project.Project.toObject(message.projects[j], options);
            }
            return object;
        };

        /**
         * Converts this Projects to JSON.
         * @function toJSON
         * @memberof gridscope_dashboard.Projects
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Projects.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Projects;
    })();

    return gridscope_dashboard;
})();

export const gridscope = $root.gridscope = (() => {

    /**
     * Namespace gridscope.
     * @exports gridscope
     * @namespace
     */
    const gridscope = {};

    gridscope.ProfileData = (function() {

        /**
         * Properties of a ProfileData.
         * @memberof gridscope
         * @interface IProfileData
         * @property {number|null} [id] ProfileData id
         * @property {string|null} [name] ProfileData name
         * @property {Array.<string>|null} [groups] ProfileData groups
         */

        /**
         * Constructs a new ProfileData.
         * @memberof gridscope
         * @classdesc Represents a ProfileData.
         * @implements IProfileData
         * @constructor
         * @param {gridscope.IProfileData=} [properties] Properties to set
         */
        function ProfileData(properties) {
            this.groups = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProfileData id.
         * @member {number} id
         * @memberof gridscope.ProfileData
         * @instance
         */
        ProfileData.prototype.id = 0;

        /**
         * ProfileData name.
         * @member {string} name
         * @memberof gridscope.ProfileData
         * @instance
         */
        ProfileData.prototype.name = "";

        /**
         * ProfileData groups.
         * @member {Array.<string>} groups
         * @memberof gridscope.ProfileData
         * @instance
         */
        ProfileData.prototype.groups = $util.emptyArray;

        /**
         * Creates a new ProfileData instance using the specified properties.
         * @function create
         * @memberof gridscope.ProfileData
         * @static
         * @param {gridscope.IProfileData=} [properties] Properties to set
         * @returns {gridscope.ProfileData} ProfileData instance
         */
        ProfileData.create = function create(properties) {
            return new ProfileData(properties);
        };

        /**
         * Encodes the specified ProfileData message. Does not implicitly {@link gridscope.ProfileData.verify|verify} messages.
         * @function encode
         * @memberof gridscope.ProfileData
         * @static
         * @param {gridscope.IProfileData} message ProfileData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfileData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.groups != null && message.groups.length)
                for (let i = 0; i < message.groups.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.groups[i]);
            return writer;
        };

        /**
         * Encodes the specified ProfileData message, length delimited. Does not implicitly {@link gridscope.ProfileData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope.ProfileData
         * @static
         * @param {gridscope.IProfileData} message ProfileData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfileData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProfileData message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope.ProfileData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope.ProfileData} ProfileData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfileData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope.ProfileData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 4:
                    if (!(message.groups && message.groups.length))
                        message.groups = [];
                    message.groups.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProfileData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope.ProfileData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope.ProfileData} ProfileData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfileData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProfileData message.
         * @function verify
         * @memberof gridscope.ProfileData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProfileData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.groups != null && message.hasOwnProperty("groups")) {
                if (!Array.isArray(message.groups))
                    return "groups: array expected";
                for (let i = 0; i < message.groups.length; ++i)
                    if (!$util.isString(message.groups[i]))
                        return "groups: string[] expected";
            }
            return null;
        };

        /**
         * Creates a ProfileData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope.ProfileData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope.ProfileData} ProfileData
         */
        ProfileData.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope.ProfileData)
                return object;
            let message = new $root.gridscope.ProfileData();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.name != null)
                message.name = String(object.name);
            if (object.groups) {
                if (!Array.isArray(object.groups))
                    throw TypeError(".gridscope.ProfileData.groups: array expected");
                message.groups = [];
                for (let i = 0; i < object.groups.length; ++i)
                    message.groups[i] = String(object.groups[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a ProfileData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope.ProfileData
         * @static
         * @param {gridscope.ProfileData} message ProfileData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProfileData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.groups = [];
            if (options.defaults) {
                object.id = 0;
                object.name = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.groups && message.groups.length) {
                object.groups = [];
                for (let j = 0; j < message.groups.length; ++j)
                    object.groups[j] = message.groups[j];
            }
            return object;
        };

        /**
         * Converts this ProfileData to JSON.
         * @function toJSON
         * @memberof gridscope.ProfileData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProfileData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ProfileData;
    })();

    /**
     * GridscopeError enum.
     * @name gridscope.GridscopeError
     * @enum {number}
     * @property {number} error_unknown=0 error_unknown value
     * @property {number} error_object_does_not_exist=10 error_object_does_not_exist value
     * @property {number} error_unauthorized=20 error_unauthorized value
     * @property {number} error_invalid_data=30 error_invalid_data value
     * @property {number} error_invalid_state=40 error_invalid_state value
     */
    gridscope.GridscopeError = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "error_unknown"] = 0;
        values[valuesById[10] = "error_object_does_not_exist"] = 10;
        values[valuesById[20] = "error_unauthorized"] = 20;
        values[valuesById[30] = "error_invalid_data"] = 30;
        values[valuesById[40] = "error_invalid_state"] = 40;
        return values;
    })();

    return gridscope;
})();

export const gridscope_view = $root.gridscope_view = (() => {

    /**
     * Namespace gridscope_view.
     * @exports gridscope_view
     * @namespace
     */
    const gridscope_view = {};

    gridscope_view.LoadProjectResults = (function() {

        /**
         * Properties of a LoadProjectResults.
         * @memberof gridscope_view
         * @interface ILoadProjectResults
         * @property {string|null} [projectKey] LoadProjectResults projectKey
         */

        /**
         * Constructs a new LoadProjectResults.
         * @memberof gridscope_view
         * @classdesc Represents a LoadProjectResults.
         * @implements ILoadProjectResults
         * @constructor
         * @param {gridscope_view.ILoadProjectResults=} [properties] Properties to set
         */
        function LoadProjectResults(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadProjectResults projectKey.
         * @member {string} projectKey
         * @memberof gridscope_view.LoadProjectResults
         * @instance
         */
        LoadProjectResults.prototype.projectKey = "";

        /**
         * Creates a new LoadProjectResults instance using the specified properties.
         * @function create
         * @memberof gridscope_view.LoadProjectResults
         * @static
         * @param {gridscope_view.ILoadProjectResults=} [properties] Properties to set
         * @returns {gridscope_view.LoadProjectResults} LoadProjectResults instance
         */
        LoadProjectResults.create = function create(properties) {
            return new LoadProjectResults(properties);
        };

        /**
         * Encodes the specified LoadProjectResults message. Does not implicitly {@link gridscope_view.LoadProjectResults.verify|verify} messages.
         * @function encode
         * @memberof gridscope_view.LoadProjectResults
         * @static
         * @param {gridscope_view.ILoadProjectResults} message LoadProjectResults message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadProjectResults.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectKey != null && Object.hasOwnProperty.call(message, "projectKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.projectKey);
            return writer;
        };

        /**
         * Encodes the specified LoadProjectResults message, length delimited. Does not implicitly {@link gridscope_view.LoadProjectResults.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_view.LoadProjectResults
         * @static
         * @param {gridscope_view.ILoadProjectResults} message LoadProjectResults message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadProjectResults.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadProjectResults message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_view.LoadProjectResults
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_view.LoadProjectResults} LoadProjectResults
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadProjectResults.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_view.LoadProjectResults();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.projectKey = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadProjectResults message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_view.LoadProjectResults
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_view.LoadProjectResults} LoadProjectResults
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadProjectResults.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadProjectResults message.
         * @function verify
         * @memberof gridscope_view.LoadProjectResults
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadProjectResults.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                if (!$util.isString(message.projectKey))
                    return "projectKey: string expected";
            return null;
        };

        /**
         * Creates a LoadProjectResults message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_view.LoadProjectResults
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_view.LoadProjectResults} LoadProjectResults
         */
        LoadProjectResults.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_view.LoadProjectResults)
                return object;
            let message = new $root.gridscope_view.LoadProjectResults();
            if (object.projectKey != null)
                message.projectKey = String(object.projectKey);
            return message;
        };

        /**
         * Creates a plain object from a LoadProjectResults message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_view.LoadProjectResults
         * @static
         * @param {gridscope_view.LoadProjectResults} message LoadProjectResults
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadProjectResults.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.projectKey = "";
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                object.projectKey = message.projectKey;
            return object;
        };

        /**
         * Converts this LoadProjectResults to JSON.
         * @function toJSON
         * @memberof gridscope_view.LoadProjectResults
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadProjectResults.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoadProjectResults;
    })();

    gridscope_view.ProjectResults = (function() {

        /**
         * Properties of a ProjectResults.
         * @memberof gridscope_view
         * @interface IProjectResults
         * @property {string|null} [projectKey] ProjectResults projectKey
         * @property {Array.<gridscope_view.IProjectResult>|null} [results] ProjectResults results
         */

        /**
         * Constructs a new ProjectResults.
         * @memberof gridscope_view
         * @classdesc Represents a ProjectResults.
         * @implements IProjectResults
         * @constructor
         * @param {gridscope_view.IProjectResults=} [properties] Properties to set
         */
        function ProjectResults(properties) {
            this.results = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProjectResults projectKey.
         * @member {string} projectKey
         * @memberof gridscope_view.ProjectResults
         * @instance
         */
        ProjectResults.prototype.projectKey = "";

        /**
         * ProjectResults results.
         * @member {Array.<gridscope_view.IProjectResult>} results
         * @memberof gridscope_view.ProjectResults
         * @instance
         */
        ProjectResults.prototype.results = $util.emptyArray;

        /**
         * Creates a new ProjectResults instance using the specified properties.
         * @function create
         * @memberof gridscope_view.ProjectResults
         * @static
         * @param {gridscope_view.IProjectResults=} [properties] Properties to set
         * @returns {gridscope_view.ProjectResults} ProjectResults instance
         */
        ProjectResults.create = function create(properties) {
            return new ProjectResults(properties);
        };

        /**
         * Encodes the specified ProjectResults message. Does not implicitly {@link gridscope_view.ProjectResults.verify|verify} messages.
         * @function encode
         * @memberof gridscope_view.ProjectResults
         * @static
         * @param {gridscope_view.IProjectResults} message ProjectResults message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProjectResults.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectKey != null && Object.hasOwnProperty.call(message, "projectKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.projectKey);
            if (message.results != null && message.results.length)
                for (let i = 0; i < message.results.length; ++i)
                    $root.gridscope_view.ProjectResult.encode(message.results[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ProjectResults message, length delimited. Does not implicitly {@link gridscope_view.ProjectResults.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_view.ProjectResults
         * @static
         * @param {gridscope_view.IProjectResults} message ProjectResults message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProjectResults.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProjectResults message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_view.ProjectResults
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_view.ProjectResults} ProjectResults
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProjectResults.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_view.ProjectResults();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.projectKey = reader.string();
                    break;
                case 2:
                    if (!(message.results && message.results.length))
                        message.results = [];
                    message.results.push($root.gridscope_view.ProjectResult.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProjectResults message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_view.ProjectResults
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_view.ProjectResults} ProjectResults
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProjectResults.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProjectResults message.
         * @function verify
         * @memberof gridscope_view.ProjectResults
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProjectResults.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                if (!$util.isString(message.projectKey))
                    return "projectKey: string expected";
            if (message.results != null && message.hasOwnProperty("results")) {
                if (!Array.isArray(message.results))
                    return "results: array expected";
                for (let i = 0; i < message.results.length; ++i) {
                    let error = $root.gridscope_view.ProjectResult.verify(message.results[i]);
                    if (error)
                        return "results." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ProjectResults message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_view.ProjectResults
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_view.ProjectResults} ProjectResults
         */
        ProjectResults.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_view.ProjectResults)
                return object;
            let message = new $root.gridscope_view.ProjectResults();
            if (object.projectKey != null)
                message.projectKey = String(object.projectKey);
            if (object.results) {
                if (!Array.isArray(object.results))
                    throw TypeError(".gridscope_view.ProjectResults.results: array expected");
                message.results = [];
                for (let i = 0; i < object.results.length; ++i) {
                    if (typeof object.results[i] !== "object")
                        throw TypeError(".gridscope_view.ProjectResults.results: object expected");
                    message.results[i] = $root.gridscope_view.ProjectResult.fromObject(object.results[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ProjectResults message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_view.ProjectResults
         * @static
         * @param {gridscope_view.ProjectResults} message ProjectResults
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProjectResults.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.results = [];
            if (options.defaults)
                object.projectKey = "";
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                object.projectKey = message.projectKey;
            if (message.results && message.results.length) {
                object.results = [];
                for (let j = 0; j < message.results.length; ++j)
                    object.results[j] = $root.gridscope_view.ProjectResult.toObject(message.results[j], options);
            }
            return object;
        };

        /**
         * Converts this ProjectResults to JSON.
         * @function toJSON
         * @memberof gridscope_view.ProjectResults
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProjectResults.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ProjectResults;
    })();

    gridscope_view.ProjectResult = (function() {

        /**
         * Properties of a ProjectResult.
         * @memberof gridscope_view
         * @interface IProjectResult
         * @property {string|null} [id] ProjectResult id
         * @property {string|null} [name] ProjectResult name
         * @property {gridscope_types.IDate|null} [dateCreated] ProjectResult dateCreated
         * @property {gridscope_types.IDate|null} [dateCompleted] ProjectResult dateCompleted
         * @property {Array.<gridscope_view.IResultFile>|null} [results] ProjectResult results
         * @property {string|null} [moduleName] ProjectResult moduleName
         * @property {string|null} [dataType] ProjectResult dataType
         * @property {gridscope_view.IResultHeaderData|null} [headerData] ProjectResult headerData
         */

        /**
         * Constructs a new ProjectResult.
         * @memberof gridscope_view
         * @classdesc Represents a ProjectResult.
         * @implements IProjectResult
         * @constructor
         * @param {gridscope_view.IProjectResult=} [properties] Properties to set
         */
        function ProjectResult(properties) {
            this.results = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProjectResult id.
         * @member {string} id
         * @memberof gridscope_view.ProjectResult
         * @instance
         */
        ProjectResult.prototype.id = "";

        /**
         * ProjectResult name.
         * @member {string} name
         * @memberof gridscope_view.ProjectResult
         * @instance
         */
        ProjectResult.prototype.name = "";

        /**
         * ProjectResult dateCreated.
         * @member {gridscope_types.IDate|null|undefined} dateCreated
         * @memberof gridscope_view.ProjectResult
         * @instance
         */
        ProjectResult.prototype.dateCreated = null;

        /**
         * ProjectResult dateCompleted.
         * @member {gridscope_types.IDate|null|undefined} dateCompleted
         * @memberof gridscope_view.ProjectResult
         * @instance
         */
        ProjectResult.prototype.dateCompleted = null;

        /**
         * ProjectResult results.
         * @member {Array.<gridscope_view.IResultFile>} results
         * @memberof gridscope_view.ProjectResult
         * @instance
         */
        ProjectResult.prototype.results = $util.emptyArray;

        /**
         * ProjectResult moduleName.
         * @member {string} moduleName
         * @memberof gridscope_view.ProjectResult
         * @instance
         */
        ProjectResult.prototype.moduleName = "";

        /**
         * ProjectResult dataType.
         * @member {string} dataType
         * @memberof gridscope_view.ProjectResult
         * @instance
         */
        ProjectResult.prototype.dataType = "";

        /**
         * ProjectResult headerData.
         * @member {gridscope_view.IResultHeaderData|null|undefined} headerData
         * @memberof gridscope_view.ProjectResult
         * @instance
         */
        ProjectResult.prototype.headerData = null;

        /**
         * Creates a new ProjectResult instance using the specified properties.
         * @function create
         * @memberof gridscope_view.ProjectResult
         * @static
         * @param {gridscope_view.IProjectResult=} [properties] Properties to set
         * @returns {gridscope_view.ProjectResult} ProjectResult instance
         */
        ProjectResult.create = function create(properties) {
            return new ProjectResult(properties);
        };

        /**
         * Encodes the specified ProjectResult message. Does not implicitly {@link gridscope_view.ProjectResult.verify|verify} messages.
         * @function encode
         * @memberof gridscope_view.ProjectResult
         * @static
         * @param {gridscope_view.IProjectResult} message ProjectResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProjectResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.dateCreated != null && Object.hasOwnProperty.call(message, "dateCreated"))
                $root.gridscope_types.Date.encode(message.dateCreated, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.dateCompleted != null && Object.hasOwnProperty.call(message, "dateCompleted"))
                $root.gridscope_types.Date.encode(message.dateCompleted, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.results != null && message.results.length)
                for (let i = 0; i < message.results.length; ++i)
                    $root.gridscope_view.ResultFile.encode(message.results[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.moduleName != null && Object.hasOwnProperty.call(message, "moduleName"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.moduleName);
            if (message.dataType != null && Object.hasOwnProperty.call(message, "dataType"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.dataType);
            if (message.headerData != null && Object.hasOwnProperty.call(message, "headerData"))
                $root.gridscope_view.ResultHeaderData.encode(message.headerData, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ProjectResult message, length delimited. Does not implicitly {@link gridscope_view.ProjectResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_view.ProjectResult
         * @static
         * @param {gridscope_view.IProjectResult} message ProjectResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProjectResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProjectResult message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_view.ProjectResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_view.ProjectResult} ProjectResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProjectResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_view.ProjectResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.dateCreated = $root.gridscope_types.Date.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.dateCompleted = $root.gridscope_types.Date.decode(reader, reader.uint32());
                    break;
                case 5:
                    if (!(message.results && message.results.length))
                        message.results = [];
                    message.results.push($root.gridscope_view.ResultFile.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.moduleName = reader.string();
                    break;
                case 7:
                    message.dataType = reader.string();
                    break;
                case 8:
                    message.headerData = $root.gridscope_view.ResultHeaderData.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProjectResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_view.ProjectResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_view.ProjectResult} ProjectResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProjectResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProjectResult message.
         * @function verify
         * @memberof gridscope_view.ProjectResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProjectResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.dateCreated != null && message.hasOwnProperty("dateCreated")) {
                let error = $root.gridscope_types.Date.verify(message.dateCreated);
                if (error)
                    return "dateCreated." + error;
            }
            if (message.dateCompleted != null && message.hasOwnProperty("dateCompleted")) {
                let error = $root.gridscope_types.Date.verify(message.dateCompleted);
                if (error)
                    return "dateCompleted." + error;
            }
            if (message.results != null && message.hasOwnProperty("results")) {
                if (!Array.isArray(message.results))
                    return "results: array expected";
                for (let i = 0; i < message.results.length; ++i) {
                    let error = $root.gridscope_view.ResultFile.verify(message.results[i]);
                    if (error)
                        return "results." + error;
                }
            }
            if (message.moduleName != null && message.hasOwnProperty("moduleName"))
                if (!$util.isString(message.moduleName))
                    return "moduleName: string expected";
            if (message.dataType != null && message.hasOwnProperty("dataType"))
                if (!$util.isString(message.dataType))
                    return "dataType: string expected";
            if (message.headerData != null && message.hasOwnProperty("headerData")) {
                let error = $root.gridscope_view.ResultHeaderData.verify(message.headerData);
                if (error)
                    return "headerData." + error;
            }
            return null;
        };

        /**
         * Creates a ProjectResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_view.ProjectResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_view.ProjectResult} ProjectResult
         */
        ProjectResult.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_view.ProjectResult)
                return object;
            let message = new $root.gridscope_view.ProjectResult();
            if (object.id != null)
                message.id = String(object.id);
            if (object.name != null)
                message.name = String(object.name);
            if (object.dateCreated != null) {
                if (typeof object.dateCreated !== "object")
                    throw TypeError(".gridscope_view.ProjectResult.dateCreated: object expected");
                message.dateCreated = $root.gridscope_types.Date.fromObject(object.dateCreated);
            }
            if (object.dateCompleted != null) {
                if (typeof object.dateCompleted !== "object")
                    throw TypeError(".gridscope_view.ProjectResult.dateCompleted: object expected");
                message.dateCompleted = $root.gridscope_types.Date.fromObject(object.dateCompleted);
            }
            if (object.results) {
                if (!Array.isArray(object.results))
                    throw TypeError(".gridscope_view.ProjectResult.results: array expected");
                message.results = [];
                for (let i = 0; i < object.results.length; ++i) {
                    if (typeof object.results[i] !== "object")
                        throw TypeError(".gridscope_view.ProjectResult.results: object expected");
                    message.results[i] = $root.gridscope_view.ResultFile.fromObject(object.results[i]);
                }
            }
            if (object.moduleName != null)
                message.moduleName = String(object.moduleName);
            if (object.dataType != null)
                message.dataType = String(object.dataType);
            if (object.headerData != null) {
                if (typeof object.headerData !== "object")
                    throw TypeError(".gridscope_view.ProjectResult.headerData: object expected");
                message.headerData = $root.gridscope_view.ResultHeaderData.fromObject(object.headerData);
            }
            return message;
        };

        /**
         * Creates a plain object from a ProjectResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_view.ProjectResult
         * @static
         * @param {gridscope_view.ProjectResult} message ProjectResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProjectResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.results = [];
            if (options.defaults) {
                object.id = "";
                object.name = "";
                object.dateCreated = null;
                object.dateCompleted = null;
                object.moduleName = "";
                object.dataType = "";
                object.headerData = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.dateCreated != null && message.hasOwnProperty("dateCreated"))
                object.dateCreated = $root.gridscope_types.Date.toObject(message.dateCreated, options);
            if (message.dateCompleted != null && message.hasOwnProperty("dateCompleted"))
                object.dateCompleted = $root.gridscope_types.Date.toObject(message.dateCompleted, options);
            if (message.results && message.results.length) {
                object.results = [];
                for (let j = 0; j < message.results.length; ++j)
                    object.results[j] = $root.gridscope_view.ResultFile.toObject(message.results[j], options);
            }
            if (message.moduleName != null && message.hasOwnProperty("moduleName"))
                object.moduleName = message.moduleName;
            if (message.dataType != null && message.hasOwnProperty("dataType"))
                object.dataType = message.dataType;
            if (message.headerData != null && message.hasOwnProperty("headerData"))
                object.headerData = $root.gridscope_view.ResultHeaderData.toObject(message.headerData, options);
            return object;
        };

        /**
         * Converts this ProjectResult to JSON.
         * @function toJSON
         * @memberof gridscope_view.ProjectResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProjectResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ProjectResult;
    })();

    gridscope_view.ResultFile = (function() {

        /**
         * Properties of a ResultFile.
         * @memberof gridscope_view
         * @interface IResultFile
         * @property {string|null} [id] ResultFile id
         * @property {string|null} [label] ResultFile label
         * @property {number|null} [count] ResultFile count
         * @property {string|null} [url] ResultFile url
         * @property {string|null} [header] ResultFile header
         */

        /**
         * Constructs a new ResultFile.
         * @memberof gridscope_view
         * @classdesc Represents a ResultFile.
         * @implements IResultFile
         * @constructor
         * @param {gridscope_view.IResultFile=} [properties] Properties to set
         */
        function ResultFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResultFile id.
         * @member {string} id
         * @memberof gridscope_view.ResultFile
         * @instance
         */
        ResultFile.prototype.id = "";

        /**
         * ResultFile label.
         * @member {string} label
         * @memberof gridscope_view.ResultFile
         * @instance
         */
        ResultFile.prototype.label = "";

        /**
         * ResultFile count.
         * @member {number} count
         * @memberof gridscope_view.ResultFile
         * @instance
         */
        ResultFile.prototype.count = 0;

        /**
         * ResultFile url.
         * @member {string} url
         * @memberof gridscope_view.ResultFile
         * @instance
         */
        ResultFile.prototype.url = "";

        /**
         * ResultFile header.
         * @member {string} header
         * @memberof gridscope_view.ResultFile
         * @instance
         */
        ResultFile.prototype.header = "";

        /**
         * Creates a new ResultFile instance using the specified properties.
         * @function create
         * @memberof gridscope_view.ResultFile
         * @static
         * @param {gridscope_view.IResultFile=} [properties] Properties to set
         * @returns {gridscope_view.ResultFile} ResultFile instance
         */
        ResultFile.create = function create(properties) {
            return new ResultFile(properties);
        };

        /**
         * Encodes the specified ResultFile message. Does not implicitly {@link gridscope_view.ResultFile.verify|verify} messages.
         * @function encode
         * @memberof gridscope_view.ResultFile
         * @static
         * @param {gridscope_view.IResultFile} message ResultFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResultFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.label);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.url);
            if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.header);
            return writer;
        };

        /**
         * Encodes the specified ResultFile message, length delimited. Does not implicitly {@link gridscope_view.ResultFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_view.ResultFile
         * @static
         * @param {gridscope_view.IResultFile} message ResultFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResultFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResultFile message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_view.ResultFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_view.ResultFile} ResultFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResultFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_view.ResultFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 3:
                    message.label = reader.string();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.url = reader.string();
                    break;
                case 7:
                    message.header = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResultFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_view.ResultFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_view.ResultFile} ResultFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResultFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResultFile message.
         * @function verify
         * @memberof gridscope_view.ResultFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResultFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            if (message.count != null && message.hasOwnProperty("count"))
                if (!$util.isInteger(message.count))
                    return "count: integer expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.header != null && message.hasOwnProperty("header"))
                if (!$util.isString(message.header))
                    return "header: string expected";
            return null;
        };

        /**
         * Creates a ResultFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_view.ResultFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_view.ResultFile} ResultFile
         */
        ResultFile.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_view.ResultFile)
                return object;
            let message = new $root.gridscope_view.ResultFile();
            if (object.id != null)
                message.id = String(object.id);
            if (object.label != null)
                message.label = String(object.label);
            if (object.count != null)
                message.count = object.count | 0;
            if (object.url != null)
                message.url = String(object.url);
            if (object.header != null)
                message.header = String(object.header);
            return message;
        };

        /**
         * Creates a plain object from a ResultFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_view.ResultFile
         * @static
         * @param {gridscope_view.ResultFile} message ResultFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResultFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.label = "";
                object.count = 0;
                object.url = "";
                object.header = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            if (message.count != null && message.hasOwnProperty("count"))
                object.count = message.count;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.header != null && message.hasOwnProperty("header"))
                object.header = message.header;
            return object;
        };

        /**
         * Converts this ResultFile to JSON.
         * @function toJSON
         * @memberof gridscope_view.ResultFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResultFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResultFile;
    })();

    gridscope_view.ResultHeaderData = (function() {

        /**
         * Properties of a ResultHeaderData.
         * @memberof gridscope_view
         * @interface IResultHeaderData
         * @property {boolean|null} [collapsible] ResultHeaderData collapsible
         * @property {Array.<gridscope_view.IResultHeaderDataDetail>|null} [details] ResultHeaderData details
         */

        /**
         * Constructs a new ResultHeaderData.
         * @memberof gridscope_view
         * @classdesc Represents a ResultHeaderData.
         * @implements IResultHeaderData
         * @constructor
         * @param {gridscope_view.IResultHeaderData=} [properties] Properties to set
         */
        function ResultHeaderData(properties) {
            this.details = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResultHeaderData collapsible.
         * @member {boolean} collapsible
         * @memberof gridscope_view.ResultHeaderData
         * @instance
         */
        ResultHeaderData.prototype.collapsible = false;

        /**
         * ResultHeaderData details.
         * @member {Array.<gridscope_view.IResultHeaderDataDetail>} details
         * @memberof gridscope_view.ResultHeaderData
         * @instance
         */
        ResultHeaderData.prototype.details = $util.emptyArray;

        /**
         * Creates a new ResultHeaderData instance using the specified properties.
         * @function create
         * @memberof gridscope_view.ResultHeaderData
         * @static
         * @param {gridscope_view.IResultHeaderData=} [properties] Properties to set
         * @returns {gridscope_view.ResultHeaderData} ResultHeaderData instance
         */
        ResultHeaderData.create = function create(properties) {
            return new ResultHeaderData(properties);
        };

        /**
         * Encodes the specified ResultHeaderData message. Does not implicitly {@link gridscope_view.ResultHeaderData.verify|verify} messages.
         * @function encode
         * @memberof gridscope_view.ResultHeaderData
         * @static
         * @param {gridscope_view.IResultHeaderData} message ResultHeaderData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResultHeaderData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.collapsible != null && Object.hasOwnProperty.call(message, "collapsible"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.collapsible);
            if (message.details != null && message.details.length)
                for (let i = 0; i < message.details.length; ++i)
                    $root.gridscope_view.ResultHeaderDataDetail.encode(message.details[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ResultHeaderData message, length delimited. Does not implicitly {@link gridscope_view.ResultHeaderData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_view.ResultHeaderData
         * @static
         * @param {gridscope_view.IResultHeaderData} message ResultHeaderData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResultHeaderData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResultHeaderData message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_view.ResultHeaderData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_view.ResultHeaderData} ResultHeaderData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResultHeaderData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_view.ResultHeaderData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.collapsible = reader.bool();
                    break;
                case 2:
                    if (!(message.details && message.details.length))
                        message.details = [];
                    message.details.push($root.gridscope_view.ResultHeaderDataDetail.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResultHeaderData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_view.ResultHeaderData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_view.ResultHeaderData} ResultHeaderData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResultHeaderData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResultHeaderData message.
         * @function verify
         * @memberof gridscope_view.ResultHeaderData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResultHeaderData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.collapsible != null && message.hasOwnProperty("collapsible"))
                if (typeof message.collapsible !== "boolean")
                    return "collapsible: boolean expected";
            if (message.details != null && message.hasOwnProperty("details")) {
                if (!Array.isArray(message.details))
                    return "details: array expected";
                for (let i = 0; i < message.details.length; ++i) {
                    let error = $root.gridscope_view.ResultHeaderDataDetail.verify(message.details[i]);
                    if (error)
                        return "details." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ResultHeaderData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_view.ResultHeaderData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_view.ResultHeaderData} ResultHeaderData
         */
        ResultHeaderData.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_view.ResultHeaderData)
                return object;
            let message = new $root.gridscope_view.ResultHeaderData();
            if (object.collapsible != null)
                message.collapsible = Boolean(object.collapsible);
            if (object.details) {
                if (!Array.isArray(object.details))
                    throw TypeError(".gridscope_view.ResultHeaderData.details: array expected");
                message.details = [];
                for (let i = 0; i < object.details.length; ++i) {
                    if (typeof object.details[i] !== "object")
                        throw TypeError(".gridscope_view.ResultHeaderData.details: object expected");
                    message.details[i] = $root.gridscope_view.ResultHeaderDataDetail.fromObject(object.details[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ResultHeaderData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_view.ResultHeaderData
         * @static
         * @param {gridscope_view.ResultHeaderData} message ResultHeaderData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResultHeaderData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.details = [];
            if (options.defaults)
                object.collapsible = false;
            if (message.collapsible != null && message.hasOwnProperty("collapsible"))
                object.collapsible = message.collapsible;
            if (message.details && message.details.length) {
                object.details = [];
                for (let j = 0; j < message.details.length; ++j)
                    object.details[j] = $root.gridscope_view.ResultHeaderDataDetail.toObject(message.details[j], options);
            }
            return object;
        };

        /**
         * Converts this ResultHeaderData to JSON.
         * @function toJSON
         * @memberof gridscope_view.ResultHeaderData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResultHeaderData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResultHeaderData;
    })();

    gridscope_view.ResultHeaderDataDetail = (function() {

        /**
         * Properties of a ResultHeaderDataDetail.
         * @memberof gridscope_view
         * @interface IResultHeaderDataDetail
         * @property {string|null} [label] ResultHeaderDataDetail label
         * @property {string|null} [value] ResultHeaderDataDetail value
         */

        /**
         * Constructs a new ResultHeaderDataDetail.
         * @memberof gridscope_view
         * @classdesc Represents a ResultHeaderDataDetail.
         * @implements IResultHeaderDataDetail
         * @constructor
         * @param {gridscope_view.IResultHeaderDataDetail=} [properties] Properties to set
         */
        function ResultHeaderDataDetail(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResultHeaderDataDetail label.
         * @member {string} label
         * @memberof gridscope_view.ResultHeaderDataDetail
         * @instance
         */
        ResultHeaderDataDetail.prototype.label = "";

        /**
         * ResultHeaderDataDetail value.
         * @member {string} value
         * @memberof gridscope_view.ResultHeaderDataDetail
         * @instance
         */
        ResultHeaderDataDetail.prototype.value = "";

        /**
         * Creates a new ResultHeaderDataDetail instance using the specified properties.
         * @function create
         * @memberof gridscope_view.ResultHeaderDataDetail
         * @static
         * @param {gridscope_view.IResultHeaderDataDetail=} [properties] Properties to set
         * @returns {gridscope_view.ResultHeaderDataDetail} ResultHeaderDataDetail instance
         */
        ResultHeaderDataDetail.create = function create(properties) {
            return new ResultHeaderDataDetail(properties);
        };

        /**
         * Encodes the specified ResultHeaderDataDetail message. Does not implicitly {@link gridscope_view.ResultHeaderDataDetail.verify|verify} messages.
         * @function encode
         * @memberof gridscope_view.ResultHeaderDataDetail
         * @static
         * @param {gridscope_view.IResultHeaderDataDetail} message ResultHeaderDataDetail message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResultHeaderDataDetail.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
            return writer;
        };

        /**
         * Encodes the specified ResultHeaderDataDetail message, length delimited. Does not implicitly {@link gridscope_view.ResultHeaderDataDetail.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_view.ResultHeaderDataDetail
         * @static
         * @param {gridscope_view.IResultHeaderDataDetail} message ResultHeaderDataDetail message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResultHeaderDataDetail.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResultHeaderDataDetail message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_view.ResultHeaderDataDetail
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_view.ResultHeaderDataDetail} ResultHeaderDataDetail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResultHeaderDataDetail.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_view.ResultHeaderDataDetail();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.label = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResultHeaderDataDetail message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_view.ResultHeaderDataDetail
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_view.ResultHeaderDataDetail} ResultHeaderDataDetail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResultHeaderDataDetail.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResultHeaderDataDetail message.
         * @function verify
         * @memberof gridscope_view.ResultHeaderDataDetail
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResultHeaderDataDetail.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (!$util.isString(message.value))
                    return "value: string expected";
            return null;
        };

        /**
         * Creates a ResultHeaderDataDetail message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_view.ResultHeaderDataDetail
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_view.ResultHeaderDataDetail} ResultHeaderDataDetail
         */
        ResultHeaderDataDetail.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_view.ResultHeaderDataDetail)
                return object;
            let message = new $root.gridscope_view.ResultHeaderDataDetail();
            if (object.label != null)
                message.label = String(object.label);
            if (object.value != null)
                message.value = String(object.value);
            return message;
        };

        /**
         * Creates a plain object from a ResultHeaderDataDetail message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_view.ResultHeaderDataDetail
         * @static
         * @param {gridscope_view.ResultHeaderDataDetail} message ResultHeaderDataDetail
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResultHeaderDataDetail.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.label = "";
                object.value = "";
            }
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            return object;
        };

        /**
         * Converts this ResultHeaderDataDetail to JSON.
         * @function toJSON
         * @memberof gridscope_view.ResultHeaderDataDetail
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResultHeaderDataDetail.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResultHeaderDataDetail;
    })();

    gridscope_view.DeleteModuleResult = (function() {

        /**
         * Properties of a DeleteModuleResult.
         * @memberof gridscope_view
         * @interface IDeleteModuleResult
         * @property {string|null} [id] DeleteModuleResult id
         */

        /**
         * Constructs a new DeleteModuleResult.
         * @memberof gridscope_view
         * @classdesc Represents a DeleteModuleResult.
         * @implements IDeleteModuleResult
         * @constructor
         * @param {gridscope_view.IDeleteModuleResult=} [properties] Properties to set
         */
        function DeleteModuleResult(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteModuleResult id.
         * @member {string} id
         * @memberof gridscope_view.DeleteModuleResult
         * @instance
         */
        DeleteModuleResult.prototype.id = "";

        /**
         * Creates a new DeleteModuleResult instance using the specified properties.
         * @function create
         * @memberof gridscope_view.DeleteModuleResult
         * @static
         * @param {gridscope_view.IDeleteModuleResult=} [properties] Properties to set
         * @returns {gridscope_view.DeleteModuleResult} DeleteModuleResult instance
         */
        DeleteModuleResult.create = function create(properties) {
            return new DeleteModuleResult(properties);
        };

        /**
         * Encodes the specified DeleteModuleResult message. Does not implicitly {@link gridscope_view.DeleteModuleResult.verify|verify} messages.
         * @function encode
         * @memberof gridscope_view.DeleteModuleResult
         * @static
         * @param {gridscope_view.IDeleteModuleResult} message DeleteModuleResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteModuleResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified DeleteModuleResult message, length delimited. Does not implicitly {@link gridscope_view.DeleteModuleResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_view.DeleteModuleResult
         * @static
         * @param {gridscope_view.IDeleteModuleResult} message DeleteModuleResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteModuleResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteModuleResult message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_view.DeleteModuleResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_view.DeleteModuleResult} DeleteModuleResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteModuleResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_view.DeleteModuleResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteModuleResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_view.DeleteModuleResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_view.DeleteModuleResult} DeleteModuleResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteModuleResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteModuleResult message.
         * @function verify
         * @memberof gridscope_view.DeleteModuleResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteModuleResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            return null;
        };

        /**
         * Creates a DeleteModuleResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_view.DeleteModuleResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_view.DeleteModuleResult} DeleteModuleResult
         */
        DeleteModuleResult.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_view.DeleteModuleResult)
                return object;
            let message = new $root.gridscope_view.DeleteModuleResult();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a DeleteModuleResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_view.DeleteModuleResult
         * @static
         * @param {gridscope_view.DeleteModuleResult} message DeleteModuleResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteModuleResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.id = "";
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this DeleteModuleResult to JSON.
         * @function toJSON
         * @memberof gridscope_view.DeleteModuleResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteModuleResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteModuleResult;
    })();

    gridscope_view.SaveModuleResult = (function() {

        /**
         * Properties of a SaveModuleResult.
         * @memberof gridscope_view
         * @interface ISaveModuleResult
         * @property {string|null} [id] SaveModuleResult id
         * @property {string|null} [name] SaveModuleResult name
         */

        /**
         * Constructs a new SaveModuleResult.
         * @memberof gridscope_view
         * @classdesc Represents a SaveModuleResult.
         * @implements ISaveModuleResult
         * @constructor
         * @param {gridscope_view.ISaveModuleResult=} [properties] Properties to set
         */
        function SaveModuleResult(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SaveModuleResult id.
         * @member {string} id
         * @memberof gridscope_view.SaveModuleResult
         * @instance
         */
        SaveModuleResult.prototype.id = "";

        /**
         * SaveModuleResult name.
         * @member {string} name
         * @memberof gridscope_view.SaveModuleResult
         * @instance
         */
        SaveModuleResult.prototype.name = "";

        /**
         * Creates a new SaveModuleResult instance using the specified properties.
         * @function create
         * @memberof gridscope_view.SaveModuleResult
         * @static
         * @param {gridscope_view.ISaveModuleResult=} [properties] Properties to set
         * @returns {gridscope_view.SaveModuleResult} SaveModuleResult instance
         */
        SaveModuleResult.create = function create(properties) {
            return new SaveModuleResult(properties);
        };

        /**
         * Encodes the specified SaveModuleResult message. Does not implicitly {@link gridscope_view.SaveModuleResult.verify|verify} messages.
         * @function encode
         * @memberof gridscope_view.SaveModuleResult
         * @static
         * @param {gridscope_view.ISaveModuleResult} message SaveModuleResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveModuleResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified SaveModuleResult message, length delimited. Does not implicitly {@link gridscope_view.SaveModuleResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_view.SaveModuleResult
         * @static
         * @param {gridscope_view.ISaveModuleResult} message SaveModuleResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveModuleResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SaveModuleResult message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_view.SaveModuleResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_view.SaveModuleResult} SaveModuleResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveModuleResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_view.SaveModuleResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SaveModuleResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_view.SaveModuleResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_view.SaveModuleResult} SaveModuleResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveModuleResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SaveModuleResult message.
         * @function verify
         * @memberof gridscope_view.SaveModuleResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SaveModuleResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a SaveModuleResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_view.SaveModuleResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_view.SaveModuleResult} SaveModuleResult
         */
        SaveModuleResult.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_view.SaveModuleResult)
                return object;
            let message = new $root.gridscope_view.SaveModuleResult();
            if (object.id != null)
                message.id = String(object.id);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a SaveModuleResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_view.SaveModuleResult
         * @static
         * @param {gridscope_view.SaveModuleResult} message SaveModuleResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SaveModuleResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.name = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this SaveModuleResult to JSON.
         * @function toJSON
         * @memberof gridscope_view.SaveModuleResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SaveModuleResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SaveModuleResult;
    })();

    return gridscope_view;
})();

export const gridscope_processing = $root.gridscope_processing = (() => {

    /**
     * Namespace gridscope_processing.
     * @exports gridscope_processing
     * @namespace
     */
    const gridscope_processing = {};

    gridscope_processing.LoadProcessingModules = (function() {

        /**
         * Properties of a LoadProcessingModules.
         * @memberof gridscope_processing
         * @interface ILoadProcessingModules
         * @property {string|null} [projectKey] LoadProcessingModules projectKey
         */

        /**
         * Constructs a new LoadProcessingModules.
         * @memberof gridscope_processing
         * @classdesc Represents a LoadProcessingModules.
         * @implements ILoadProcessingModules
         * @constructor
         * @param {gridscope_processing.ILoadProcessingModules=} [properties] Properties to set
         */
        function LoadProcessingModules(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadProcessingModules projectKey.
         * @member {string} projectKey
         * @memberof gridscope_processing.LoadProcessingModules
         * @instance
         */
        LoadProcessingModules.prototype.projectKey = "";

        /**
         * Creates a new LoadProcessingModules instance using the specified properties.
         * @function create
         * @memberof gridscope_processing.LoadProcessingModules
         * @static
         * @param {gridscope_processing.ILoadProcessingModules=} [properties] Properties to set
         * @returns {gridscope_processing.LoadProcessingModules} LoadProcessingModules instance
         */
        LoadProcessingModules.create = function create(properties) {
            return new LoadProcessingModules(properties);
        };

        /**
         * Encodes the specified LoadProcessingModules message. Does not implicitly {@link gridscope_processing.LoadProcessingModules.verify|verify} messages.
         * @function encode
         * @memberof gridscope_processing.LoadProcessingModules
         * @static
         * @param {gridscope_processing.ILoadProcessingModules} message LoadProcessingModules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadProcessingModules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectKey != null && Object.hasOwnProperty.call(message, "projectKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.projectKey);
            return writer;
        };

        /**
         * Encodes the specified LoadProcessingModules message, length delimited. Does not implicitly {@link gridscope_processing.LoadProcessingModules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_processing.LoadProcessingModules
         * @static
         * @param {gridscope_processing.ILoadProcessingModules} message LoadProcessingModules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadProcessingModules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadProcessingModules message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_processing.LoadProcessingModules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_processing.LoadProcessingModules} LoadProcessingModules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadProcessingModules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_processing.LoadProcessingModules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.projectKey = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadProcessingModules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_processing.LoadProcessingModules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_processing.LoadProcessingModules} LoadProcessingModules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadProcessingModules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadProcessingModules message.
         * @function verify
         * @memberof gridscope_processing.LoadProcessingModules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadProcessingModules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                if (!$util.isString(message.projectKey))
                    return "projectKey: string expected";
            return null;
        };

        /**
         * Creates a LoadProcessingModules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_processing.LoadProcessingModules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_processing.LoadProcessingModules} LoadProcessingModules
         */
        LoadProcessingModules.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_processing.LoadProcessingModules)
                return object;
            let message = new $root.gridscope_processing.LoadProcessingModules();
            if (object.projectKey != null)
                message.projectKey = String(object.projectKey);
            return message;
        };

        /**
         * Creates a plain object from a LoadProcessingModules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_processing.LoadProcessingModules
         * @static
         * @param {gridscope_processing.LoadProcessingModules} message LoadProcessingModules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadProcessingModules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.projectKey = "";
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                object.projectKey = message.projectKey;
            return object;
        };

        /**
         * Converts this LoadProcessingModules to JSON.
         * @function toJSON
         * @memberof gridscope_processing.LoadProcessingModules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadProcessingModules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoadProcessingModules;
    })();

    gridscope_processing.LoadProcessingSelectOptions = (function() {

        /**
         * Properties of a LoadProcessingSelectOptions.
         * @memberof gridscope_processing
         * @interface ILoadProcessingSelectOptions
         * @property {string|null} [projectKey] LoadProcessingSelectOptions projectKey
         * @property {string|null} [moduleKey] LoadProcessingSelectOptions moduleKey
         * @property {string|null} [key] LoadProcessingSelectOptions key
         * @property {string|null} [search] LoadProcessingSelectOptions search
         */

        /**
         * Constructs a new LoadProcessingSelectOptions.
         * @memberof gridscope_processing
         * @classdesc Represents a LoadProcessingSelectOptions.
         * @implements ILoadProcessingSelectOptions
         * @constructor
         * @param {gridscope_processing.ILoadProcessingSelectOptions=} [properties] Properties to set
         */
        function LoadProcessingSelectOptions(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadProcessingSelectOptions projectKey.
         * @member {string} projectKey
         * @memberof gridscope_processing.LoadProcessingSelectOptions
         * @instance
         */
        LoadProcessingSelectOptions.prototype.projectKey = "";

        /**
         * LoadProcessingSelectOptions moduleKey.
         * @member {string} moduleKey
         * @memberof gridscope_processing.LoadProcessingSelectOptions
         * @instance
         */
        LoadProcessingSelectOptions.prototype.moduleKey = "";

        /**
         * LoadProcessingSelectOptions key.
         * @member {string} key
         * @memberof gridscope_processing.LoadProcessingSelectOptions
         * @instance
         */
        LoadProcessingSelectOptions.prototype.key = "";

        /**
         * LoadProcessingSelectOptions search.
         * @member {string} search
         * @memberof gridscope_processing.LoadProcessingSelectOptions
         * @instance
         */
        LoadProcessingSelectOptions.prototype.search = "";

        /**
         * Creates a new LoadProcessingSelectOptions instance using the specified properties.
         * @function create
         * @memberof gridscope_processing.LoadProcessingSelectOptions
         * @static
         * @param {gridscope_processing.ILoadProcessingSelectOptions=} [properties] Properties to set
         * @returns {gridscope_processing.LoadProcessingSelectOptions} LoadProcessingSelectOptions instance
         */
        LoadProcessingSelectOptions.create = function create(properties) {
            return new LoadProcessingSelectOptions(properties);
        };

        /**
         * Encodes the specified LoadProcessingSelectOptions message. Does not implicitly {@link gridscope_processing.LoadProcessingSelectOptions.verify|verify} messages.
         * @function encode
         * @memberof gridscope_processing.LoadProcessingSelectOptions
         * @static
         * @param {gridscope_processing.ILoadProcessingSelectOptions} message LoadProcessingSelectOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadProcessingSelectOptions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectKey != null && Object.hasOwnProperty.call(message, "projectKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.projectKey);
            if (message.moduleKey != null && Object.hasOwnProperty.call(message, "moduleKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.moduleKey);
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.key);
            if (message.search != null && Object.hasOwnProperty.call(message, "search"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.search);
            return writer;
        };

        /**
         * Encodes the specified LoadProcessingSelectOptions message, length delimited. Does not implicitly {@link gridscope_processing.LoadProcessingSelectOptions.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_processing.LoadProcessingSelectOptions
         * @static
         * @param {gridscope_processing.ILoadProcessingSelectOptions} message LoadProcessingSelectOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadProcessingSelectOptions.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadProcessingSelectOptions message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_processing.LoadProcessingSelectOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_processing.LoadProcessingSelectOptions} LoadProcessingSelectOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadProcessingSelectOptions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_processing.LoadProcessingSelectOptions();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.projectKey = reader.string();
                    break;
                case 2:
                    message.moduleKey = reader.string();
                    break;
                case 3:
                    message.key = reader.string();
                    break;
                case 4:
                    message.search = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadProcessingSelectOptions message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_processing.LoadProcessingSelectOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_processing.LoadProcessingSelectOptions} LoadProcessingSelectOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadProcessingSelectOptions.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadProcessingSelectOptions message.
         * @function verify
         * @memberof gridscope_processing.LoadProcessingSelectOptions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadProcessingSelectOptions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                if (!$util.isString(message.projectKey))
                    return "projectKey: string expected";
            if (message.moduleKey != null && message.hasOwnProperty("moduleKey"))
                if (!$util.isString(message.moduleKey))
                    return "moduleKey: string expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.search != null && message.hasOwnProperty("search"))
                if (!$util.isString(message.search))
                    return "search: string expected";
            return null;
        };

        /**
         * Creates a LoadProcessingSelectOptions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_processing.LoadProcessingSelectOptions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_processing.LoadProcessingSelectOptions} LoadProcessingSelectOptions
         */
        LoadProcessingSelectOptions.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_processing.LoadProcessingSelectOptions)
                return object;
            let message = new $root.gridscope_processing.LoadProcessingSelectOptions();
            if (object.projectKey != null)
                message.projectKey = String(object.projectKey);
            if (object.moduleKey != null)
                message.moduleKey = String(object.moduleKey);
            if (object.key != null)
                message.key = String(object.key);
            if (object.search != null)
                message.search = String(object.search);
            return message;
        };

        /**
         * Creates a plain object from a LoadProcessingSelectOptions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_processing.LoadProcessingSelectOptions
         * @static
         * @param {gridscope_processing.LoadProcessingSelectOptions} message LoadProcessingSelectOptions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadProcessingSelectOptions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.projectKey = "";
                object.moduleKey = "";
                object.key = "";
                object.search = "";
            }
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                object.projectKey = message.projectKey;
            if (message.moduleKey != null && message.hasOwnProperty("moduleKey"))
                object.moduleKey = message.moduleKey;
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.search != null && message.hasOwnProperty("search"))
                object.search = message.search;
            return object;
        };

        /**
         * Converts this LoadProcessingSelectOptions to JSON.
         * @function toJSON
         * @memberof gridscope_processing.LoadProcessingSelectOptions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadProcessingSelectOptions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoadProcessingSelectOptions;
    })();

    gridscope_processing.ProcessingSelectOptions = (function() {

        /**
         * Properties of a ProcessingSelectOptions.
         * @memberof gridscope_processing
         * @interface IProcessingSelectOptions
         * @property {string|null} [projectKey] ProcessingSelectOptions projectKey
         * @property {string|null} [moduleKey] ProcessingSelectOptions moduleKey
         * @property {inputs.ISelectOptions|null} [options] ProcessingSelectOptions options
         */

        /**
         * Constructs a new ProcessingSelectOptions.
         * @memberof gridscope_processing
         * @classdesc Represents a ProcessingSelectOptions.
         * @implements IProcessingSelectOptions
         * @constructor
         * @param {gridscope_processing.IProcessingSelectOptions=} [properties] Properties to set
         */
        function ProcessingSelectOptions(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProcessingSelectOptions projectKey.
         * @member {string} projectKey
         * @memberof gridscope_processing.ProcessingSelectOptions
         * @instance
         */
        ProcessingSelectOptions.prototype.projectKey = "";

        /**
         * ProcessingSelectOptions moduleKey.
         * @member {string} moduleKey
         * @memberof gridscope_processing.ProcessingSelectOptions
         * @instance
         */
        ProcessingSelectOptions.prototype.moduleKey = "";

        /**
         * ProcessingSelectOptions options.
         * @member {inputs.ISelectOptions|null|undefined} options
         * @memberof gridscope_processing.ProcessingSelectOptions
         * @instance
         */
        ProcessingSelectOptions.prototype.options = null;

        /**
         * Creates a new ProcessingSelectOptions instance using the specified properties.
         * @function create
         * @memberof gridscope_processing.ProcessingSelectOptions
         * @static
         * @param {gridscope_processing.IProcessingSelectOptions=} [properties] Properties to set
         * @returns {gridscope_processing.ProcessingSelectOptions} ProcessingSelectOptions instance
         */
        ProcessingSelectOptions.create = function create(properties) {
            return new ProcessingSelectOptions(properties);
        };

        /**
         * Encodes the specified ProcessingSelectOptions message. Does not implicitly {@link gridscope_processing.ProcessingSelectOptions.verify|verify} messages.
         * @function encode
         * @memberof gridscope_processing.ProcessingSelectOptions
         * @static
         * @param {gridscope_processing.IProcessingSelectOptions} message ProcessingSelectOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProcessingSelectOptions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectKey != null && Object.hasOwnProperty.call(message, "projectKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.projectKey);
            if (message.moduleKey != null && Object.hasOwnProperty.call(message, "moduleKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.moduleKey);
            if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                $root.inputs.SelectOptions.encode(message.options, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ProcessingSelectOptions message, length delimited. Does not implicitly {@link gridscope_processing.ProcessingSelectOptions.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_processing.ProcessingSelectOptions
         * @static
         * @param {gridscope_processing.IProcessingSelectOptions} message ProcessingSelectOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProcessingSelectOptions.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProcessingSelectOptions message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_processing.ProcessingSelectOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_processing.ProcessingSelectOptions} ProcessingSelectOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProcessingSelectOptions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_processing.ProcessingSelectOptions();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.projectKey = reader.string();
                    break;
                case 2:
                    message.moduleKey = reader.string();
                    break;
                case 4:
                    message.options = $root.inputs.SelectOptions.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProcessingSelectOptions message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_processing.ProcessingSelectOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_processing.ProcessingSelectOptions} ProcessingSelectOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProcessingSelectOptions.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProcessingSelectOptions message.
         * @function verify
         * @memberof gridscope_processing.ProcessingSelectOptions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProcessingSelectOptions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                if (!$util.isString(message.projectKey))
                    return "projectKey: string expected";
            if (message.moduleKey != null && message.hasOwnProperty("moduleKey"))
                if (!$util.isString(message.moduleKey))
                    return "moduleKey: string expected";
            if (message.options != null && message.hasOwnProperty("options")) {
                let error = $root.inputs.SelectOptions.verify(message.options);
                if (error)
                    return "options." + error;
            }
            return null;
        };

        /**
         * Creates a ProcessingSelectOptions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_processing.ProcessingSelectOptions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_processing.ProcessingSelectOptions} ProcessingSelectOptions
         */
        ProcessingSelectOptions.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_processing.ProcessingSelectOptions)
                return object;
            let message = new $root.gridscope_processing.ProcessingSelectOptions();
            if (object.projectKey != null)
                message.projectKey = String(object.projectKey);
            if (object.moduleKey != null)
                message.moduleKey = String(object.moduleKey);
            if (object.options != null) {
                if (typeof object.options !== "object")
                    throw TypeError(".gridscope_processing.ProcessingSelectOptions.options: object expected");
                message.options = $root.inputs.SelectOptions.fromObject(object.options);
            }
            return message;
        };

        /**
         * Creates a plain object from a ProcessingSelectOptions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_processing.ProcessingSelectOptions
         * @static
         * @param {gridscope_processing.ProcessingSelectOptions} message ProcessingSelectOptions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProcessingSelectOptions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.projectKey = "";
                object.moduleKey = "";
                object.options = null;
            }
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                object.projectKey = message.projectKey;
            if (message.moduleKey != null && message.hasOwnProperty("moduleKey"))
                object.moduleKey = message.moduleKey;
            if (message.options != null && message.hasOwnProperty("options"))
                object.options = $root.inputs.SelectOptions.toObject(message.options, options);
            return object;
        };

        /**
         * Converts this ProcessingSelectOptions to JSON.
         * @function toJSON
         * @memberof gridscope_processing.ProcessingSelectOptions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProcessingSelectOptions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ProcessingSelectOptions;
    })();

    gridscope_processing.ProcessingModules = (function() {

        /**
         * Properties of a ProcessingModules.
         * @memberof gridscope_processing
         * @interface IProcessingModules
         * @property {Array.<gridscope_processing.IProcessingModule>|null} [modules] ProcessingModules modules
         */

        /**
         * Constructs a new ProcessingModules.
         * @memberof gridscope_processing
         * @classdesc Represents a ProcessingModules.
         * @implements IProcessingModules
         * @constructor
         * @param {gridscope_processing.IProcessingModules=} [properties] Properties to set
         */
        function ProcessingModules(properties) {
            this.modules = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProcessingModules modules.
         * @member {Array.<gridscope_processing.IProcessingModule>} modules
         * @memberof gridscope_processing.ProcessingModules
         * @instance
         */
        ProcessingModules.prototype.modules = $util.emptyArray;

        /**
         * Creates a new ProcessingModules instance using the specified properties.
         * @function create
         * @memberof gridscope_processing.ProcessingModules
         * @static
         * @param {gridscope_processing.IProcessingModules=} [properties] Properties to set
         * @returns {gridscope_processing.ProcessingModules} ProcessingModules instance
         */
        ProcessingModules.create = function create(properties) {
            return new ProcessingModules(properties);
        };

        /**
         * Encodes the specified ProcessingModules message. Does not implicitly {@link gridscope_processing.ProcessingModules.verify|verify} messages.
         * @function encode
         * @memberof gridscope_processing.ProcessingModules
         * @static
         * @param {gridscope_processing.IProcessingModules} message ProcessingModules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProcessingModules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.modules != null && message.modules.length)
                for (let i = 0; i < message.modules.length; ++i)
                    $root.gridscope_processing.ProcessingModule.encode(message.modules[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ProcessingModules message, length delimited. Does not implicitly {@link gridscope_processing.ProcessingModules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_processing.ProcessingModules
         * @static
         * @param {gridscope_processing.IProcessingModules} message ProcessingModules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProcessingModules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProcessingModules message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_processing.ProcessingModules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_processing.ProcessingModules} ProcessingModules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProcessingModules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_processing.ProcessingModules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.modules && message.modules.length))
                        message.modules = [];
                    message.modules.push($root.gridscope_processing.ProcessingModule.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProcessingModules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_processing.ProcessingModules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_processing.ProcessingModules} ProcessingModules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProcessingModules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProcessingModules message.
         * @function verify
         * @memberof gridscope_processing.ProcessingModules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProcessingModules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.modules != null && message.hasOwnProperty("modules")) {
                if (!Array.isArray(message.modules))
                    return "modules: array expected";
                for (let i = 0; i < message.modules.length; ++i) {
                    let error = $root.gridscope_processing.ProcessingModule.verify(message.modules[i]);
                    if (error)
                        return "modules." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ProcessingModules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_processing.ProcessingModules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_processing.ProcessingModules} ProcessingModules
         */
        ProcessingModules.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_processing.ProcessingModules)
                return object;
            let message = new $root.gridscope_processing.ProcessingModules();
            if (object.modules) {
                if (!Array.isArray(object.modules))
                    throw TypeError(".gridscope_processing.ProcessingModules.modules: array expected");
                message.modules = [];
                for (let i = 0; i < object.modules.length; ++i) {
                    if (typeof object.modules[i] !== "object")
                        throw TypeError(".gridscope_processing.ProcessingModules.modules: object expected");
                    message.modules[i] = $root.gridscope_processing.ProcessingModule.fromObject(object.modules[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ProcessingModules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_processing.ProcessingModules
         * @static
         * @param {gridscope_processing.ProcessingModules} message ProcessingModules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProcessingModules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.modules = [];
            if (message.modules && message.modules.length) {
                object.modules = [];
                for (let j = 0; j < message.modules.length; ++j)
                    object.modules[j] = $root.gridscope_processing.ProcessingModule.toObject(message.modules[j], options);
            }
            return object;
        };

        /**
         * Converts this ProcessingModules to JSON.
         * @function toJSON
         * @memberof gridscope_processing.ProcessingModules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProcessingModules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ProcessingModules;
    })();

    gridscope_processing.LoadProcessingModule = (function() {

        /**
         * Properties of a LoadProcessingModule.
         * @memberof gridscope_processing
         * @interface ILoadProcessingModule
         * @property {string|null} [moduleKey] LoadProcessingModule moduleKey
         * @property {string|null} [projectKey] LoadProcessingModule projectKey
         */

        /**
         * Constructs a new LoadProcessingModule.
         * @memberof gridscope_processing
         * @classdesc Represents a LoadProcessingModule.
         * @implements ILoadProcessingModule
         * @constructor
         * @param {gridscope_processing.ILoadProcessingModule=} [properties] Properties to set
         */
        function LoadProcessingModule(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadProcessingModule moduleKey.
         * @member {string} moduleKey
         * @memberof gridscope_processing.LoadProcessingModule
         * @instance
         */
        LoadProcessingModule.prototype.moduleKey = "";

        /**
         * LoadProcessingModule projectKey.
         * @member {string} projectKey
         * @memberof gridscope_processing.LoadProcessingModule
         * @instance
         */
        LoadProcessingModule.prototype.projectKey = "";

        /**
         * Creates a new LoadProcessingModule instance using the specified properties.
         * @function create
         * @memberof gridscope_processing.LoadProcessingModule
         * @static
         * @param {gridscope_processing.ILoadProcessingModule=} [properties] Properties to set
         * @returns {gridscope_processing.LoadProcessingModule} LoadProcessingModule instance
         */
        LoadProcessingModule.create = function create(properties) {
            return new LoadProcessingModule(properties);
        };

        /**
         * Encodes the specified LoadProcessingModule message. Does not implicitly {@link gridscope_processing.LoadProcessingModule.verify|verify} messages.
         * @function encode
         * @memberof gridscope_processing.LoadProcessingModule
         * @static
         * @param {gridscope_processing.ILoadProcessingModule} message LoadProcessingModule message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadProcessingModule.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.moduleKey != null && Object.hasOwnProperty.call(message, "moduleKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.moduleKey);
            if (message.projectKey != null && Object.hasOwnProperty.call(message, "projectKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.projectKey);
            return writer;
        };

        /**
         * Encodes the specified LoadProcessingModule message, length delimited. Does not implicitly {@link gridscope_processing.LoadProcessingModule.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_processing.LoadProcessingModule
         * @static
         * @param {gridscope_processing.ILoadProcessingModule} message LoadProcessingModule message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadProcessingModule.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadProcessingModule message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_processing.LoadProcessingModule
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_processing.LoadProcessingModule} LoadProcessingModule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadProcessingModule.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_processing.LoadProcessingModule();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.moduleKey = reader.string();
                    break;
                case 2:
                    message.projectKey = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadProcessingModule message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_processing.LoadProcessingModule
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_processing.LoadProcessingModule} LoadProcessingModule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadProcessingModule.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadProcessingModule message.
         * @function verify
         * @memberof gridscope_processing.LoadProcessingModule
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadProcessingModule.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.moduleKey != null && message.hasOwnProperty("moduleKey"))
                if (!$util.isString(message.moduleKey))
                    return "moduleKey: string expected";
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                if (!$util.isString(message.projectKey))
                    return "projectKey: string expected";
            return null;
        };

        /**
         * Creates a LoadProcessingModule message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_processing.LoadProcessingModule
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_processing.LoadProcessingModule} LoadProcessingModule
         */
        LoadProcessingModule.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_processing.LoadProcessingModule)
                return object;
            let message = new $root.gridscope_processing.LoadProcessingModule();
            if (object.moduleKey != null)
                message.moduleKey = String(object.moduleKey);
            if (object.projectKey != null)
                message.projectKey = String(object.projectKey);
            return message;
        };

        /**
         * Creates a plain object from a LoadProcessingModule message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_processing.LoadProcessingModule
         * @static
         * @param {gridscope_processing.LoadProcessingModule} message LoadProcessingModule
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadProcessingModule.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.moduleKey = "";
                object.projectKey = "";
            }
            if (message.moduleKey != null && message.hasOwnProperty("moduleKey"))
                object.moduleKey = message.moduleKey;
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                object.projectKey = message.projectKey;
            return object;
        };

        /**
         * Converts this LoadProcessingModule to JSON.
         * @function toJSON
         * @memberof gridscope_processing.LoadProcessingModule
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadProcessingModule.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoadProcessingModule;
    })();

    gridscope_processing.ProcessingModule = (function() {

        /**
         * Properties of a ProcessingModule.
         * @memberof gridscope_processing
         * @interface IProcessingModule
         * @property {string|null} [key] ProcessingModule key
         * @property {string|null} [name] ProcessingModule name
         * @property {Array.<inputs.IInput>|null} [configuration] ProcessingModule configuration
         */

        /**
         * Constructs a new ProcessingModule.
         * @memberof gridscope_processing
         * @classdesc Represents a ProcessingModule.
         * @implements IProcessingModule
         * @constructor
         * @param {gridscope_processing.IProcessingModule=} [properties] Properties to set
         */
        function ProcessingModule(properties) {
            this.configuration = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProcessingModule key.
         * @member {string} key
         * @memberof gridscope_processing.ProcessingModule
         * @instance
         */
        ProcessingModule.prototype.key = "";

        /**
         * ProcessingModule name.
         * @member {string} name
         * @memberof gridscope_processing.ProcessingModule
         * @instance
         */
        ProcessingModule.prototype.name = "";

        /**
         * ProcessingModule configuration.
         * @member {Array.<inputs.IInput>} configuration
         * @memberof gridscope_processing.ProcessingModule
         * @instance
         */
        ProcessingModule.prototype.configuration = $util.emptyArray;

        /**
         * Creates a new ProcessingModule instance using the specified properties.
         * @function create
         * @memberof gridscope_processing.ProcessingModule
         * @static
         * @param {gridscope_processing.IProcessingModule=} [properties] Properties to set
         * @returns {gridscope_processing.ProcessingModule} ProcessingModule instance
         */
        ProcessingModule.create = function create(properties) {
            return new ProcessingModule(properties);
        };

        /**
         * Encodes the specified ProcessingModule message. Does not implicitly {@link gridscope_processing.ProcessingModule.verify|verify} messages.
         * @function encode
         * @memberof gridscope_processing.ProcessingModule
         * @static
         * @param {gridscope_processing.IProcessingModule} message ProcessingModule message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProcessingModule.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.configuration != null && message.configuration.length)
                for (let i = 0; i < message.configuration.length; ++i)
                    $root.inputs.Input.encode(message.configuration[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ProcessingModule message, length delimited. Does not implicitly {@link gridscope_processing.ProcessingModule.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_processing.ProcessingModule
         * @static
         * @param {gridscope_processing.IProcessingModule} message ProcessingModule message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProcessingModule.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProcessingModule message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_processing.ProcessingModule
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_processing.ProcessingModule} ProcessingModule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProcessingModule.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_processing.ProcessingModule();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    if (!(message.configuration && message.configuration.length))
                        message.configuration = [];
                    message.configuration.push($root.inputs.Input.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProcessingModule message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_processing.ProcessingModule
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_processing.ProcessingModule} ProcessingModule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProcessingModule.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProcessingModule message.
         * @function verify
         * @memberof gridscope_processing.ProcessingModule
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProcessingModule.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.configuration != null && message.hasOwnProperty("configuration")) {
                if (!Array.isArray(message.configuration))
                    return "configuration: array expected";
                for (let i = 0; i < message.configuration.length; ++i) {
                    let error = $root.inputs.Input.verify(message.configuration[i]);
                    if (error)
                        return "configuration." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ProcessingModule message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_processing.ProcessingModule
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_processing.ProcessingModule} ProcessingModule
         */
        ProcessingModule.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_processing.ProcessingModule)
                return object;
            let message = new $root.gridscope_processing.ProcessingModule();
            if (object.key != null)
                message.key = String(object.key);
            if (object.name != null)
                message.name = String(object.name);
            if (object.configuration) {
                if (!Array.isArray(object.configuration))
                    throw TypeError(".gridscope_processing.ProcessingModule.configuration: array expected");
                message.configuration = [];
                for (let i = 0; i < object.configuration.length; ++i) {
                    if (typeof object.configuration[i] !== "object")
                        throw TypeError(".gridscope_processing.ProcessingModule.configuration: object expected");
                    message.configuration[i] = $root.inputs.Input.fromObject(object.configuration[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ProcessingModule message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_processing.ProcessingModule
         * @static
         * @param {gridscope_processing.ProcessingModule} message ProcessingModule
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProcessingModule.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.configuration = [];
            if (options.defaults) {
                object.key = "";
                object.name = "";
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.configuration && message.configuration.length) {
                object.configuration = [];
                for (let j = 0; j < message.configuration.length; ++j)
                    object.configuration[j] = $root.inputs.Input.toObject(message.configuration[j], options);
            }
            return object;
        };

        /**
         * Converts this ProcessingModule to JSON.
         * @function toJSON
         * @memberof gridscope_processing.ProcessingModule
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProcessingModule.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ProcessingModule;
    })();

    gridscope_processing.SaveProcessingModule = (function() {

        /**
         * Properties of a SaveProcessingModule.
         * @memberof gridscope_processing
         * @interface ISaveProcessingModule
         * @property {string|null} [key] SaveProcessingModule key
         * @property {string|null} [projectKey] SaveProcessingModule projectKey
         * @property {Array.<inputs.IInput>|null} [configuration] SaveProcessingModule configuration
         */

        /**
         * Constructs a new SaveProcessingModule.
         * @memberof gridscope_processing
         * @classdesc Represents a SaveProcessingModule.
         * @implements ISaveProcessingModule
         * @constructor
         * @param {gridscope_processing.ISaveProcessingModule=} [properties] Properties to set
         */
        function SaveProcessingModule(properties) {
            this.configuration = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SaveProcessingModule key.
         * @member {string} key
         * @memberof gridscope_processing.SaveProcessingModule
         * @instance
         */
        SaveProcessingModule.prototype.key = "";

        /**
         * SaveProcessingModule projectKey.
         * @member {string} projectKey
         * @memberof gridscope_processing.SaveProcessingModule
         * @instance
         */
        SaveProcessingModule.prototype.projectKey = "";

        /**
         * SaveProcessingModule configuration.
         * @member {Array.<inputs.IInput>} configuration
         * @memberof gridscope_processing.SaveProcessingModule
         * @instance
         */
        SaveProcessingModule.prototype.configuration = $util.emptyArray;

        /**
         * Creates a new SaveProcessingModule instance using the specified properties.
         * @function create
         * @memberof gridscope_processing.SaveProcessingModule
         * @static
         * @param {gridscope_processing.ISaveProcessingModule=} [properties] Properties to set
         * @returns {gridscope_processing.SaveProcessingModule} SaveProcessingModule instance
         */
        SaveProcessingModule.create = function create(properties) {
            return new SaveProcessingModule(properties);
        };

        /**
         * Encodes the specified SaveProcessingModule message. Does not implicitly {@link gridscope_processing.SaveProcessingModule.verify|verify} messages.
         * @function encode
         * @memberof gridscope_processing.SaveProcessingModule
         * @static
         * @param {gridscope_processing.ISaveProcessingModule} message SaveProcessingModule message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveProcessingModule.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.projectKey != null && Object.hasOwnProperty.call(message, "projectKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.projectKey);
            if (message.configuration != null && message.configuration.length)
                for (let i = 0; i < message.configuration.length; ++i)
                    $root.inputs.Input.encode(message.configuration[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SaveProcessingModule message, length delimited. Does not implicitly {@link gridscope_processing.SaveProcessingModule.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_processing.SaveProcessingModule
         * @static
         * @param {gridscope_processing.ISaveProcessingModule} message SaveProcessingModule message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveProcessingModule.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SaveProcessingModule message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_processing.SaveProcessingModule
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_processing.SaveProcessingModule} SaveProcessingModule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveProcessingModule.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_processing.SaveProcessingModule();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.projectKey = reader.string();
                    break;
                case 3:
                    if (!(message.configuration && message.configuration.length))
                        message.configuration = [];
                    message.configuration.push($root.inputs.Input.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SaveProcessingModule message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_processing.SaveProcessingModule
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_processing.SaveProcessingModule} SaveProcessingModule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveProcessingModule.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SaveProcessingModule message.
         * @function verify
         * @memberof gridscope_processing.SaveProcessingModule
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SaveProcessingModule.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                if (!$util.isString(message.projectKey))
                    return "projectKey: string expected";
            if (message.configuration != null && message.hasOwnProperty("configuration")) {
                if (!Array.isArray(message.configuration))
                    return "configuration: array expected";
                for (let i = 0; i < message.configuration.length; ++i) {
                    let error = $root.inputs.Input.verify(message.configuration[i]);
                    if (error)
                        return "configuration." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SaveProcessingModule message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_processing.SaveProcessingModule
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_processing.SaveProcessingModule} SaveProcessingModule
         */
        SaveProcessingModule.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_processing.SaveProcessingModule)
                return object;
            let message = new $root.gridscope_processing.SaveProcessingModule();
            if (object.key != null)
                message.key = String(object.key);
            if (object.projectKey != null)
                message.projectKey = String(object.projectKey);
            if (object.configuration) {
                if (!Array.isArray(object.configuration))
                    throw TypeError(".gridscope_processing.SaveProcessingModule.configuration: array expected");
                message.configuration = [];
                for (let i = 0; i < object.configuration.length; ++i) {
                    if (typeof object.configuration[i] !== "object")
                        throw TypeError(".gridscope_processing.SaveProcessingModule.configuration: object expected");
                    message.configuration[i] = $root.inputs.Input.fromObject(object.configuration[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a SaveProcessingModule message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_processing.SaveProcessingModule
         * @static
         * @param {gridscope_processing.SaveProcessingModule} message SaveProcessingModule
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SaveProcessingModule.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.configuration = [];
            if (options.defaults) {
                object.key = "";
                object.projectKey = "";
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                object.projectKey = message.projectKey;
            if (message.configuration && message.configuration.length) {
                object.configuration = [];
                for (let j = 0; j < message.configuration.length; ++j)
                    object.configuration[j] = $root.inputs.Input.toObject(message.configuration[j], options);
            }
            return object;
        };

        /**
         * Converts this SaveProcessingModule to JSON.
         * @function toJSON
         * @memberof gridscope_processing.SaveProcessingModule
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SaveProcessingModule.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SaveProcessingModule;
    })();

    gridscope_processing.ExecuteModule = (function() {

        /**
         * Properties of an ExecuteModule.
         * @memberof gridscope_processing
         * @interface IExecuteModule
         * @property {string|null} [projectKey] ExecuteModule projectKey
         * @property {string|null} [moduleKey] ExecuteModule moduleKey
         * @property {Array.<inputs.IInput>|null} [configuration] ExecuteModule configuration
         * @property {string|null} [outputName] ExecuteModule outputName
         */

        /**
         * Constructs a new ExecuteModule.
         * @memberof gridscope_processing
         * @classdesc Represents an ExecuteModule.
         * @implements IExecuteModule
         * @constructor
         * @param {gridscope_processing.IExecuteModule=} [properties] Properties to set
         */
        function ExecuteModule(properties) {
            this.configuration = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExecuteModule projectKey.
         * @member {string} projectKey
         * @memberof gridscope_processing.ExecuteModule
         * @instance
         */
        ExecuteModule.prototype.projectKey = "";

        /**
         * ExecuteModule moduleKey.
         * @member {string} moduleKey
         * @memberof gridscope_processing.ExecuteModule
         * @instance
         */
        ExecuteModule.prototype.moduleKey = "";

        /**
         * ExecuteModule configuration.
         * @member {Array.<inputs.IInput>} configuration
         * @memberof gridscope_processing.ExecuteModule
         * @instance
         */
        ExecuteModule.prototype.configuration = $util.emptyArray;

        /**
         * ExecuteModule outputName.
         * @member {string} outputName
         * @memberof gridscope_processing.ExecuteModule
         * @instance
         */
        ExecuteModule.prototype.outputName = "";

        /**
         * Creates a new ExecuteModule instance using the specified properties.
         * @function create
         * @memberof gridscope_processing.ExecuteModule
         * @static
         * @param {gridscope_processing.IExecuteModule=} [properties] Properties to set
         * @returns {gridscope_processing.ExecuteModule} ExecuteModule instance
         */
        ExecuteModule.create = function create(properties) {
            return new ExecuteModule(properties);
        };

        /**
         * Encodes the specified ExecuteModule message. Does not implicitly {@link gridscope_processing.ExecuteModule.verify|verify} messages.
         * @function encode
         * @memberof gridscope_processing.ExecuteModule
         * @static
         * @param {gridscope_processing.IExecuteModule} message ExecuteModule message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExecuteModule.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectKey != null && Object.hasOwnProperty.call(message, "projectKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.projectKey);
            if (message.moduleKey != null && Object.hasOwnProperty.call(message, "moduleKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.moduleKey);
            if (message.configuration != null && message.configuration.length)
                for (let i = 0; i < message.configuration.length; ++i)
                    $root.inputs.Input.encode(message.configuration[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.outputName != null && Object.hasOwnProperty.call(message, "outputName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.outputName);
            return writer;
        };

        /**
         * Encodes the specified ExecuteModule message, length delimited. Does not implicitly {@link gridscope_processing.ExecuteModule.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_processing.ExecuteModule
         * @static
         * @param {gridscope_processing.IExecuteModule} message ExecuteModule message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExecuteModule.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExecuteModule message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_processing.ExecuteModule
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_processing.ExecuteModule} ExecuteModule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExecuteModule.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_processing.ExecuteModule();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.projectKey = reader.string();
                    break;
                case 2:
                    message.moduleKey = reader.string();
                    break;
                case 3:
                    if (!(message.configuration && message.configuration.length))
                        message.configuration = [];
                    message.configuration.push($root.inputs.Input.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.outputName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExecuteModule message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_processing.ExecuteModule
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_processing.ExecuteModule} ExecuteModule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExecuteModule.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExecuteModule message.
         * @function verify
         * @memberof gridscope_processing.ExecuteModule
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExecuteModule.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                if (!$util.isString(message.projectKey))
                    return "projectKey: string expected";
            if (message.moduleKey != null && message.hasOwnProperty("moduleKey"))
                if (!$util.isString(message.moduleKey))
                    return "moduleKey: string expected";
            if (message.configuration != null && message.hasOwnProperty("configuration")) {
                if (!Array.isArray(message.configuration))
                    return "configuration: array expected";
                for (let i = 0; i < message.configuration.length; ++i) {
                    let error = $root.inputs.Input.verify(message.configuration[i]);
                    if (error)
                        return "configuration." + error;
                }
            }
            if (message.outputName != null && message.hasOwnProperty("outputName"))
                if (!$util.isString(message.outputName))
                    return "outputName: string expected";
            return null;
        };

        /**
         * Creates an ExecuteModule message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_processing.ExecuteModule
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_processing.ExecuteModule} ExecuteModule
         */
        ExecuteModule.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_processing.ExecuteModule)
                return object;
            let message = new $root.gridscope_processing.ExecuteModule();
            if (object.projectKey != null)
                message.projectKey = String(object.projectKey);
            if (object.moduleKey != null)
                message.moduleKey = String(object.moduleKey);
            if (object.configuration) {
                if (!Array.isArray(object.configuration))
                    throw TypeError(".gridscope_processing.ExecuteModule.configuration: array expected");
                message.configuration = [];
                for (let i = 0; i < object.configuration.length; ++i) {
                    if (typeof object.configuration[i] !== "object")
                        throw TypeError(".gridscope_processing.ExecuteModule.configuration: object expected");
                    message.configuration[i] = $root.inputs.Input.fromObject(object.configuration[i]);
                }
            }
            if (object.outputName != null)
                message.outputName = String(object.outputName);
            return message;
        };

        /**
         * Creates a plain object from an ExecuteModule message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_processing.ExecuteModule
         * @static
         * @param {gridscope_processing.ExecuteModule} message ExecuteModule
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExecuteModule.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.configuration = [];
            if (options.defaults) {
                object.projectKey = "";
                object.moduleKey = "";
                object.outputName = "";
            }
            if (message.projectKey != null && message.hasOwnProperty("projectKey"))
                object.projectKey = message.projectKey;
            if (message.moduleKey != null && message.hasOwnProperty("moduleKey"))
                object.moduleKey = message.moduleKey;
            if (message.configuration && message.configuration.length) {
                object.configuration = [];
                for (let j = 0; j < message.configuration.length; ++j)
                    object.configuration[j] = $root.inputs.Input.toObject(message.configuration[j], options);
            }
            if (message.outputName != null && message.hasOwnProperty("outputName"))
                object.outputName = message.outputName;
            return object;
        };

        /**
         * Converts this ExecuteModule to JSON.
         * @function toJSON
         * @memberof gridscope_processing.ExecuteModule
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExecuteModule.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ExecuteModule;
    })();

    return gridscope_processing;
})();

export const gridscope_global = $root.gridscope_global = (() => {

    /**
     * Namespace gridscope_global.
     * @exports gridscope_global
     * @namespace
     */
    const gridscope_global = {};

    gridscope_global.UiState = (function() {

        /**
         * Properties of an UiState.
         * @memberof gridscope_global
         * @interface IUiState
         * @property {Array.<gridscope_project.IProject>|null} [recentProjects] UiState recentProjects
         */

        /**
         * Constructs a new UiState.
         * @memberof gridscope_global
         * @classdesc Represents an UiState.
         * @implements IUiState
         * @constructor
         * @param {gridscope_global.IUiState=} [properties] Properties to set
         */
        function UiState(properties) {
            this.recentProjects = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UiState recentProjects.
         * @member {Array.<gridscope_project.IProject>} recentProjects
         * @memberof gridscope_global.UiState
         * @instance
         */
        UiState.prototype.recentProjects = $util.emptyArray;

        /**
         * Creates a new UiState instance using the specified properties.
         * @function create
         * @memberof gridscope_global.UiState
         * @static
         * @param {gridscope_global.IUiState=} [properties] Properties to set
         * @returns {gridscope_global.UiState} UiState instance
         */
        UiState.create = function create(properties) {
            return new UiState(properties);
        };

        /**
         * Encodes the specified UiState message. Does not implicitly {@link gridscope_global.UiState.verify|verify} messages.
         * @function encode
         * @memberof gridscope_global.UiState
         * @static
         * @param {gridscope_global.IUiState} message UiState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UiState.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.recentProjects != null && message.recentProjects.length)
                for (let i = 0; i < message.recentProjects.length; ++i)
                    $root.gridscope_project.Project.encode(message.recentProjects[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UiState message, length delimited. Does not implicitly {@link gridscope_global.UiState.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gridscope_global.UiState
         * @static
         * @param {gridscope_global.IUiState} message UiState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UiState.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UiState message from the specified reader or buffer.
         * @function decode
         * @memberof gridscope_global.UiState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gridscope_global.UiState} UiState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UiState.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gridscope_global.UiState();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.recentProjects && message.recentProjects.length))
                        message.recentProjects = [];
                    message.recentProjects.push($root.gridscope_project.Project.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UiState message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gridscope_global.UiState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gridscope_global.UiState} UiState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UiState.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UiState message.
         * @function verify
         * @memberof gridscope_global.UiState
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UiState.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.recentProjects != null && message.hasOwnProperty("recentProjects")) {
                if (!Array.isArray(message.recentProjects))
                    return "recentProjects: array expected";
                for (let i = 0; i < message.recentProjects.length; ++i) {
                    let error = $root.gridscope_project.Project.verify(message.recentProjects[i]);
                    if (error)
                        return "recentProjects." + error;
                }
            }
            return null;
        };

        /**
         * Creates an UiState message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gridscope_global.UiState
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gridscope_global.UiState} UiState
         */
        UiState.fromObject = function fromObject(object) {
            if (object instanceof $root.gridscope_global.UiState)
                return object;
            let message = new $root.gridscope_global.UiState();
            if (object.recentProjects) {
                if (!Array.isArray(object.recentProjects))
                    throw TypeError(".gridscope_global.UiState.recentProjects: array expected");
                message.recentProjects = [];
                for (let i = 0; i < object.recentProjects.length; ++i) {
                    if (typeof object.recentProjects[i] !== "object")
                        throw TypeError(".gridscope_global.UiState.recentProjects: object expected");
                    message.recentProjects[i] = $root.gridscope_project.Project.fromObject(object.recentProjects[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an UiState message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gridscope_global.UiState
         * @static
         * @param {gridscope_global.UiState} message UiState
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UiState.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.recentProjects = [];
            if (message.recentProjects && message.recentProjects.length) {
                object.recentProjects = [];
                for (let j = 0; j < message.recentProjects.length; ++j)
                    object.recentProjects[j] = $root.gridscope_project.Project.toObject(message.recentProjects[j], options);
            }
            return object;
        };

        /**
         * Converts this UiState to JSON.
         * @function toJSON
         * @memberof gridscope_global.UiState
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UiState.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UiState;
    })();

    return gridscope_global;
})();

export const sfiles = $root.sfiles = (() => {

    /**
     * Namespace sfiles.
     * @exports sfiles
     * @namespace
     */
    const sfiles = {};

    sfiles.UploadStartSlot = (function() {

        /**
         * Properties of an UploadStartSlot.
         * @memberof sfiles
         * @interface IUploadStartSlot
         * @property {string|null} [key] UploadStartSlot key
         * @property {string|null} [localKey] UploadStartSlot localKey
         */

        /**
         * Constructs a new UploadStartSlot.
         * @memberof sfiles
         * @classdesc Represents an UploadStartSlot.
         * @implements IUploadStartSlot
         * @constructor
         * @param {sfiles.IUploadStartSlot=} [properties] Properties to set
         */
        function UploadStartSlot(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadStartSlot key.
         * @member {string} key
         * @memberof sfiles.UploadStartSlot
         * @instance
         */
        UploadStartSlot.prototype.key = "";

        /**
         * UploadStartSlot localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadStartSlot
         * @instance
         */
        UploadStartSlot.prototype.localKey = "";

        /**
         * Creates a new UploadStartSlot instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.IUploadStartSlot=} [properties] Properties to set
         * @returns {sfiles.UploadStartSlot} UploadStartSlot instance
         */
        UploadStartSlot.create = function create(properties) {
            return new UploadStartSlot(properties);
        };

        /**
         * Encodes the specified UploadStartSlot message. Does not implicitly {@link sfiles.UploadStartSlot.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.IUploadStartSlot} message UploadStartSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStartSlot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.localKey);
            return writer;
        };

        /**
         * Encodes the specified UploadStartSlot message, length delimited. Does not implicitly {@link sfiles.UploadStartSlot.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.IUploadStartSlot} message UploadStartSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStartSlot.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadStartSlot message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadStartSlot} UploadStartSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStartSlot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadStartSlot();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.localKey = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadStartSlot message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadStartSlot} UploadStartSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStartSlot.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadStartSlot message.
         * @function verify
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadStartSlot.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            return null;
        };

        /**
         * Creates an UploadStartSlot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadStartSlot} UploadStartSlot
         */
        UploadStartSlot.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadStartSlot)
                return object;
            let message = new $root.sfiles.UploadStartSlot();
            if (object.key != null)
                message.key = String(object.key);
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            return message;
        };

        /**
         * Creates a plain object from an UploadStartSlot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.UploadStartSlot} message UploadStartSlot
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadStartSlot.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.localKey = "";
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            return object;
        };

        /**
         * Converts this UploadStartSlot to JSON.
         * @function toJSON
         * @memberof sfiles.UploadStartSlot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadStartSlot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadStartSlot;
    })();

    sfiles.UploadProgress = (function() {

        /**
         * Properties of an UploadProgress.
         * @memberof sfiles
         * @interface IUploadProgress
         * @property {number|Long|null} [nBytes] UploadProgress nBytes
         * @property {string|null} [key] UploadProgress key
         * @property {string|null} [localKey] UploadProgress localKey
         */

        /**
         * Constructs a new UploadProgress.
         * @memberof sfiles
         * @classdesc Represents an UploadProgress.
         * @implements IUploadProgress
         * @constructor
         * @param {sfiles.IUploadProgress=} [properties] Properties to set
         */
        function UploadProgress(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadProgress nBytes.
         * @member {number|Long} nBytes
         * @memberof sfiles.UploadProgress
         * @instance
         */
        UploadProgress.prototype.nBytes = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UploadProgress key.
         * @member {string} key
         * @memberof sfiles.UploadProgress
         * @instance
         */
        UploadProgress.prototype.key = "";

        /**
         * UploadProgress localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadProgress
         * @instance
         */
        UploadProgress.prototype.localKey = "";

        /**
         * Creates a new UploadProgress instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.IUploadProgress=} [properties] Properties to set
         * @returns {sfiles.UploadProgress} UploadProgress instance
         */
        UploadProgress.create = function create(properties) {
            return new UploadProgress(properties);
        };

        /**
         * Encodes the specified UploadProgress message. Does not implicitly {@link sfiles.UploadProgress.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.IUploadProgress} message UploadProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadProgress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nBytes != null && Object.hasOwnProperty.call(message, "nBytes"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.nBytes);
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.key);
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.localKey);
            return writer;
        };

        /**
         * Encodes the specified UploadProgress message, length delimited. Does not implicitly {@link sfiles.UploadProgress.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.IUploadProgress} message UploadProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadProgress.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadProgress message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadProgress} UploadProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadProgress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadProgress();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.nBytes = reader.int64();
                    break;
                case 2:
                    message.key = reader.string();
                    break;
                case 3:
                    message.localKey = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadProgress message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadProgress} UploadProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadProgress.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadProgress message.
         * @function verify
         * @memberof sfiles.UploadProgress
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadProgress.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.nBytes != null && message.hasOwnProperty("nBytes"))
                if (!$util.isInteger(message.nBytes) && !(message.nBytes && $util.isInteger(message.nBytes.low) && $util.isInteger(message.nBytes.high)))
                    return "nBytes: integer|Long expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            return null;
        };

        /**
         * Creates an UploadProgress message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadProgress
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadProgress} UploadProgress
         */
        UploadProgress.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadProgress)
                return object;
            let message = new $root.sfiles.UploadProgress();
            if (object.nBytes != null)
                if ($util.Long)
                    (message.nBytes = $util.Long.fromValue(object.nBytes)).unsigned = false;
                else if (typeof object.nBytes === "string")
                    message.nBytes = parseInt(object.nBytes, 10);
                else if (typeof object.nBytes === "number")
                    message.nBytes = object.nBytes;
                else if (typeof object.nBytes === "object")
                    message.nBytes = new $util.LongBits(object.nBytes.low >>> 0, object.nBytes.high >>> 0).toNumber();
            if (object.key != null)
                message.key = String(object.key);
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            return message;
        };

        /**
         * Creates a plain object from an UploadProgress message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.UploadProgress} message UploadProgress
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadProgress.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.nBytes = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.nBytes = options.longs === String ? "0" : 0;
                object.key = "";
                object.localKey = "";
            }
            if (message.nBytes != null && message.hasOwnProperty("nBytes"))
                if (typeof message.nBytes === "number")
                    object.nBytes = options.longs === String ? String(message.nBytes) : message.nBytes;
                else
                    object.nBytes = options.longs === String ? $util.Long.prototype.toString.call(message.nBytes) : options.longs === Number ? new $util.LongBits(message.nBytes.low >>> 0, message.nBytes.high >>> 0).toNumber() : message.nBytes;
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            return object;
        };

        /**
         * Converts this UploadProgress to JSON.
         * @function toJSON
         * @memberof sfiles.UploadProgress
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadProgress.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadProgress;
    })();

    sfiles.UploadDone = (function() {

        /**
         * Properties of an UploadDone.
         * @memberof sfiles
         * @interface IUploadDone
         * @property {string|null} [key] UploadDone key
         * @property {sfiles.IUploadedFile|null} [file] UploadDone file
         */

        /**
         * Constructs a new UploadDone.
         * @memberof sfiles
         * @classdesc Represents an UploadDone.
         * @implements IUploadDone
         * @constructor
         * @param {sfiles.IUploadDone=} [properties] Properties to set
         */
        function UploadDone(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadDone key.
         * @member {string} key
         * @memberof sfiles.UploadDone
         * @instance
         */
        UploadDone.prototype.key = "";

        /**
         * UploadDone file.
         * @member {sfiles.IUploadedFile|null|undefined} file
         * @memberof sfiles.UploadDone
         * @instance
         */
        UploadDone.prototype.file = null;

        /**
         * Creates a new UploadDone instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.IUploadDone=} [properties] Properties to set
         * @returns {sfiles.UploadDone} UploadDone instance
         */
        UploadDone.create = function create(properties) {
            return new UploadDone(properties);
        };

        /**
         * Encodes the specified UploadDone message. Does not implicitly {@link sfiles.UploadDone.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.IUploadDone} message UploadDone message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadDone.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                $root.sfiles.UploadedFile.encode(message.file, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadDone message, length delimited. Does not implicitly {@link sfiles.UploadDone.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.IUploadDone} message UploadDone message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadDone.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadDone message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadDone
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadDone} UploadDone
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadDone.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadDone();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.file = $root.sfiles.UploadedFile.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadDone message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadDone
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadDone} UploadDone
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadDone.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadDone message.
         * @function verify
         * @memberof sfiles.UploadDone
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadDone.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.file != null && message.hasOwnProperty("file")) {
                let error = $root.sfiles.UploadedFile.verify(message.file);
                if (error)
                    return "file." + error;
            }
            return null;
        };

        /**
         * Creates an UploadDone message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadDone
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadDone} UploadDone
         */
        UploadDone.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadDone)
                return object;
            let message = new $root.sfiles.UploadDone();
            if (object.key != null)
                message.key = String(object.key);
            if (object.file != null) {
                if (typeof object.file !== "object")
                    throw TypeError(".sfiles.UploadDone.file: object expected");
                message.file = $root.sfiles.UploadedFile.fromObject(object.file);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadDone message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.UploadDone} message UploadDone
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadDone.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.file = null;
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.file != null && message.hasOwnProperty("file"))
                object.file = $root.sfiles.UploadedFile.toObject(message.file, options);
            return object;
        };

        /**
         * Converts this UploadDone to JSON.
         * @function toJSON
         * @memberof sfiles.UploadDone
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadDone.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadDone;
    })();

    sfiles.UploadedFile = (function() {

        /**
         * Properties of an UploadedFile.
         * @memberof sfiles
         * @interface IUploadedFile
         * @property {string|null} [localKey] UploadedFile localKey
         * @property {string|null} [url] UploadedFile url
         * @property {string|null} [id] UploadedFile id
         * @property {string|null} [mime] UploadedFile mime
         * @property {string|null} [thumbUrl] UploadedFile thumbUrl
         * @property {string|null} [name] UploadedFile name
         */

        /**
         * Constructs a new UploadedFile.
         * @memberof sfiles
         * @classdesc Represents an UploadedFile.
         * @implements IUploadedFile
         * @constructor
         * @param {sfiles.IUploadedFile=} [properties] Properties to set
         */
        function UploadedFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadedFile localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.localKey = "";

        /**
         * UploadedFile url.
         * @member {string} url
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.url = "";

        /**
         * UploadedFile id.
         * @member {string} id
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.id = "";

        /**
         * UploadedFile mime.
         * @member {string} mime
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.mime = "";

        /**
         * UploadedFile thumbUrl.
         * @member {string} thumbUrl
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.thumbUrl = "";

        /**
         * UploadedFile name.
         * @member {string} name
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.name = "";

        /**
         * Creates a new UploadedFile instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.IUploadedFile=} [properties] Properties to set
         * @returns {sfiles.UploadedFile} UploadedFile instance
         */
        UploadedFile.create = function create(properties) {
            return new UploadedFile(properties);
        };

        /**
         * Encodes the specified UploadedFile message. Does not implicitly {@link sfiles.UploadedFile.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.IUploadedFile} message UploadedFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadedFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.localKey);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.id);
            if (message.mime != null && Object.hasOwnProperty.call(message, "mime"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.mime);
            if (message.thumbUrl != null && Object.hasOwnProperty.call(message, "thumbUrl"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.thumbUrl);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified UploadedFile message, length delimited. Does not implicitly {@link sfiles.UploadedFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.IUploadedFile} message UploadedFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadedFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadedFile message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadedFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadedFile} UploadedFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadedFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadedFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.localKey = reader.string();
                    break;
                case 2:
                    message.url = reader.string();
                    break;
                case 3:
                    message.id = reader.string();
                    break;
                case 4:
                    message.mime = reader.string();
                    break;
                case 5:
                    message.thumbUrl = reader.string();
                    break;
                case 6:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadedFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadedFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadedFile} UploadedFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadedFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadedFile message.
         * @function verify
         * @memberof sfiles.UploadedFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadedFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.mime != null && message.hasOwnProperty("mime"))
                if (!$util.isString(message.mime))
                    return "mime: string expected";
            if (message.thumbUrl != null && message.hasOwnProperty("thumbUrl"))
                if (!$util.isString(message.thumbUrl))
                    return "thumbUrl: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates an UploadedFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadedFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadedFile} UploadedFile
         */
        UploadedFile.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadedFile)
                return object;
            let message = new $root.sfiles.UploadedFile();
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            if (object.url != null)
                message.url = String(object.url);
            if (object.id != null)
                message.id = String(object.id);
            if (object.mime != null)
                message.mime = String(object.mime);
            if (object.thumbUrl != null)
                message.thumbUrl = String(object.thumbUrl);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from an UploadedFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.UploadedFile} message UploadedFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadedFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.localKey = "";
                object.url = "";
                object.id = "";
                object.mime = "";
                object.thumbUrl = "";
                object.name = "";
            }
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.mime != null && message.hasOwnProperty("mime"))
                object.mime = message.mime;
            if (message.thumbUrl != null && message.hasOwnProperty("thumbUrl"))
                object.thumbUrl = message.thumbUrl;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this UploadedFile to JSON.
         * @function toJSON
         * @memberof sfiles.UploadedFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadedFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadedFile;
    })();

    sfiles.UploadStart = (function() {

        /**
         * Properties of an UploadStart.
         * @memberof sfiles
         * @interface IUploadStart
         * @property {string|null} [localKey] UploadStart localKey
         * @property {string|null} [extension] UploadStart extension
         * @property {string|null} [name] UploadStart name
         * @property {string|null} [mime] UploadStart mime
         */

        /**
         * Constructs a new UploadStart.
         * @memberof sfiles
         * @classdesc Represents an UploadStart.
         * @implements IUploadStart
         * @constructor
         * @param {sfiles.IUploadStart=} [properties] Properties to set
         */
        function UploadStart(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadStart localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.localKey = "";

        /**
         * UploadStart extension.
         * @member {string} extension
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.extension = "";

        /**
         * UploadStart name.
         * @member {string} name
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.name = "";

        /**
         * UploadStart mime.
         * @member {string} mime
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.mime = "";

        /**
         * Creates a new UploadStart instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.IUploadStart=} [properties] Properties to set
         * @returns {sfiles.UploadStart} UploadStart instance
         */
        UploadStart.create = function create(properties) {
            return new UploadStart(properties);
        };

        /**
         * Encodes the specified UploadStart message. Does not implicitly {@link sfiles.UploadStart.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.IUploadStart} message UploadStart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStart.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.localKey);
            if (message.extension != null && Object.hasOwnProperty.call(message, "extension"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.extension);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.mime != null && Object.hasOwnProperty.call(message, "mime"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.mime);
            return writer;
        };

        /**
         * Encodes the specified UploadStart message, length delimited. Does not implicitly {@link sfiles.UploadStart.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.IUploadStart} message UploadStart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStart.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadStart message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadStart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadStart} UploadStart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStart.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadStart();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.localKey = reader.string();
                    break;
                case 2:
                    message.extension = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.mime = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadStart message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadStart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadStart} UploadStart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStart.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadStart message.
         * @function verify
         * @memberof sfiles.UploadStart
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadStart.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            if (message.extension != null && message.hasOwnProperty("extension"))
                if (!$util.isString(message.extension))
                    return "extension: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.mime != null && message.hasOwnProperty("mime"))
                if (!$util.isString(message.mime))
                    return "mime: string expected";
            return null;
        };

        /**
         * Creates an UploadStart message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadStart
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadStart} UploadStart
         */
        UploadStart.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadStart)
                return object;
            let message = new $root.sfiles.UploadStart();
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            if (object.extension != null)
                message.extension = String(object.extension);
            if (object.name != null)
                message.name = String(object.name);
            if (object.mime != null)
                message.mime = String(object.mime);
            return message;
        };

        /**
         * Creates a plain object from an UploadStart message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.UploadStart} message UploadStart
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadStart.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.localKey = "";
                object.extension = "";
                object.name = "";
                object.mime = "";
            }
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            if (message.extension != null && message.hasOwnProperty("extension"))
                object.extension = message.extension;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.mime != null && message.hasOwnProperty("mime"))
                object.mime = message.mime;
            return object;
        };

        /**
         * Converts this UploadStart to JSON.
         * @function toJSON
         * @memberof sfiles.UploadStart
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadStart.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadStart;
    })();

    sfiles.DeleteFile = (function() {

        /**
         * Properties of a DeleteFile.
         * @memberof sfiles
         * @interface IDeleteFile
         * @property {sfiles.IUploadedFile|null} [file] DeleteFile file
         */

        /**
         * Constructs a new DeleteFile.
         * @memberof sfiles
         * @classdesc Represents a DeleteFile.
         * @implements IDeleteFile
         * @constructor
         * @param {sfiles.IDeleteFile=} [properties] Properties to set
         */
        function DeleteFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteFile file.
         * @member {sfiles.IUploadedFile|null|undefined} file
         * @memberof sfiles.DeleteFile
         * @instance
         */
        DeleteFile.prototype.file = null;

        /**
         * Creates a new DeleteFile instance using the specified properties.
         * @function create
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.IDeleteFile=} [properties] Properties to set
         * @returns {sfiles.DeleteFile} DeleteFile instance
         */
        DeleteFile.create = function create(properties) {
            return new DeleteFile(properties);
        };

        /**
         * Encodes the specified DeleteFile message. Does not implicitly {@link sfiles.DeleteFile.verify|verify} messages.
         * @function encode
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.IDeleteFile} message DeleteFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                $root.sfiles.UploadedFile.encode(message.file, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DeleteFile message, length delimited. Does not implicitly {@link sfiles.DeleteFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.IDeleteFile} message DeleteFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteFile message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.DeleteFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.DeleteFile} DeleteFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.DeleteFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.file = $root.sfiles.UploadedFile.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.DeleteFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.DeleteFile} DeleteFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteFile message.
         * @function verify
         * @memberof sfiles.DeleteFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.file != null && message.hasOwnProperty("file")) {
                let error = $root.sfiles.UploadedFile.verify(message.file);
                if (error)
                    return "file." + error;
            }
            return null;
        };

        /**
         * Creates a DeleteFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.DeleteFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.DeleteFile} DeleteFile
         */
        DeleteFile.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.DeleteFile)
                return object;
            let message = new $root.sfiles.DeleteFile();
            if (object.file != null) {
                if (typeof object.file !== "object")
                    throw TypeError(".sfiles.DeleteFile.file: object expected");
                message.file = $root.sfiles.UploadedFile.fromObject(object.file);
            }
            return message;
        };

        /**
         * Creates a plain object from a DeleteFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.DeleteFile} message DeleteFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.file = null;
            if (message.file != null && message.hasOwnProperty("file"))
                object.file = $root.sfiles.UploadedFile.toObject(message.file, options);
            return object;
        };

        /**
         * Converts this DeleteFile to JSON.
         * @function toJSON
         * @memberof sfiles.DeleteFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteFile;
    })();

    sfiles.UploadEnd = (function() {

        /**
         * Properties of an UploadEnd.
         * @memberof sfiles
         * @interface IUploadEnd
         */

        /**
         * Constructs a new UploadEnd.
         * @memberof sfiles
         * @classdesc Represents an UploadEnd.
         * @implements IUploadEnd
         * @constructor
         * @param {sfiles.IUploadEnd=} [properties] Properties to set
         */
        function UploadEnd(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UploadEnd instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.IUploadEnd=} [properties] Properties to set
         * @returns {sfiles.UploadEnd} UploadEnd instance
         */
        UploadEnd.create = function create(properties) {
            return new UploadEnd(properties);
        };

        /**
         * Encodes the specified UploadEnd message. Does not implicitly {@link sfiles.UploadEnd.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.IUploadEnd} message UploadEnd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadEnd.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UploadEnd message, length delimited. Does not implicitly {@link sfiles.UploadEnd.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.IUploadEnd} message UploadEnd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadEnd.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadEnd message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadEnd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadEnd} UploadEnd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadEnd.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadEnd();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadEnd message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadEnd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadEnd} UploadEnd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadEnd.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadEnd message.
         * @function verify
         * @memberof sfiles.UploadEnd
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadEnd.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UploadEnd message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadEnd
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadEnd} UploadEnd
         */
        UploadEnd.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadEnd)
                return object;
            return new $root.sfiles.UploadEnd();
        };

        /**
         * Creates a plain object from an UploadEnd message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.UploadEnd} message UploadEnd
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadEnd.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UploadEnd to JSON.
         * @function toJSON
         * @memberof sfiles.UploadEnd
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadEnd.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadEnd;
    })();

    return sfiles;
})();

export const socket_api = $root.socket_api = (() => {

    /**
     * Namespace socket_api.
     * @exports socket_api
     * @namespace
     */
    const socket_api = {};

    socket_api.Ack = (function() {

        /**
         * Properties of an Ack.
         * @memberof socket_api
         * @interface IAck
         * @property {string|null} [uuid] Ack uuid
         * @property {string|null} [errorMessage] Ack errorMessage
         * @property {string|null} [asyncProgressKey] Ack asyncProgressKey
         * @property {number|null} [errorCode] Ack errorCode
         */

        /**
         * Constructs a new Ack.
         * @memberof socket_api
         * @classdesc Represents an Ack.
         * @implements IAck
         * @constructor
         * @param {socket_api.IAck=} [properties] Properties to set
         */
        function Ack(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Ack uuid.
         * @member {string} uuid
         * @memberof socket_api.Ack
         * @instance
         */
        Ack.prototype.uuid = "";

        /**
         * Ack errorMessage.
         * @member {string} errorMessage
         * @memberof socket_api.Ack
         * @instance
         */
        Ack.prototype.errorMessage = "";

        /**
         * Ack asyncProgressKey.
         * @member {string} asyncProgressKey
         * @memberof socket_api.Ack
         * @instance
         */
        Ack.prototype.asyncProgressKey = "";

        /**
         * Ack errorCode.
         * @member {number} errorCode
         * @memberof socket_api.Ack
         * @instance
         */
        Ack.prototype.errorCode = 0;

        /**
         * Creates a new Ack instance using the specified properties.
         * @function create
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.IAck=} [properties] Properties to set
         * @returns {socket_api.Ack} Ack instance
         */
        Ack.create = function create(properties) {
            return new Ack(properties);
        };

        /**
         * Encodes the specified Ack message. Does not implicitly {@link socket_api.Ack.verify|verify} messages.
         * @function encode
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.IAck} message Ack message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ack.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
            if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.errorMessage);
            if (message.asyncProgressKey != null && Object.hasOwnProperty.call(message, "asyncProgressKey"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.asyncProgressKey);
            if (message.errorCode != null && Object.hasOwnProperty.call(message, "errorCode"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.errorCode);
            return writer;
        };

        /**
         * Encodes the specified Ack message, length delimited. Does not implicitly {@link socket_api.Ack.verify|verify} messages.
         * @function encodeDelimited
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.IAck} message Ack message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ack.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Ack message from the specified reader or buffer.
         * @function decode
         * @memberof socket_api.Ack
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {socket_api.Ack} Ack
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ack.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.socket_api.Ack();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uuid = reader.string();
                    break;
                case 2:
                    message.errorMessage = reader.string();
                    break;
                case 3:
                    message.asyncProgressKey = reader.string();
                    break;
                case 4:
                    message.errorCode = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Ack message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof socket_api.Ack
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {socket_api.Ack} Ack
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ack.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Ack message.
         * @function verify
         * @memberof socket_api.Ack
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Ack.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                if (!$util.isString(message.uuid))
                    return "uuid: string expected";
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                if (!$util.isString(message.errorMessage))
                    return "errorMessage: string expected";
            if (message.asyncProgressKey != null && message.hasOwnProperty("asyncProgressKey"))
                if (!$util.isString(message.asyncProgressKey))
                    return "asyncProgressKey: string expected";
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                if (!$util.isInteger(message.errorCode))
                    return "errorCode: integer expected";
            return null;
        };

        /**
         * Creates an Ack message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof socket_api.Ack
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {socket_api.Ack} Ack
         */
        Ack.fromObject = function fromObject(object) {
            if (object instanceof $root.socket_api.Ack)
                return object;
            let message = new $root.socket_api.Ack();
            if (object.uuid != null)
                message.uuid = String(object.uuid);
            if (object.errorMessage != null)
                message.errorMessage = String(object.errorMessage);
            if (object.asyncProgressKey != null)
                message.asyncProgressKey = String(object.asyncProgressKey);
            if (object.errorCode != null)
                message.errorCode = object.errorCode | 0;
            return message;
        };

        /**
         * Creates a plain object from an Ack message. Also converts values to other types if specified.
         * @function toObject
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.Ack} message Ack
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Ack.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.uuid = "";
                object.errorMessage = "";
                object.asyncProgressKey = "";
                object.errorCode = 0;
            }
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                object.uuid = message.uuid;
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                object.errorMessage = message.errorMessage;
            if (message.asyncProgressKey != null && message.hasOwnProperty("asyncProgressKey"))
                object.asyncProgressKey = message.asyncProgressKey;
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                object.errorCode = message.errorCode;
            return object;
        };

        /**
         * Converts this Ack to JSON.
         * @function toJSON
         * @memberof socket_api.Ack
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Ack.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Ack;
    })();

    socket_api.AsyncProgress = (function() {

        /**
         * Properties of an AsyncProgress.
         * @memberof socket_api
         * @interface IAsyncProgress
         * @property {string|null} [key] AsyncProgress key
         * @property {number|null} [progress] AsyncProgress progress
         * @property {string|null} [info] AsyncProgress info
         * @property {boolean|null} [done] AsyncProgress done
         * @property {string|null} [errorMessage] AsyncProgress errorMessage
         */

        /**
         * Constructs a new AsyncProgress.
         * @memberof socket_api
         * @classdesc Represents an AsyncProgress.
         * @implements IAsyncProgress
         * @constructor
         * @param {socket_api.IAsyncProgress=} [properties] Properties to set
         */
        function AsyncProgress(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsyncProgress key.
         * @member {string} key
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.key = "";

        /**
         * AsyncProgress progress.
         * @member {number} progress
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.progress = 0;

        /**
         * AsyncProgress info.
         * @member {string} info
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.info = "";

        /**
         * AsyncProgress done.
         * @member {boolean} done
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.done = false;

        /**
         * AsyncProgress errorMessage.
         * @member {string} errorMessage
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.errorMessage = "";

        /**
         * Creates a new AsyncProgress instance using the specified properties.
         * @function create
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.IAsyncProgress=} [properties] Properties to set
         * @returns {socket_api.AsyncProgress} AsyncProgress instance
         */
        AsyncProgress.create = function create(properties) {
            return new AsyncProgress(properties);
        };

        /**
         * Encodes the specified AsyncProgress message. Does not implicitly {@link socket_api.AsyncProgress.verify|verify} messages.
         * @function encode
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.IAsyncProgress} message AsyncProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsyncProgress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.progress != null && Object.hasOwnProperty.call(message, "progress"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.progress);
            if (message.info != null && Object.hasOwnProperty.call(message, "info"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.info);
            if (message.done != null && Object.hasOwnProperty.call(message, "done"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.done);
            if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.errorMessage);
            return writer;
        };

        /**
         * Encodes the specified AsyncProgress message, length delimited. Does not implicitly {@link socket_api.AsyncProgress.verify|verify} messages.
         * @function encodeDelimited
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.IAsyncProgress} message AsyncProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsyncProgress.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsyncProgress message from the specified reader or buffer.
         * @function decode
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {socket_api.AsyncProgress} AsyncProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsyncProgress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.socket_api.AsyncProgress();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.progress = reader.double();
                    break;
                case 3:
                    message.info = reader.string();
                    break;
                case 4:
                    message.done = reader.bool();
                    break;
                case 5:
                    message.errorMessage = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsyncProgress message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {socket_api.AsyncProgress} AsyncProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsyncProgress.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsyncProgress message.
         * @function verify
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsyncProgress.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.progress != null && message.hasOwnProperty("progress"))
                if (typeof message.progress !== "number")
                    return "progress: number expected";
            if (message.info != null && message.hasOwnProperty("info"))
                if (!$util.isString(message.info))
                    return "info: string expected";
            if (message.done != null && message.hasOwnProperty("done"))
                if (typeof message.done !== "boolean")
                    return "done: boolean expected";
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                if (!$util.isString(message.errorMessage))
                    return "errorMessage: string expected";
            return null;
        };

        /**
         * Creates an AsyncProgress message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {socket_api.AsyncProgress} AsyncProgress
         */
        AsyncProgress.fromObject = function fromObject(object) {
            if (object instanceof $root.socket_api.AsyncProgress)
                return object;
            let message = new $root.socket_api.AsyncProgress();
            if (object.key != null)
                message.key = String(object.key);
            if (object.progress != null)
                message.progress = Number(object.progress);
            if (object.info != null)
                message.info = String(object.info);
            if (object.done != null)
                message.done = Boolean(object.done);
            if (object.errorMessage != null)
                message.errorMessage = String(object.errorMessage);
            return message;
        };

        /**
         * Creates a plain object from an AsyncProgress message. Also converts values to other types if specified.
         * @function toObject
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.AsyncProgress} message AsyncProgress
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsyncProgress.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.progress = 0;
                object.info = "";
                object.done = false;
                object.errorMessage = "";
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.progress != null && message.hasOwnProperty("progress"))
                object.progress = options.json && !isFinite(message.progress) ? String(message.progress) : message.progress;
            if (message.info != null && message.hasOwnProperty("info"))
                object.info = message.info;
            if (message.done != null && message.hasOwnProperty("done"))
                object.done = message.done;
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                object.errorMessage = message.errorMessage;
            return object;
        };

        /**
         * Converts this AsyncProgress to JSON.
         * @function toJSON
         * @memberof socket_api.AsyncProgress
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsyncProgress.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AsyncProgress;
    })();

    socket_api.UpgradeApiVersion = (function() {

        /**
         * Properties of an UpgradeApiVersion.
         * @memberof socket_api
         * @interface IUpgradeApiVersion
         * @property {number|null} [latest] UpgradeApiVersion latest
         */

        /**
         * Constructs a new UpgradeApiVersion.
         * @memberof socket_api
         * @classdesc Represents an UpgradeApiVersion.
         * @implements IUpgradeApiVersion
         * @constructor
         * @param {socket_api.IUpgradeApiVersion=} [properties] Properties to set
         */
        function UpgradeApiVersion(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpgradeApiVersion latest.
         * @member {number} latest
         * @memberof socket_api.UpgradeApiVersion
         * @instance
         */
        UpgradeApiVersion.prototype.latest = 0;

        /**
         * Creates a new UpgradeApiVersion instance using the specified properties.
         * @function create
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.IUpgradeApiVersion=} [properties] Properties to set
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion instance
         */
        UpgradeApiVersion.create = function create(properties) {
            return new UpgradeApiVersion(properties);
        };

        /**
         * Encodes the specified UpgradeApiVersion message. Does not implicitly {@link socket_api.UpgradeApiVersion.verify|verify} messages.
         * @function encode
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.IUpgradeApiVersion} message UpgradeApiVersion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpgradeApiVersion.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.latest != null && Object.hasOwnProperty.call(message, "latest"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.latest);
            return writer;
        };

        /**
         * Encodes the specified UpgradeApiVersion message, length delimited. Does not implicitly {@link socket_api.UpgradeApiVersion.verify|verify} messages.
         * @function encodeDelimited
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.IUpgradeApiVersion} message UpgradeApiVersion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpgradeApiVersion.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpgradeApiVersion message from the specified reader or buffer.
         * @function decode
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpgradeApiVersion.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.socket_api.UpgradeApiVersion();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.latest = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpgradeApiVersion message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpgradeApiVersion.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpgradeApiVersion message.
         * @function verify
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpgradeApiVersion.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.latest != null && message.hasOwnProperty("latest"))
                if (!$util.isInteger(message.latest))
                    return "latest: integer expected";
            return null;
        };

        /**
         * Creates an UpgradeApiVersion message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion
         */
        UpgradeApiVersion.fromObject = function fromObject(object) {
            if (object instanceof $root.socket_api.UpgradeApiVersion)
                return object;
            let message = new $root.socket_api.UpgradeApiVersion();
            if (object.latest != null)
                message.latest = object.latest | 0;
            return message;
        };

        /**
         * Creates a plain object from an UpgradeApiVersion message. Also converts values to other types if specified.
         * @function toObject
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.UpgradeApiVersion} message UpgradeApiVersion
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpgradeApiVersion.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.latest = 0;
            if (message.latest != null && message.hasOwnProperty("latest"))
                object.latest = message.latest;
            return object;
        };

        /**
         * Converts this UpgradeApiVersion to JSON.
         * @function toJSON
         * @memberof socket_api.UpgradeApiVersion
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpgradeApiVersion.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpgradeApiVersion;
    })();

    return socket_api;
})();

export const authentication = $root.authentication = (() => {

    /**
     * Namespace authentication.
     * @exports authentication
     * @namespace
     */
    const authentication = {};

    authentication.LoginToken = (function() {

        /**
         * Properties of a LoginToken.
         * @memberof authentication
         * @interface ILoginToken
         * @property {string|null} [token] LoginToken token
         */

        /**
         * Constructs a new LoginToken.
         * @memberof authentication
         * @classdesc Represents a LoginToken.
         * @implements ILoginToken
         * @constructor
         * @param {authentication.ILoginToken=} [properties] Properties to set
         */
        function LoginToken(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginToken token.
         * @member {string} token
         * @memberof authentication.LoginToken
         * @instance
         */
        LoginToken.prototype.token = "";

        /**
         * Creates a new LoginToken instance using the specified properties.
         * @function create
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.ILoginToken=} [properties] Properties to set
         * @returns {authentication.LoginToken} LoginToken instance
         */
        LoginToken.create = function create(properties) {
            return new LoginToken(properties);
        };

        /**
         * Encodes the specified LoginToken message. Does not implicitly {@link authentication.LoginToken.verify|verify} messages.
         * @function encode
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.ILoginToken} message LoginToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
            return writer;
        };

        /**
         * Encodes the specified LoginToken message, length delimited. Does not implicitly {@link authentication.LoginToken.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.ILoginToken} message LoginToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginToken.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginToken message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.LoginToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.LoginToken} LoginToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.LoginToken();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.token = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginToken message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.LoginToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.LoginToken} LoginToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginToken.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginToken message.
         * @function verify
         * @memberof authentication.LoginToken
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginToken.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            return null;
        };

        /**
         * Creates a LoginToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.LoginToken
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.LoginToken} LoginToken
         */
        LoginToken.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.LoginToken)
                return object;
            let message = new $root.authentication.LoginToken();
            if (object.token != null)
                message.token = String(object.token);
            return message;
        };

        /**
         * Creates a plain object from a LoginToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.LoginToken} message LoginToken
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginToken.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.token = "";
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            return object;
        };

        /**
         * Converts this LoginToken to JSON.
         * @function toJSON
         * @memberof authentication.LoginToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoginToken;
    })();

    authentication.LoginError = (function() {

        /**
         * Properties of a LoginError.
         * @memberof authentication
         * @interface ILoginError
         * @property {string|null} [errorText] LoginError errorText
         * @property {string|null} [errorCode] LoginError errorCode
         */

        /**
         * Constructs a new LoginError.
         * @memberof authentication
         * @classdesc Represents a LoginError.
         * @implements ILoginError
         * @constructor
         * @param {authentication.ILoginError=} [properties] Properties to set
         */
        function LoginError(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginError errorText.
         * @member {string} errorText
         * @memberof authentication.LoginError
         * @instance
         */
        LoginError.prototype.errorText = "";

        /**
         * LoginError errorCode.
         * @member {string} errorCode
         * @memberof authentication.LoginError
         * @instance
         */
        LoginError.prototype.errorCode = "";

        /**
         * Creates a new LoginError instance using the specified properties.
         * @function create
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.ILoginError=} [properties] Properties to set
         * @returns {authentication.LoginError} LoginError instance
         */
        LoginError.create = function create(properties) {
            return new LoginError(properties);
        };

        /**
         * Encodes the specified LoginError message. Does not implicitly {@link authentication.LoginError.verify|verify} messages.
         * @function encode
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.ILoginError} message LoginError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginError.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.errorText != null && Object.hasOwnProperty.call(message, "errorText"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.errorText);
            if (message.errorCode != null && Object.hasOwnProperty.call(message, "errorCode"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.errorCode);
            return writer;
        };

        /**
         * Encodes the specified LoginError message, length delimited. Does not implicitly {@link authentication.LoginError.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.ILoginError} message LoginError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginError.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginError message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.LoginError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.LoginError} LoginError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginError.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.LoginError();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.errorText = reader.string();
                    break;
                case 2:
                    message.errorCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginError message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.LoginError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.LoginError} LoginError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginError.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginError message.
         * @function verify
         * @memberof authentication.LoginError
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginError.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.errorText != null && message.hasOwnProperty("errorText"))
                if (!$util.isString(message.errorText))
                    return "errorText: string expected";
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                if (!$util.isString(message.errorCode))
                    return "errorCode: string expected";
            return null;
        };

        /**
         * Creates a LoginError message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.LoginError
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.LoginError} LoginError
         */
        LoginError.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.LoginError)
                return object;
            let message = new $root.authentication.LoginError();
            if (object.errorText != null)
                message.errorText = String(object.errorText);
            if (object.errorCode != null)
                message.errorCode = String(object.errorCode);
            return message;
        };

        /**
         * Creates a plain object from a LoginError message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.LoginError} message LoginError
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginError.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.errorText = "";
                object.errorCode = "";
            }
            if (message.errorText != null && message.hasOwnProperty("errorText"))
                object.errorText = message.errorText;
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                object.errorCode = message.errorCode;
            return object;
        };

        /**
         * Converts this LoginError to JSON.
         * @function toJSON
         * @memberof authentication.LoginError
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginError.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoginError;
    })();

    authentication.Login = (function() {

        /**
         * Properties of a Login.
         * @memberof authentication
         * @interface ILogin
         * @property {string|null} [username] Login username
         * @property {string|null} [password] Login password
         */

        /**
         * Constructs a new Login.
         * @memberof authentication
         * @classdesc Represents a Login.
         * @implements ILogin
         * @constructor
         * @param {authentication.ILogin=} [properties] Properties to set
         */
        function Login(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Login username.
         * @member {string} username
         * @memberof authentication.Login
         * @instance
         */
        Login.prototype.username = "";

        /**
         * Login password.
         * @member {string} password
         * @memberof authentication.Login
         * @instance
         */
        Login.prototype.password = "";

        /**
         * Creates a new Login instance using the specified properties.
         * @function create
         * @memberof authentication.Login
         * @static
         * @param {authentication.ILogin=} [properties] Properties to set
         * @returns {authentication.Login} Login instance
         */
        Login.create = function create(properties) {
            return new Login(properties);
        };

        /**
         * Encodes the specified Login message. Does not implicitly {@link authentication.Login.verify|verify} messages.
         * @function encode
         * @memberof authentication.Login
         * @static
         * @param {authentication.ILogin} message Login message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Login.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.username);
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
            return writer;
        };

        /**
         * Encodes the specified Login message, length delimited. Does not implicitly {@link authentication.Login.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.Login
         * @static
         * @param {authentication.ILogin} message Login message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Login.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Login message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.Login
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.Login} Login
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Login.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.Login();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.username = reader.string();
                    break;
                case 2:
                    message.password = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Login message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.Login
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.Login} Login
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Login.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Login message.
         * @function verify
         * @memberof authentication.Login
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Login.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.username != null && message.hasOwnProperty("username"))
                if (!$util.isString(message.username))
                    return "username: string expected";
            if (message.password != null && message.hasOwnProperty("password"))
                if (!$util.isString(message.password))
                    return "password: string expected";
            return null;
        };

        /**
         * Creates a Login message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.Login
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.Login} Login
         */
        Login.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.Login)
                return object;
            let message = new $root.authentication.Login();
            if (object.username != null)
                message.username = String(object.username);
            if (object.password != null)
                message.password = String(object.password);
            return message;
        };

        /**
         * Creates a plain object from a Login message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.Login
         * @static
         * @param {authentication.Login} message Login
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Login.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.username = "";
                object.password = "";
            }
            if (message.username != null && message.hasOwnProperty("username"))
                object.username = message.username;
            if (message.password != null && message.hasOwnProperty("password"))
                object.password = message.password;
            return object;
        };

        /**
         * Converts this Login to JSON.
         * @function toJSON
         * @memberof authentication.Login
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Login.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Login;
    })();

    authentication.VerifyToken = (function() {

        /**
         * Properties of a VerifyToken.
         * @memberof authentication
         * @interface IVerifyToken
         */

        /**
         * Constructs a new VerifyToken.
         * @memberof authentication
         * @classdesc Represents a VerifyToken.
         * @implements IVerifyToken
         * @constructor
         * @param {authentication.IVerifyToken=} [properties] Properties to set
         */
        function VerifyToken(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new VerifyToken instance using the specified properties.
         * @function create
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.IVerifyToken=} [properties] Properties to set
         * @returns {authentication.VerifyToken} VerifyToken instance
         */
        VerifyToken.create = function create(properties) {
            return new VerifyToken(properties);
        };

        /**
         * Encodes the specified VerifyToken message. Does not implicitly {@link authentication.VerifyToken.verify|verify} messages.
         * @function encode
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.IVerifyToken} message VerifyToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VerifyToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified VerifyToken message, length delimited. Does not implicitly {@link authentication.VerifyToken.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.IVerifyToken} message VerifyToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VerifyToken.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VerifyToken message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.VerifyToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.VerifyToken} VerifyToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VerifyToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.VerifyToken();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VerifyToken message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.VerifyToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.VerifyToken} VerifyToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VerifyToken.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VerifyToken message.
         * @function verify
         * @memberof authentication.VerifyToken
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VerifyToken.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a VerifyToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.VerifyToken
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.VerifyToken} VerifyToken
         */
        VerifyToken.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.VerifyToken)
                return object;
            return new $root.authentication.VerifyToken();
        };

        /**
         * Creates a plain object from a VerifyToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.VerifyToken} message VerifyToken
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VerifyToken.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this VerifyToken to JSON.
         * @function toJSON
         * @memberof authentication.VerifyToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VerifyToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VerifyToken;
    })();

    authentication.TokenInvalid = (function() {

        /**
         * Properties of a TokenInvalid.
         * @memberof authentication
         * @interface ITokenInvalid
         */

        /**
         * Constructs a new TokenInvalid.
         * @memberof authentication
         * @classdesc Represents a TokenInvalid.
         * @implements ITokenInvalid
         * @constructor
         * @param {authentication.ITokenInvalid=} [properties] Properties to set
         */
        function TokenInvalid(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new TokenInvalid instance using the specified properties.
         * @function create
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.ITokenInvalid=} [properties] Properties to set
         * @returns {authentication.TokenInvalid} TokenInvalid instance
         */
        TokenInvalid.create = function create(properties) {
            return new TokenInvalid(properties);
        };

        /**
         * Encodes the specified TokenInvalid message. Does not implicitly {@link authentication.TokenInvalid.verify|verify} messages.
         * @function encode
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.ITokenInvalid} message TokenInvalid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TokenInvalid.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified TokenInvalid message, length delimited. Does not implicitly {@link authentication.TokenInvalid.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.ITokenInvalid} message TokenInvalid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TokenInvalid.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TokenInvalid message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.TokenInvalid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.TokenInvalid} TokenInvalid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TokenInvalid.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.TokenInvalid();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TokenInvalid message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.TokenInvalid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.TokenInvalid} TokenInvalid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TokenInvalid.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TokenInvalid message.
         * @function verify
         * @memberof authentication.TokenInvalid
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TokenInvalid.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a TokenInvalid message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.TokenInvalid
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.TokenInvalid} TokenInvalid
         */
        TokenInvalid.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.TokenInvalid)
                return object;
            return new $root.authentication.TokenInvalid();
        };

        /**
         * Creates a plain object from a TokenInvalid message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.TokenInvalid} message TokenInvalid
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TokenInvalid.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this TokenInvalid to JSON.
         * @function toJSON
         * @memberof authentication.TokenInvalid
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TokenInvalid.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TokenInvalid;
    })();

    return authentication;
})();

export { $root as default };
