import {makeAutoObservable, ObservableMap} from "mobx";
import {SocketApi} from "proto_socket_typescript";
import {proto} from "../proto/messages";
import {inputs} from "../proto/compiled";
import {createContext} from "react";
import {Subscription} from "rxjs";

export class InputsStore {
    api: SocketApi;
    selectOptions: ObservableMap<string, ObservableMap<string, inputs.IInputValue>> = new ObservableMap();
    completeOptionSets: Set<string> = new Set<string>();
    loadOptions: (key: string, search: string) => any;
    private disposeCallbacks: (() => any)[] = [];

    constructor(api: SocketApi, loadOptions?: (key: string, search: string) => any) {
        this.api = api;
        this.loadOptions = loadOptions ?? ((k, s) => this.api.sendMessage(proto.TxLoadSelectOptions.create({
            key: k,
            search: s,
        })));
        if (!loadOptions) {
            this.addSub(api.getMessageHandler(new proto.RxSelectOptions()).subscribe((m) => this.onSelectOptions(m.proto)));
        }
        makeAutoObservable(this);
    }

    clear() {
        this.selectOptions.clear();
        this.completeOptionSets.clear();
    }

    onSelectOptions(m: inputs.ISelectOptions) {
        if (!this.selectOptions.has(m.key!)) {
            this.selectOptions.set(m.key!, new ObservableMap());
        }
        const existing = this.selectOptions.get(m.key!)!;
        if (m.isAll) {
            existing.clear();
        }
        for (const option of m.options!) {
            existing.set(option.text!, option);
        }
        if (m.isAll) {
            this.completeOptionSets.add(m.key!);
        }

    }

    dispose() {
        this.disposeCallbacks.map((dc) => dc());
    }

    private addSub(subscription: Subscription) {
        this.disposeCallbacks.push(() => subscription.unsubscribe());
    }

    invalidate(key: string) {
        this.selectOptions.get(key)?.clear();
    }
}

export const InputsStoreContext = createContext<InputsStore | undefined>(undefined);
