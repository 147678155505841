import {observer} from "mobx-react-lite";
import styled, {useTheme} from "styled-components";
import {SP, Theme} from "../theme";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {api, ApiContext, GridscopeStoreContext} from "../app";
import {useContext, useEffect, useState} from "react";
import {OverlayContainer} from "./overlays";
import {DialogContainer} from "./dialogs";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {GridscopeRoutes} from "../strings";
import {proto} from "../proto/messages";
import {ProfileIndicator} from "./app_bar";

const Container = styled.div`
  display: flex;
  height: 100vh;
  max-width: 100vw;
  flex-direction: column;
  overflow: hidden;
  background-color:   ${({theme}: SP) => theme.shade3};

  * {
    flex-shrink: 0;
  }
`;

export const GridscopeScaffold = observer(({
                                           children,
                                           waitConnected,
                                           publicRoute,
                                       }: { children: any, waitConnected?: boolean, publicRoute?: boolean }) => {
    const api = useContext(ApiContext);
    const [connected, setConnected] = useState(api.connection.connected);
    const [auth, setAuth] = useState(api.authenticated);
    const location = useLocation();
    const store = useContext(GridscopeStoreContext);

    const navigate = useNavigate();
    useEffect(() => {
        const sub = api.getMessageHandler(new proto.RxSetProject())
            .subscribe((m) => navigate(GridscopeRoutes.project.processing(m.proto.key)));
        return () => sub.unsubscribe();
    });
    
    useEffect(() => {
        if (waitConnected !== false)
            api.connection.whenConnected.then(() => setConnected(true));
    }, [api, waitConnected]);

    useEffect(() => {
        const sub = api.authenticatedChanges.subscribe((a) => {
            setAuth(a);
        });
        return () => sub.unsubscribe();
    });

    if (!publicRoute && !auth) {
        return <Navigate to={GridscopeRoutes.login(location.pathname)}/>;
    }
    return <Container>
        <div style={{
            position: 'fixed',
            top: '8px',
            right: '8px',
            zIndex: '300'
        }}>
            <ProfileIndicator/>
        </div>
        {(waitConnected === false || connected) && store && children}
        <ConnectedOverlay/>
        {store?.dialogs.map((e) => e)}
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </Container>
});

export const ConnectedOverlay = () => {
    const api = useContext(ApiContext);
    const theme = useTheme() as Theme;

    // handle connected overlay
    const [connected, setConnected] = useState(true);
    useEffect(() => {
        const sub = api.connection.connectedChanges.subscribe((connected) => setConnected(connected));
        const timeout = new Promise((res) => setTimeout(() => res(null), 1000));
        Promise.race([api.connection.whenConnected, timeout]).then(
            (_) => setConnected(api.connection.connected)
        );
        return () => sub.unsubscribe();
    }, [api.connection]);

    return <OverlayContainer hidden={connected}>
        <DialogContainer style={{padding: '50px'}}>
            <theme.textTheme.headline3.span>
                Povezava s strežnikom ni uspela!
            </theme.textTheme.headline3.span>
            <theme.textTheme.body.span>
                <ol>
                    <li>Preverite ali imate delujočo internetno povezavo.</li>
                    <li>Poskusite malo kasneje. Morda poteka nadgradnja ali vzdrževanje sistema.</li>
                </ol>
            </theme.textTheme.body.span>
        </DialogContainer>
    </OverlayContainer>;
};

export const GridscopeBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 0;
  flex-grow: 1;
  width: 0;
  ${({theme}: SP) => theme.textTheme.body.css};
  overflow: auto;
`;

export const GridscopeSideBar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  background-color: ${({theme}: SP) => theme.shade3};
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.08);
  width: 330px;
`;