import {observer} from "mobx-react-lite";
import {useContext, useMemo} from "react";
import {GridscopeStoreContext} from "../../../app";
import styled from "styled-components";
import {LoadingOverlay, LoadingOverlayAction} from "../../../components/overlays";
import {inputs} from "../../../proto/compiled";
import {SP} from "../../../theme";
import {FlexCenter, NoScrollbar} from "../../../styles/gridscope";
import useWave from "use-wave";
import {CommonInput} from "../../../components/common_inputs/common_input";
import {GridscopeButton} from "../../../components/buttons";
import {useNavigate} from "react-router-dom";
import {GridscopeRoutes} from "../../../strings";

export const ProcessingModules = observer(() => {
    const store = useContext(GridscopeStoreContext)!;
    const action = useMemo(() => new LoadingOverlayAction(), []);
    const wave = useWave();
    const navigate = useNavigate();

    return <Container>
        <ModulesContainer>
            {store.project.processing.modules?.map((m, i) => <ModuleButton
                key={i}
                selected={store.project.processing.module?.key === m.key}
                ref={wave}
                onClick={() => action.trigger(store.project.processing.loadModule(m))}>
                {m.name}
            </ModuleButton>)}
        </ModulesContainer>
        <Container style={{overflow: 'scroll', paddingBottom: '32px'}}>
            <LoadingOverlay style={{flexGrow: '1'}} overlayStyle={{borderRadius: '10px'}} action={action}>
                <Headline>Module configuration</Headline>
                <ConfigurationContainer>
                    {store.project.processing.module?.configuration?.map((i: inputs.IInput, k) => <InputContainer
                        key={i.key! + k}>
                        <CommonInput input={i} alwaysReload={true}/>
                    </InputContainer>)}
                </ConfigurationContainer>
                <Headline>Output</Headline>
                <ConfigurationContainer>
                    <InputContainer><CommonInput input={store.project.processing.output}/></InputContainer>
                    <GridscopeButton
                        style={{margin: 'auto 0 8px 8px', width: '150px'}}
                        onClick={() => action.trigger(store.project.processing.execute().then((s) => {
                            if (s) navigate(GridscopeRoutes.project.processing(store.project.key!, 2));
                        }))}>
                        EXECUTE
                    </GridscopeButton>
                </ConfigurationContainer>
            </LoadingOverlay>
        </Container>
    </Container>;
});

const Container = styled.div`
  ${NoScrollbar};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0;
  padding: 0 32px 0 32px;
`;

const ConfigurationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ModulesContainer = styled.div`
  ${FlexCenter};
  justify-content: start;
  background-color: white;
  height: ${({theme}: SP) => theme.dimens.clickable};
  margin: 0 -32px 16px -32px;
  padding: 0 64px 0 64px;
`;

const ModuleButton = styled.div<{ selected: boolean }>`
  ${FlexCenter};
  border: solid 1px ${({theme, selected}: SP) => selected ? 'transparent' : theme.shade1};
  border-radius: ${({theme}: SP) => theme.dimens.radius3};
  background-color: ${({theme, selected}: SP) => selected ? theme.primary : 'transparent'};
  height: ${({theme}: SP) => theme.dimens.minClickable};
  cursor: pointer;
  ${({theme}: SP) => theme.textTheme.button.css};
  color: ${({theme, selected}: SP) => selected ? theme.neutral : theme.content};
  font-weight: 600;
  padding: 0 16px 0 16px;
  transition: all 300ms;
  margin: 0 4px 0 4px;
`;

const InputContainer = styled.div`
  width: 300px;
  margin: 0 8px 8px 8px;
`;

const Headline = styled.div`
  ${({theme}: SP) => theme.textTheme.headline4.css};
  color: ${({theme}: SP) => theme.primary};
  margin: 16px 0 4px 16px;
`;