import {makeAutoObservable, ObservableMap} from "mobx";
import * as tus from 'tus-js-client'
import {uploader} from "../proto/compiled";
import {SocketApi} from "proto_socket_typescript";
import {proto} from "../proto/messages";
import {toast} from "react-toastify";

export class UploadStore {
    api: SocketApi;
    private disposeCallbacks: (() => any)[] = [];
    private disposed = false;
    preset = 0;
    uploads: { [k: string]: tus.Upload } = {};
    uploadTasks: { [k: string]: uploader.IUploadTask } = {};
    files = new ObservableMap<string|undefined, File>();
    uFiles = new ObservableMap<string|undefined, uploader.IUFile>();

    started = new ObservableMap<string|undefined, boolean>();
    progress = new ObservableMap<string|undefined, number>();
    done = new ObservableMap<string|undefined, boolean>();
    error = new ObservableMap<string|undefined, string>();
    extensions: Set<string>;

    constructor(api: SocketApi, extensions?: Set<string>) {
        this.extensions = extensions ?? new Set(['wav', 'mp3', 'trm', 'zip', 'wma', 'm4a', 'flac']);
        this.api = api;
        makeAutoObservable(this);
        this.onSub(this.api.getMessageHandler(new proto.RxUploadSlot()).subscribe((m) => this.onUploadSlot(m)));
    }

    private onSub(subscription: any) {
        this.disposeCallbacks.push(() => subscription.unsubscribe());
    }

    dispose() {
        this.disposed = true;
        for (const dc of this.disposeCallbacks) {
            dc();
        }
        for (const fingerprint of Object.keys(this.uploads)) {
            this.cancel(fingerprint);
        }
    }

    uploadFile(acceptedFiles: File[]) {
        if (!acceptedFiles.length) return;
        if (acceptedFiles.length > 1) {
            toast.error('Pick one file');
            return;
        }
        const file = acceptedFiles[0];
        const fnParts = file.name.toLowerCase().split('.');
        if (!this.extensions.has(fnParts[fnParts.length - 1])) {
            toast.error('Unsupported format!');
            return;
        }
        const fingerprint = Math.round(Math.random() * 10000000000).toString();
        const task = uploader.UploadTask.create({
            status: uploader.UploadStatus.scheduled,
            created: Date.now(),
            name: file.name,
            path: file.webkitRelativePath,
            fingerprint: fingerprint,
            mime: file.type,
        });
        this.uploadTasks[fingerprint] = task;
        this.files.set(fingerprint, file);
        this.api.sendMessage(proto.TxUploadUFile.create({task: task}));
        return fingerprint;
    }

    private onUploadSlot(m: proto.RxUploadSlot) {
        if (!m.proto.file) return;
        const fingerprint = m.proto.file.uploadTask!;
        const file = this.files.get(fingerprint);
        if (!m.proto.file || !file) return;
        this.uFiles.set(fingerprint, m.proto.file);
        this.started.set(fingerprint, true);
        const cs = file.size / 20;
        const min = 200 * 1000;
        const max = 100 * 1000 * 1000;
        const endpoint = `${window.location.protocol}//${window.location.host}/upload/`.replaceAll(':3000', ':9000');
        this.uploads[fingerprint] = new tus.Upload(file, {
            endpoint: endpoint,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: m.proto.metadata,
            chunkSize: cs < min ? min : cs > max ? max : cs,
            onError: (error) => {
                this.error.set(fingerprint, error.toString());
                this.done.set(fingerprint, true);
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                this.progress.set(fingerprint, bytesUploaded / bytesTotal);
            },
            onSuccess: () => {
                this.done.set(fingerprint, true);
            }
        });
        this.uploads[fingerprint].start();
    }

    cancel(fingerprint: string) {
        if (this.started.get(fingerprint) && !this.done.get(fingerprint)) {
            this.uploads[fingerprint]?.abort(true);
        }
        delete this.uploads[fingerprint];
        delete this.uploadTasks[fingerprint];
        this.files.delete(fingerprint);
        this.uFiles.delete(fingerprint);
        this.progress.delete(fingerprint);
        this.error.delete(fingerprint);
        this.started.delete(fingerprint);
        this.done.delete(fingerprint);
    }
}