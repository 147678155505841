import {observer} from "mobx-react-lite";
import styled from "styled-components";
import useWave from "use-wave";
import {SP} from "../theme";
import {CSSProperties} from "react";

interface TabBarProps {
    children: any[];
    selected: number;
    onSelected: (i: number) => any;
    style?: CSSProperties;
    numbers?: boolean;
    width?: string;
}

export const TabBar = observer((props: TabBarProps) => {
    const wave = useWave();
    return <Container style={props.style}>
        {props.children.map((c, i) =>
            <TabContainer style={{width: props.width ?? '200px'}} key={i} ref={wave} selected={i === props.selected}
                          onClick={() => props.onSelected(i)}>
                {props.numbers && <TabNumber selected={i === props.selected}>{i + 1}</TabNumber>}
                {c}
            </TabContainer>)}
    </Container>
});

const Container = styled.div`
  display: flex;
`;

const TabContainer = styled.div<{ selected: boolean }>`
  cursor: pointer;
  position: relative;
  height: ${({theme}: SP) => theme.dimens.minClickable};
  border-radius: calc(${({theme}: SP) => theme.dimens.minClickable} / 2);
  background-color: ${({theme, selected}: SP) => selected ? theme.primary : theme.shade2};
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}: SP) => theme.textTheme.button.css}
  color: ${({theme, selected}: SP) => selected ? theme.neutral : theme.content};
  transition: all 300ms;
  margin: 0 8px 0 8px;
`;

const TabNumber = styled.div<{ selected: boolean }>`
  position: absolute;
  left: 1px;
  top: 1px;
  bottom: 1px;
  height: calc(${({theme}: SP) => theme.dimens.minClickable} - 2px);
  width: calc(${({theme}: SP) => theme.dimens.minClickable} - 2px);
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}: SP) => theme.textTheme.indicator.css}
  color: ${({theme, selected}: SP) => selected ? theme.neutral : theme.content};
  border-radius: 100%;
  border: solid 1px ${({theme, selected}: SP) => selected ? theme.neutral : theme.content};
  transition: all 300ms;
`;