import {makeAutoObservable, ObservableMap} from "mobx";
import {SocketApi, SocketApiAckStatus, SocketRxMessageData} from "proto_socket_typescript";
import {proto} from "../proto/messages";
import {AuthStore} from "./auth_store";
import {gridscope_data, gridscope_global, gridscope_project, gridscope_view, inputs} from "../proto/compiled";
import {GridscopeProjectStore} from "./gridscope_project_store";
import {SocketRxMessage} from "proto_socket_typescript/src/socket_messages";
import {toast} from "react-toastify";

export class GridscopeStore {
    api: SocketApi;
    dialogs: any[] = [];
    selectOptions: ObservableMap<string, ObservableMap<string, inputs.IInputValue>> = new ObservableMap();
    completeOptionSets: Set<string> = new Set<string>();

    // stores
    auth: AuthStore;
    project: GridscopeProjectStore;
    projects?: gridscope_project.IProject[];
    global: GlobalStore;
    uiState?: gridscope_global.IUiState;

    constructor(api: SocketApi, authStore: AuthStore) {
        this.api = api;
        this.global = new GlobalStore(api);
        this.auth = authStore;
        makeAutoObservable(this);

        api.getMessageHandler(new proto.RxSelectOptions()).subscribe((m) => {
            if (!this.selectOptions.has(m.proto.key)) {
                this.selectOptions.set(m.proto.key, new ObservableMap());
            }
            const existing = this.selectOptions.get(m.proto.key)!;
            for (const option of m.proto.options) {
                existing.set(option.text!, option);
            }
            if (m.proto.isAll) {
                this.completeOptionSets.add(m.proto.key);
            }
        });

        api.getMessageHandler(new proto.RxProjects()).subscribe((m) => this.onProjects(m));
        api.getMessageHandler(new proto.RxUiState()).subscribe((m) => this.onUiState(m));

        this.project = new GridscopeProjectStore(this);
    }

    async deleteModuleResult(id: string) {
        const response = await this.api.sendMessage(proto.TxDeleteModuleResult.create({
            id: id
        }), {ack: true});
        if (response.status !== SocketApiAckStatus.success) {
            toast.error(response.errorMessage ?? 'Unknown error');
            return;
        }
        this.project.view.load();
    }

    async deleteDataFiles(files: string[]) {
        const response = await this.api.sendMessage(proto.TxDeleteDataFiles.create({
            ids: files
        }), {ack: true});
        if (response.status !== SocketApiAckStatus.success) {
            toast.error(response.errorMessage ?? 'Unknown error');
            return;
        }
        this.project.load();
    }

    load() {
        this.api.sendMessage(proto.TxLoadProjects.create());
    }


    private onProjects(m: proto.RxProjects) {
        this.projects = m.proto.projects;
    }

    private onUiState(m: proto.RxUiState) {
        this.uiState = m.proto;
    }
}

class GlobalStore {
    api: SocketApi;
    dataFiles = new ObservableMap<string, gridscope_data.IDataFile>();
    measurements = new ObservableMap<string, gridscope_data.IMeasurement>();
    dataFilesGroups = new ObservableMap<string, gridscope_data.IDataFilesGroup>();
    results = new ObservableMap<string, gridscope_view.IProjectResult>();

    constructor(api: SocketApi) {
        this.api = api;
        makeAutoObservable(this);
        this.listen(new proto.RxDataFile(), this.dataFiles);
        this.listen(new proto.RxMeasurement(), this.measurements);
        this.listen(new proto.RxDataFilesGroup(), this.dataFilesGroups);
        this.api.authenticatedChanges.subscribe((a) => {
            if (!a) {
                this.dataFilesGroups.clear();
                this.measurements.clear();
                this.dataFiles.clear();
                this.results.clear();
            }
        });
    }

    listen(message: SocketRxMessage<any>, map: ObservableMap) {
        this.api.getMessageHandler(message).subscribe((m) => map.set(m.proto.id, m.proto));
    }
}