import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {TabBar} from "../../components/tab_bar";
import {useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {GridscopeRoutes} from "../../strings";
import {ProcessingData} from "./processing/processing_data";
import {ProcessingModules} from "./processing/processing_modules";
import {ProcessingView} from "./processing/processing_view";

export const Processing = observer(() => {
    const {id, step} = useParams();
    const navigate = useNavigate();
    const iStep = useMemo(() => {
        const i = parseInt(step ?? '0');
        if (isNaN(i)) return 0;
        return i;
    }, [step]);

    return <Container>
        <TabBar style={{margin: '0 auto 16px auto'}} selected={iStep} onSelected={(i) => {
            navigate(GridscopeRoutes.project.processing(id!, i), {replace: true});
        }}>
            <span>DATA</span>
            <span>PROCESSING</span>
            <span>VIEW</span>
        </TabBar>
        {iStep === 0 && <ProcessingData/>}
        {iStep === 1 && <ProcessingModules/>}
        {iStep === 2 && <ProcessingView/>}
    </Container>;
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  flex-grow: 1;
  height: 0;
  overflow: hidden;
`;