import styled from "styled-components";
import {SP} from "../theme";
import React from "react";

export const CheckboxLabel = styled.label`
  margin-left: 8px;
  ${({theme}: SP) => theme.textTheme.body.css};
  font-weight: bold;
  cursor: pointer;
`;

export const CheckboxContainer = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  cursor: pointer;
  user-select: none;
`

const CheckboxControl = styled.span`
  position: relative;
  height: 15px;
  width: 15px;
  border: solid 1px ${({theme}: SP) => theme.content};
  margin-top: -1px;

  :after {
    position: absolute;
    content: "";
    left: 4.5px;
    top: 0;
    width: 4px;
    height: 10px;
    border: solid ${({theme}: SP) => theme.content};
    border-width: 0 2px 2px 0;
    opacity: 0;
    rotation: 20deg;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(20deg);
    transition: all 150ms;
  }
`;

export const CheckboxHidden = styled.input.attrs(props => ({
    type: 'checkbox',
    name: props.name
}))`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &&:checked ~ ${CheckboxControl}:after {
    opacity: 1;
    transform: rotate(45deg);
  }
`;

interface CheckboxProps {
    name?: string;
    id?: string;
    label?: any;
    checked: boolean;
    tabIndex?: number;
    onChange: () => any;
}

export const Checkbox = (props: CheckboxProps) => {
    return <CheckboxContainer
        onClick={(e) => {
            props.onChange();
        }}>
        <CheckboxHidden
            id={props.name + '-' + props.id}
            name={props.name}
            checked={props.checked}
            value={props.id}
            onChange={(e) => props.onChange()}
            {...props.tabIndex !== undefined && {tabIndex: props.tabIndex}}
        />
        <CheckboxControl/>
        {props.label && <CheckboxLabel htmlFor={props.id}>{props.label}</CheckboxLabel>}
    </CheckboxContainer>;
}