import styled from "styled-components";
import {SP} from "../../theme";
import {GridscopeInputContainerCss} from "./text_input";
import {gridscope_types} from "../../proto/compiled";
import {useMemo, useRef} from "react";
import {isNaN} from "lodash";
import {observer} from "mobx-react-lite";

const VDivider = styled.div`
  height: 100%;
  width: 2px;
  margin: 0 4px 0 4px;
  background-color: ${({theme}: SP) => theme.shade1};
`;

const InputButton = styled.div`
  font-size: 20px;
  z-index: 500;
  font-weight: bold;
  padding-bottom: 2px;
  width: 20px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({theme}: SP) => theme.primary};
  cursor: pointer;
  background-color: ${({theme}: SP) => theme.shade2};
  border-radius: ${({theme}: SP) => theme.dimens.radius4};
  border: solid 1px transparent;

  :hover {
    border: solid 1px ${({theme}: SP) => theme.primary};
  }
`;

const InputField = styled.input.attrs({type: 'number'})`
  position: relative;
  border: none;
  background-color: transparent;
  border-bottom: solid 1px ${({theme}: SP) => theme.shade1};
  margin: 0 8px 0 8px;
  ${({theme}: SP) => theme.textTheme.input.css};
  line-height: 25px;
  padding-right: 4px;
  outline: none;
  flex: 1;
  width: 0;
  transition: all 300ms;
  text-align: center;

  :focus {
    border-bottom-color: ${({theme}: SP) => theme.primary};
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
`;

const Container = styled.div`
  ${GridscopeInputContainerCss};
  display: flex;
  align-items: center;
`;


interface GridscopeDateTimeInputProps {
    value: gridscope_types.IDate;
    onChange: (d: gridscope_types.IDate) => any;
}

export const GridscopeDateTimeInput = observer((props: GridscopeDateTimeInputProps) => {
    const value = useMemo(() => props.value, [props.value]);
    const refDay = useRef<any>();
    const refMonth = useRef<any>();
    const refYear = useRef<any>();
    const refHour = useRef<any>();
    const refMinute = useRef<any>();

    const onChange = () => {
        let prevTs = value.ts;
        value.ts = new Date(
            value.year!,
            value.month! - 1,
            value.day!,
            value.hour!,
            value.minute!,
        ).getTime();
        if (isNaN(value.ts)) {
            value.ts = prevTs;
        }
        props.onChange(value);
    };
    return <Container>
        <InputField
            ref={refDay}
            placeholder={'DD'}
            onFocus={(e) => {
                e.currentTarget.select();
            }}
            value={value.day ? value.day : ''}
            onChange={(e) => {
                let num = e.target.valueAsNumber;
                if (num > 31) {
                    num = 31;
                }
                if (num > 3) {
                    refMonth.current.focus();
                }
                value.day = num;
                onChange();
            }}/>
        <InputField
            ref={refMonth}
            placeholder={'MM'}
            onFocus={(e) => {
                e.currentTarget.select();
            }}
            value={value.month ? value.month : ''}
            onChange={(e) => {
                let num = e.target.valueAsNumber;
                if (num > 1) {
                    refYear.current.focus();
                }
                value.month = num;
                onChange();
            }}/>
        <InputField
            ref={refYear}
            style={{
                flex: '1.5'
            }}
            placeholder={'YYYY'}
            onFocus={(e) => {
                e.currentTarget.select();
            }}
            value={value.year ? value.year : ''}
            onChange={(e) => {
                value.year = e.target.valueAsNumber;
                onChange();
            }}/>
        <VDivider/>
        <InputField
            ref={refHour}
            placeholder={'hh'}
            onFocus={(e) => {
                e.currentTarget.select();
            }}
            value={value.hour === undefined ? '' : value.hour!}
            onChange={(e) => {
                if (e.target.valueAsNumber >= 24) return;
                let num = e.target.valueAsNumber;
                if (num > 2) {
                    refMinute.current.focus();
                }
                value.hour = num;
                onChange();
            }}/>
        <InputField
            ref={refMinute}
            placeholder={'mm'}
            onFocus={(e) => {
                e.currentTarget.select();
            }}
            value={value.minute === undefined ? '' : value.minute!}
            onChange={(e) => {
                if (e.target.valueAsNumber >= 60) return;
                value.minute = e.target.valueAsNumber;
                onChange();
            }}/>
    </Container>
});
