import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {inputs} from "../../proto/compiled";
import {GridscopeInputField} from "./input";
import {GridscopeTextInput} from "./text_input";
import {GridscopeDateInput} from "./date_input";
import {GridscopeSelectInput} from "./select_input";
import {GridscopeBoolInput} from "./bool_input";
import {GridscopeDateTimeInput} from "./date_time_input";


export interface CommonInputProps {
    icon?: any;
    label?: any;
    input: inputs.IInput
    onChange?: (input: inputs.IInput) => any;
    isFilter?: boolean;
    alwaysReload?: boolean;
}

export const CommonInput = observer((props: CommonInputProps) => {
    let input;
    props.input.value ??= inputs.InputValue.create();
    switch (props.input.type) {
        case inputs.InputType.date:
            input = <DateInput {...props}/>
            break;
        case inputs.InputType.dateTime:
            input = <DateTimeInput {...props}/>
            break;
        case inputs.InputType.text:
            input = <TextInput {...props}/>
            break;
        case inputs.InputType.check:
            input = <BoolInput {...props}/>
            break;
        case inputs.InputType.number:
            input = <NumberInput {...props}/>
            break;
        case inputs.InputType.free_select:
        case inputs.InputType.fixed_select:
            input = <SelectInput {...props}/>
            break;
        default:
            input =
                <div style={{
                    border: 'solid 1px gray',
                    borderRadius: '5px',
                    padding: '9px',
                    marginTop: 'auto',
                }}>{props.input.value?.text} - vnos tipa {props.input.type} še ni podprt</div>
            break;
    }

    return <Container>
        <GridscopeInputField
            label={props.input.type === inputs.InputType.check ? undefined : props.label ?? props.input.label}
            icon={props.icon}
            style={{flexGrow: '1'}}
            input={input}/>
    </Container>;
});

const SelectInput = observer((props: CommonInputProps) => {
    return <GridscopeSelectInput {...props}/>;
});

const TextInput = observer((props: CommonInputProps) => {
    return <GridscopeTextInput value={props.input.value!.text || ''}
                               readOnly={!!props.input.readonly}
                               tabIndex={props.input.readonly ? -1 : undefined}
                               onChange={props.input.readonly ? undefined : (e) => {
                                   props.input.value!.text = e.target.value;
                                   props.onChange && props.onChange(props.input);
                               }}/>;
});

const NumberInput = observer((props: CommonInputProps) => {
    return <GridscopeTextInput
        type={'number'}
        value={props.input.value!.number || ''}
        readOnly={!!props.input.readonly}
        tabIndex={props.input.readonly ? -1 : undefined}
        onChange={props.input.readonly ? undefined : (e) => {
            props.input.value!.number = e.target.valueAsNumber;
            props.onChange && props.onChange(props.input);
        }}/>;
});

const BoolInput = observer((props: CommonInputProps) => {
    return <GridscopeBoolInput
        value={!!props.input.value!.boolVal}
        onChange={props.input.readonly ? undefined : (e) => {
            props.input.value!.boolVal = e;
            props.onChange && props.onChange(props.input);
        }}
        label={props.label ?? props.input.label ?? ''}
    />;
});

const DateInput = observer((props: CommonInputProps) => {
    return <GridscopeDateInput value={props.input.value!.date!}
                               filter={props.isFilter}
                               onChange={(d) => {
                                   props.input.value!.date = d;
                                   props.onChange && props.onChange(props.input);
                               }}/>;
});

const DateTimeInput = observer((props: CommonInputProps) => {
    return <GridscopeDateTimeInput value={props.input.value!.date!}
                                   onChange={(d) => {
                                       props.input.value!.date = d;
                                       props.onChange && props.onChange(props.input);
                                   }}/>;
});


const Container = styled.div`
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: center;
`;
