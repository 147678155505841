import {css} from "styled-components";
import {SP} from "../theme";

export const FlexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ShadowLight = css`
  box-shadow: 0 0 0.2em ${({theme}: SP) => theme.shadow2};
`;

export const Shadow = css`
  box-shadow: 0 0.25em 0.5em ${({theme}: SP) => theme.shadow2};
`;

export const NoScrollbar = css`
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

export const ShadowStrongRight = css`
  box-shadow: 0.1em 0 0.25em ${({theme}: SP) => theme.shadow1};
`;
