import {useEffect, useMemo, useState} from "react";
import {debounce} from "lodash";

const DEFAULT_OPTIONS = {
    config: {attributes: true, childList: true, subtree: true},
    debounceTime: 0
};

export function useMutationObservable(ref: React.RefObject<HTMLElement>, cb: () => any, options = DEFAULT_OPTIONS) {
    const callback = useMemo(() => {
        if (options.debounceTime > 0) {
            return debounce(cb, options.debounceTime);
        } else {
            return cb;
        }
    }, [cb, options.debounceTime]);

    const [observer, setObserver] = useState<MutationObserver | null>(null);

    useEffect(() => {
        const obs = new MutationObserver(callback);
        setObserver(obs);
    }, [callback, options, setObserver]);

    useEffect(() => {
        if (!observer) return;
        if (!ref.current) {
            console.warn(`You must provide a valid DOM element to observe, instead you've provided ${ref}`);
        }
        const {config} = options;
        try {
            observer.observe(ref.current!, config);
        } catch (e) {
            console.error(e);
        }
        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [observer, ref, options]);

    useEffect(() => {
        if (!ref.current) return;
        ref.current.ontransitionend = () => {
            callback();
        };
    }, [ref, callback]);
}