import {autorun, makeAutoObservable, ObservableMap} from "mobx";
import {gridscope_data, gridscope_project, inputs, uploader} from "../proto/compiled";
import {GridscopeStore} from "./gridscope_store";
import {proto} from "../proto/messages";
import {toast} from "react-toastify";
import {SocketApiAckStatus} from "proto_socket_typescript";
import {GridscopeProjectProcessingStore} from "./gridscope_project_processing_store";
import {GridscopeProjectViewStore} from "./gridscope_project_view_store";

export class GridscopeProjectStore {
    key?: string;

    base: GridscopeStore;
    private disposeCallbacks: (() => any)[] = [];
    dialogs: any[] = [];
    selectOptions: ObservableMap<string, ObservableMap<string, inputs.IInputValue>> = new ObservableMap();
    completeOptionSets: Set<string> = new Set<string>();
    measurement?: gridscope_data.IMeasurement;
    data?: gridscope_data.IData;
    processing: GridscopeProjectProcessingStore;
    view: GridscopeProjectViewStore;

    constructor(base: GridscopeStore) {
        this.base = base;
        makeAutoObservable(this);
        this.base.api.getMessageHandler(new proto.RxData()).subscribe((m) => this.onData(m));
        this.base.api.getMessageHandler(new proto.RxMeasurement()).subscribe((m) => this.onMeasurement(m));
        this.base.api.getMessageHandler(new proto.RxSelectOptions()).subscribe((m) => this.onSelectOptions(m));
        this.processing = new GridscopeProjectProcessingStore(this.base);
        this.view = new GridscopeProjectViewStore(this.base);
        this.disposeCallbacks.push(autorun(() => {
            const data = this.data;
            this.processing.modules = undefined;
            this.processing.module = undefined;
            if (!this.base.project?.key) return;
            this.base.api.sendMessage(proto.TxLoadProcessingModules.create({
                projectKey: this.base.project.key,
            }));
        }));
    }

    setProjectKey(key?: string) {
        if (!key || this.key === key) return;
        this.key = key;
        this.measurement = undefined;
        this.data = undefined;
        this.load();
    }

    load() {
        this.base.api.sendMessage(proto.TxLoadProject.create({
            projectKey: this.key
        }));
    }

    async measurementFileUploaded(f: uploader.IUFile) {
        const response = await this.base.api.sendMessage(proto.TxCreateMeasurement.create({
            ufileId: f.id,
            projectKey: this.key
        }), {ack: true, timeoutMs: 20 * 60 * 1000});
        if (response.status !== SocketApiAckStatus.success) {
            toast.error(response.errorMessage ?? 'Neznana napaka');
            return;
        }
    }

    // rx handlers
    private onMeasurement(m: proto.RxMeasurement) {
        if (!m.proto.processing) {
            this.measurement = undefined;
        } else {
            this.measurement = m.proto;
        }
        makeAutoObservable(m.proto);
    }

    private onSelectOptions(m: proto.RxSelectOptions) {
        if (!this.selectOptions.has(m.proto.key)) {
            this.selectOptions.set(m.proto.key, new ObservableMap());
        }
        const existing = this.selectOptions.get(m.proto.key)!;
        if (m.proto.isAll) {
            existing.clear();
        }
        for (const option of m.proto.options) {
            existing.set(option.text!, option);
        }
        if (m.proto.isAll) {
            this.completeOptionSets.add(m.proto.key);
        }
    }

    dispose() {
        this.disposeCallbacks.map((dc) => dc());
    }

    private onData(m: proto.RxData) {
        m.proto.measurements.forEach((i: any) => {
            this.base.global.measurements.set(i.id, i);
            if (i.processing) {
                this.measurement = i;
            }
        });
        m.proto.files.forEach((i: any) => this.base.global.dataFiles.set(i.id, i));
        m.proto.groups.forEach((i: any) => this.base.global.dataFilesGroups.set(i.id, i));
        this.data = m.proto;
    }
}
