import {observer} from "mobx-react-lite";
import styled, {useTheme} from "styled-components";
import {Card} from "../components/card";
import {useContext, useEffect} from "react";
import {GridscopeStoreContext} from "../app";
import {GridscopeLoadingOverlay} from "../components/overlays";
import {SP, Theme} from "../theme";
import Logo from "../assets/logo_icon_primary.png";
import {useNavigate} from "react-router-dom";
import {GridscopeRoutes} from "../strings";
import {GridscopeFab} from "../components/buttons";
import {MdAdd} from "react-icons/md";
import {proto} from "../proto/messages";

export const Dashboard = observer(() => {
    const store = useContext(GridscopeStoreContext)!;
    const theme = useTheme() as Theme;
    const navigate = useNavigate();
    useEffect(() => {
        store.load();
    }, []);

    return <Container>
        <theme.textTheme.headline3.span style={{marginBottom: '16px', color: theme.primary}}>
            Workspaces
        </theme.textTheme.headline3.span>
        <ProjectsContainer>
            {store.projects && store.projects.map((p, i) => <ProjectCard
                key={i}
                onClick={() => navigate(GridscopeRoutes.project.processing(p.projectKey!))}>
                <img src={Logo} width={'30px'} style={{marginRight: '8px'}}/>
                {p.name}
            </ProjectCard>)}
        </ProjectsContainer>
        <GridscopeFab onClick={() => store.api.sendMessage(proto.TxCreateProject.create())}><MdAdd/></GridscopeFab>
        {!store.projects && <GridscopeLoadingOverlay visible={true}/>}
    </Container>
});

const ProjectsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px;
  height: 0;
  flex-grow: 1;
  overflow: auto;
`;

const ProjectCard = styled(Card)`
  align-items: center;
  padding: 0 16px 0 16px;
  margin: 0 16px 16px;
  box-sizing: border-box;
  width: 190px;
  height: 50px;
  ${({theme}: SP) => theme.textTheme.headline4.css};
`;