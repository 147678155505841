import React, {createContext, useContext, useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import {GridscopeScaffold} from "./components/gridscope_scaffold";
import MultiProvider from "./utils";
import {ThemeProvider} from "styled-components";
import {GridscopeTheme} from "./theme";
import {SocketApi} from "proto_socket_typescript";
import {GridscopeStore} from "./stores/gridscope_store";
import {observer} from "mobx-react-lite";
import {GridscopeRoutes} from "./strings";
import {AuthStore} from "./stores/auth_store";
import {Login} from "./pages/login";
import {ProjectPage} from "./pages/project";
import {InputsStore, InputsStoreContext} from "./stores/inputs_store";
import {Dashboard} from "./pages/dashboard";
import {proto} from "./proto/messages";
import {first} from "rxjs";

const port = window.location.port === '3000' ? '8000' : window.location.port;
export const api = new SocketApi(`${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.hostname}:${port}/ws/`);
export const ApiContext = createContext<SocketApi>(api);

export const authStore = new AuthStore(api);
export const AuthStoreContext = createContext<AuthStore>(authStore);

export const GridscopeStoreContext = createContext<GridscopeStore | undefined>(undefined);
const globalInputsStore = new InputsStore(api);
let gridscopeStore: GridscopeStore | undefined;
const storeFuture = api.whenAuthenticated.then(() => api.connection.whenConnected.then(() => gridscopeStore = new GridscopeStore(api, authStore)));

function App() {
    const [_, setRerender] = useState(0);

    useEffect(() => {
        storeFuture.then(() => setRerender(Math.random()));
    }, []);

    return (
        <MultiProvider providers={[
            <ThemeProvider theme={GridscopeTheme}/>,
            <ApiContext.Provider value={api}/>,
            <GridscopeStoreContext.Provider value={gridscopeStore}/>,
            <InputsStoreContext.Provider value={globalInputsStore}/>,
        ]}>
            <BrowserRouter>
                <Routes>
                    <Route
                        path={GridscopeRoutes.project.dashboardR}
                        element={
                            <GridscopeScaffold waitConnected={true}>
                                <ProjectPage location={GridscopeRoutes.project.dashboardR}/>
                            </GridscopeScaffold>
                        }
                    />
                    <Route
                        path={GridscopeRoutes.index}
                        element={
                            <GridscopeScaffold waitConnected={true}>
                                <Dashboard/>
                            </GridscopeScaffold>
                        }
                    />
                    <Route
                        path={GridscopeRoutes.project.processingR}
                        element={
                            <GridscopeScaffold waitConnected={true}>
                                <ProjectPage location={GridscopeRoutes.project.processingR}/>
                            </GridscopeScaffold>
                        }
                    />
                    <Route
                        path={GridscopeRoutes.project.settingsR}
                        element={
                            <GridscopeScaffold waitConnected={true}>
                                <ProjectPage location={GridscopeRoutes.project.settingsR}/>
                            </GridscopeScaffold>
                        }
                    />
                    <Route
                        path={GridscopeRoutes.login()}
                        element={<Login/>}
                    />
                </Routes>
            </BrowserRouter>
        </MultiProvider>
    );
}

export default observer(App);
