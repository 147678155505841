import {observer} from "mobx-react-lite";
import {gridscope_data} from "../proto/compiled";
import styled, {useTheme} from "styled-components";
import {Card} from "./card";
import {SP, Theme} from "../theme";
import {DateFormatters} from "../utils";
import {CSSProperties, useContext, useEffect, useMemo} from "react";
import {
    MdCheck,
    MdDelete,
    MdDragIndicator, MdEdit,
} from "react-icons/md";
import useWave from "use-wave";
import {GridscopeStoreContext} from "../app";
import {GridscopeStore} from "../stores/gridscope_store";
import {YesNoDialog} from "./dialogs";
import {autorun, makeAutoObservable} from "mobx";
import {ClassicTextInput} from "./common_inputs/input";
import {IconButtonContainer} from "./buttons";
import {proto} from "../proto/messages";

export const DataFileSummary = observer(({file, style}: { file: gridscope_data.IDataFile, style?: CSSProperties }) => {
    const theme = useTheme() as Theme;
    const wave = useWave();
    const store = useContext(GridscopeStoreContext) as GridscopeStore;
    const state = useMemo(() => {
        const s = {
            editing: false,
            label: file.name ?? '',
        };
        makeAutoObservable(s);
        return s;
    }, []);
    useEffect(() => autorun(() => file.name = state.label), []);
    const save = () => {
        state.editing = false;
        store.api.sendMessage(proto.TxSaveDataFile.create({
            id: file.id,
            name: state.label
        }));
    };
    return <DataFileContainer style={style}>
        <DataFileRow style={{backgroundColor: theme.primary, width: '230px', flexGrow: '0'}}>
            <DataFileRowInnerPadding>
                <DataFileLabel style={{color: theme.neutral}}><MdDragIndicator style={{
                    fontSize: '16px',
                    marginRight: '12px',
                    marginLeft: '4px',
                }}/><ClassicTextInput
                    disabled={!state.editing}
                    onSubmit={() => save()}
                    value={state.label} onChanged={(v) => state.label = v}
                    style={{...theme.textTheme.headline4.style(), color: 'white'}}
                />
                    <IconButtonContainer ref={wave} onClick={(e) => {
                        if (state.editing) {
                            save();
                        } else {
                            state.editing = true;
                        }
                        e.stopPropagation();
                        e.preventDefault();
                    }}>
                        {state.editing && <MdCheck/>}
                        {!state.editing && <MdEdit/>}
                    </IconButtonContainer></DataFileLabel>
            </DataFileRowInnerPadding>
        </DataFileRow>
        <DataFileRow>
            <DataFileRowInnerPadding>
                <DataFileLabel>data type</DataFileLabel>
                <DataFileValue>{file.label}</DataFileValue>
            </DataFileRowInnerPadding>
        </DataFileRow>
        <DataFileRow>
            <DataFileRowInnerPadding>
                <DataFileLabel>row count</DataFileLabel>
                <DataFileValue>{file.count}</DataFileValue>
            </DataFileRowInnerPadding>
        </DataFileRow>
        <DataFileRow>
            <DataFileRowInnerPadding>
                <DataFileLabel>series present</DataFileLabel>
                <DataFileValue>{file.header?.join(', ')}</DataFileValue>
            </DataFileRowInnerPadding>
        </DataFileRow>
        <DataFileRow>
            <DataFileRowInnerPadding>
                <DataFileLabel>start date</DataFileLabel>
                <DataFileValue>{DateFormatters.dateTime(new Date(file.firstDate!.ts as number))}</DataFileValue>
            </DataFileRowInnerPadding>
        </DataFileRow>
        <DataFileRow>
            <DataFileRowInnerPadding>
                <DataFileLabel>end date</DataFileLabel>
                <DataFileValue>{DateFormatters.dateTime(new Date(file.lastDate!.ts as number))}</DataFileValue>
            </DataFileRowInnerPadding>
        </DataFileRow>
        <DataFileRow>
            <DataFileRowInnerPadding>
                <DataFileLabel>time step</DataFileLabel>
                <DataFileValue>{Math.round((file.interval!.milliseconds as number) / 100) / 10}s</DataFileValue>
            </DataFileRowInnerPadding>
        </DataFileRow>
        <div ref={wave} onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            store.dialogs.push(<YesNoDialog result={(v) => {
                if (v) {
                    store.deleteDataFiles([file.id!]);
                }
                store.dialogs.pop();
            }}>
                Are you sure?
            </YesNoDialog>)
        }} style={{
            display: 'flex',
            alignItems: 'center',
            borderLeft: 'solid 1px ' + theme.content10,
            padding: '0 16px 0 16px',
            cursor: 'pointer',
        }}>
            <MdDelete/>
        </div>
    </DataFileContainer>
});

const DataFileContainer = styled(Card).attrs({clickable: false})`
  height: auto;
  flex-direction: row;
  box-shadow: none;
  border: solid 1px ${({theme}: SP) => theme.shade2};
  margin-bottom: 4px;
`;

const DataFileRow = styled.div`
  display: flex;
  flex-grow: 1;
  width: 0;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
`;

export const DataFileRowInnerPadding = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex-grow: 1;
  padding: 2px 4px 2px 16px;
`;

const DataFileLabel = styled.span`
  ${({theme}: SP) => theme.textTheme.headline4.css};
  display: flex;
  align-items: center;
`;

const DataFileValue = styled.span`
`;
