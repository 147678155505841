export const GridscopeRoutes = {
    project: {
        dashboardR: '/project/:id/',
        dashboard: (id: string) => GridscopeRoutes.project.dashboardR.replaceAll(':id', id),
        processingR: '/project/:id/processing/:step/',
        processing: (id: string, step?: number) => GridscopeRoutes.project.processingR.replaceAll(':id', id).replaceAll(':step', (step ?? 0).toString()),
        settingsR: '/project/:id/settings/',
        settings: (id: string) => GridscopeRoutes.project.settingsR.replaceAll(':id', id),

    },
    index: '/',

    login: (from?: string) => '/login/' + (from ? `?from=${from}` : ''),
};