import {observer} from "mobx-react-lite";
import styled, {useTheme} from "styled-components";
import {SP, Theme} from "../theme";
import Logo from "../assets/logo_white.png";
import {ShadowStrongRight} from "../styles/gridscope";
import useWave from "use-wave";
import {MdDashboard, MdMemory, MdSettings} from "react-icons/md";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {GridscopeRoutes} from "../strings";

export const GridscopeProjectSidebar = ({location}: { location: string }) => {
    const navigate = useNavigate();
    const {id} = useParams();
    if (!id) return <Navigate to={GridscopeRoutes.index}/>

    return <Sidebar>
        <SidebarItem selected={location === GridscopeRoutes.project.dashboardR}
                     onClick={() => navigate(GridscopeRoutes.project.dashboard(id))}>
            <SidebarItemIcon><MdDashboard/></SidebarItemIcon>
            <SidebarItemLabel>Dashboard</SidebarItemLabel>
        </SidebarItem>
        <SidebarItem selected={location === GridscopeRoutes.project.processingR}
                     onClick={() => navigate(GridscopeRoutes.project.processing(id))}>
            <SidebarItemIcon><MdMemory/></SidebarItemIcon>
            <SidebarItemLabel>Analysis</SidebarItemLabel>
        </SidebarItem>
        <SidebarItem selected={location === GridscopeRoutes.project.settingsR}
                     onClick={() => navigate(GridscopeRoutes.project.settings(id))}>
            <SidebarItemIcon><MdSettings/></SidebarItemIcon>
            <SidebarItemLabel>Settings</SidebarItemLabel>
        </SidebarItem>
    </Sidebar>
};

export const Sidebar = observer(({children}: { children?: any }) => {
    const navigate = useNavigate();
    const theme = useTheme() as Theme;
    return <Container>
        <ContainerInner>
            {children}
            <div style={{
                backgroundColor: theme.content,
                height: '1px',
                width: '100%',
                marginTop: 'auto'
            }}/>
            <img style={{cursor: 'pointer'}} className={'logo'} src={Logo} alt={'logo'}
                 onClick={() => navigate(GridscopeRoutes.index)}/>
        </ContainerInner>
    </Container>
});

export const SidebarItem = observer(({
                                         children,
                                         selected,
                                         onClick
                                     }: { children: any, selected?: boolean, onClick?: () => any }) => {
    const wave = useWave();
    return <SidebarItemContainer ref={wave} onClick={onClick} selected={!!selected}>
        {children}
    </SidebarItemContainer>;
});


export const SidebarItemLabel = styled.div`
  display: flex;
  height: var(--sidebar-width-collapsed);
  width: 0;
  flex-grow: 1;
  align-items: center;
`;

export const SidebarItemIcon = styled.div.attrs({className: 'sidebar-item-icon'})`
  height: var(--sidebar-width-collapsed);
  width: var(--sidebar-width-collapsed);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(var(--sidebar-width-collapsed) - 20px);
  transition: all 300ms;
`;

const Container = styled.div`
  ${ShadowStrongRight};
  --sidebar-width: 300px;
  --sidebar-width-collapsed: ${({theme}: SP) => theme.dimens.clickable};
  --sidebar-width-calc: var(--sidebar-overlap);
  overflow: hidden;
  z-index: 999;
  left: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  width: var(--sidebar-width-collapsed);
  transition: all 300ms;
  background-color: ${({theme}: SP) => theme.dark};

  .logo {
    height: 35px;
    transition: all 300ms;
    margin: 7px 0 7px 5px;
  }

  :hover {
    width: var(--sidebar-width);

    .logo {
      margin: 7px 0 7px 12px;
    }

    .sidebar-item-container {
      border-radius: ${({theme}: SP) => theme.dimens.radius3};
    }

    .sidebar-item-icon {
      margin: 0 16px 0 8px;
    }
  }
`;

const ContainerInner = styled.div`
  width: var(--sidebar-width);
  height: 100%;
  box-sizing: border-box;
  transition: all 300ms;
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: start;

  :hover {
    padding: 8px 4px 0 4px;
  }
`;

const SidebarItemContainer = styled.div.attrs({className: 'sidebar-item-container'})<{ selected: boolean }>`
  margin-bottom: 2px;
  cursor: pointer;
  display: flex;
  width: 100%;
  height: var(--sidebar-width-collapsed);
  --content-height: calc(var(--sidebar-width-collapsed) - 8px);
  ${({theme}: SP) => theme.textTheme.button.css};
  transition: all 100ms;
  background-color: ${({theme, selected}: SP) => selected ? theme.brighten1 : 'transparent'};

  :hover {
    background-color: ${({theme, selected}: SP) => selected ? theme.brighten1 : theme.brighten2};
  }
`;
