import {observer} from "mobx-react-lite";
import {createContext, useContext, useEffect, useState} from "react";
import {UploadStore} from "../stores/upload_store";
import Dropzone from 'react-dropzone'
import styled, {useTheme} from "styled-components";
import {SP, Theme, GridscopeColors} from "../theme";
import {MdUploadFile, MdCheck, MdClear, MdError} from "react-icons/md"
import useWave from "use-wave";
import LinearProgress from '@material/react-linear-progress';
import {sfiles, uploader} from "../proto/compiled";

const Container = styled.div`
  font-weight: bold;
  background-color: ${({theme}: SP) => theme.neutral};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  transition: all 300ms;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: 10px;
  font-weight: normal;
`;

const IconContainer = styled.div<{ done?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({done}: SP) => (done ?? true) ? 'auto' : '0'};
  margin-right: ${({done}: SP) => (done ?? true) ? '8px' : '0'};
  overflow: hidden;
  font-size: 24px;
  transition: all 300ms;
`;

const CancelButtonContainer = styled.div`
  position: absolute;
  cursor: pointer;
  right: 16px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinearProgressContainer = styled.div<{ done: boolean }>`
  --mdc-theme-primary: ${({theme, done}: SP) => done ? GridscopeColors.green : theme.primary};
  height: ${({done}: SP) => done ? '0' : 'auto'};
  margin-top: ${({done}: SP) => done ? '0' : '4px'};
  overflow: hidden;
  width: 400px;
  max-width: 80%;
  transition: all 300ms;
`;

export const UploadStoreContext = createContext<UploadStore | undefined>(undefined);
export const UploadArea = observer(({
                                        onUploadStart,
                                        onUploadDone,
                                        onCancel
                                    }: { onCancel?: () => any, onUploadStart?: (fingerprint: string) => any, onUploadDone?: (file: uploader.IUFile) => any }) => {
    const store = useContext(UploadStoreContext) as UploadStore;
    const theme = useTheme() as Theme;
    const wave = useWave();
    const [fingerprint, setFingerprint] = useState<string | undefined>();
    const file = store.files.get(fingerprint);
    const uFile = store.uFiles.get(fingerprint);
    const progress = store.progress.get(fingerprint);
    const started = !!store.started.get(fingerprint);
    const done = !!store.done.get(fingerprint);
    useEffect(() => {
        if (done && onUploadDone && uFile) {
            onUploadDone(uFile);
        }
    }, [done])
    const error = store.error.get(fingerprint);
    if (started || done) return <UFileUploadCard
        file={file}
        done={done}
        error={error}
        progress={progress}
        onCancel={() => {
            store.cancel(fingerprint!);
            setFingerprint(undefined);
            if (onCancel) {
                onCancel();
            }
        }}/>;
    return <Dropzone onDrop={acceptedFiles => {
        const fingerprint = store.uploadFile(acceptedFiles);
        if (fingerprint && onUploadStart) {
            onUploadStart(fingerprint);
        }
        setFingerprint(fingerprint ?? '');
    }}>
        {({getRootProps, getInputProps, isDragActive}) => (
            <Container ref={wave}>
                <div {...getRootProps()} style={{
                    position: "absolute",
                    left: '0',
                    right: '0',
                    top: '0',
                    bottom: '0',
                    zIndex: '100',
                    cursor: "pointer"
                }}>
                    <input {...getInputProps({
                        multiple: false,
                        accept: Array.from(store.extensions.values()).map((e) => '.' + e).join(',')
                    })} />
                </div>
                <MdUploadFile style={{marginRight: '8px'}}/>
                <LabelContainer>
                    <theme.textTheme.headline4.span style={{fontSize: '20px'}}>
                        Pick a file
                    </theme.textTheme.headline4.span>
                    <span>{Array.from(store.extensions.values()).join(', ')}</span>
                    <span>{uFile?.id}</span>
                </LabelContainer>
            </Container>
        )}
    </Dropzone>
});

export const UFileUploadCard = ({file, error, done, progress, onCancel}: {
    file?: uploader.IUFile,
    error?: string,
    done?: boolean,
    progress?: number,
    onCancel?: () => any,
}) => {
    const theme = useTheme() as Theme;
    const wave = useWave();

    return <Container style={{
        flexDirection: 'column',
        color: error ? theme.error : theme.content
    }}>
        <theme.textTheme.headline4.span style={{
            fontSize: error ? '10px' : '16px',
            margin: '0 32px 0 32px',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
        }}>
            <IconContainer done={done}>
                {!error && <MdCheck/>}
            </IconContainer>{error || file?.name}
        </theme.textTheme.headline4.span>
        <LinearProgressContainer done={done ?? true}>
            <LinearProgress progress={progress}/>
        </LinearProgressContainer>
        {onCancel && <CancelButtonContainer
            ref={wave}
            onClick={() => onCancel()}
            style={{
                position: 'absolute',
                right: '16px',
            }}>
            <MdClear/>
        </CancelButtonContainer>}
    </Container>;
};