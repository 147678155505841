import {observer} from "mobx-react-lite";
import styled from "styled-components";

export const ProjectSettings = observer(() => {
    return <Container>
    </Container>;
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
`;