import {observer} from "mobx-react-lite";
import {gridscope_data} from "../proto/compiled";
import styled, {useTheme} from "styled-components";
import {Card} from "./card";
import {SP, Theme} from "../theme";
import {DateFormatters} from "../utils";
import {CSSProperties, useContext, useEffect, useMemo} from "react";
import {GridscopeStoreContext} from "../app";
import {GridscopeCollapsible} from "./collapsible";
import {autorun, makeAutoObservable, ObservableSet} from "mobx";
import {DataFileRowInnerPadding, DataFileSummary} from "./data_file_summary";
import useWave from "use-wave";
import {MdAdd, MdCheck, MdDelete, MdEdit, MdExpand, MdExpandMore, MdFolder, MdOutlineFolder} from "react-icons/md";
import {GridscopeStaticTable} from "./table/gridscope_table";
import {Checkbox} from "./checkbox";
import {YesNoDialog} from "./dialogs";
import {GridscopeStore} from "../stores/gridscope_store";
import {IconButtonContainer} from "./buttons";
import {ClassicTextInput} from "./common_inputs/input";
import {proto} from "../proto/messages";

export const DataGroupSummary = observer(({
                                              group,
                                              style
                                          }: { group: gridscope_data.IDataFilesGroup, style?: CSSProperties }) => {
    const theme = useTheme() as Theme;
    const store = useContext(GridscopeStoreContext)!;
    const state = useMemo(() => {
        const s = {
            expanded: false,
            selected: new ObservableSet<string>(),
        };
        makeAutoObservable(s);
        return s;
    }, []);

    return <Container>
        <GroupSummaryHeader group={group} expanded={state.expanded} onClick={() => state.expanded = !state.expanded}/>
        <GridscopeCollapsible expanded={state.expanded}>
            <span style={{width: '100%', height: '1px', backgroundColor: theme.shade1}}/>
            {store.project.data?.ungroupedFiles && <GridscopeStaticTable
                cols={['', 'node ID', 'num of phases', 'series present', 'count', 'step (s)', 'start date time', 'end date time']}
                rows={group.dataFileIds!.map((id: string) => {
                    const df = store.global.dataFiles.get(id)!;
                    return [
                        <div style={{margin: '0 -4px 0 4px'}}><Checkbox checked={state.selected.has(id)}
                                                                        onChange={() => {
                                                                            state.selected.delete(id) || state.selected.add(id);
                                                                        }}/></div>,
                        df.name,
                        df.nPhases,
                        df.header?.join(', '),
                        df.count,
                        Math.round((df.interval!.milliseconds as number) / 100) / 10,
                        DateFormatters.dateTime(new Date(df.firstDate!.ts as number)),
                        DateFormatters.dateTime(new Date(df.lastDate!.ts as number)),
                    ];
                })}/>}
        </GridscopeCollapsible>
        <GridscopeCollapsible expanded={state.selected.size > 0}>
            <GridscopeToolbar selected={state.selected}/>
        </GridscopeCollapsible>
    </Container>;
});

const GridscopeToolbar = observer(({selected}: { selected: Set<string> }) => {
    const wave = useWave();
    const store = useContext(GridscopeStoreContext)!;

    return <GridscopeToolbarContainer ref={wave} onClick={() => {
        store.dialogs.push(<YesNoDialog result={(v) => {
            if (v) {
                store.deleteDataFiles(Array.from(selected)).then(() => selected.clear());
            }
            store.dialogs.pop();
        }}>
            Are you sure?
        </YesNoDialog>)
    }}>
        <MdDelete/>
    </GridscopeToolbarContainer>
});

const GridscopeToolbarContainer = styled.div`
  cursor: pointer;
  height: ${({theme}: SP) => theme.dimens.minClickable};
  width: 100%;
  box-sizing: border-box;
  background-color: ${({theme}: SP) => theme.error};
  color: ${({theme}: SP) => theme.neutral};
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0 8px 0 8px;
  margin-top: 4px;
`;

const GroupSummaryHeader = observer(({
                                         group,
                                         expanded,
                                         onClick
                                     }: { group: gridscope_data.IDataFilesGroup, expanded: boolean, onClick: () => any }) => {
    const theme = useTheme() as Theme;
    const wave = useWave();
    const store = useContext(GridscopeStoreContext) as GridscopeStore;
    const state = useMemo(() => {
        const s = {
            editing: false,
            label: group.label ?? '',
        };
        makeAutoObservable(s);
        return s;
    }, []);
    useEffect(() => autorun(() => group.label = state.label), []);

    const save = () => {
        state.editing = false;
        store.api.sendMessage(proto.TxSaveDataFilesGroup.create({
            id: group.id,
            label: state.label,
        }));
    };
    return <DataFileContainer onClick={() => onClick()}>
        <DataFileRow style={{backgroundColor: theme.primary, width: '230px', flexGrow: '0'}}>
            <DataFileRowInnerPadding>
                <DataFileLabel style={{color: theme.neutral}}>
                    <MdExpandMore style={{
                        fontSize: '24px',
                        marginRight: '8px',
                        transition: 'all 300ms',
                        transform: `rotate(${expanded ? '180' : '0'}deg)`
                    }}/>
                    <ClassicTextInput
                        disabled={!state.editing}
                        onSubmit={() => save()}
                        value={state.label} onChanged={(v) => state.label = v}
                        style={{...theme.textTheme.headline4.style(), color: 'white'}}
                    />
                    <IconButtonContainer ref={wave} onClick={(e) => {
                        if (state.editing) {
                            save();
                        } else {
                            state.editing = true;
                        }
                        e.stopPropagation();
                        e.preventDefault();
                    }}>
                        {state.editing && <MdCheck/>}
                        {!state.editing && <MdEdit/>}
                    </IconButtonContainer>
                </DataFileLabel>
            </DataFileRowInnerPadding>
        </DataFileRow>
        <DataFileRow>
            <DataFileRowInnerPadding>
                <DataFileLabel>data type</DataFileLabel>
                <DataFileValue>{group.dataType}</DataFileValue>
            </DataFileRowInnerPadding>
        </DataFileRow>
        <DataFileRow style={{flex: '2'}}>
            <DataFileRowInnerPadding>
                <DataFileLabel>
                    {group.dataType?.includes('AMI') ? 'element count' : 'row count'}
                </DataFileLabel>
                <DataFileValue>{group.count}</DataFileValue>
            </DataFileRowInnerPadding>
        </DataFileRow>
        <DataFileRow>
            <DataFileRowInnerPadding>
                <DataFileLabel>start date</DataFileLabel>
                <DataFileValue>{DateFormatters.dateTime(new Date(group.firstDate!.ts as number))}</DataFileValue>
            </DataFileRowInnerPadding>
        </DataFileRow>
        <DataFileRow>
            <DataFileRowInnerPadding>
                <DataFileLabel>end date</DataFileLabel>
                <DataFileValue>{DateFormatters.dateTime(new Date(group.lastDate!.ts as number))}</DataFileValue>
            </DataFileRowInnerPadding>
        </DataFileRow>
        <DataFileRow>
            <DataFileRowInnerPadding>
                <DataFileLabel>time step</DataFileLabel>
                <DataFileValue>{Math.round((group.interval!.milliseconds as number) / 100) / 10}s</DataFileValue>
            </DataFileRowInnerPadding>
        </DataFileRow>
        <div ref={wave} onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            store.dialogs.push(<YesNoDialog result={(v) => {
                if (v) {
                    store.deleteDataFiles(group.dataFileIds!);
                }
                store.dialogs.pop();
            }}>
                Are you sure?
            </YesNoDialog>)
        }} style={{
            display: 'flex',
            alignItems: 'center',
            borderLeft: 'solid 1px ' + theme.content10,
            padding: '0 16px 0 16px',
        }}>
            <MdDelete/>
        </div>
    </DataFileContainer>
});

const Container = styled(Card).attrs({clickable: false})`
  height: auto;
  flex-direction: column;
  box-shadow: none;
  border: solid 1px ${({theme}: SP) => theme.shade2};
  margin-bottom: 4px;
`;


const DataFileContainer = styled(Card).attrs({clickable: false})`
  height: auto;
  flex-direction: row;
  border-radius: 0;
  cursor: pointer;
  box-shadow: none;
  flex-shrink: 0;
`;

const DataFileRow = styled.div`
  display: flex;
  flex-grow: 1;
  width: 0;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
`;

const DataFileLabel = styled.span`
  ${({theme}: SP) => theme.textTheme.headline4.css};
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const DataFileValue = styled.span`
`;
