import styled, {useTheme} from "styled-components";
import {SP, Theme} from "../theme";
import {OverlayContainer} from "./overlays";
import {useEffect, useState} from "react";
import {findAndReplace} from 'dom-find-and-replace';
import {FlexCenter, Shadow, ShadowLight} from "../styles/gridscope";
import {GridscopeButton} from "./buttons";


interface YesNoDialogProps {
    yes?: any;
    no?: any;
    width?: string;
    result: (result: boolean) => any;
    children?: any;
}

export const YesNoDialog = (props: YesNoDialogProps) => {
    const [first, setFirst] = useState(true);
    useEffect(() => setFirst(false), []);
    const theme = useTheme() as Theme;

    return <OverlayContainer hidden={first}>
        <DialogContainer style={{padding: '0', width: props.width ?? '500px'}}>
            <DialogContent>
                {props.children}
            </DialogContent>
            <DialogFooter style={{height: '50px'}}>
                <GridscopeButton
                    small={true}
                    onClick={() => {
                        setFirst(true);
                        setTimeout(() => props.result(false), 100);
                    }}
                    style={{width: '180px', height: '30px', marginRight: '16px'}}
                >{props.no ?? 'NO'}</GridscopeButton>
                <GridscopeButton
                    small={true}
                    onClick={() => {
                        setFirst(true);
                        setTimeout(() => props.result(true), 100);
                    }}
                    style={{width: '180px', height: '30px',}}
                >{props.yes ?? 'YES'}</GridscopeButton>
            </DialogFooter>
        </DialogContainer>
    </OverlayContainer>;
};

export const DialogContent = styled.div`
  ${FlexCenter}
  ${({theme}: SP) => theme.textTheme.headline4.css};
  box-sizing: border-box;
  margin-top: auto;
  margin-bottom: auto;
  min-height: 100px;
  width: 100%;
  padding: 16px 50px 16px 50px;
`;

export const DialogFooter = styled.div`
  ${FlexCenter};
  ${Shadow};
  width: 100%;
`;

export const DialogContainer = styled.div<{ width?: string, height?: string }>`
  ${FlexCenter};
  ${ShadowLight};
  border-radius: ${({theme}: SP) => theme.dimens.radius1};
  background-color: ${({theme}: SP) => theme.neutral};
  position: relative;
  overflow: hidden;
  max-width: ${({width}) => width};
  max-height: ${({height}) => height};
  width: ${({width}) => width ? 'calc(100% - 32px)' : ''};
  height: ${({height}) => height ? 'calc(100% - 32px)' : ''};
  flex-direction: column;
`;