import {observer} from "mobx-react-lite";
import {useContext} from "react";
import {GridscopeStoreContext} from "../../../app";
import styled from "styled-components";
import {DataFilesTable} from "../../../components/table/data_files_table";
import {Card} from "../../../components/card";
import {SP} from "../../../theme";
import useWave from "use-wave";
import {gridscope_data, gridscope_view} from "../../../proto/compiled";
import {DateFormatters} from "../../../utils";

export const ProcessingView = observer(() => {
    const store = useContext(GridscopeStoreContext)!;
    return <Container>
        <TableContainer>
            {store.project.view.selectedId && <DataFilesTable
                style={{flexGrow: '1'}}
                files={store.project.view.dataFiles}/>}
        </TableContainer>
        <ResultsMenu/>
    </Container>;
});

const ResultsMenu = observer(() => {
    const store = useContext(GridscopeStoreContext)!;
    const wave = useWave();
    return <div style={{display: 'flex', flexDirection: 'column'}}><ResultsMenuContainer>
        <ResultsMenuHeader style={{marginTop: '0'}}>CALCULATED</ResultsMenuHeader>
        {store.project.view.results?.results?.map((r: gridscope_view.IProjectResult) => store.global.results.get(r.id!)!)
            .map((r: gridscope_view.IProjectResult, i) =>
                <ResultContainer
                    key={i}
                    ref={wave}
                    onClick={() => store.project.view.selectedId = r.id!}
                    processing={!r.dateCompleted?.ts}
                    selected={store.project.view.selectedId === r.id}>
                    <span>{r.name}</span>
                    <ResultDetails>{DateFormatters.dateTime(new Date(r.dateCreated!.ts as number))}</ResultDetails>
                </ResultContainer>)}
        <ResultsMenuHeader>IMPORTED</ResultsMenuHeader>
        {store.project.data?.groups?.map((r: gridscope_data.IDataFilesGroup, i) => <ResultContainer
            key={i}
            ref={wave}
            onClick={() => store.project.view.selectedId = r.id!}
            selected={store.project.view.selectedId === r.id}>
            <span>{r.label}</span>
            <ResultDetails>{r.dataFileIds?.length} nodes</ResultDetails>
        </ResultContainer>)}
        {store.project.data?.ungroupedFiles?.map((r: string) => <ResultContainer
            key={r}
            ref={wave}
            onClick={() => store.project.view.selectedId = r}
            selected={store.project.view.selectedId === r}>
            <span>{store.global.dataFiles.get(r)?.name}</span>
            <ResultDetails>{store.global.dataFiles.get(r)?.count} elements</ResultDetails>
        </ResultContainer>)}
    </ResultsMenuContainer></div>
});

const ResultContainer = styled.div<{ selected: boolean, processing?: boolean }>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 8px;
  border-bottom: solid 1px ${({theme}: SP) => theme.shade2};
  ${({theme}: SP) => theme.textTheme.button.css};
  color: ${({theme, selected, processing}: SP) => processing ? theme.shade1 : selected ? theme.primary : theme.content};
  border-left: ${({theme, selected}: SP) => (selected ? 'solid 5px ' : 'solid 0px ') + theme.primary};
  transition: all 300ms;
`;

const ResultDetails = styled.span`
  font-weight: normal;
  font-size: 12px;
`;

const ResultsMenuHeader = styled.div`
  margin-top: 8px;
  height: ${({theme}: SP) => theme.dimens.minClickable};
  ${({theme}: SP) => theme.textTheme.button.css};
  color: ${({theme}: SP) => theme.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}: SP) => theme.shade3};
`;

const ResultsMenuContainer = styled(Card).attrs({clickable: false})`
  width: 240px;
  height: 0;
  flex-grow: 1;
  margin: 0 32px 0 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-grow: 1;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 0;
  padding: 0 16px 0 32px;
`;
