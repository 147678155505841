import styled from "styled-components";
import {FlexCenter} from "../styles/gridscope";
import {SP} from "../theme";
import {CSSProperties} from "react";
import useWave from "use-wave";

export const GridscopeButton = styled.button<{ bgDark?: boolean, disabled?: boolean, small?: boolean }>`
  ${FlexCenter};
  cursor: pointer;
  border-radius: ${({theme}: SP) => theme.dimens.radius3};
  ${({theme, bgDark}: SP) => bgDark ? theme.neutral : theme.textTheme.button.css}
  border: none;
  outline: none;
  padding: 0 16px 0 16px;
  min-height: ${({theme, small}: SP) => small ? theme.dimens.minClickable : theme.dimens.clickable};
  background-color: ${({theme}: SP) => theme.primary};
  opacity: ${({disabled}: SP) => disabled ? '0.5' : '1'};
  transition: all 300ms;
`;

export const GridscopeFab = ({
                                 children,
                                 style,
                                 onClick
                             }: { children?: any, style?: CSSProperties, onClick: () => any }) => {
    const wave = useWave();
    return <FabContainer onClick={() => onClick()} ref={wave} style={style}>{children}</FabContainer>;
};

const FabContainer = styled.div`
  ${FlexCenter};
  font-size: 24px;
  color: ${({theme}: SP) => theme.neutral};
  background-color: ${({theme}: SP) => theme.primary};
  width: 60px;
  height: 60px;
  border-radius: 100px;
  box-shadow: 0 0 0.3em rgba(0, 45, 98, .3);
  position: fixed;
  bottom: 16px;
  right: 16px;
  cursor: pointer;
`;

export const IconButtonContainer = styled.div`
  ${FlexCenter};
  width: 30px;
  height: 30px;
  border-radius: 30px;
  cursor: pointer;
`;