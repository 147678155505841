import {observer} from "mobx-react-lite";
import styled, {useTheme} from "styled-components";
import {SP, Theme} from "../theme";
import {useContext, useEffect, useMemo, useRef} from "react";
import {GridscopeStoreContext} from "../app";
import {gridscope_project} from "../proto/compiled";
import {FlexCenter, NoScrollbar} from "../styles/gridscope";
import useWave from "use-wave";
import {useNavigate} from "react-router-dom";
import {GridscopeRoutes} from "../strings";
import {MdAdd, MdCheck, MdDelete, MdEdit} from "react-icons/md";
import {autorun, makeAutoObservable} from "mobx";
import {GridscopeStore} from "../stores/gridscope_store";
import {proto} from "../proto/messages";
import {YesNoDialog} from "./dialogs";
import {IconButtonContainer} from "./buttons";
import {ClassicTextInput} from "./common_inputs/input";

export const Bottombar = observer(() => {
    const store = useContext(GridscopeStoreContext) as GridscopeStore;
    const wave = useWave();
    const theme = useTheme() as Theme;

    return <Container>
        <ContainerInner>
            {store?.uiState?.recentProjects?.map((p, i) => <Item project={p} key={i}/>)}
        </ContainerInner>
        <div onClick={() => {
            store.api.sendMessage(proto.TxCreateProject.create());
        }} ref={wave} style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '50px',
            fontSize: '24px',
            color: theme.primary,
            backgroundColor: theme.content10,
            cursor: 'pointer',
        }}>
            <MdAdd/>
        </div>
    </Container>
});

const Item = observer(({project}: { project: gridscope_project.IProject }) => {
    const store = useContext(GridscopeStoreContext) as GridscopeStore;
    const wave = useWave();
    const navigate = useNavigate();
    const selected = store?.project?.key === project.projectKey;
    const ref = useRef<any>();
    useEffect(() => {
        if (selected) {
            ref.current?.scrollIntoView();
        }
    }, [selected, ref.current]);
    const state = useMemo(() => {
        const s = {
            editing: false,
            value: project.name ?? ''
        };
        makeAutoObservable(s);
        return s
    }, []);

    useEffect(() => autorun(() => project.name = state.value), []);

    const theme = useTheme() as Theme;
    const save = () => {
        state.editing = false;
        store.api.sendMessage(proto.TxSaveProject.create({
            projectKey: store.project.key,
            name: state.value,
        }))
    };

    return <ProjectContainer
        selected={selected}
        ref={ref}
        onClick={selected ? undefined : () => navigate(GridscopeRoutes.project.processing(project.projectKey!))}>
        <span style={{opacity: '0', minWidth: '30px'}}>
            {state.value?.replaceAll(/\s/g, '\u00a0')}
        </span>
        <ClassicTextInput
            style={{
                position: 'absolute',
                right: selected ? '50px' : '8px',
                left: '8px',
                width: 'auto', ...theme.textTheme.button.style()
            }}
            onSubmit={() => save()}
            disabled={!state.editing} value={state.value}
            onChanged={(v) => state.value = v}/>
        <ProjectContainerIcons style={{opacity: selected ? 1 : 0, pointerEvents: selected ? 'auto' : 'none'}}>
            <IconButtonContainer style={{marginRight: '-6px'}} ref={wave} onClick={() => {
                if (state.editing) {
                    save();
                } else {
                    state.editing = true;
                }
            }}>
                {state.editing && <MdCheck/>}
                {!state.editing && <MdEdit/>}
            </IconButtonContainer>
            <IconButtonContainer style={{marginLeft: '-6px'}} ref={wave} onClick={() => {
                store.dialogs.push(<YesNoDialog result={(v) => {
                    if (v) {
                        store.api.sendMessage(proto.TxArchiveProject.create({
                            projectKey: store.project.key
                        }));
                        navigate(GridscopeRoutes.index);
                    }
                    store.dialogs.pop();
                }}>
                    Are you sure you want to delete project "{project.name}"?
                </YesNoDialog>)
            }}>
                <MdDelete/>
            </IconButtonContainer>
        </ProjectContainerIcons>
    </ProjectContainer>;
});

const Container = styled.div`
  padding-left: 6px;
  display: flex;
  justify-content: start;
  align-items: stretch;
  position: fixed;
  bottom: 0;
  left: ${({theme}: SP) => theme.dimens.clickable};
  right: 0;
  height: 50px;
  background-color: ${({theme}: SP) => theme.dark};
  z-index: 800;
`;

const ContainerInner = styled.div`
  ${NoScrollbar};
  display: flex;
  justify-content: start;
  align-items: stretch;
  width: 0;
  flex-grow: 1;
`;

const ProjectContainer = styled.div<{ selected: boolean }>`
  ${FlexCenter};
  position: relative;
  cursor: pointer;
  margin: 6px 6px 6px 0;
  padding: 0 ${({theme, selected}: SP) => selected ? '50px' : '8px'} 0 8px;
  background-color: ${({theme, selected}: SP) => selected ? theme.primary : 'transparent'};
  border: solid 1px ${({theme, selected}: SP) => selected ? 'transparent' : theme.content};
  border-radius: 5px;
  ${({theme}: SP) => theme.textTheme.button.css};
  transition: all 300ms;
`;

const ProjectContainerIcons = styled.div`
  ${FlexCenter};
  position: absolute;
  right: 4px;
  top: 0;
  bottom: 0;
  color: white;
  font-size: 15px;
  transition: all 300ms;
`;