import styled, {css} from "styled-components";

export interface Theme {
    primary: string;
    primaryShade: string;
    shadow1: string;
    shadow2: string;
    shade1: string;
    shade2: string;
    shade3: string;
    shade4: string;
    neutral: string;
    content: string;
    content5: string;
    content10: string;
    brighten1: string;
    brighten2: string;
    dark: string;
    error: string;
    textTheme: TextTheme,
    dimens: Dimens,
}

export interface Dimens {
    clickable: string;
    minClickable: string;
    radius1: string,
    radius2: string,
    radius3: string,
    radius4: string,
}

export interface TextTheme {
    headline1: TextStyle;
    headline2: TextStyle;
    headline3: TextStyle;
    headline4: TextStyle;
    headline5: TextStyle;
    headline6: TextStyle;
    indicator: TextStyle;
    label: TextStyle;
    body: TextStyle;
    error: TextStyle;
    input: TextStyle;
    button: TextStyle;
}

interface TextStyleData {
    size?: string;
    weight?: string;
    height?: string;
    color?: string;
}

export class TextStyle {
    _overrides: { [k: string]: TextStyle } = {};

    _overridesKey(overrides: TextStyleData): string {
        let value = '';
        for (const style of Object.values(overrides)) {
            value += style;
        }
        return value;
    }

    data: TextStyleData;
    span: any;

    constructor(data: TextStyleData) {
        this.data = data;
        this.span = styled.span`
          ${() => this.css}
        `;
    }

    with(overrides: TextStyleData): TextStyle {
        const key = this._overridesKey(overrides);
        if (!this._overrides[key]) {
            this._overrides[key] = new TextStyle(
                {
                    ...this.data,
                    ...overrides
                }
            );
        }
        return this._overrides[key];
    }

    style(theme?: Theme) {
        return {
            color: this.data.color,
            fontSize: this.data.size || theme?.textTheme.body.data.size,
            fontWeight: this.data.weight || theme?.textTheme.body.data.weight,
            lineHeight: this.data.height || theme?.textTheme.body.data.height,
        }
    }

    get css() {
        return ({theme}: { theme: Theme }) => css`
          color: ${this.data.color};
          font-size: ${this.data.size || theme.textTheme.body.data.size};
          font-weight: ${this.data.weight || theme.textTheme.body.data.weight};
          line-height: ${this.data.height || theme.textTheme.body.data.height};
        `;
    }
}

export abstract class GridscopeColors {
    static primary = '#EBB318';
    static shadow1 = 'rgba(0,0,0,0.5)';
    static shadow2 = 'rgba(86,86,86,0.3)';
    static shade1 = '#dadada';
    static shade2 = '#ededed';
    static shade3 = '#f6f6f6';
    static shade4 = '#fafafa';
    static neutral = '#FFFFFF';
    static dark = '#333333';
    static content = '#565656';
    static content5 = 'rgba(86,86,86,0.05)';
    static content10 = 'rgba(86,86,86,0.10)';
    static content50 = 'rgba(86,86,86,0.50)';
    static error = 'rgba(255,0,29,1.0)';
    static textTheme = '#E5EAEF';
    static green = '#6FCF97';
    static yellow = '#F2C94C';
    static gray = '#BDBDBD';
    static brighten2 = 'rgba(255, 255, 255, 0.05)';
    static brighten1 = 'rgba(255, 255, 255, 0.1)';
}

export interface SP {
    theme: Theme;

    [x: string]: any;
}

export const GridscopeTheme: Theme = {
    primary: GridscopeColors.primary,
    primaryShade: GridscopeColors.content,
    shadow1: GridscopeColors.shadow1,
    shadow2: GridscopeColors.shadow2,
    shade1: GridscopeColors.shade1,
    shade2: GridscopeColors.shade2,
    shade3: GridscopeColors.shade3,
    shade4: GridscopeColors.shade4,
    neutral: GridscopeColors.neutral,
    content: GridscopeColors.content,
    brighten1: GridscopeColors.brighten1,
    brighten2: GridscopeColors.brighten2,
    dark: GridscopeColors.dark,
    content5: GridscopeColors.content5,
    content10: GridscopeColors.content10,
    error: GridscopeColors.error,
    dimens: {
        clickable: '45px',
        minClickable: '30px',
        radius1: '15px',
        radius2: '10px',
        radius3: '5px',
        radius4: '3px',
    },
    textTheme: {
        headline1: new TextStyle({
            size: '60px',
            weight: 'bold',
        }),
        headline2: new TextStyle({
            size: '40px',
            weight: '300',
        }),
        headline3: new TextStyle({
            size: '30px',
            weight: '400'
        }),
        headline4: new TextStyle({
            size: '15px',
            weight: 'bold'
        }),
        headline5: new TextStyle({
            size: '12px',
            weight: '700'
        }),
        headline6: new TextStyle({
            size: '15px',
            weight: 'bold'
        }),
        indicator: new TextStyle({
            size: '12px',
            weight: 'bold'
        }),
        label: new TextStyle({
            size: '12px',
            weight: '600',
        }),
        body: new TextStyle({
            size: '12px',
        }),
        error: new TextStyle({
            size: '16px',
            color: GridscopeColors.error
        }),
        input: new TextStyle({
            size: '16px',
            height: '1.2',
        }),
        button: new TextStyle({
            size: '16px',
            weight: 'bold',
            color: GridscopeColors.neutral,
        })
    },
};
