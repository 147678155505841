import {makeAutoObservable} from "mobx";
import {GridscopeStore} from "./gridscope_store";
import {proto} from "../proto/messages";
import {InputsStore} from "./inputs_store";
import {first, Subscription} from "rxjs";
import {gridscope_processing, inputs} from "../proto/compiled";
import {toast} from "react-toastify";
import {SocketApiAckStatus} from "proto_socket_typescript";
import {GridscopeTypes} from "../gridscope_types";

export class GridscopeProjectProcessingStore {
    private disposeCallbacks: (() => any)[] = [];
    base: GridscopeStore;
    inputsStore: InputsStore;
    module?: gridscope_processing.ProcessingModule;
    modules?: gridscope_processing.IProcessingModule[];
    output = inputs.Input.create({
        type: inputs.InputType.text,
        label: 'Output name',
        value: inputs.InputValue.create({}),
    })

    constructor(base: GridscopeStore) {
        this.base = base;
        makeAutoObservable(this);
        GridscopeTypes.makeInputObservable(this.output);
        this.addSub(this.base.api.getMessageHandler(new proto.RxProcessingModules()).subscribe((m) => this.onModules(m)))
        this.addSub(this.base.api.getMessageHandler(new proto.RxProcessingModule()).subscribe((m) => this.onModule(m)))
        this.addSub(this.base.api.getMessageHandler(new proto.RxProcessingSelectOptions()).subscribe((m) => this.onSelectOptions(m)))
        this.inputsStore = new InputsStore(this.base.api, (k, s) => this.loadSelectOptions(k, s));
    }

    private loadSelectOptions(k: string, s: string) {
        if (!this.module?.key) return;
        this.base.api.sendMessage(proto.TxLoadProcessingSelectOptions.create({
            projectKey: this.base.project.key,
            moduleKey: this.module.key,
            key: k,
            search: s,
        }));
    }

    private addSub(subscription: Subscription) {
        this.disposeCallbacks.push(() => subscription.unsubscribe());
    }

    dispose() {
        this.disposeCallbacks.map((dc) => dc());
        this.inputsStore.dispose();
    }

    private onModules(m: proto.RxProcessingModules) {
        this.modules = m.proto.modules;
        if (!this.module && this.modules?.length) this.loadModule(this.modules[0]);
    }

    private onModule(m: proto.RxProcessingModule) {
        this.module = m.proto;
        this.inputsStore.clear();
        this.output.value!.text = this.module.name;
    }

    async saveModule() {
        if (!this.module?.key || !this.base.project.measurement?.id) return;
        const response = await this.base.api.sendMessage(proto.TxSaveProcessingModule.create({
            key: this.module.key,
            projectKey: this.base.project.key,
            configuration: this.module.configuration,
        }), {ack: true});
        if (response.status !== SocketApiAckStatus.success) {
            toast.error(response.errorMessage ?? 'Neznana napaka');
            return;
        }
    }

    async loadModule(m: gridscope_processing.IProcessingModule) {
        if (this.module?.key === m.key) return;
        const response = await this.base.api.sendMessage(proto.TxLoadProcessingModule.create({
            moduleKey: m.key,
            projectKey: this.base.project.key
        }), {ack: true});
        if (response.status !== SocketApiAckStatus.success) {
            toast.error(response.errorMessage ?? 'Neznana napaka');
            return;
        }
    }

    private onSelectOptions(m: proto.RxProcessingSelectOptions) {
        this.inputsStore?.onSelectOptions(m.proto.options!);
    }

    async execute() {
        if (!this.module?.key) return false;
        this.base.api.getMessageHandler(new proto.RxProjectResult()).pipe(first()).subscribe((m) => this.base.project.view.selectedId = m.proto.id);
        const response = await this.base.api.sendMessage(proto.TxExecuteModule.create({
            moduleKey: this.module.key,
            projectKey: this.base.project.key,
            configuration: this.module.configuration,
            outputName: this.output.value!.text!,
        }), {ack: true, timeoutMs: 3600 * 60 * 1000});

        if (response.status !== SocketApiAckStatus.success) {
            toast.error(response.errorMessage ?? 'Neznana napaka');
            return false;
        }
        return true;
    }
}
