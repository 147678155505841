import {autorun, makeAutoObservable} from "mobx";
import {GridscopeStore} from "./gridscope_store";
import {proto} from "../proto/messages";
import {Subscription} from "rxjs";
import {gridscope_view} from "../proto/compiled";

export class GridscopeProjectViewStore {
    private disposeCallbacks: (() => any)[] = [];
    base: GridscopeStore;
    selectedId?: string;
    results?: gridscope_view.IProjectResults;

    constructor(base: GridscopeStore) {
        this.base = base;
        makeAutoObservable(this);
        this.addSub(this.base.api.getMessageHandler(new proto.RxProjectResults()).subscribe((m) => this.onProjectResults(m)))
        this.addSub(this.base.api.getMessageHandler(new proto.RxProjectResult()).subscribe((m) => this.onProjectResult(m)))
        this.disposeCallbacks.push(autorun(() => {
            this.load();
        }));
    }

    load() {
        this.base.api.sendMessage(proto.TxLoadProjectResults.create({projectKey: this.base.project?.key}));
    }

    get dataFiles() {
        const df = this.base.global.dataFiles.get(this.selectedId ?? '');
        if (df) {
            return [df];
        }
        return this.base.global.results.get(this.selectedId ?? '')?.results
            ?? this.base.global.dataFilesGroups.get(this.selectedId ?? '')?.dataFileIds?.map((id: string) => this.base.global.dataFiles.get(id)!);
    }

    private addSub(subscription: Subscription) {
        this.disposeCallbacks.push(() => subscription.unsubscribe());
    }

    dispose() {
        this.disposeCallbacks.map((dc) => dc());
    }

    private onProjectResult(m: proto.RxProjectResult) {
        if (!this.base.global.results.has(m.proto.id)) {
            this.results?.results?.unshift(m.proto);
        }
        this.base.global.results.set(m.proto.id, m.proto);
    }

    private onProjectResults(m: proto.RxProjectResults) {
        this.results = m.proto;
        this.results.results?.forEach((r: any) => this.base.global.results.set(r.id, r));
        this.selectedId = (this.results?.results && this.results.results.find((r: any) => r.dateCompleted?.ts)?.id) ?? undefined;
        makeAutoObservable(this.results);
    }
}
