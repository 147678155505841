import styled from "styled-components";
import {GridscopeInputContainerCss} from "./text_input";
import useWave from "use-wave";
import {MdCheckBox, MdCheckBoxOutlineBlank} from "react-icons/md";
import {SP} from "../../theme";


const Container = styled.div.attrs({tabIndex: 0})`
  ${GridscopeInputContainerCss};
  display: flex;
  height: auto;
  justify-content: start;
  align-items: center;
  padding: 4px 16px 4px 8px;
  cursor: pointer;
  color: ${({theme}: SP) => theme.primary};
  font-size: 22px;

  :focus {
    box-shadow: 0 0 0.3em rgba(0, 45, 98, .3);
  }
`;

const Label = styled.div`
  margin-left: 8px;
  ${({theme}: SP) => theme.textTheme.input.css};
  font-weight: 500;
  width: 0;
  flex-grow: 1;
`;

interface GridscopeBoolInputProps {
    value: boolean;
    label: string;
    onChange?: (v: boolean) => any;
}

export const GridscopeBoolInput = (props: GridscopeBoolInputProps) => {
    const wave = useWave();
    return <Container
        onKeyPress={(e) => props.onChange && props.onChange(!props.value)}
        ref={wave}
        onClick={(e) => props.onChange && props.onChange(!props.value)}>
        {props.value ? <MdCheckBox/> : <MdCheckBoxOutlineBlank/>}
        <Label>{props.label}</Label>
    </Container>
};
