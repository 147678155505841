import {gridscope_data, gridscope, uploader, gridscope_dashboard, gridscope_global, authentication, sfiles, gridscope_view, gridscope_project, inputs, socket_api, gridscope_processing} from "./compiled";
import {SocketRxMessage, SocketRxMessageData, SocketTxMessage} from "proto_socket_typescript";

export namespace proto {

export class TxLogin extends SocketTxMessage<authentication.Login> {
    static type: string = 'login';
    proto: authentication.Login;
    protoClass = authentication.Login;
    

    constructor(proto: authentication.Login) {
        super(TxLogin.type, true);
        this.proto = proto;
    }

    static create(properties: authentication.ILogin = {}) {
        return new TxLogin(authentication.Login.create(properties));
    }
}


export class RxLoginError extends SocketRxMessage<authentication.LoginError> {
    static type: string = 'login-error';
    proto = authentication.LoginError.create({});
    protoClass = authentication.LoginError;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxLoginError.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxLoginError(message);
    };
}


export class RxLoginToken extends SocketRxMessage<authentication.LoginToken> {
    static type: string = 'login-token';
    proto = authentication.LoginToken.create({});
    protoClass = authentication.LoginToken;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxLoginToken.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxLoginToken(message);
    };
}


export class RxTokenInvalid extends SocketRxMessage<authentication.TokenInvalid> {
    static type: string = 'token-invalid';
    proto = authentication.TokenInvalid.create({});
    protoClass = authentication.TokenInvalid;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxTokenInvalid.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxTokenInvalid(message);
    };
}


export class TxVerifyToken extends SocketTxMessage<authentication.VerifyToken> {
    static type: string = 'verify-token';
    proto: authentication.VerifyToken;
    protoClass = authentication.VerifyToken;
    

    constructor(proto: authentication.VerifyToken) {
        super(TxVerifyToken.type, true);
        this.proto = proto;
    }

    static create(properties: authentication.IVerifyToken = {}) {
        return new TxVerifyToken(authentication.VerifyToken.create(properties));
    }
}


export class RxProfileData extends SocketRxMessage<gridscope.ProfileData> {
    static type: string = 'profile-data';
    proto = gridscope.ProfileData.create({});
    protoClass = gridscope.ProfileData;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxProfileData.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxProfileData(message);
    };
}


export class TxLoadProjects extends SocketTxMessage<gridscope_dashboard.LoadProjects> {
    static type: string = 'load-projects';
    proto: gridscope_dashboard.LoadProjects;
    protoClass = gridscope_dashboard.LoadProjects;
    

    constructor(proto: gridscope_dashboard.LoadProjects) {
        super(TxLoadProjects.type, true);
        this.proto = proto;
    }

    static create(properties: gridscope_dashboard.ILoadProjects = {}) {
        return new TxLoadProjects(gridscope_dashboard.LoadProjects.create(properties));
    }
}


export class RxProjects extends SocketRxMessage<gridscope_dashboard.Projects> {
    static type: string = 'projects';
    proto = gridscope_dashboard.Projects.create({});
    protoClass = gridscope_dashboard.Projects;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxProjects.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxProjects(message);
    };
}


export class TxCreateMeasurement extends SocketTxMessage<gridscope_data.CreateMeasurement> {
    static type: string = 'create-measurement';
    proto: gridscope_data.CreateMeasurement;
    protoClass = gridscope_data.CreateMeasurement;
    

    constructor(proto: gridscope_data.CreateMeasurement) {
        super(TxCreateMeasurement.type, true);
        this.proto = proto;
    }

    static create(properties: gridscope_data.ICreateMeasurement = {}) {
        return new TxCreateMeasurement(gridscope_data.CreateMeasurement.create(properties));
    }
}


export class RxData extends SocketRxMessage<gridscope_data.Data> {
    static type: string = 'data';
    proto = gridscope_data.Data.create({});
    protoClass = gridscope_data.Data;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxData.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxData(message);
    };
}


export class RxDataFile extends SocketRxMessage<gridscope_data.DataFile> {
    static type: string = 'data-file';
    proto = gridscope_data.DataFile.create({});
    protoClass = gridscope_data.DataFile;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxDataFile.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxDataFile(message);
    };
}


export class RxDataFilesGroup extends SocketRxMessage<gridscope_data.DataFilesGroup> {
    static type: string = 'data-files-group';
    proto = gridscope_data.DataFilesGroup.create({});
    protoClass = gridscope_data.DataFilesGroup;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxDataFilesGroup.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxDataFilesGroup(message);
    };
}


export class TxDeleteDataFiles extends SocketTxMessage<gridscope_data.DeleteDataFiles> {
    static type: string = 'delete-data-files';
    proto: gridscope_data.DeleteDataFiles;
    protoClass = gridscope_data.DeleteDataFiles;
    

    constructor(proto: gridscope_data.DeleteDataFiles) {
        super(TxDeleteDataFiles.type, true);
        this.proto = proto;
    }

    static create(properties: gridscope_data.IDeleteDataFiles = {}) {
        return new TxDeleteDataFiles(gridscope_data.DeleteDataFiles.create(properties));
    }
}


export class TxLoadData extends SocketTxMessage<gridscope_data.LoadData> {
    static type: string = 'load-data';
    proto: gridscope_data.LoadData;
    protoClass = gridscope_data.LoadData;
    

    constructor(proto: gridscope_data.LoadData) {
        super(TxLoadData.type, true);
        this.proto = proto;
    }

    static create(properties: gridscope_data.ILoadData = {}) {
        return new TxLoadData(gridscope_data.LoadData.create(properties));
    }
}


export class RxMeasurement extends SocketRxMessage<gridscope_data.Measurement> {
    static type: string = 'measurement';
    proto = gridscope_data.Measurement.create({});
    protoClass = gridscope_data.Measurement;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxMeasurement.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxMeasurement(message);
    };
}


export class TxSaveDataFile extends SocketTxMessage<gridscope_data.SaveDataFile> {
    static type: string = 'save-data-file';
    proto: gridscope_data.SaveDataFile;
    protoClass = gridscope_data.SaveDataFile;
    

    constructor(proto: gridscope_data.SaveDataFile) {
        super(TxSaveDataFile.type, true);
        this.proto = proto;
    }

    static create(properties: gridscope_data.ISaveDataFile = {}) {
        return new TxSaveDataFile(gridscope_data.SaveDataFile.create(properties));
    }
}


export class TxSaveDataFilesGroup extends SocketTxMessage<gridscope_data.SaveDataFilesGroup> {
    static type: string = 'save-data-files-group';
    proto: gridscope_data.SaveDataFilesGroup;
    protoClass = gridscope_data.SaveDataFilesGroup;
    

    constructor(proto: gridscope_data.SaveDataFilesGroup) {
        super(TxSaveDataFilesGroup.type, true);
        this.proto = proto;
    }

    static create(properties: gridscope_data.ISaveDataFilesGroup = {}) {
        return new TxSaveDataFilesGroup(gridscope_data.SaveDataFilesGroup.create(properties));
    }
}


export class RxUiState extends SocketRxMessage<gridscope_global.UiState> {
    static type: string = 'ui-state';
    proto = gridscope_global.UiState.create({});
    protoClass = gridscope_global.UiState;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUiState.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUiState(message);
    };
}


export class TxExecuteModule extends SocketTxMessage<gridscope_processing.ExecuteModule> {
    static type: string = 'execute-module';
    proto: gridscope_processing.ExecuteModule;
    protoClass = gridscope_processing.ExecuteModule;
    

    constructor(proto: gridscope_processing.ExecuteModule) {
        super(TxExecuteModule.type, true);
        this.proto = proto;
    }

    static create(properties: gridscope_processing.IExecuteModule = {}) {
        return new TxExecuteModule(gridscope_processing.ExecuteModule.create(properties));
    }
}


export class TxLoadProcessingModule extends SocketTxMessage<gridscope_processing.LoadProcessingModule> {
    static type: string = 'load-processing-module';
    proto: gridscope_processing.LoadProcessingModule;
    protoClass = gridscope_processing.LoadProcessingModule;
    

    constructor(proto: gridscope_processing.LoadProcessingModule) {
        super(TxLoadProcessingModule.type, true);
        this.proto = proto;
    }

    static create(properties: gridscope_processing.ILoadProcessingModule = {}) {
        return new TxLoadProcessingModule(gridscope_processing.LoadProcessingModule.create(properties));
    }
}


export class TxLoadProcessingModules extends SocketTxMessage<gridscope_processing.LoadProcessingModules> {
    static type: string = 'load-processing-modules';
    proto: gridscope_processing.LoadProcessingModules;
    protoClass = gridscope_processing.LoadProcessingModules;
    

    constructor(proto: gridscope_processing.LoadProcessingModules) {
        super(TxLoadProcessingModules.type, true);
        this.proto = proto;
    }

    static create(properties: gridscope_processing.ILoadProcessingModules = {}) {
        return new TxLoadProcessingModules(gridscope_processing.LoadProcessingModules.create(properties));
    }
}


export class TxLoadProcessingSelectOptions extends SocketTxMessage<gridscope_processing.LoadProcessingSelectOptions> {
    static type: string = 'load-processing-select-options';
    proto: gridscope_processing.LoadProcessingSelectOptions;
    protoClass = gridscope_processing.LoadProcessingSelectOptions;
    

    constructor(proto: gridscope_processing.LoadProcessingSelectOptions) {
        super(TxLoadProcessingSelectOptions.type, true);
        this.proto = proto;
    }

    static create(properties: gridscope_processing.ILoadProcessingSelectOptions = {}) {
        return new TxLoadProcessingSelectOptions(gridscope_processing.LoadProcessingSelectOptions.create(properties));
    }
}


export class RxProcessingModule extends SocketRxMessage<gridscope_processing.ProcessingModule> {
    static type: string = 'processing-module';
    proto = gridscope_processing.ProcessingModule.create({});
    protoClass = gridscope_processing.ProcessingModule;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxProcessingModule.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxProcessingModule(message);
    };
}


export class RxProcessingModules extends SocketRxMessage<gridscope_processing.ProcessingModules> {
    static type: string = 'processing-modules';
    proto = gridscope_processing.ProcessingModules.create({});
    protoClass = gridscope_processing.ProcessingModules;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxProcessingModules.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxProcessingModules(message);
    };
}


export class RxProcessingSelectOptions extends SocketRxMessage<gridscope_processing.ProcessingSelectOptions> {
    static type: string = 'processing-select-options';
    proto = gridscope_processing.ProcessingSelectOptions.create({});
    protoClass = gridscope_processing.ProcessingSelectOptions;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxProcessingSelectOptions.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxProcessingSelectOptions(message);
    };
}


export class TxSaveProcessingModule extends SocketTxMessage<gridscope_processing.SaveProcessingModule> {
    static type: string = 'save-processing-module';
    proto: gridscope_processing.SaveProcessingModule;
    protoClass = gridscope_processing.SaveProcessingModule;
    

    constructor(proto: gridscope_processing.SaveProcessingModule) {
        super(TxSaveProcessingModule.type, true);
        this.proto = proto;
    }

    static create(properties: gridscope_processing.ISaveProcessingModule = {}) {
        return new TxSaveProcessingModule(gridscope_processing.SaveProcessingModule.create(properties));
    }
}


export class TxArchiveProject extends SocketTxMessage<gridscope_project.ArchiveProject> {
    static type: string = 'archive-project';
    proto: gridscope_project.ArchiveProject;
    protoClass = gridscope_project.ArchiveProject;
    

    constructor(proto: gridscope_project.ArchiveProject) {
        super(TxArchiveProject.type, true);
        this.proto = proto;
    }

    static create(properties: gridscope_project.IArchiveProject = {}) {
        return new TxArchiveProject(gridscope_project.ArchiveProject.create(properties));
    }
}


export class TxCreateProject extends SocketTxMessage<gridscope_project.CreateProject> {
    static type: string = 'create-project';
    proto: gridscope_project.CreateProject;
    protoClass = gridscope_project.CreateProject;
    

    constructor(proto: gridscope_project.CreateProject) {
        super(TxCreateProject.type, true);
        this.proto = proto;
    }

    static create(properties: gridscope_project.ICreateProject = {}) {
        return new TxCreateProject(gridscope_project.CreateProject.create(properties));
    }
}


export class TxLoadProject extends SocketTxMessage<gridscope_project.LoadProject> {
    static type: string = 'load-project';
    proto: gridscope_project.LoadProject;
    protoClass = gridscope_project.LoadProject;
    

    constructor(proto: gridscope_project.LoadProject) {
        super(TxLoadProject.type, true);
        this.proto = proto;
    }

    static create(properties: gridscope_project.ILoadProject = {}) {
        return new TxLoadProject(gridscope_project.LoadProject.create(properties));
    }
}


export class RxProject extends SocketRxMessage<gridscope_project.Project> {
    static type: string = 'project';
    proto = gridscope_project.Project.create({});
    protoClass = gridscope_project.Project;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxProject.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxProject(message);
    };
}


export class TxSaveProject extends SocketTxMessage<gridscope_project.SaveProject> {
    static type: string = 'save-project';
    proto: gridscope_project.SaveProject;
    protoClass = gridscope_project.SaveProject;
    

    constructor(proto: gridscope_project.SaveProject) {
        super(TxSaveProject.type, true);
        this.proto = proto;
    }

    static create(properties: gridscope_project.ISaveProject = {}) {
        return new TxSaveProject(gridscope_project.SaveProject.create(properties));
    }
}


export class RxSetProject extends SocketRxMessage<gridscope_project.SetProject> {
    static type: string = 'set-project';
    proto = gridscope_project.SetProject.create({});
    protoClass = gridscope_project.SetProject;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxSetProject.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxSetProject(message);
    };
}


export class TxDeleteModuleResult extends SocketTxMessage<gridscope_view.DeleteModuleResult> {
    static type: string = 'delete-module-result';
    proto: gridscope_view.DeleteModuleResult;
    protoClass = gridscope_view.DeleteModuleResult;
    

    constructor(proto: gridscope_view.DeleteModuleResult) {
        super(TxDeleteModuleResult.type, true);
        this.proto = proto;
    }

    static create(properties: gridscope_view.IDeleteModuleResult = {}) {
        return new TxDeleteModuleResult(gridscope_view.DeleteModuleResult.create(properties));
    }
}


export class TxLoadProjectResults extends SocketTxMessage<gridscope_view.LoadProjectResults> {
    static type: string = 'load-project-results';
    proto: gridscope_view.LoadProjectResults;
    protoClass = gridscope_view.LoadProjectResults;
    

    constructor(proto: gridscope_view.LoadProjectResults) {
        super(TxLoadProjectResults.type, true);
        this.proto = proto;
    }

    static create(properties: gridscope_view.ILoadProjectResults = {}) {
        return new TxLoadProjectResults(gridscope_view.LoadProjectResults.create(properties));
    }
}


export class RxProjectResult extends SocketRxMessage<gridscope_view.ProjectResult> {
    static type: string = 'project-result';
    proto = gridscope_view.ProjectResult.create({});
    protoClass = gridscope_view.ProjectResult;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxProjectResult.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxProjectResult(message);
    };
}


export class RxProjectResults extends SocketRxMessage<gridscope_view.ProjectResults> {
    static type: string = 'project-results';
    proto = gridscope_view.ProjectResults.create({});
    protoClass = gridscope_view.ProjectResults;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxProjectResults.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxProjectResults(message);
    };
}


export class TxSaveModuleResult extends SocketTxMessage<gridscope_view.SaveModuleResult> {
    static type: string = 'save-module-result';
    proto: gridscope_view.SaveModuleResult;
    protoClass = gridscope_view.SaveModuleResult;
    

    constructor(proto: gridscope_view.SaveModuleResult) {
        super(TxSaveModuleResult.type, true);
        this.proto = proto;
    }

    static create(properties: gridscope_view.ISaveModuleResult = {}) {
        return new TxSaveModuleResult(gridscope_view.SaveModuleResult.create(properties));
    }
}


export class TxLoadSelectOptions extends SocketTxMessage<inputs.LoadSelectOptions> {
    static type: string = 'load-select-options';
    proto: inputs.LoadSelectOptions;
    protoClass = inputs.LoadSelectOptions;
    

    constructor(proto: inputs.LoadSelectOptions) {
        super(TxLoadSelectOptions.type, true);
        this.proto = proto;
    }

    static create(properties: inputs.ILoadSelectOptions = {}) {
        return new TxLoadSelectOptions(inputs.LoadSelectOptions.create(properties));
    }
}


export class RxSelectOptions extends SocketRxMessage<inputs.SelectOptions> {
    static type: string = 'select-options';
    proto = inputs.SelectOptions.create({});
    protoClass = inputs.SelectOptions;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxSelectOptions.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxSelectOptions(message);
    };
}


export class TxDeleteFile extends SocketTxMessage<sfiles.DeleteFile> {
    static type: string = 'delete-file';
    proto: sfiles.DeleteFile;
    protoClass = sfiles.DeleteFile;
    

    constructor(proto: sfiles.DeleteFile) {
        super(TxDeleteFile.type, true);
        this.proto = proto;
    }

    static create(properties: sfiles.IDeleteFile = {}) {
        return new TxDeleteFile(sfiles.DeleteFile.create(properties));
    }
}


export class RxUploadDone extends SocketRxMessage<sfiles.UploadDone> {
    static type: string = 'upload-done';
    proto = sfiles.UploadDone.create({});
    protoClass = sfiles.UploadDone;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadDone.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadDone(message);
    };
}


export class TxUploadEnd extends SocketTxMessage<sfiles.UploadEnd> {
    static type: string = 'upload-end';
    proto: sfiles.UploadEnd;
    protoClass = sfiles.UploadEnd;
    

    constructor(proto: sfiles.UploadEnd) {
        super(TxUploadEnd.type, true);
        this.proto = proto;
    }

    static create(properties: sfiles.IUploadEnd = {}) {
        return new TxUploadEnd(sfiles.UploadEnd.create(properties));
    }
}


export class RxUploadProgress extends SocketRxMessage<sfiles.UploadProgress> {
    static type: string = 'upload-progress';
    proto = sfiles.UploadProgress.create({});
    protoClass = sfiles.UploadProgress;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadProgress.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadProgress(message);
    };
}


export class TxUploadStart extends SocketTxMessage<sfiles.UploadStart> {
    static type: string = 'upload-start';
    proto: sfiles.UploadStart;
    protoClass = sfiles.UploadStart;
    

    constructor(proto: sfiles.UploadStart) {
        super(TxUploadStart.type, true);
        this.proto = proto;
    }

    static create(properties: sfiles.IUploadStart = {}) {
        return new TxUploadStart(sfiles.UploadStart.create(properties));
    }
}


export class RxUploadStartSlot extends SocketRxMessage<sfiles.UploadStartSlot> {
    static type: string = 'upload-start';
    proto = sfiles.UploadStartSlot.create({});
    protoClass = sfiles.UploadStartSlot;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadStartSlot.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadStartSlot(message);
    };
}


export class RxAck extends SocketRxMessage<socket_api.Ack> {
    static type: string = 'ack';
    proto = socket_api.Ack.create({});
    protoClass = socket_api.Ack;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAck.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAck(message);
    };
}


export class RxAsyncProgress extends SocketRxMessage<socket_api.AsyncProgress> {
    static type: string = 'async-progress';
    proto = socket_api.AsyncProgress.create({});
    protoClass = socket_api.AsyncProgress;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAsyncProgress.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAsyncProgress(message);
    };
}


export class RxUpgradeApiVersion extends SocketRxMessage<socket_api.UpgradeApiVersion> {
    static type: string = 'upgrade-api-version';
    proto = socket_api.UpgradeApiVersion.create({});
    protoClass = socket_api.UpgradeApiVersion;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUpgradeApiVersion.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUpgradeApiVersion(message);
    };
}


export class TxDeleteUFile extends SocketTxMessage<uploader.DeleteUFile> {
    static type: string = 'delete-ufile';
    proto: uploader.DeleteUFile;
    protoClass = uploader.DeleteUFile;
    

    constructor(proto: uploader.DeleteUFile) {
        super(TxDeleteUFile.type, true);
        this.proto = proto;
    }

    static create(properties: uploader.IDeleteUFile = {}) {
        return new TxDeleteUFile(uploader.DeleteUFile.create(properties));
    }
}


export class RxUploadSlot extends SocketRxMessage<uploader.UploadSlot> {
    static type: string = 'upload-slot';
    proto = uploader.UploadSlot.create({});
    protoClass = uploader.UploadSlot;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadSlot.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadSlot(message);
    };
}


export class RxUploadTask extends SocketRxMessage<uploader.UploadTask> {
    static type: string = 'upload-task';
    proto = uploader.UploadTask.create({});
    protoClass = uploader.UploadTask;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadTask.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadTask(message);
    };
}


export class TxUploadUFile extends SocketTxMessage<uploader.UploadUFile> {
    static type: string = 'upload-ufile';
    proto: uploader.UploadUFile;
    protoClass = uploader.UploadUFile;
    

    constructor(proto: uploader.UploadUFile) {
        super(TxUploadUFile.type, true);
        this.proto = proto;
    }

    static create(properties: uploader.IUploadUFile = {}) {
        return new TxUploadUFile(uploader.UploadUFile.create(properties));
    }
}


        export const rxMessages: SocketRxMessage<any>[] = [
            new RxLoginError(),
    new RxLoginToken(),
    new RxTokenInvalid(),
    new RxProfileData(),
    new RxProjects(),
    new RxData(),
    new RxDataFile(),
    new RxDataFilesGroup(),
    new RxMeasurement(),
    new RxUiState(),
    new RxProcessingModule(),
    new RxProcessingModules(),
    new RxProcessingSelectOptions(),
    new RxProject(),
    new RxSetProject(),
    new RxProjectResult(),
    new RxProjectResults(),
    new RxSelectOptions(),
    new RxUploadDone(),
    new RxUploadProgress(),
    new RxUploadStartSlot(),
    new RxAck(),
    new RxAsyncProgress(),
    new RxUpgradeApiVersion(),
    new RxUploadSlot(),
    new RxUploadTask()
        ];
}